<template>
	<div class="drawer-container">
		<aside id="mdc-drawer" class="mdc-drawer mdc-drawer--dismissible"
			   data-mdc-auto-init="MDCDrawer">
			<div v-show="drawerOpen" class="mdc-drawer__content">
				<nav class="drawer-nav-main mdc-list">
					<DrawerItems
						:items="visibleDrawerItemsMain"
						:hidden="visibleDrawerItemsMain.length === 0"
						:selectedId="selectedId"
						@drawer-click="clickHandler"
					/>
				</nav>
				<nav class="drawer-nav-footer mdc-list">
					<DrawerItems
						:items="visibleDrawerItemsFooter"
						:hidden="visibleDrawerItemsFooter.length === 0"
						:selectedId="selectedId"
						@drawer-click="clickHandler"
					/>
				</nav>
			</div>
		</aside>
		<div class="mdc-drawer-app-content">
			<main id="main-content" class="main-content mdc-typography">
				<div class="content-area">
					<div :class="{'max-width-area': limitContentWidth === true}">
						<slot>
							<!-- DEFAULT DRAWER CONTENT -->
						</slot>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>

import autoInit from '@material/auto-init'

import DrawerItems from './DrawerItems'

export default {
	components: {
		DrawerItems,
	},
	props: [
		'drawerOpen',
		'drawerItems',
		'navigate',
		'limitContentWidth'
	],
	data () {
		return {
			navMainEl: undefined,
			navFooterEl: undefined,
			contentEl: undefined,
			drawEl: undefined,
			draw: undefined,
			initialHeight: undefined,

			footerInline: false,
		}
	},
	watch: {
		drawerOpen () {
			// console.debug('drawer: drawerOpen value changed to', this.drawerOpen)
			this.draw.open = this.drawerOpen
		},
		visibleDrawerItemsMain () {
			this.$nextTick(this.fixHeight)
		},
		visibleDrawerItemsFooter () {
			this.$nextTick(this.fixHeight)
		},
	},
	computed: {
		selectedId () {
			if (this.$route.meta.selectedId !== undefined) {
				if (this.$route.meta.selectedId.startsWith(':')) {
					const paramsKey = this.$route.meta.selectedId.slice(1)
					if (this.$route.params[paramsKey] !== undefined) {
						return this.$route.params[paramsKey]
					}
				}
				return this.$route.meta.selectedId
			}
			return this.$route.name
		},
		visibleDrawerItemsMain () {
			if (this.footerInline) {
				return this.drawerItems.filter(item => {
					return !item.hidden
				})
			}
			return this.drawerItems.filter(item => {
				return !item.hidden && !item.footer
			})
		},
		visibleDrawerItemsFooter () {
			if (this.footerInline) {
				return []
			}
			return this.drawerItems.filter(item => {
				return item.footer && !item.hidden
			})
		},
	},
	mounted () {
		autoInit()
		this.navMainEl = this.$el.querySelector('.drawer-nav-main')
		this.navFooterEl = this.$el.querySelector('.drawer-nav-footer')
		this.contentEl = this.$el.querySelector('.main-content')
		this.drawEl = this.$el.querySelector('.mdc-drawer')
		this.draw = this.drawEl.MDCDrawer
		// console.debug('drawer: drawerOpen value starting at', this.drawerOpen)
		this.draw.open = this.drawerOpen
		this.$nextTick(this.setupResizeObserver)
	},
	updated () {
		this.draw.open = this.drawerOpen
	},
	methods: {
		clickHandler ([event, sender]) {
			// console.debug('drawer: clickHandler', event, sender)
			if (this.navigate !== true) {
				// console.debug('drawer: emit drawer-click')
				this.$emit('drawer-click', [event, sender, this])
			} else {
				console.debug('drawer: navigate to', sender.id)
				this.$router.push({name: sender.id})
			}
		},
		setupResizeObserver () {
			const resizeObserver = new ResizeObserver(() => {
				this.$nextTick(this.fixHeight)
			})
			resizeObserver.observe(document.body)
			window.addEventListener('resize', () => {
				this.$nextTick(this.fixFooter)
			})
			window.addEventListener('scroll', () => {
				this.$nextTick(this.fixFooter)
			})
			this.fixHeight()
		},
		fixHeight () {
			// we need to know our initial height - because this is the correct height (calculated by
			// the browser) for having the aside fill the viewport.
			let currentHeight = this.drawEl.clientHeight
			if (this.initialHeight === undefined) {
				this.initialHeight = currentHeight
			}

			// we assume that we desire to be our initial height (e.g. same as the viewport)
			let desiredHeight = this.initialHeight

			// if the content is taller than our assumption (i.e. scrolling is possible) then we
			// actually want to be as tall as the content (not the viewport)
			let contentHeight = this.contentEl.clientHeight
			if (contentHeight > desiredHeight) {
				desiredHeight = contentHeight
			}

			// if we now desire a change in height, change it ...
			if (desiredHeight !== currentHeight) {
				this.drawEl.style.height = desiredHeight + 'px'
			}

			this.fixFooter()
		},
		fixFooter () {
			// the footer has to be inlined if the height of the drawer is
			// taller than the available space in the viewport.
			let mainNavBottom = this.navMainEl.getBoundingClientRect().bottom
			let footerNavTop = this.navFooterEl.getBoundingClientRect().top
			if (mainNavBottom >= footerNavTop) {
				this.footerInline = true
			} else {
				this.footerInline = false
			}
		},
	},
}
</script>

<style lang="scss">

@import "../../node_modules/@material/drawer/dist/mdc.drawer.css";

.drawer-container {
	// TODO this should ideally be full height of the viewport

	// It is always full height of the content
	// This contains both the drawer and the content

	display: flex;
	flex: auto;

	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

// This is the box which contains the drawer menu
.mdc-drawer {
	// TODO this should ideally be full height of the viewport or content

	background-color: white;
	height: 100%;

}

// This is the content within the drawer menu.
.mdc-drawer__content {
	height: 100%;
}

.mdc-drawer__content .mdc-list-divider {
	padding-top: 1em;
}

.mdc-drawer__content .mdc-list-group__subheader {
	padding-bottom: 0.5em;
}

// This contains the content - but also contains a gutter under the menu
.mdc-drawer-app-content {
	//display: flex;
	//flex: auto;
	margin: 0;
	width: 100%;
	height: 100%;
	//min-height: 80vh;
	//padding: 0 0 0 1px;
}

// This is the box which contains the content.
.main-content {
	display: block;
	flex: auto;
	overflow-x: hidden; /* no side scrolling please */
	overflow-y: auto;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background-color: #fafafa;
}

// This is within main-content
.content-area {
	position: relative;
	display: block;
	height: auto;
	min-height: 75vh;
	padding: 1em 1em 0 1em; /* top, right, bottom, left */
	border: 0 dashed orange;
	overflow: hidden;
}

.max-width-area {
	margin: 0 auto;
	min-height: 100%;
	max-width: 1200px;
	//background-color: #fafafa;
}

.drawer-nav-footer {
	width: 256px;
	position: fixed;
	bottom: 0;
	padding-bottom: 2em;
}

</style>
