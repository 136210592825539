//
// State Sync/Publish/Subscribe plugin for Vuex.
//
// This is heavily integrated with the server side backend.
//
// This differs a little bit from PubSub because here we care about app state
//  rather than just messages. As such the messages are in of themselves just
//  notifications about state changes. So what we're subscribing to is a state
//  change.
//
// To use this you should call the 'subscribe' action for a specific topic.
// It is best to do this call in a Vue object creation event handler.
// Available topics are defined by the backend API.
//
// When you no longer need the data you should call 'unsubscribe'.
//
// The data will appear in the Vuex state and can be read in the normal way.
//
// This plugin only provides communication from the backend to the UI and does
//  not provide a way for the UI to change the data which is received. It is
//  expected that data specific code will be created for such actions, and
//  the results of those actions will be synchronised to the state
//  automatically by this plugin.
//
//
// @see https://en.wikipedia.org/wiki/Publish%E2%80%93subscribe_pattern
// @see https://vuex.vuejs.org/guide/plugins.html
// @see https://vuex.vuejs.org
//
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

/* ----------------------------------------------------------------------------

Interface:

Actions (Network)
 - syncConnect				Connects websocket to the server
 - syncDisconnect			Disconnects websocket from the server

Actions (Subscriptions/Topics)
 - subscribeTopic			Subscribes to a topic
 - resubscribeTopic			Reminds the server of a previously created topic subscription
 - unsubscribeTopic			Removes a previously created topic subscription

Actions (Subscriptions/Events)
 - subscribeEvents			Subscribes to the events stream
 - resubscribeEvents		Reminds the server of the subscription to send the events stream
 - unsubscribeEvents		Removes the subscription to the events stream

Actions (Watches)
 - watch					Watches for updates to a resource
 - rewatch					Reminds the server of a previously created watch
 - unwatch					Removes a previously created watch

Actions (RPC)
 - rpc                      Initiate a Remote Procedure Call request

 -----------------------------------------------------------------------------

Getters (Network)
 - sessionUUID				The uuid for the current client session
 - websocketConnected		Returns true if the websocket is connected
 - websocketFailure			Contains an error if there was a terminal failure

Getters (Subscriptions/Topics)
 - isSubscribed				True if the topic is subscribed
 - numberSubscriptions		Number of subscriptions for a given topic
 - allSubscriptions			Counts of all subscriptions for all topics

Getters (Subscriptions/Events)
 - isSubscribedToEvents		True if subscribed to the events stream
 - recentEvents				Received events (most recent first, max 100)

Getters (Watches)
 - isWatching				True if given resource is being watched
 - numberWatches			Number of watches for a given resource
 - allWatches				Counts of all watches for all resources

Getters (Resources)
 - allResources				Dump of all resource data
 - topicResources			Dump of all resource data for a given topic
 - topicCounts              Server side counts of resources for all topics

Getters (Time)
 - topicLastReceiveTime		Last time we received data for a given topic (if subscribed)
 - now						Current time (multiple formats)

 ---------------------------------------------------------------------------- */

// noinspection NpmUsedModulesInstalled
import io from 'socket.io-client'

import axios from 'axios'
import Vue from 'vue'

// Mutations
export const MUTATE_CONNECTED = 'MUTATE_CONNECTED'
export const MUTATE_FAILURE = 'MUTATE_FAILURE'
export const MUTATE_INCREMENT_TOPIC_SUBSCRIBERS = 'MUTATE_INCREMENT_TOPIC_SUBSCRIBERS'
export const MUTATE_DECREMENT_TOPIC_SUBSCRIBERS = 'MUTATE_DECREMENT_TOPIC_SUBSCRIBERS'
export const MUTATE_INCREMENT_EVENTS_SUBSCRIBERS = 'MUTATE_INCREMENT_EVENTS_SUBSCRIBERS'
export const MUTATE_DECREMENT_EVENTS_SUBSCRIBERS = 'MUTATE_DECREMENT_EVENTS_SUBSCRIBERS'
export const MUTATE_INCREMENT_WATCHERS = 'MUTATE_INCREMENT_WATCHERS'
export const MUTATE_DECREMENT_WATCHERS = 'MUTATE_DECREMENT_WATCHERS'
export const MUTATE_RESOURCES = 'MUTATE_RESOURCES'
export const MUTATE_COUNTS = 'MUTATE_COUNTS'
export const MUTATE_EVENTS = 'MUTATE_EVENTS'

// Synchronisation Endpoints
export const API_SYNC_AUTHENTICATE = '/api/sync/authenticate/'
export const API_SYNC_SUBSCRIBE_TOPIC = '/api/sync/subscribe/topic/'
export const API_SYNC_UNSUBSCRIBE_TOPIC = '/api/sync/unsubscribe/topic/'
export const API_SYNC_SUBSCRIBE_EVENTS = '/api/sync/subscribe/events/'
export const API_SYNC_UNSUBSCRIBE_EVENTS = '/api/sync/unsubscribe/events/'
export const API_SYNC_WATCH = '/api/sync/watch/'
export const API_SYNC_UNWATCH = '/api/sync/unwatch/'
export const API_SYNC_RECEIVE = '/api/sync/receive/'
export const API_SYNC_RPC = '/api/sync/rpc/'

// Vuex Plugin Factory
export default function createSyncPlugin () {
	return function syncPlugin (store) {

		console.debug('CacheFly Websocket Data Sync Vuex plugin initializing')

		const nowUnix = () => {
			return Math.floor((new Date()).getTime() / 1000)
		}
		const nowRfc = () => {
			return (new Date()).toISOString()
		}
		const rfcToUnix = (timestamp) => {
			if (isNaN(timestamp)) {
				return Math.round(new Date(timestamp).getTime() / 1000)
			}
			return timestamp
		}
		const unixToRfc = (timestamp) => {
			return new Date(timestamp * 1000).toISOString()
		}

		// uuid for this client session (to track sessions across reconnects)
		// noinspection JSUnresolvedFunction
		const uuid = window.self.crypto.randomUUID()

		// Callbacks which are triggered on websocket connect (after authentication)
		// or disconnect. Used to allow code to be reactive these events, either by
		// waiting for a connection, or by terminating ongoing promise (or similar)
		// when the connection fails.
		let connectCallbacks = []
		let disconnectCallbacks = []
		let isConnected = false

		//
		// This is where we create the socket.io client, and set up
		// the event handlers. This is done as a factory / callback
		// so that the client can be replaced on demand.
		//
		const socketFactory = ({
			                       url,
			                       prefix,
			                       identity,
			                       timeout, // connection timeout
		                       }) => {

			// Define the arguments for the authentication request
			// here, so that they can be modified before the request
			// is actually sent.
			let authentication = {
				'timestamp': nowRfc(),
				'session': store.getters.sessionUUID,
				'version': window.appVersion,
				'identity': identity,
				// NB. The token field is added later (below)
			}

			// Create the socket.io client
			const socket = io(url, {
				path: prefix + 'socket.io',
				reconnection: true,
				reconnectionAttempts: 2,
				timeout: timeout, // connection timeout
				autoConnect: false,
				query: {
					session: store.getters.sessionUUID,
				},
				transports: [
					'websocket',
				]
			})

			// Define the authentication callback function - uses the above
			// authentication and socket objects (via closure).
			const authenticate = () => {
				return new Promise((resolve, reject) => {

					if (url.endsWith('/') && prefix.startsWith('/')) {
						prefix = prefix.slice(1) // strip leading slash
					}

					let path = API_SYNC_AUTHENTICATE
					if (prefix.endsWith('/') && path.startsWith('/')) {
						path = path.slice(1) // strip leading slash
					}

					if (prefix === '') {
						if (url.endsWith('/') && path.startsWith('/')) {
							path = path.slice(1) // strip leading slash
						}
					}

					console.log('sync: obtaining new websocket authentication token')
					let config = {
						'url': url + prefix + path + '?session=' + store.getters.sessionUUID,
						'method': 'POST',
						'data': authentication,
						'withCredentials': true,
					}
					// console.debug(config)
					return axios.request(config).then(function (response) {
						// noinspection JSUnresolvedVariable
						if (response.data.jsonapi !== undefined && response.data.errors === undefined) {
							if (response.data.data !== undefined) {
								let result = response.data.data[0]
								if (result !== undefined && result.attributes.token !== undefined) {

									// add our new token to the authentication message
									authentication.token = result.attributes.token

									// initiate the websocket connection
									socket.connect()

									// return the socket to the caller
									resolve(socket)

									return // success
								}
							}
						}

						// failed
						let error = new Error('invalid sync auth http response')
						error.response = response
						store.commit(MUTATE_FAILURE, {'error': error})
						reject(error)

					}).catch(function (error) {
						console.log('sync: api auth error:', error)
						store.commit(MUTATE_FAILURE, {'error': error})
						reject(error)
					})

				})
			}

			let authFailureCount = 0

			// Set up the required socket.io event handlers
			socket.on(
				'connect', // socket.io connected (or reconnected)
				() => {
					// Authenticate with the server.
					socket.emit(
						API_SYNC_AUTHENTICATE,
						authentication,
						ack => {
							console.log('sync: auth:', ack)
							if (ack === 'OK') {
								// Authentication succeeded.
								authFailureCount = 0

								console.log('sync: connected')
								store.commit(MUTATE_CONNECTED, {'connected': true})
								isConnected = true

								// Run the connection callbacks.
								for (const callback of connectCallbacks) {
									try {
										callback()
									} catch (error) {
										console.error('sync: connect callback error (caught):', error)
									}
								}
								connectCallbacks = []

								// Remind the server about all of our subscriptions and watches.
								for (const topic in store.getters.allSubscriptions) {
									store.dispatch('resubscribeTopic', topic)
								}
								if (store.getters.isSubscribedToEvents) {
									store.dispatch('resubscribeEvents')
								}
								let watches = store.getters.allWatches
								for (const topic in watches) {
									for (const id in watches[topic]) {
										store.dispatch('rewatch', {
											topic,
											id
										})
									}
								}

							} else {
								// Authentication failed.
								authFailureCount++

								// Disconnect the socket.
								socket.disconnect()

								// If we have failed to authenticate too many times,
								// then we need to force a page reload.
								if (authFailureCount > 3) {
									let error = new Error('sync auth failed too many times')
									store.commit(MUTATE_FAILURE, {'error': error})
									return
								}

								// Reauthenticate and reconnect.
								authenticate().then(() => {
									// success!

								}).catch((error) => {
									// failure!
									store.commit(MUTATE_FAILURE, {'error': error})
									// give up!
								})
							}
						}
					) // end emit
				}
			)
			socket.on(
				'disconnect', // socket.io disconnected
				(reason) => {
					console.log('sync: disconnected')
					store.commit(MUTATE_CONNECTED, {'connected': false})

					// Were we successfully connected before this disconnect?
					if (isConnected) { // Yes...
						// Run the disconnect callbacks.
						for (const callback of disconnectCallbacks) {
							try {
								callback()
							} catch (error) {
								console.error('sync: disconnect callback error (caught):', error)
							}
						}
						disconnectCallbacks = []
					}
					isConnected = false

					switch (reason) {
					case 'io server disconnect':
						// the disconnection was initiated by the server
						// manually attempt reconnection
						console.log('sync: websocket disconnected by server')
						socket.connect()
						break
					case 'io client disconnect':
						// the disconnection was initiated by the client
						console.log('sync: websocket disconnected by client')
						// DO NOTHING
						break
					default:
						// socket.io will attempt to automatically reconnect
					}
				}
			)
			socket.on(
				'error', // socket.io error
				(error) => {
					store.commit(MUTATE_FAILURE, {'error': error})
				}
			)
			socket.on(
				'pong', // socket.io pong
				(latency) => {
					// console.debug('sync: websocket latency', latency)
					if (latency > 500) { // 0.5 seconds
						console.warn('sync: websocket high latency', latency, 'ms')
					}
					if (latency > 10000) { // 10 seconds
						console.warn('sync: reconnecting due to very high latency')
						socket.disconnect()
						queueMicrotask(() => {
							socket.connect()
						})
					}
				}
			)
			socket.on(
				'reconnect_failed', // failed (without automatic recovery)
				() => {
					// no auto recovery here
					let error = new Error('websocket reconnect failed too many times')
					store.commit(MUTATE_FAILURE, {'error': error})
				}
			)
			socket.on(
				'flash',
				(message) => {
					// These are messages from the server that should
					// be displayed in the logs. They exist to help
					// with debugging (usually after the fact).
					console.info('sync: server message:', message)
				}
			)
			socket.on(
				API_SYNC_RECEIVE, // Messages from the server
				(payload) => {
					if (payload.timestamp === undefined) {
						payload.timestamp = nowRfc()
					}

					// update the resources
					if (payload.updates !== undefined || payload.deletions !== undefined) {
						if (payload.topic === undefined) {
							console.warn('invalid sync message', payload)
							return
						}
						queueMicrotask(() => {
							store.commit(MUTATE_RESOURCES, payload)
						})
					}

					if (payload.topics !== undefined) {
						queueMicrotask(() => {
							store.commit(MUTATE_COUNTS, payload)
						})
					}

					if (payload.events !== undefined) {
						queueMicrotask(() => {
							store.commit(MUTATE_EVENTS, payload)
						})
					}
				}
			)

			// Authenticate and connect to the server.
			return authenticate()
		}

		// Container which stores the socket.io client.
		let client = {
			socket: undefined, // The socket.io client.
		}

		store.registerModule('sync', {
			strict: true,

			// [Read only] data (change it via mutations, via actions)
			state: {

				// Status of the connection to the server.
				websocketConnected: false,

				// Terminal failure message.
				websocketFailure: undefined,

				// Counts of subscriptions for a given topic.
				subscriptions: {},

				// Counts of subscriptions for the events stream.
				eventsSubscriptions: 0,

				// Counts of watches for a given resource.
				watches: {},

				// Resources received from the server, by topic.
				resources: {},

				// Events received from the server. Most recent first.
				events: [],

				// Server side counts of resources for all topics.
				topicsCounts: {},

				// Last time we received an update for a given topic.
				lastReceiveTimes: {},

			},

			// [Read only] computed state (not cached).
			// These methods are allowed to read the state, but not change it.
			getters: {

				///////////////////////////////////////////////////////
				// Network

				// The uuid for the current client session.
				sessionUUID: () => uuid,

				// Whether we are connected to the server (boolean response).
				websocketConnected: (state) => state.websocketConnected,

				// Websocket failure
				websocketFailure: (state) => state.websocketFailure,

				///////////////////////////////////////////////////////
				// Subscriptions

				// Whether we are subscribed to a given topic (boolean response).
				isSubscribed: (state) => (topic) => {
					return (
						topic in state.subscriptions
						&& state.subscriptions[topic] >= 1
					)
				},

				// Number of subscriptions for a given topic (integer response).
				numberSubscriptions: (state) => (topic) => {
					if (topic in state.subscriptions
						&& state.subscriptions[topic] >= 1
					) {
						return state.subscriptions[topic]
					}
					return 0
				},

				// All topics that we are subscribed to.
				allSubscriptions: (state) => state.subscriptions,

				// Whether we are subscribed to a given topic (boolean response).
				isSubscribedToEvents: (state) => {
					return state.eventsSubscriptions >= 1
				},

				// Events that have been received from the server.
				// Most recent first, up to a maximum of 100.
				recentEvents: (state) => state.events,

				///////////////////////////////////////////////////////
				// Watches

				// Whether we are watching a given topic and id (boolean response).
				isWatched: (state) => (topic, id) => {
					if (topic in state.watches) {
						if (id in state.watches[topic]) {
							if (state.watches[topic][id] >= 1) {
								return true
							}
						}
					}
					return false
				},

				// Number of watches for a given topic and id (integer response).
				numberWatches: (state) => (topic, id) => {
					if (topic in state.watches) {
						if (id in state.watches[topic]) {
							if (state.watches[topic][id] >= 1) {
								return state.watches[topic][id]
							}
						}
					}
					return 0
				},

				// All topics and ids that we are watching.
				allWatches: (state) => state.watches,

				///////////////////////////////////////////////////////
				// Resources

				// All resources received from the server.
				allResources: (state) => state.resources,

				// All resources received from the server for a given topic.
				topicResources: (state) => (topic) => state.resources[topic],

				// Server side counts of resources for all topics.
				topicCounts: (state) => state.topicsCounts,

				///////////////////////////////////////////////////////
				// Time

				// The last time we received an update for a given topic.
				topicLastReceiveTime: (state) => (topic) => {
					if (topic in state.lastReceiveTimes) {
						return unixToRfc(state.lastReceiveTimes[topic])
					}
					return undefined
				},

				debugLastReceiveTimes: (state) => state.lastReceiveTimes,

				// The current time.
				now: () => {
					return {
						'rfc': nowRfc(),
						'unix': nowUnix()
					}
				},
			},

			// [Synchronous] trackable changes to the state.
			// These methods are allowed to change the state.
			mutations: {

				// Set the connected status.
				[MUTATE_CONNECTED] (state, {connected}) {
					state.websocketConnected = connected
					if (connected) {
						state.websocketFailure = undefined
					}
				},

				// Set the failure message.
				[MUTATE_FAILURE] (state, {error}) {
					state.websocketFailure = error
					state.websocketConnected = false
				},

				// Increment the subscription counter for a given topic.
				[MUTATE_INCREMENT_TOPIC_SUBSCRIBERS] (state, topic) {
					// update the subscription counter
					let numberSubscriptions = 0
					if (topic in state.subscriptions) {
						numberSubscriptions = state.subscriptions[topic]
						if (numberSubscriptions < 0) {
							numberSubscriptions = 0
						}
					}
					if (numberSubscriptions === 0) {
						// Initialize the other relevant state variables.
						if (!(topic in state.lastReceiveTimes)) {
							Vue.set(state.lastReceiveTimes, topic, 0)
						}
						if (!(topic in state.resources)) {
							Vue.set(state.resources, topic, {})
						}
					}
					numberSubscriptions += 1
					Vue.set(state.subscriptions, topic, numberSubscriptions)
					console.debug('sync: topic', topic, 'now has', numberSubscriptions, '(local) subscriptions')
				},

				// Decrements the subscription counter for a given topic.
				[MUTATE_DECREMENT_TOPIC_SUBSCRIBERS] (state, topic) {

					// Calculate the new value for the subscription counter
					let numberSubscriptions = 0
					if (topic in state.subscriptions) {
						numberSubscriptions = state.subscriptions[topic]
						numberSubscriptions -= 1
						if (numberSubscriptions < 0) {
							numberSubscriptions = 0
						}
					}

					// If the counter has reached zero ...
					if (numberSubscriptions <= 0) {

						// Delete the subscription counter.
						if (topic in state.subscriptions) {
							Vue.delete(state.subscriptions, topic)
						}

						// Do we have watches for this topic?
						if (topic in state.watches) { // Yes ...

							// Delete all resources which aren't being watched.
							if (topic in state.resources) {
								Object.keys(state.resources[topic]).forEach(id => {
									if (id in state.watches[topic]) {
										if (state.watches[topic][id] > 0) {
											return // keep it
										}
									}
									Vue.delete(state.resources[topic], id)
								})
							}

						} else { // No watches ...

							// Delete the data that we no longer care about.
							if (topic in state.resources) {
								Vue.delete(state.resources, topic)
							}

						}

						// Delete the last receive time.
						// (we only care about this for subscriptions)
						if (topic in state.lastReceiveTimes) {
							Vue.delete(state.lastReceiveTimes, topic)
						}

					} else {
						// Otherwise... store the new value for the counter.
						Vue.set(state.subscriptions, topic, numberSubscriptions)
					}

					console.debug('sync: topic', topic, 'now has', numberSubscriptions, '(local) subscriptions')
				},

				// Increment the subscription counter for a given events.
				[MUTATE_INCREMENT_EVENTS_SUBSCRIBERS] (state) {
					// update the subscription counter
					let numberSubscriptions = state.eventsSubscriptions
					if (numberSubscriptions < 0) {
						numberSubscriptions = 0
					}

					if (numberSubscriptions === 0) {
						Vue.set(state, 'events', [])
					}

					numberSubscriptions += 1
					Vue.set(state, 'eventsSubscriptions', numberSubscriptions)
					console.debug('sync: events stream now has', numberSubscriptions, '(local) subscriptions')
				},

				// Decrements the subscription counter for a given events.
				[MUTATE_DECREMENT_EVENTS_SUBSCRIBERS] (state) {

					// Calculate the new value for the subscription counter
					let numberSubscriptions = state.eventsSubscriptions
					numberSubscriptions -= 1
					if (numberSubscriptions < 0) {
						numberSubscriptions = 0
					}

					// If the counter has reached zero ...
					if (numberSubscriptions <= 0) {
						Vue.set(state, 'events', [])
					}

					// Store the new value for the counter.
					Vue.set(state, 'eventsSubscriptions', numberSubscriptions)
					console.debug('sync: events stream now has', numberSubscriptions, '(local) subscriptions')
				},

				// Increment the watchers counter for a given topic and id.
				[MUTATE_INCREMENT_WATCHERS] (state, {
					topic,
					id
				}) {
					// update the watcher counter
					let numberWatchers = 0
					if (topic in state.watches) {
						if (id in state.watches[topic]) {
							numberWatchers = state.watches[topic][id]
							if (numberWatchers < 0) {
								numberWatchers = 0
							}
						}
					}
					if (numberWatchers === 0) {
						// Initialize the other relevant state variables.
						if (!(topic in state.resources)) {
							Vue.set(state.resources, topic, {})
						}
					}
					numberWatchers += 1
					if (!(topic in state.watches)) {
						Vue.set(state.watches, topic, {})
					}
					Vue.set(state.watches[topic], id, numberWatchers)
					console.debug('sync: topic', topic, 'now has', numberWatchers, '(local) watchers')
				},

				// Decrements the watchers counter for a given topic and id.
				[MUTATE_DECREMENT_WATCHERS] (state, {
					topic,
					id
				}) {

					// Calculate the new value for the watcher counter
					let numberWatchers = 0
					if (topic in state.watches) {
						if (id in state.watches[topic]) {
							numberWatchers = state.watches[topic][id]
							numberWatchers -= 1
							if (numberWatchers < 0) {
								numberWatchers = 0
							}
						}
					}

					// If the counter has reached zero ...
					if (numberWatchers <= 0) {

						// Delete the watcher counter.
						if (topic in state.watches) {
							if (id in state.watches[topic]) {
								Vue.delete(state.watches[topic], id)
							}
							if (Object.keys(state.watches[topic]).length === 0) {
								Vue.delete(state.watches, topic)
							}
						}

						// Are we also subscribed to this topic?
						if (!(topic in state.subscriptions)) { // No ...

							// Delete the data that we no longer care about.
							if (topic in state.resources) {
								Vue.delete(state.resources, topic)
							}
						}

					} else {
						// Otherwise... store the new value for the counter.
						if (!(topic in state.watches)) {
							Vue.set(state.watches, topic, {})
						}
						Vue.set(state.watches[topic], id, numberWatchers)
					}

					console.debug('sync: topic', topic, 'now has', numberWatchers, '(local) watchers')
				},

				// Mutate the resources for a given topic.
				[MUTATE_RESOURCES] (state, {
					topic,		// string
					updates, 	// array of objects
					deletions, 	// object of id:timestamp
					timestamp, 	// rfc3339
				}) {

					if (!(topic in state.resources)) {
						Vue.set(state.resources, topic, {})
					}

					if (updates !== undefined) {
						for (let resource of updates) { // iterate array (for of)
							let id = resource.id
							if (resource.timestamp === undefined) {
								if (resource.modified === undefined) {
									resource.timestamp = rfcToUnix(timestamp)
								} else {
									resource.timestamp = rfcToUnix(resource.modified)
								}
							}
							Object.freeze(resource) // make it immutable
							Vue.set(state.resources[topic], id, resource)
						}
					}

					if (deletions !== undefined) {
						for (let id in deletions) { // iterate object (for in)
							let timestamp = rfcToUnix(deletions[id])
							let resource = state.resources[topic][id]
							if (resource !== undefined && resource.timestamp !== undefined && resource.timestamp > timestamp) {
								// The resource has been updated since it was
								// deleted. We don't want to delete it.
								continue
							}
							Vue.delete(state.resources[topic], id)
						}
					}

					if (timestamp !== undefined) {
						// Only track last receive time if we are subscribed.
						if (topic in state.subscriptions) {
							let unixTimestamp = rfcToUnix(timestamp)
							if (unixTimestamp > 0) {
								let lastReceiveTime = 0
								if (topic in state.lastReceiveTimes) {
									lastReceiveTime = state.lastReceiveTimes[topic]
								}
								if (unixTimestamp >= lastReceiveTime) {
									Vue.set(state.lastReceiveTimes, topic, unixTimestamp)
								}
							}
						}
					}

				},

				// Mutate the resources for a given topic.
				[MUTATE_COUNTS] (state, {
					topics, 	// object of topic:count
				}) {
					for (let topic in topics) {
						Vue.set(state.topicsCounts, topic, topics[topic])
					}
				},

				// Mutate the resources for a given topic.
				[MUTATE_EVENTS] (state, {
					events, 	// array of objects
					timestamp, 	// rfc3339
				}) {
					const maximumEventsToStore = 100 // please update above comments if you change this
					let length = state.events.unshift(...events.reverse())
					if (length > maximumEventsToStore) {
						state.events.splice(maximumEventsToStore, length - maximumEventsToStore)
					}
				},

			},

			// [Asynchronous] actions which may change the state.
			// These methods must use the getters and mutations to access state.
			actions: {

				// Connect to the server. If already connected this will trigger
				// a reconnection.
				// This is an async method which returns a promise.
				syncConnect (context, options) {
					return new Promise((resolve, reject) => {

						if (options === undefined) {
							options = {}
						}
						if (options.timeout === undefined) {
							options.timeout = 20000 // default connection timeout
						}

						// Disconnect any existing socket.io client
						if (client.socket !== undefined) {
							client.socket.disconnect()
						}

						// Set up and connect a new socket.io client.
						socketFactory(options).then((socket) => {

							client.socket = socket

							const oneSecond = 1000
							let timeoutAt = nowUnix() + (options.timeout / oneSecond)
							let watcher = setInterval(() => {
								if (context.getters.websocketConnected) {
									clearInterval(watcher)
									resolve(context.getters.websocketConnected)
								}
								if (nowUnix() > timeoutAt) {
									clearInterval(watcher)
									console.log('sync: websocket connect failed:', 'connect_timeout')
									reject('connect_timeout')
								}
							}, oneSecond / 5)

						}).catch((error) => {
							console.log('sync: websocket connect failed:', error)
							reject(error)
						})
					})
				},

				// Force disconnection from the server. This will prevent any
				// automatic reconnection. Call syncConnect to reconnect.
				// This is an async method which returns a promise.
				syncDisconnect () {
					return new Promise((resolve) => {
						// Disconnect any existing socket.io client
						if (client.socket !== undefined) {
							client.socket.disconnect()
						}
						resolve()
					})
				},

				// Subscribe to a topic.
				// This is an async method which returns a promise.
				subscribeTopic (context, topic) {
					// console.debug('sync: subscribe' + topic)
					return new Promise((resolve, reject) => {
						let subscribed = context.getters.isSubscribed(topic)
						context.commit(MUTATE_INCREMENT_TOPIC_SUBSCRIBERS, topic)
						if (!subscribed) {
							context
								.dispatch('resubscribeTopic', topic)
								.then(resolve)
								.catch(reject)
						} else {
							resolve('subscribed')
						}
					})
				},

				// Remind the server about a subscription (this is the subscribe
				// action but without incrementing the internal counter).
				// This is an async method which returns a promise.
				resubscribeTopic (context, topic) {
					return new Promise((resolve, reject) => {
						if (client.socket === undefined) { // not initialized
							resolve('queued')
						}
						client.socket.emit(
							API_SYNC_SUBSCRIBE_TOPIC,
							{
								timestamp: nowRfc(),
								// session: store.getters.sessionUUID,
								topic: topic,
								since: context.getters.topicLastReceiveTime(topic),
							},
							function (data) {
								// console.debug(data)
								if (data === 'OK') {
									console.log('sync: subscribed to ' + topic)
									resolve('subscribed')
								} else {
									console.warn('sync: unable to subscribe to ' + topic)
									reject(data)
								}
							})
					})
				},

				// Unsubscribe from a topic.
				// This is an async method which returns a promise.
				unsubscribeTopic (context, topic) {
					// console.debug('sync: unsubscribe' + topic)
					return new Promise((resolve, reject) => {
						context.commit(MUTATE_DECREMENT_TOPIC_SUBSCRIBERS, topic)
						if (!context.getters.isSubscribed(topic)) {
							client.socket.emit(
								API_SYNC_UNSUBSCRIBE_TOPIC,
								{
									timestamp: nowRfc(),
									// session: store.getters.sessionUUID,
									topic: topic,
								},
								function (data) {
									// console.debug(data)
									if (data === 'OK') {
										console.log('sync: unsubscribed from ' + topic)
										resolve('unsubscribed')
									} else {
										console.warn('sync: unable to unsubscribe from ' + topic)
										reject(data)
									}
								})
						} else {
							resolve('not subscribed')
						}
					})
				},

				// Subscribe to the events stream.
				// This is an async method which returns a promise.
				subscribeEvents (context) {
					// console.debug('sync: subscribe' + events)
					return new Promise((resolve, reject) => {
						let subscribed = context.getters.isSubscribedToEvents
						context.commit(MUTATE_INCREMENT_EVENTS_SUBSCRIBERS)
						if (!subscribed) {
							context
								.dispatch('resubscribeEvents')
								.then(resolve)
								.catch(reject)
						} else {
							resolve('subscribed')
						}
					})
				},

				// Reminds the server of the subscription to send the
				// events stream (this is the subscribe action but without
				// incrementing the internal counter).
				// This is an async method which returns a promise.
				resubscribeEvents (context) {
					return new Promise((resolve, reject) => {
						if (client.socket === undefined) { // not initialized
							return resolve('queued')
						}
						client.socket.emit(
							API_SYNC_SUBSCRIBE_EVENTS,
							{
								timestamp: nowRfc(),
								// session: store.getters.sessionUUID,
							},
							function (data) {
								// console.debug(data)
								if (data === 'OK') {
									console.log('sync: subscribed to the events stream')
									resolve('subscribed')
								} else {
									console.warn('sync: unable to subscribe to the events stream')
									reject(data)
								}
							})
					})
				},

				// Removes the subscription to the events stream.
				// This is an async method which returns a promise.
				unsubscribeEvents (context) {
					// console.debug('sync: unsubscribe' + events)
					return new Promise((resolve, reject) => {
						context.commit(MUTATE_DECREMENT_EVENTS_SUBSCRIBERS)
						if (!context.getters.isSubscribedToEvents) {
							client.socket.emit(
								API_SYNC_UNSUBSCRIBE_EVENTS,
								{
									timestamp: nowRfc(),
									// session: store.getters.sessionUUID,
								},
								function (data) {
									// console.debug(data)
									if (data === 'OK') {
										console.log('sync: unsubscribed from the events stream')
										resolve('unsubscribed')
									} else {
										console.warn('sync: unable to unsubscribe from the events stream')
										reject(data)
									}
								})
						} else {
							resolve('not subscribed')
						}
					})
				},

				// Watch a resource (topic and id).
				// This is an async method which returns a promise.
				watch (context, {
					topic,
					id
				}) {
					// console.debug('sync: watch', topic + ':' + id)
					return new Promise((resolve, reject) => {
						let watched = context.getters.isWatched(topic, id)
						context.commit(MUTATE_INCREMENT_WATCHERS, {
							topic: topic,
							id: id
						})
						if (!watched) {
							context
								.dispatch('rewatch', {
									topic,
									id
								})
								.then(resolve)
								.catch(reject)
						} else {
							resolve('watched')
						}
					})
				},

				// Remind the server about a watch (this is the watch action
				// but without incrementing the internal counter).
				// This is an async method which returns a promise.
				rewatch (context, {
					topic,
					id
				}) {
					return new Promise((resolve, reject) => {
						if (client.socket === undefined) { // not initialized
							return resolve('queued')
						}
						client.socket.emit(
							API_SYNC_WATCH,
							{
								timestamp: nowRfc(),
								// session: store.getters.sessionUUID,
								topic: topic,
								id: id,
							},
							function (data) {
								// console.debug(data)
								if (data === 'OK') {
									console.log('sync: watched ' + topic + ':' + id)
									resolve('watched')
								} else {
									console.warn('sync: unable to watch ' + topic + ':' + id)
									reject(data)
								}
							})
					})
				},

				// Unwatch a resource (topic and id).
				// This is an async method which returns a promise.
				unwatch (context, {
					topic,
					id
				}) {
					// console.debug('sync: unwatch', topic + ':' + id)
					return new Promise((resolve, reject) => {
						context.commit(MUTATE_DECREMENT_WATCHERS, {
							topic: topic,
							id: id
						})
						if (!context.getters.isWatched(topic, id)) {
							client.socket.emit(
								API_SYNC_UNWATCH,
								{
									// session: store.getters.sessionUUID,
									topic: topic,
									id: id,
								},
								function (data) {
									// console.debug(data)
									if (data === 'OK') {
										console.log('sync: unwatched ' + topic + ':' + id)
										resolve('unwatched')
									} else {
										console.warn('sync: unable to unwatch ' + topic + ':' + id)
										reject(data)
									}
								})
						} else {
							resolve('not watched')
						}
					})
				},

				// Remote Procedure Call.
				// This is an async method which returns a promise.
				rpc (context, {
					name,
					args,
				}) {
					console.debug('sync: rpc: name:', name, 'args:', args)
					return new Promise((resolve, reject) => {
						let endpoint
						if (name.endsWith('/')) {
							name = name.slice(0, -1)
						}
						if (name.startsWith('!')) {
							endpoint = API_SYNC_RPC
						} else {
							endpoint = API_SYNC_RPC + name + '/'
						}
						const performRemoteProcedureCall = () => {
							console.debug('sync: rpc: sending')
							let completed = false
							disconnectCallbacks.push(() => {
								if (!completed) {
									completed = true
									return reject('websocket connection lost')
								}
							})
							client.socket.emit(
								endpoint,
								{
									name: name,
									args: args,
								},
								function (payload) {
									if (completed) {
										console.error('sync: rpc: received response for already completed exchange')
										return
									}
									completed = true
									console.debug('sync: rpc: response: ', payload)
									if (payload.result !== undefined) {
										return resolve(payload.result)

									} else if (payload.error !== undefined) {
										return reject(payload.error)

									} else {
										return reject('invalid sync rpc response from API')
									}
								})
						}
						if (!isConnected || client.socket === undefined) { // not initialized
							console.debug('sync: rpc: queued to occur on next connection')
							connectCallbacks.push(performRemoteProcedureCall)
						} else {
							performRemoteProcedureCall()
						}
					})
				},

			},

			// Special functionality
			plugins: [],

			// Extendability and Optional Namespaces
			modules: {},

		}) // end sync module
	} // end plugin
}
