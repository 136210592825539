<template>
	<div>
		<div class="mdc-switch" data-mdc-auto-init="MDCSwitch">
			<div class="mdc-switch__track"></div>
			<div class="mdc-switch__thumb-underlay">
				<div class="mdc-switch__thumb">
					<input
						v-bind:id="htmlId"
						v-model="checked"
						class="mdc-switch__native-control"
						role="switch"
						type="checkbox"
					>
				</div>
			</div>
		</div>
		<label v-bind:for="htmlId">{{ label }}</label>
	</div>
</template>

<script>

// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'MaterialSwitch',
	props: [
		'id',
		'name',
		'label',
		'disabled',
		'checkedDesired', // bool
	],
	data () {
		return {
			mdcSwitch: undefined,
			checked: false,
		}
	},
	computed: {
		htmlId () {
			return 'switch-' + this.id
		},
		checkedWord () {
			if (this.checked) {
				return 'true'
			} else {
				return 'false'
			}
		},
		isDisabled () {
			if (this.disabled !== undefined) {
				return this.disabled
			}
			return false
		},
	},
	watch: {
		checked () {
			this.$emit('update:checked', this.id, this.checked)
		},
		checkedDesired () {
			this.selectDesiredValue()
		},
		isDisabled () {
			if (this.mdcSwitch !== undefined) {
				this.mdcSwitch.disabled = this.isDisabled
			}
		},
	},
	created () {
		this.selectDesiredValue()
	},
	mounted () {
		this.setup()
	},
	updated () {
		this.setup()
	},
	methods: {
		setup () {
			autoInit()
			this.mdcSwitch = this.$el.querySelector('.mdc-switch').MDCSwitch
			this.mdcSwitch.disabled = this.isDisabled
			this.selectDesiredValue()
		},
		selectDesiredValue () {
			this.checked = this.checkedDesired
			if (this.mdcSwitch !== undefined) {
				this.mdcSwitch.checked = this.checked
				this.mdcSwitch.disabled = this.isDisabled
			}
		},

	}
}

</script>

<style scoped>

label {
	padding-left: 4px;
}

</style>
