<script>

// Client side encryption with session storage

import StorageMixin from './StorageMixin'

import {hash, randomBytes, secretbox} from 'tweetnacl'
import {decodeBase64, decodeUTF8, encodeBase64, encodeUTF8} from 'tweetnacl-util'

const hardCodedSalt = 'efaf6217-ee43-4284-9853-09a03bad6311'

const now = () => Math.floor(Date.now() / 1000)

const join = (a, b) => {
	const c = new Uint8Array(a.length + b.length)
	c.set(a)
	c.set(b, a.length)
	return c
}
const split = (offset, bytes) => ([bytes.subarray(0, offset), bytes.subarray(offset),])

const calculateStashKey = (salt) => {
	// the intention here is to create a predictable key that is likely
	// to be unique to this browser/user, but also stable enough that
	// it won't change due to upgrades, etc.
	let stashStr = 'STASH'
	stashStr += String(navigator.vendor)
	stashStr += String(navigator.platform)
	stashStr += String(hardCodedSalt)
	stashStr += String(salt)
	let stashKey = decodeUTF8(stashStr)
	stashKey = hash(stashKey)
	stashKey = stashKey.subarray(0, secretbox.keyLength)
	return stashKey // Uint8Array
}

const encryptValue = (value, salt = '', ttl = 5400) => {
	let timestamp = now()
	if (ttl === undefined || ttl <= 1) {
		ttl = 5400 // 90 minutes
	}
	let expiry = timestamp + ttl
	let messageBytes = decodeUTF8(JSON.stringify({
		'_decrypted': true,
		'timestamp': timestamp,
		'expiry': expiry,
		'value': value,
	}))
	let nonce = randomBytes(secretbox.nonceLength)
	let box = secretbox(messageBytes, nonce, calculateStashKey(salt))
	return {
		'_encrypted': true,
		'expiry': expiry,
		'data': encodeBase64(join(nonce, box)),
	}
}
const decryptValue = (encrypted, salt) => {
	if (encrypted === null || encrypted === undefined) {
		return
	}
	let container = encrypted
	if (typeof encrypted === 'string') {
		container = JSON.parse(encrypted)
	}
	if (container._encrypted !== true) {
		return undefined
	}
	if (container.expiry !== undefined) { // Fail fast
		let age = now() - container.expiry
		if (age >= 0) {
			return undefined
		}
	}
	let [nonce, box] = split(secretbox.nonceLength, decodeBase64(container.data))
	let bytes = secretbox.open(box, nonce, calculateStashKey(salt))
	if (bytes !== null && bytes !== undefined) {
		let message = JSON.parse(encodeUTF8(bytes))
		if (message._decrypted !== true) {
			return undefined
		}
		if (message.expiry !== undefined) {
			let age = now() - message.expiry
			if (age >= 0) {
				return undefined
			}
		}
		return message.value
	}
	return undefined
}

export default {
	name: 'EncryptedStorageMixin',
	mixins: [
		StorageMixin,
	],
	methods: {

		storeEncrypted (key, value) {
			let salt = this.loadCookieValue('adminDisplayName', this.storageInstanceId) + key
			this.storeTransient(key, encryptValue(value, salt))
		},

		loadEncrypted (key, defaultValue = undefined) {
			let salt = this.loadCookieValue('adminDisplayName', this.storageInstanceId) + key
			let storedValue = this.loadStoredValue(key, undefined)
			try {
				return decryptValue(storedValue, salt) || defaultValue
			} catch (error) {
				console.warn('Failed to stored decrypt value for:', key, error)
				return defaultValue
			}
		},

	},
}

</script>
