<template>
	<div class="results mdc-typography">
		<table v-if="result.length > 0" class="basic-table mdc-typography--body1">
			<thead>
			<tr>
				<th v-show="hasUid">UID</th>
				<th>Name</th>
				<th v-if="created">Created</th>
				<th v-if="updated">Updated</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="resultItem in result" v-bind:key="resultItemKey(resultItem)">
				<td v-show="false">{{ resultItem.id }}</td>
				<td v-show="hasUid">{{ resultItem.attributes.uid }}</td>
				<td class="limited-width">{{ resultItem.attributes.name }}</td>
				<td v-if="created" class="timestamp">
					<Timestamp
						v-bind:unixtime="toUnixtime(resultItem.attributes.created)"
						v-bind:wide="false"
					/>
				</td>
				<td v-if="updated" class="timestamp">
					<Timestamp
						v-bind:unixtime="toUnixtime(resultItem.attributes.updated)"
						v-bind:wide="false"
					/>
				</td>
				<td class="actions">
					<a class="mdc-button mdc-button--raised"
					   v-bind:href="detailsUrl(resultItem.id)"
					   v-on:click.prevent="$router.push({path: detailsUrl(resultItem.id)})"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Details</span>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
		<div v-else class="max-width">
			<p><i>No {{ noun }}s to show</i></p>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import Timestamp from '../../material/Timestamp'

export default {
	name: 'SummaryTable',
	components: {
		Timestamp,
	},
	props: {
		'result': {
			type: Array,
		},
		'created': {
			type: Boolean,
			default: true,
		},
		'updated': {
			type: Boolean,
			default: false,
		},
		'noun': {
			type: String,
			default: 'service',
		},
	},
	data () {
		return {}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		hasUid () {
			return this.result.length > 0 && this.result[0].attributes.uid !== undefined
		}
	},
	methods: {
		resultItemKey (resultItem) {
			if (resultItem.order !== undefined) {
				return resultItem.order
			}
			return resultItem.id
		},
		toUnixtime (timestamp) {
			return Math.round(new Date(timestamp).getTime() / 1000)
		},
		detailsUrl (entityId) {
			let noun = this.noun.toLowerCase().replaceAll(/\s/g, 's/') + 's'
			return this.appConfig('BASE_URL') + noun + '/' + entityId + '/'
		},

	}
}

</script>

<style lang="scss" scoped>

.results .output-title {
	display: flex;
	flex-direction: row;
}

.results .output-title-inline-label,
.results .output-title-inline-input {
	font-weight: inherit;
	font-size: inherit;
	background-color: inherit;
	border: 0;
	padding: 5px;
}

.results .output-title-inline-label {
	flex: 0 1 auto;
	padding-left: 0;
}

.results .output-title-inline-input {
	flex: 0 1 auto;
	width: 100%;
}

.basic-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
}

.basic-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
}

.basic-table td, .basic-table th {
	border: 1px solid darkgrey;
	padding: .5em;
}

.basic-table tr {
	background-color: white;
}

.basic-table tr:hover {
	background-color: lightyellow;
}

.basic-table .actions {
	width: 5%;
	text-align: center;
}

/*noinspection SpellCheckingInspection*/
.basic-table .serveractions {
	text-align: right;
}

/*noinspection SpellCheckingInspection*/
.basic-table tr.actionsexpanded {
	visibility: visible;
	display: table-row;
}

/*noinspection SpellCheckingInspection*/
.basic-table td.actionsexpanded {
	visibility: visible;
	display: table-cell;
}

/*noinspection SpellCheckingInspection*/
.basic-table .actionscollapsed {
	visibility: hidden;
	display: none;
}

.basic-table .hostname {
	margin: 2px 0;
	padding: 0
}

td.limited-width, td.timestamp {
	overflow: hidden;
	word-wrap: anywhere;
}

td.hostnames {
	width: 45%;
}

td.algorithm {
	width: 10%;
}

td.timestamp {
	width: 25%;
}

.max-width {
	width: 100%;
	text-align: center;
}

th .mdc-button {
	border: 1px solid #fff;
	color: #fff;
}

th .on {
	opacity: 1;
}

th .off {
	opacity: 0.8;
}

</style>
