//
// Locally Cached Data
//
// This is a very simple key value store using IndexedDB (via Dexie).
//
// This is designed to handle the scenario that you have a volume of data large
// enough that you don't want to keep it all in memory all of the time.
//
// For basic usage you call the store action with an item to store, and you can
// call the load getter to retrieve the item.
//
// Items are always a key, value, but may also have tags and other metadata.
// Tags must be an array of strings. You may query for items by tag and
// combinations of tags (this is performant).
//
// Items loaded through load or query are not reactive.
//
// Watches allow for reactive items. You can watch a term, which is either a key
// or a tag. The watchedItems property of the Vuex state contains all the items
// which match any of the watched terms. This updated in a reactive fashion.
// Note that all of the items being watched are kept in memory for the duration
// of the watch. Take care to unwatch terms when you are done with them.
//
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

/* ----------------------------------------------------------------------------

Interface:

State
 - tags				List of all tags which have been used to date
 - stored			Total number of stored items
 - watches 			Counts of watches for each term
 - watchedItems		List of items matching watched terms (reactive)

 -----------------------------------------------------------------------------

Actions
 - load				Asynchronous	Load a value from the cache
 - store			Asynchronous	Store a value in the cache
 - watch			Asynchronous	Start watching items matching the given term
 - unwatch			Asynchronous	Remove a term from the list of watched terms

 -----------------------------------------------------------------------------

Getters
 - load				Asynchronous	Load a value from the cache
 - query			Asynchronous	Query the cache for items with the given tags
 - isWatched		Synchronous		Whether we are watching a given target (boolean response)

 ---------------------------------------------------------------------------- */

import Dexie, { liveQuery } from 'dexie'
import Vue from 'vue'

import { hash, randomBytes, secretbox } from 'tweetnacl'
import { decodeBase64, decodeUTF8, encodeBase64, encodeUTF8 } from 'tweetnacl-util'

import { getCookie } from 'tiny-cookie'

const ENCRYPTED_PREFIX = 'E:'
const STASH_STORAGE_KEY = 'cachefly-cache'

// Namespace prefix
const NS = 'cache/'

// Mutations
export const MUTATE_CACHE_TAGS = 'MUTATE_CACHE_TAGS'
export const MUTATE_CACHE_STORED = 'MUTATE_CACHE_STORED'
export const MUTATE_INCREMENT_WATCHERS = 'MUTATE_INCREMENT_WATCHERS'
export const MUTATE_DECREMENT_WATCHERS = 'MUTATE_DECREMENT_WATCHERS'
export const MUTATE_WATCHED_ITEMS = 'MUTATE_WATCHED_ITEMS'
export const MUTATE_PRUNE_WATCHED_ITEMS = 'MUTATE_PRUNE_WATCHED_ITEMS'

// Vuex Plugin Factory
export default function createCachePlugin (options) {
	return function cachePlugin (store) {

		if (!('name' in options)) {
			options.name = 'unamed'
		}
		if (!('secretToken' in options)) {
			options.secretToken = window.location.hostname
		}

		options.useEncryption = options.useEncryption === true

		// Encryption and decryption functionality.
		const now = () => Math.floor(Date.now() / 1000)
		const secrets = {
			timestamp: 0,
			token: decodeUTF8(options.secretToken), // application secret (hard coded)
			stash: new Uint8Array(),				// session secret
			key: new Uint8Array(),					// user secret
		}
		const join = (a, b) => {
			const c = new Uint8Array(a.length + b.length)
			c.set(a)
			c.set(b, a.length)
			return c
		}
		const split = (offset, bytes) => ([bytes.subarray(0, offset), bytes.subarray(offset),])
		const encrypt = (message) => {
			if (!options.useEncryption) {
				return message
			}
			if (secrets.key.length !== secretbox.keyLength) {
				console.error('cache: encrypt called but encryption key is not the correct length: ' + secrets.key.length + ' !== ' + secretbox.keyLength)
				return message
			}
			if (typeof (message) !== 'string') {
				message = String(message)
			}
			let messageBytes = decodeUTF8(message)
			let nonce = randomBytes(secretbox.nonceLength)
			let box = secretbox(messageBytes, nonce, secrets.key)
			return ENCRYPTED_PREFIX + encodeBase64(join(nonce, box)) // encrypted
		}
		const decrypt = (encrypted) => {
			if (typeof (encrypted) !== 'string' || !encrypted.startsWith(ENCRYPTED_PREFIX)) {
				return encrypted
			}
			if (secrets.key.length !== secretbox.keyLength) {
				throw new Error('cache: decrypt called but encryption key is not the correct length: ' + secrets.key.length + ' !== ' + secretbox.keyLength)
			}
			encrypted = encrypted.substring(ENCRYPTED_PREFIX.length) // remove the prefix
			let [nonce, box] = split(secretbox.nonceLength, decodeBase64(encrypted))
			let bytes = secretbox.open(box, nonce, secrets.key)
			if (bytes === null || bytes === undefined) {
				throw new Error('decryption failed')
			}
			return encodeUTF8(bytes) // message
		}
		const encryptItem = (item) => {
			if (!options.useEncryption) {
				return item
			}
			// item = { key: '', tags: [], value: Any, ...meta }
			if (typeof (item) !== 'object') {
				return item
			}
			if (item.value === undefined || item.value === null) {
				return item
			}
			if (item.skipEncryption !== undefined && item.skipEncryption === true) {
				return item
			}
			return {
				key: item.key,
				tags: item.tags,
				stored: item.stored,
				encrypted: true,
				value: encrypt(JSON.stringify(item)),
			}
		}
		const decryptItem = (item) => {
			// item = { key: '', tags: [], value: Any, ...meta }
			if (typeof (item) !== 'object') {
				return item
			}
			if (item.value === undefined || item.value === null) {
				return item
			}
			if (item.encrypted !== true) {
				return item
			}
			let message = decrypt(item.value)
			return JSON.parse(message)
		}
		const calculateStashKey = () => {
			// the intention here is to create a predictable key that is likely
			// to be unique to this browser/user, but also stable enough that
			// it won't change due to upgrades, etc.
			let stashKey = 'STASH'
			stashKey += String(navigator.vendor)
			stashKey += String(navigator.platform)
			stashKey += String(getCookie('adminDisplayName')) // from sso
			stashKey = decodeUTF8(stashKey)
			stashKey = join(secrets.token, stashKey) // from config
			stashKey = hash(stashKey)
			stashKey = stashKey.subarray(0, secretbox.keyLength)
			secrets.stash = stashKey
		}
		const stashSecrets = () => {
			let messageBytes = decodeUTF8(JSON.stringify({
				timestamp: secrets.timestamp,
				key: encodeBase64(secrets.key),
			}))
			let nonce = randomBytes(secretbox.nonceLength)
			let box = secretbox(messageBytes, nonce, secrets.stash)
			let encrypted = encodeBase64(join(nonce, box))
			sessionStorage.setItem(STASH_STORAGE_KEY, encrypted)
			// in 1 hour and 60 seconds, call unstash so that the stash is
			// deleted (if it hasn't been refreshed yet)
			setTimeout(unstashSecrets, (3600 + 60) * 1000)
		}
		const unstashSecrets = () => {
			let encrypted = sessionStorage.getItem(STASH_STORAGE_KEY)
			if (encrypted === null || encrypted === undefined) {
				return
			}
			let [nonce, box] = split(secretbox.nonceLength, decodeBase64(encrypted))
			let bytes = secretbox.open(box, nonce, secrets.stash)
			if (bytes !== null && bytes !== undefined) {
				let message = JSON.parse(encodeUTF8(bytes))
				const oldTimestamp = 1609462861 // 2021-01-01 01:01:01
				if (message.timestamp <= oldTimestamp) { // 2021-01-01 01:01:01
					message.timestamp = oldTimestamp
				}
				let age = now() - message.timestamp
				if (age < 3600 && (message.timestamp > secrets.timestamp)) {
					secrets.timestamp = message.timestamp
					secrets.key = decodeBase64(message.key)
					// console.debug('cache:', 'loaded stashed secrets')
					if (!options.useEncryption) {
						options.useEncryption = true
						console.log('cache:', 'encryption is now enabled')
					}
					return
				}
			}
			// we didn't use the stashed secrets, so delete them
			clearStash()
		}
		const clearStash = () => {
			sessionStorage.removeItem(STASH_STORAGE_KEY)
		}
		calculateStashKey()
		unstashSecrets() // (unstash secrets on page reload)

		// Initialise database singleton.
		const db = new Dexie('cachefly-cache-' + options.name)
		db.version(1).stores({
			// Schemas only define the primary key and indexed properties.
			tags: '&tag',
			keyval: '&key, *tags',
		})

		// Subscribe to changes in the tags table.
		liveQuery(() => db.tags.toArray()).subscribe(tags => {
			//console.debug('observed tags changed')
			let list = []
			tags.forEach(tag => list.push(tag.tag))
			store.commit(NS + MUTATE_CACHE_TAGS, list)
		})

		// Load the initial stored items count value.
		db.keyval.count().then(count => {
			store.commit(NS + MUTATE_CACHE_STORED, count)
		})

		// Register a new module in the store.
		store.registerModule('cache', {
			strict: true,
			namespaced: true,

			// [Read only] data (change it via mutations, via actions)
			state: {

				// List of all tags which have been used to date.
				tags: [],

				// Total number of stored items.
				stored: 0,

				// Counts of watches for each key.
				watches: {},

				// Watched items.
				watchedItems: {},
			},

			// [Read only] computed state (not cached).
			// These methods are allowed to read the state, but not change it.
			getters: {

				// [Asynchronous] Load a value from the cache.
				load: () => key => { // Returns a promise
					let currentTimestamp = now()
					return db.keyval
						.get(key)
						.then(item => {
							if (item === undefined || item === null) {
								return undefined
							}
							if (item.expires === undefined || item.expires >= currentTimestamp) {
								try {
									return decryptItem(item)
								} catch (error) {
									console.error('cache:', 'load:', 'error:', error)
									return undefined
								}
							}
							return db.keyval.delete(key).then(() => {
								return undefined
							})
						})
				},

				// [Asynchronous] Query the cache for items with the given tags.
				query: () => tags => { // Returns a promise

					if (!Array.isArray(tags)) {
						tags = [tags]
					}

					let currentTimestamp = now()
					return db.keyval
						.where('tags').anyOf(tags)
						.distinct()
						.toArray()
						.then(items => {
							return Promise.resolve(items.map(item => {
								if (item !== undefined && item !== null) {
									if (item.expires === undefined || item.expires >= currentTimestamp) {
										try {
											return decryptItem(item)
										} catch (error) {
											console.error('cache:', 'query:', 'error:', error)
										}
									}
									if (item.key !== undefined) {
										db.keyval.delete(item.key)
									}
								}
								return undefined
							}))
						})
				},

				// [Synchronous] Whether we are watching a given target (boolean response).
				isWatched: (state) => target => {
					//console.debug('isWatched:', 'target:', target)

					// Scenario 1: target is a string
					if (typeof (target) === 'string') {
						//console.debug('isWatched:', 'target is a string')
						if (target in state.watches) {
							return state.watches[target] >= 1
						}
					}

					// Scenario 2: target is an item
					if (typeof (target) === 'object') {
						//console.debug('isWatched:', 'target is an object')
						if (typeof (target.key) !== 'string' || target.key === '') {
							console.error('isWatched:', 'target is not a valid item; target.key is not a string')
							return // target is not a valid item; target.key is not a string
						}
						if (target.tags === undefined || target.tags === null) {
							target.tags = []
						}
						if (!Array.isArray(target.tags)) {
							console.error('isWatched:', 'target is not a valid item; target.tags is not an array')
							return // target is not a valid item; target.tags is not an array
						}

						// Check if the key is watched.
						if (target.key in state.watches) {
							if (state.watches[target.key] >= 1) {
								//console.debug('isWatched:', 'target key is watched')
								return true
							}
						}

						// Check if any of the tags are watched.
						let watched = false
						target.tags.forEach(tag => {
							if (tag in state.watches) {
								if (state.watches[tag] >= 1) {
									//console.debug('isWatched:', 'target tag', tag, 'is watched')
									watched = true
								}
							}
						})
						if (watched) {
							return true
						}

					}

					//console.debug('isWatched:', 'target is not watched')
					return false // not being watched
				},

			},

			// [Synchronous] trackable changes to the state.
			// These methods are allowed to change the state.
			mutations: {

				// Update the list of tags.
				[MUTATE_CACHE_TAGS] (state, tags) {
					state.tags = tags
				},

				// Update the number of stored items.
				[MUTATE_CACHE_STORED] (state, count) {
					state.stored = count
				},

				// Increment the number of watchers for a given term.
				[MUTATE_INCREMENT_WATCHERS] (state, term) {
					let numberWatchers = 0
					if (term in state.watches) {
						numberWatchers = state.watches[term]
						if (numberWatchers < 0) {
							numberWatchers = 0
						}
					}
					numberWatchers += 1
					Vue.set(state.watches, term, numberWatchers)
					// console.log('cache:', term, 'now has', numberWatchers, '(local) watchers') // TOO VERBOSE
				},

				// Decrement the number of watchers for a given term.
				[MUTATE_DECREMENT_WATCHERS] (state, term) {
					let numberWatchers = 0
					if (term in state.watches) {
						numberWatchers = state.watches[term]
						numberWatchers -= 1
						if (numberWatchers < 0) {
							numberWatchers = 0
						}
					}
					if (numberWatchers <= 0) {
						Vue.delete(state.watches, term)
					} else {
						Vue.set(state.watches, term, numberWatchers)
					}
					// console.log('cache:', term, 'now has', numberWatchers, '(local) watchers') // TOO VERBOSE
				},

				// Update items in the list of watched items.
				[MUTATE_WATCHED_ITEMS] (state, items) {
					if (!Array.isArray(items)) {
						items = [items]
					}
					items.forEach(item => {
						// item = { key: '', tags: [], value: Any, ...meta }
						if (item.value === undefined || item.value === null) {
							Vue.delete(state.watchedItems, item.key)
						} else {
							Vue.set(state.watchedItems, item.key, item)
						}
					})
				},

				// Remove items from the watched items list which are no longer watched.
				[MUTATE_PRUNE_WATCHED_ITEMS] (state) {
					let keys = Object.keys(state.watchedItems)
					keys.forEach(key => {
						let item = state.watchedItems[key]
						let watched = this.getters[NS + 'isWatched'](item)
						if (!watched) {
							Vue.delete(state.watchedItems, key)
						}
					})
				},

			},

			// [Asynchronous] actions which may change the state.
			// These methods must use the getters and mutations to access state.
			actions: {

				// [Asynchronous] Set secret key and enable encryption.
				setEncryptionKey (context, key) {
					return new Promise((resolve, reject) => {
						if (typeof (key) !== 'string' || key === '') {
							reject('secret key is not a string')
							return
						}
						let useEncryption = options.useEncryption
						let bytes = hash(decodeUTF8(key + options.secretToken)) // 64 bytes
						let previousKey = secrets.key
						secrets.key = bytes.subarray(0, secretbox.keyLength) // 32 bytes
						secrets.timestamp = now()
						stashSecrets()
						let keyChanged = (encodeBase64(secrets.key) !== encodeBase64(previousKey))
						if (!useEncryption) {
							options.useEncryption = true
							console.log('cache:', 'encryption is now enabled')
						} else if (keyChanged) {
							console.log('cache:', 'encryption key changed')
						}

						// Update the list of watched items using the new key for decryption.
						let promises = []
						if (keyChanged) {
							Object.keys(context.state.watches).forEach(term => {
								promises.push(context.dispatch('rewatch', term))
							})
						}
						Promise.all(promises).finally(() => resolve())
					})
				},

				// [Synchronous] Clear secret key and disable encryption.
				clearEncryptionKey () {
					secrets.key = new Uint8Array()
					secrets.timestamp = now()
					clearStash()
					console.log('cache:', 'encryption key cleared')
					this.useEncryption = false
					console.log('cache:', 'encryption is now disabled')
				},

				// [Asynchronous] Store a value in the cache.
				store (context, item) {
					// item = { key: '', tags: [], value: Any, ...meta }
					return new Promise((resolve, reject) => {

						// Validate the item.
						if (typeof (item) !== 'object') {
							reject('item is not an object')
							return
						}
						if (typeof (item.key) !== 'string' || item.key === '') {
							reject('item.key is not a string')
							return
						}
						if (item.tags === undefined || item.tags === null) {
							item.tags = []
						}
						if (!Array.isArray(item.tags)) {
							reject('item.tags is not an array')
							return
						}
						item.tags = item.tags.map(tag => String(tag)) // force tags to be strings

						let performingDelete = (item.value === undefined || item.value === null)

						Promise.resolve().then(() => {
							if (performingDelete) {
								//console.debug('cache:', item.key, 'deleting item')
								// Delete the item.
								return db.keyval.delete(item.key)

							} else {
								//console.debug('cache:', item.key, 'putting item')
								// Store the item.
								item.stored = now()
								return db.keyval.put(encryptItem(item))
							}

						}).then(() => {
							//console.debug('cache:', item.key, 'getting stored count')
							// Count the number of stored items.
							return db.keyval.count()

						}).then(count => {
							//console.debug('cache:', item.key, 'updating stored count')
							// Update the stored count in the vuex state.
							context.commit(MUTATE_CACHE_STORED, count)
							return Promise.resolve()

						}).then(() => {
							//console.debug('cache:', item.key, 'updating tags')
							// Ensure that all tags are being tracked.
							let promises = []
							item.tags.forEach(tag => {
								if (!context.state.tags.includes(tag)) {
									promises.push(db.tags.put({ tag: tag }))
								}
							})
							return Promise.all(promises)

						}).then(() => {
							//console.debug('cache:', item.key, 'updating watched items list')
							if (performingDelete) {
								// Check if the item is in the watched items list.
								if (item.key in context.state.watchedItems) {
									//console.debug('cache:', item.key, 'is in the watched items list')
									context.commit(MUTATE_WATCHED_ITEMS, item)
								}

							} else {
								// Check if the item matches the current watch list.
								if (this.getters[NS + 'isWatched'](item)) {
									//console.debug('cache:', item.key, 'is being watched')
									context.commit(MUTATE_WATCHED_ITEMS, item)
								}
							}

							return Promise.resolve()

						}).then(() => {
							//console.debug('cache:', item.key, 'stored')
							resolve()

						}).catch(error => {
							console.error('store:', item.key, 'error:', error)
							reject(error)

						})
					})
				},

				// [Asynchronous] Start watching items matching the given term.
				watch (context, term) {
					return new Promise((resolve, reject) => {
						if (typeof (term) !== 'string' || term === '') {
							reject('term is not a string')
							return
						}

						// Check if the term is already watched.
						let watched = this.getters[NS + 'isWatched'](term)

						// Increment the number of watchers.
						context.commit(MUTATE_INCREMENT_WATCHERS, term)

						// If already watched, then resolve immediately.
						if (watched) {
							resolve('incremented')
							return
						}

						context
							.dispatch('rewatch', term)
							.then(resolve)
							.catch(reject)
					})
				},

				// [Asynchronous] Start watching items matching the given term.
				rewatch (context, term) {
					return new Promise((resolve, reject) => {
						if (typeof (term) !== 'string' || term === '') {
							reject('term is not a string')
							return
						}

						// Check if the term is watched.
						if (!this.getters[NS + 'isWatched'](term)) {
							reject('term is not watched')
							return
						}

						// Populate the watched items list using queries.
						Promise.resolve().then(() => {
							// Query for the term assuming it is a key.
							return db.keyval
								.get(term)
								.then(item => {
									if (item !== undefined && item !== null) {
										try {
											item = decryptItem(item)
											context.commit(MUTATE_WATCHED_ITEMS, item)
										} catch (error) {
											console.error('cache:', 'rewatch:', 'error:', error)
										}
									}
								})

						}).then(() => {
							// Query for the term assuming it is a tag.
							return db.keyval
								.where('tags').equals(term)
								.distinct()
								.toArray()
								.then(items => {
									if (items.length > 0) {
										items = items.map(item => {
											try {
												return decryptItem(item)
											} catch (error) {
												console.error('cache:', 'rewatch:', 'error:', error)
												item.error = String(error)
												return item
											}
										}).filter(item => item !== undefined && item !== null)
										context.commit(MUTATE_WATCHED_ITEMS, items)
									}
								})

						}).then(() => {
							resolve('watched')

						}).catch(error => {
							reject(error)
						})
					})
				},

				// [Asynchronous] Remove a term from the list of watched terms.
				unwatch (context, term) {
					return new Promise((resolve, reject) => {
						if (typeof (term) !== 'string' || term === '') {
							reject('term is not a string')
							return
						}

						// Decrement the number of watchers.
						context.commit(MUTATE_DECREMENT_WATCHERS, term)

						// If we're still watching this term, nothing more to do!
						if (this.getters[NS + 'isWatched'](term)) {
							resolve('decremented')
							return
						}

						// No longer watching this term, so prune the list
						// of items to free up memory.
						context.commit(MUTATE_PRUNE_WATCHED_ITEMS)
						resolve('unwatched')
					})
				},

			},

			// Special functionality
			plugins: [],

			// Extendability and Optional Namespaces
			modules: {},

		}) // end cache module
	}// end plugin
}
