<template>
	<div class="form-dialog">
		<PageDialog
			:open="open"
			:title="title"
			:actions="actions"
			:addClose="addClose"
			:width="width"
			:height="height"
			:minWidth="minWidth"
			:minHeight="minHeight"
			@update:open="$emit('update:open', $event)"
			@closed="$emit('closed', $event)"
			@button-clicked="$emit('button-clicked', $event)"
		>
			<div v-show="isPending" class="pending-container">
				<div class="pending">
					<div>
						<ProgressSpinner
							v-if="isPending"
							v-show="isPending"
							md-diameter="75"
						/>
					</div>
					<div class="pending-message">
						<p class="mdc-typography--body2">{{ pendingMessage }}</p>
					</div>
				</div>
			</div>
			<div v-show="isVisible && !isPending">
				<FormPage
					v-if="isVisible"
					:id="id"
					:items="items"
					@resized="$emit('resized', $event)"
					@form-callback="$emit('form-callback', $event)"
				/>
			</div>
		</PageDialog>
	</div>
</template>

<script>

import PageDialog from '../PageDialog'
import FormPage from './FormPage.vue'
import ProgressSpinner from '../../material/ProgressSpinner'

export default {
	name: 'FormDialog',
	components: {
		FormPage,
		PageDialog,
		ProgressSpinner,
	},
	props: [

		// PageDialog
		'open',
		'title',
		'actions',
		'addClose',
		'width', // specified in vw
		'height', // specified in vh
		'minWidth', // specified in vw
		'minHeight', // specified in vh

		// FormPage
		'id',
		'items',

		// Pending
		'pending',
		'pendingMessage',
	],
	data: function () {
		return {
			visible: false,
		}
	},
	computed: {
		isVisible () {
			return this.visible === true
		},
		isPending () {
			return this.pending === true
		},
	},
	watch: {

		open: function (value) {
			if (value === true) {
				this.visible = true
			} else {
				this.$nextTick(() => {
					this.visible = false
				})
			}
		},

	},
}

</script>

<style lang="scss" scoped>

.pending-container {
	min-height: 10vh;
}

.pending {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pending-message {
	margin-top: 2em;
	display: block;
	clear: both;
}

</style>
