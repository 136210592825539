//
// VueJS Single Page Application main entry point.
// @see: https://vuejs.org/v2/
//
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

///////////////////////////////////////////////////////////////////////

let scripts = document.getElementsByTagName('script')
let lastScript = scripts[scripts.length - 1]
let scriptName = lastScript.src.split('/').reverse()[0]
let appVersion = scriptName.split('.')[1]
window.appVersion = appVersion
console.info('Version: ' + window.appVersion)

///////////////////////////////////////////////////////////////////////

import Vue from 'vue'

import 'material-components-web/dist/material-components-web.js'
import 'material-components-web/dist/material-components-web.css'
import './index.css'

///////////////////////////////////////////////////////////////////////

import App from './components/App'
import store from './store'
import router from './router'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

///////////////////////////////////////////////////////////////////////

Vue.config.productionTip = false
Vue.config.devtools = true

App.el = '#app'
App.store = store
App.router = router
window.app = new Vue(App)
