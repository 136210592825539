<template>
	<div class="server-action-disable-dialog">
		<FormDialog
			:open="open"
			@update:open="$emit('update:open', $event)"
			@closed="$emit('closed', $event)"
			:pending="pending"
			:pendingMessage="pendingMessage"
			:title="dialogTitle"
			:items="formItems"
			:actions="formActions"
			@form-callback="registerCallback"
		/>
	</div>
</template>

<script>

import FormCallbacksMixin from '../common/forms/FormCallbacksMixin'
import FormDialog from '../common/forms/FormDialog'
import ProvisioningAPI from '../common/ProvisioningAPI'
import {mapActions, mapGetters} from 'vuex'

const configModesLeft = [
	// 'agent',
	'scripting',
	'warming',
	'haproxy',
	'nginx',
]
const configModesRight = [
	'varnish',
	'logstash',
	'vector',
]
const supportedConfigModes = [
	'agent',
	'scripting',
	'warming',
	'haproxy',
	'nginx',
	'varnish',
	'logstash',
	'vector',
]

const modeNames = {
	'agent': 'Agent',
	'scripting': 'Scripting',
	'warming': 'Warming',
	'haproxy': 'HAProxy',
	'nginx': 'Nginx',
	'varnish': 'Varnish',
	'logstash': 'Logstash',
	'vector': 'Vector',
}

const rfcToUnix = (timestamp) => {
	if (isNaN(timestamp)) {
		return Math.round(new Date(timestamp).getTime() / 1000)
	}
	return timestamp
}

export default {
	name: 'DisableDialog',
	mixins: [
		FormCallbacksMixin,
		ProvisioningAPI,
	],
	components: {
		FormDialog
	},
	props: [
		'open',
		'serverName',
		'desiredDisabled', // list of modes
		'saveAction',
		'saveStatus',
	],
	data: function () {
		return {
			pending: true,
			pendingWatches: true,
			pendingMessage: undefined,
			disableComment: undefined,
			disableAll: false,
			disableModes: [],

			activeWatches: [],
			serverByMode: {},
		}
	},
	watch: {
		open: function () {
			if (this.open) {
				this.dialogOpened()
			} else {
				this.dialogClosed()
			}
		},
		serverName: function () {
			if (this.serverName === undefined) {
				return
			}
			if (this.activeWatches.length) {
				this.stopWatchingForUpdates()
				this.startWatchingForUpdates()
			}
		},
		watchedServers: function (value) {
			if (this.hasValue(value) && value.length > 0) {
				this.receiveServerDataFromAPI(value, 'websocket')
				this.$nextTick(() => {
					if (this.pendingWatches) {
						this.pendingWatches = false
						this.pendingMessage = undefined
					}
				})
			}
		},
		pendingWatches: function (value) {
			if (value === false) {
				this.$nextTick(() => {
					this.pending = false
				})
			}
		},
		serverByMode: function () {
			this.$nextTick(this.forceSpecificCheckboxes)
		},
		saveStatus: function (value) {
			if (value === undefined) {
				return
			}
			this.pendingMessage = value
		},
	},
	computed: {
		...mapGetters([
			'appConfig',
			'topicResources',
		]),

		selectedServerLowercase () {
			if (this.serverName === undefined) {
				return undefined
			}
			return this.serverName.toLowerCase()
		},

		dialogTitle () {
			if (this.serverName === undefined) {
				return undefined
			}
			let pop = this.popFromHostname(this.serverName)
			let name = this.nameFromHostname(this.serverName)
			// name = name.charAt(0).toUpperCase() + name.slice(1)
			let title = 'Disable ' + name + '.' + pop
			return title.trim()
		},
		formItems () {
			let result = []
			if (this.pending) {
				return result
			}

			let agentIsDisabled = this.modeIsAlreadyDisabled('agent')

			let defaultComment = undefined
			if (agentIsDisabled) {
				result.push({
					id: 'info',
					component: 'FormCard',
					text: '**The agent is already disabled.**' +
						' All other configuration modes should be disabled as a result.' +
						'\n\n' +
						' Clicking save on this dialog will correct this situation by disabling all modes.' +
						'\n\n' +
						'For more fine-grained control, you can enable the agent and then enable or disable specific modes.',
				})
				defaultComment = 'Disabling all configuration modes because agent is already disabled'
				if (this.disableAll === false) {
					// eslint-disable-next-line
					this.$nextTick(() => {
						this.setAllDisabled(true)
					})
				}
			}

			result.push({
				id: 'comment',
				component: 'FormBasicInput',
				title: 'Comment',
				placeholder: '',
				autofill: 'off',
				required: true,
				defaultValue: defaultComment,
				help: 'Provide a reason why you are disabling this (for future reference).',
				valueHandler: (text) => {
					if (text === undefined) {
						return
					}
					console.debug('[DEBUG] comment: valueHandler: text:', text)
					this.disableComment = text
				},
			})

			result.push({
				id: 'all',
				component: 'FormCheckbox',
				label: 'Disable Agent and all configuration modes',
				disabled: agentIsDisabled,
				checkedDesired: agentIsDisabled,
				valueHandler: (checked) => {
					this.$nextTick(() => {
						this.setAllDisabled(checked)
					})
				},
			})

			let leftItems = []
			let rightItems = []
			const defineCheckbox = (mode) => {
				let label = 'Disable ' + modeNames[mode] + ' mode'
				let configured = this.modeIsConfigured(mode)
				if (!configured) {
					label += ' (inactive)'
				}
				let alreadyDisabled = this.modeIsAlreadyDisabled(mode)
				if (alreadyDisabled) {
					label += ' (disabled)'
				}
				return {
					id: 'mode-' + mode,
					component: 'FormCheckbox',
					label: label,
					hidden: agentIsDisabled,
					disabled: !configured || alreadyDisabled,
					checkedDesired: alreadyDisabled,
					valueHandler: (checked) => {
						this.setMode(mode, checked)
					},
				}
			}
			configModesLeft.forEach(mode => leftItems.push(defineCheckbox(mode)))
			configModesRight.forEach(mode => rightItems.push(defineCheckbox(mode)))
			result.push({
				id: 'modes',
				component: 'FormColumns',
				left: leftItems,
				right: rightItems,
			})

			result.push({
				id: 'silence',
				component: 'FormCheckbox',
				label: 'Silence health checks for disabled functionality',
				disabled: true,
				checkedDesired: true,
			})

			// result.push({
			// 	id: 'info',
			// 	component: 'FormCard',
			// 	text: '',
			// })

			return result
		},

		canSave: function () {
			if (this.pending) {
				return false
			}
			if (!this.hasValue(this.disableComment)) {
				return false
			}
			return this.disableAll || this.disableModes.length > 0
		},
		formActions () {
			if (this.serverName === undefined) {
				return []
			}
			return [
				{
					id: 'cancel',
					label: 'Cancel',
					disabled: this.pending,
					callback: () => {
						this.$emit('update:open', false)
					},
				},
				{
					id: 'save',
					label: 'Save',
					disabled: this.pending || !this.canSave,
					callback: () => {
						this.pending = true
						if (this.saveAction === undefined) {
							console.error('[ERROR] DisableDialog: saveAction is undefined')
							return
						}
						let modesToDisable = this.disableModes
						if (this.disableAll) {
							if (!modesToDisable.includes('agent')) {
								// must be able to disable agent even if it is not configured
								modesToDisable.push('agent')
							}
							supportedConfigModes.forEach(mode => {
								if (modesToDisable.includes(mode)) {
									return
								}
								if (this.modeIsConfigured(mode) && !this.modeIsAlreadyDisabled(mode)) {
									modesToDisable.push(mode)
								}
							})
						}
						return this.saveAction(this.serverName, modesToDisable, this.disableComment)
					}
				},
			]
		},

		watchedServers () {
			let result = []
			this.activeWatches.forEach((watch) => {
				let servers = this.topicResources(watch.topic)
				if (servers !== undefined) {
					// console.debug('watchedServers', watch.topic, servers)
					let server = servers[watch.id]
					if (server !== undefined) {
						result.push(server)
					}
				}
			})
			return result
		},
	},
	beforeDestroy () {
		this.reset()
	},
	methods: {
		...mapActions([
			'watch',
			'rewatch',
			'unwatch',
		]),

		reset () {
			this.stopWatchingForUpdates()
			this.clearFormCallbacks()
			this.disableAll = false
			this.disableModes = []
			this.pending = true
			this.pendingWatches = true
			this.pendingMessage = undefined
			this.serverByMode = {}
		},

		dialogOpened () {
			console.debug('[DEBUG] DisableDialog: opened')
			this.reset()
			if (this.serverName === undefined) {
				console.error('[ERROR] DisableDialog: dialogOpened: serverName is undefined')
				return
			}
			this.startWatchingForUpdates()
		},
		dialogClosed () {
			console.debug('[DEBUG] DisableDialog: closed')
			this.reset()
		},

		forceSpecificCheckboxes () {
			console.debug('[DEBUG] DisableDialog: forceSpecificCheckboxes')
			supportedConfigModes.forEach(mode => {
				if (mode === 'agent') {
					return
				}
				if (!this.modeIsConfigured(mode)) {
					this.disableFormComponent('mode-' + mode)
					this.setFormValue('mode-' + mode, false)
					return
				}
				if (this.modeIsAlreadyDisabled(mode)) {
					this.disableFormComponent('mode-' + mode)
					this.setFormValue('mode-' + mode, true)
				}
			})
		},
		setAllDisabled (disable) {
			if (this.pending) {
				return
			}
			console.debug('[DEBUG] DisableDialog: setAllDisabled', disable)
			this.disableAll = disable
			this.forceSpecificCheckboxes()
			supportedConfigModes.forEach(mode => {
				if (mode === 'agent') {
					return
				}
				if (!this.modeIsConfigured(mode) || this.modeIsAlreadyDisabled(mode)) {
					return
				}
				if (disable) {
					this.disableFormComponent('mode-' + mode)
					this.setFormValue('mode-' + mode, true)
				} else {
					this.enableFormComponent('mode-' + mode)
					let value = this.disableModes.includes(mode)
					console.debug('[DEBUG] DisableDialog: setAllDisabled: mode', mode, value)
					this.setFormValue('mode-' + mode, value)
				}
			})
		},
		setMode (mode, disable) {
			if (this.disableAll) {
				return
			}
			if (!this.modeIsConfigured(mode)) {
				return
			}
			if (this.modeIsAlreadyDisabled(mode)) {
				return
			}
			if (disable) {
				if (this.disableModes.includes(mode)) {
					return
				}
				this.disableModes.push(mode)
			} else {
				const index = this.disableModes.indexOf(mode)
				if (index !== -1) {
					this.disableModes.splice(index, 1)
				}
			}
		},

		modeIsConfigured (mode) {
			if (this.hasValue(this.serverByMode)) {
				let configured = this.serverByMode[mode] !== undefined
				// console.debug('[DEBUG] DisableDialog: modeIsConfigured', mode, configured)
				return configured
			}
			return false
		},
		modeIsAlreadyDisabled (mode) {
			if (this.hasValue(this.serverByMode)) {
				let server = this.serverByMode[mode]
				if (server !== undefined) {
					let disabled = server.attributes.disabled === true
					console.debug('[DEBUG] DisableDialog: modeIsAlreadyDisabled', mode, disabled)
					return disabled
				}
			}
			return false
		},

		topicToMode (topic) {
			let mode = topic.replace('v1-', '')
			if (mode.includes('-')) {
				mode = mode.split('-')[0]
			}
			return mode
		},
		modeToTopic (mode) {
			switch (mode) {
			case 'agent':
			case 'haproxy':
			case 'nginx':
				return 'v1-' + mode
			default:
				return 'v1-' + mode + '-server'
			}
		},

		startWatchingForUpdates () {
			if (this.selectedServerLowercase === undefined) {
				console.error('[ERROR] DisableDialog: startWatchingForUpdates: selectedServerLowercase is undefined')
				return
			}
			this.pendingWatches = true
			this.pendingMessage = 'Loading...'
			supportedConfigModes.forEach(mode => {
				let desiredWatch = {
					'topic': this.modeToTopic(mode),
					'id': this.selectedServerLowercase,
				}
				let existingIndex = this.activeWatches.findIndex(watch => {
					return watch.topic === desiredWatch.topic && watch.id === desiredWatch.id
				})
				if (existingIndex === -1) {
					this.activeWatches.push(desiredWatch)
					this.watch(desiredWatch)
				} else {
					this.rewatch(desiredWatch)
				}
			})
		},
		stopWatchingForUpdates () {
			let previousWatches = this.activeWatches
			this.$set(this, 'activeWatches', [])
			previousWatches.forEach(watch => {
				this.unwatch(watch)
			})
		},
		isModeBeingWatched (mode) {
			let desiredWatch = {
				'topic': this.modeToTopic(mode),
				'id': this.selectedServerLowercase,
			}
			let existingIndex = this.activeWatches.findIndex(watch => {
				return watch.topic === desiredWatch.topic && watch.id === desiredWatch.id
			})
			return existingIndex !== -1
		},

		receiveServerDataFromAPI (serversList, method) {
			console.debug('[DEBUG] DisableDialog: receiveServerDataFromAPI', method, serversList.length, 'servers')
			let found = false
			serversList.forEach(server => {
				if (server.id === this.selectedServerLowercase) {
					found = true

					// Thaw frozen objects so we can modify them as needed
					if (Object.isFrozen(server)) {
						server = Object.assign({}, server)
					}

					// Extract configMode from the type field
					server.configMode = this.topicToMode(server.type)

					// if (server.configMode === 'nginx') {
					// 	console.debug('received',
					// 		this.nameFromHostname(server.id) + '.' + this.popFromHostname(server.id),
					// 		server.configMode, 'data via', method,
					// 		'for', server.modified,
					// 	)
					// }

					// discard older server data
					let existingServer = this.serverByMode[server.configMode]
					if (existingServer !== undefined) {
						let existingLastModified = rfcToUnix(existingServer.modified)
						let newLastModified = rfcToUnix(server.modified)
						if (newLastModified < existingLastModified) {
							// console.warn('received', server.configMode, 'data via', method, 'is older than existing data')
							return
						}
					}

					// Store the server data
					this.$set(this.serverByMode, server.configMode, server)

				}
			})
			if (!found) {
				return 'data not found for ' + this.selectedServerLowercase // ERROR - server not found
			}
			return undefined
		},


		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		shortPopCode (pop) {
			return pop.replace(/[0-9]/g, '')
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},

	}

}

</script>

<style scoped lang="scss">

</style>
