<template>
	<div v-show="!hidden">
		<component
			:is="item.component"
			v-for="item in visibleItems"
			:id="item.id"
			:key="item.id"
			:active="!item.hidden && item.id === selectedId"
			:callback="item.callback"
			:expandable="item.expandable"
			:hidden="item.hidden"
			:icon="item.icon"
			:iconFont="item.iconFont"
			:indent="item.indent"
			:item="item"
			:open="item.open"
			:title="item.title"
			:selectedId="selectedId"
			:kind="item.kind"
			:items="item.items"
			@drawer-click="clickHandler"
		/>
	</div>
</template>

<script>

import DrawerExpandable from './DrawerExpandable'
import DrawerLabel from './DrawerLabel'
import DrawerLink from './DrawerLink'
import DrawerSelect from './DrawerSelect'
import DrawerSeparator from './DrawerSeparator'

export default {
	name: 'DrawerItems',
	components: {
		DrawerExpandable,
		DrawerLabel,
		DrawerLink,
		DrawerSelect,
		DrawerSeparator,
	},
	props: [
		'hidden',
		'items',
		'selectedId',
	],
	computed: {
		visibleItems () {
			return this.items.filter(item => {
				return !item.hidden
			})
		},
	},
	methods: {
		clickHandler ([event, sender]) {
			// console.debug('DrawerItems: clickHandler', event, sender)
			event.preventDefault()
			if (this.callback === undefined) {
				this.$emit('drawer-click', [event, sender, this])
			} else {
				this.callback([event, sender, this])
			}
		},
	},
}
</script>

<style scoped lang="scss">

.mdc-list-item__text {
	font-family: Roboto, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 0.0178571429em;
	text-decoration: inherit;
	text-transform: inherit;
	display: block;
	line-height: normal;
	color: rgba(0, 0, 0, 0.6);

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

h6 {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

</style>
