<template>
	<div :class="{
			'no-text-select': true,
			'selection-container': (allowSelection === true),
			'selectable': !mouseSelectionShow && (allowSelection === true),
			'selecting': mouseSelectionShow,
			'debug': false,
		}"
		 v-on:mousedown="startMouseSelectionEvent"
		 v-on:mouseleave="stopMouseSelectionEvent"
		 v-on:mousemove="mouseMoveEvent"
		 v-on:mouseup="stopMouseSelectionEvent"
	>
		<div v-show="cssReady" class="item-hex-grid" draggable="false">
			<div class="hex-group-container" draggable="false">
				<div v-for="group in groupedItems"
					 v-bind:key="group.key"
					 :class="{
						'hex-group': true,
						'bordered': showGroupTitle === true,
						'good': group.status === 'good',
						'unhappy': group.status === 'unhappy',
						'bad': group.status === 'bad',
				     }"
					 draggable="false"
				>
					<h3 v-if="showGroupTitle === true"
						:class="{
							'good': group.status === 'good',
							'unhappy': group.status === 'unhappy',
							'bad': group.status === 'bad',
					     }">{{ group.title }}</h3>
					<div class="hex-grid" draggable="false">
						<div class="hex-container" draggable="false">
							<div
								v-for="(item, index) in group.items"
								v-bind:key="index"
								class="item-container"
							/>
							<div
								v-bind:class="{
										hex: true,
										clickable: !mouseSelectionShow,
										disabled: item.status === 'disabled',
										good: item.status === 'good',
										unhappy: item.status === 'unhappy',
										bad: item.status === 'bad',
										selected: isSelected(item),
										active: hasActiveFloatMenu(item),
									}"
								v-bind:data-id="item.id"
								v-on:mouseenter="itemMouseEvent($event, item)"
								v-on:mouseleave="itemMouseEvent($event, item)"
								v-on:mousedown.prevent.stop=""
								v-on:click.prevent.stop="itemClicked($event, item)"
							>
								<div v-show="item.icon" class="icon-container">
									<span class="icon-circle">
										<span class="material-icons">{{ item.icon }}</span>
									</span>
								</div>
								<span v-if="item.detailsUrl">
										<a :href="item.detailsUrl">
											<strong v-if="showItemTitle === true && item.title !== undefined">{{
													item.title
												}}</strong>
											{{ item.label }}
											<template v-if="showGroupLabel !== false">{{ item.group }}</template>
										</a>
									</span>
								<span v-else>
									<strong v-if="showItemTitle === true && item.title !== undefined">{{
											item.title
										}}</strong>
									{{ item.label }}
									<template v-if="showGroupLabel !== false">{{ item.group }}</template>
								</span>
								<div class="side-hexes" v-if="readSideHexes(item, 0) !== undefined">
									<div class="side-hex" :class="readSideHexes(item, 0)">&#x2B22;</div>
									<div class="side-hex" :class="readSideHexes(item, 1)"
										 v-show="readSideHexes(item, 1) !== undefined"
									>&#x2B22;
									</div>
									<div class="side-hex" :class="readSideHexes(item, 2)"
										 v-show="readSideHexes(item, 2) !== undefined"
									>&#x2B22;
									</div>
									<div class="side-hex" :class="readSideHexes(item, 3)"
										 v-show="readSideHexes(item, 3) !== undefined"
									>&#x2B22;
									</div>

								</div>
							</div>
							<ul v-if="hasActiveFloatMenu(item) && callFloatMenuGenerator(item).length >= 1"
								v-bind:id="floatMenuElementId(item)"
								v-bind:class="floatMenuContainerClasses(item)"
								v-on:mouseleave="deactivateFloatMenu($event, item)"
								v-on:mouseover.prevent.stop=""
								v-on:click.prevent.stop="itemClicked($event, item)"
							>
								<li v-for="button in callFloatMenuGenerator(item)"
									v-show="button.hidden !== true"
									v-bind:id="buttonElementId(item, button)"
									v-bind:key="button.id"
									:class="{
										'float-menu-element': true,
										'float-menu-element-active': shouldShowButtonHelp(item, button),
								    }"
									v-on:mouseleave="hideButtonHelp(item, button)"
								>
									<div v-bind:id="buttonHelpElementId(item, button)"
										 v-bind:class="buttonHelpClasses(item, button)"
									>
										<span :class="{
											'help-text': true,
											'help-text-visible': shouldShowButtonHelp(item, button)
										}">
											{{ button.label }}
										</span>
									</div>
									<button class="mdc-icon-button mdc-icon-button--touch material-icons"
											v-bind:aria-label="button.label"
											v-on:mouseenter="showButtonHelp(item, button)"
											v-on:mouseleave="hideButtonHelp(item, button)"
											v-on:click.prevent.stop="floatMenuButtonClicked($event, item, button)"
									>
										<div class="mdc-icon-button__ripple"></div>
										{{ button.icon }}
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="mouseSelectionShow"
			 class="mouse-selection"
			 draggable="false"
		></div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
	name: 'HexGrid',
	components: {},
	props: [
		'items',
		'allowSelection',
		'showItemTitle',
		'showGroupTitle',
		'showGroupLabel',
		'floatMenuGenerator',
	],
	data () {
		return {
			cssReady: false,
			previouslySelected: [],
			recentlySelected: [],
			activeFloatMenu: [],
			openFloatMenu: [],
			showPreviousFloatMenuItems: false,
			previousFloatMenuItems: undefined,
			buttonHelpId: undefined,
			buttonHelpReactHint: 0,
			itemActiveHovering: undefined,
			itemHoveringRef: undefined,
			mouseSelectionElement: undefined,
			mouseSelectionInterval: undefined,
			mouseSelectionShow: false,
			mouseSelectionStartX: 0,
			mouseSelectionStartY: 0,
			mouseSelectionEndX: 0,
			mouseSelectionEndY: 0,
			resizeObserver: undefined,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		groupedItems () {
			let groups = {}
			this.items.forEach(item => {
				let groupKey = 'unknown'
				if (item.group !== undefined) {
					groupKey = item.group
				}
				if (!groups.hasOwnProperty(groupKey)) {
					groups[groupKey] = {
						key: groupKey,
						title: groupKey,
						status: 'unknown',
						items: []
					}
				}
				groups[groupKey].items.push(item)
				groups[groupKey].status = this.groupStatus(groups[groupKey].items)
			})
			return Object.values(groups)
		},

		selected () {
			let result = []
			if (this.allowSelection !== true) {
				return result
			}
			this.recentlySelected.forEach(meta => {
				result.push(meta)
			})
			this.previouslySelected.forEach(meta => {
				result.push(meta)
			})
			// deduplicate result
			result = result.filter((meta, pos, self) => {
				return self.findIndex(meta2 => meta.id === meta2.id) === pos
			})
			result.sort((meta, meta2) => (meta.id > meta2.id) ? 1 : ((meta2.id > meta.id) ? -1 : 0))
			return result
		},
		selectedItems () {
			let result = []
			this.selected.forEach(meta => {
				let index = this.items.findIndex(item => item.id === meta.id)
				if (index > -1) {
					result.push(this.items[index])
				}
			})
			return result
		},
		hasSelectedItems () {
			return this.selectedItems.length > 0
		},

		mouseSelectionLeft () {
			if (this.mouseSelectionStartX < this.mouseSelectionEndX) {
				return this.mouseSelectionStartX
			} else {
				return this.mouseSelectionEndX
			}
		},
		mouseSelectionTop () {
			if (this.mouseSelectionStartY < this.mouseSelectionEndY) {
				return this.mouseSelectionStartY
			} else {
				return this.mouseSelectionEndY
			}
		},
		mouseSelectionWidth () {
			if (this.mouseSelectionStartX < this.mouseSelectionEndX) {
				return this.mouseSelectionEndX - this.mouseSelectionStartX
			} else {
				return this.mouseSelectionStartX - this.mouseSelectionEndX
			}
		},
		mouseSelectionHeight () {
			if (this.mouseSelectionStartY < this.mouseSelectionEndY) {
				return this.mouseSelectionEndY - this.mouseSelectionStartY
			} else {
				return this.mouseSelectionStartY - this.mouseSelectionEndY
			}
		},

	},
	mounted () {
		this.workaroundCssMinifyBug()
		this.setupHeightFixer()
		this.mouseSelectionElement = this.$el.querySelector('.mouse-selection')
		this.mouseSelectionInterval = setInterval(
			() => this.findSelectedHexes(false), 333
		)
	},
	beforeDestroy () {
		if (this.mouseSelectionInterval !== undefined) {
			clearInterval(this.mouseSelectionInterval)
			this.mouseSelectionInterval = undefined
		}
		if (this.resizeObserver !== undefined) {
			this.resizeObserver.disconnect()
			this.resizeObserver = undefined
		}
	},
	methods: {
		itemClicked (ev, item) {
			if (ev.ctrlKey) {
				if (this.isSelected(item)) {
					this.deselectItem(item.id)
				} else {
					this.selectItem(item, ev.target)
				}
			} else {
				this.$emit('item-clicked', {
					item: item,
					inSelection: this.isSelected(item)
				})
			}
		},
		workaroundCssMinifyBug () {
			const elementId = 'itemGridWorkaroundCssMinifyBug'
			if (document.querySelector('#' + elementId) === null) {
				const styleString = `.item-hex-grid .hex-container::before {
\tcontent: "B";
\twidth: calc(var(--s) / 2 + var(--m));
\tfloat: left;
\theight: 120%;
\tshape-outside: repeating-linear-gradient(
\t\t#0000 0 calc(var(--f) - 3px),
\t\t#000 0 var(--f));
}
`
				const style = document.createElement('style')
				style.id = 'itemGridWorkaroundCssMinifyBug'
				style.textContent = styleString
				document.head.append(style)
			}
			this.cssReady = true
		},
		setupHeightFixer () {
			if (this.resizeObserver !== undefined) {
				this.resizeObserver.disconnect()
				this.resizeObserver = undefined
			}
			this.resizeObserver = new ResizeObserver(entries => {
				this.$nextTick(this.fixHeight)
			})
			this.resizeObserver.observe(this.$el)
			this.$nextTick(this.fixHeight)
		},
		fixHeight () {
			// console.debug('HexGrid: fixHeight')

			const hexWidth = 108
			const rowHeight = 94

			const containers = this.$el.querySelectorAll('.hex-container')
			// console.debug(containers)
			containers.forEach(container => {
				const hexes = container.querySelectorAll('.hex')
				const numberOfHexes = hexes.length
				let hexesPerRow = numberOfHexes
				if (numberOfHexes % 2 === 0) {
					container.classList.add('even')
					hexesPerRow = Math.floor((container.clientWidth - (hexWidth / 2)) / hexWidth)
				} else {
					container.classList.add('odd')
					hexesPerRow = Math.floor((container.clientWidth) / hexWidth)
				}
				const numberOfRows = Math.ceil(numberOfHexes / hexesPerRow)
				const enoughHeightForRows = Math.floor(container.clientHeight / rowHeight)
				// console.debug(container, numberOfHexes, hexesPerRow, numberOfRows, enoughHeightForRows)
				if (numberOfRows > enoughHeightForRows) {
					container.classList.add('extra-row')
				} else {
					container.classList.remove('extra-row')
				}
			})

		},

		selectItem (item, element) {
			// add to recentlySelected
			this.recentlySelected.push({
				'id': item.id,
				'item': item,
				'element': element
			})
			if (!this.mouseSelectionShow) {
				this.notifySelectionChanged()
			}
		},
		deselectItem (id) {
			this.previouslySelected = this.previouslySelected.filter(meta => {
				return meta.id !== id
			})
			this.recentlySelected = this.recentlySelected.filter(meta => {
				return meta.id !== id
			})
			if (!this.mouseSelectionShow) {
				this.notifySelectionChanged()
			}
		},
		isSelected (item) {
			if (this.allowSelection !== true) {
				return false
			}
			return this.selected.findIndex(meta => meta.id === item.id) > -1
		},

		notifySelectionChanged () {
			if (this.allowSelection !== true) {
				return
			}
			this.$emit('selection-changed', {items: this.selectedItems})
		},

		startMouseSelectionEvent (ev) {
			if (this.allowSelection !== true) {
				return
			}
			if (ev.target instanceof HTMLDivElement) {
				// console.debug(ev.target.className)
				if (!(ev.target.className.split(' ').indexOf('hex') > -1)) {
					this.$nextTick(() => {

						// When the ctrl key is held down at the beginning
						// we want to perform an additive selection.
						if (ev.ctrlKey) {
							// merge the last recently selected set into our
							// previously selected set; then we can add to it.
							this.mergeRecentlySelectedInToPreviouslySelected()
						} else {
							// no ctrl key means we want to forget whatever was
							// selected before and simply start again.
							this.previouslySelected = []
						}

						// as we're starting new, nothing is recently selected.
						this.recentlySelected = []

						// set starting positions for the mouse selection box.
						this.mouseSelectionStartX = ev.clientX - 1
						this.mouseSelectionStartY = ev.clientY - 1
						this.mouseSelectionEndX = ev.clientX + 1
						this.mouseSelectionEndY = ev.clientY + 1

						// update the visual for the mouse selection box.
						this.updateMouseSelection()
					})
				}
			}
		},
		stopMouseSelectionEvent (ev) {
			if (this.mouseSelectionShow) {
				this.mouseSelectionShow = false
				this.$nextTick(() => {
					// force an update here because we just stopped
					this.findSelectedHexes(true)
					this.notifySelectionChanged()
				})
			}
		},
		mouseMoveEvent (ev) {
			if (this.mouseSelectionShow) {
				this.mouseSelectionEndX = ev.clientX + 1
				this.mouseSelectionEndY = ev.clientY + 1
				this.updateMouseSelection()
			}
		},
		updateMouseSelection () {
			this.mouseSelectionElement.style.left = this.mouseSelectionLeft + 'px'
			this.mouseSelectionElement.style.top = this.mouseSelectionTop + 'px'
			this.mouseSelectionElement.style.width = this.mouseSelectionWidth + 'px'
			this.mouseSelectionElement.style.height = this.mouseSelectionHeight + 'px'
			this.mouseSelectionShow = true
		},
		itemMouseEvent (ev, item) {
			// console.debug('itemMouseEvent', ev.type, ev, item)
			switch (ev.type) {
			case 'mouseenter':
				if (this.allowSelection === true && this.mouseSelectionShow) {
					if (ev.target.className.split(' ').indexOf('hex') > -1) {
						this.selectItem(item, ev.target) // fast path this to being selected
					}
					return
				}
				this.itemActiveHovering = item.id
				// console.debug('itemActiveHovering', item.id)
				if (this.itemHoveringRef !== undefined) {
					clearTimeout(this.itemHoveringRef)
					this.itemHoveringRef = undefined
				}
				if (item.enableFloatMenu === true) {
					this.itemHoveringRef = setTimeout(() => {
						// console.debug('itemActiveHovering', this.itemActiveHovering, '???', item.id)
						if (this.itemActiveHovering === item.id) {
							this.activateFloatMenu(item, ev.target)
						}
					}, 200)
				}
				break

			case 'mouseleave':
				this.itemActiveHovering = undefined
				// console.debug('itemActiveHovering', undefined)
				if (this.itemHoveringRef !== undefined) {
					clearTimeout(this.itemHoveringRef)
					this.itemHoveringRef = undefined
				}
				break

			}
		},

		findSelectedHexes (force) {
			if (this.allowSelection !== true) {
				return
			}
			if (this.mouseSelectionShow || force) {
				this.checkAllSelectedAreUnderSelection()
				this.$el.querySelectorAll('.hex').forEach(element => {
					const id = element.dataset.id
					if (!(this.selected.findIndex(meta => meta.id === id) > -1)) {
						if (this.checkElementIsUnderSelection(id, element)) {
							// add to recentlySelected
							this.recentlySelected.push({
								'id': id,
								'element': element
							})
						}
					}
				})
			}
		},
		checkAllSelectedAreUnderSelection () {
			// deduplicate the list
			this.recentlySelected = this.recentlySelected.filter((meta, pos, self) => {
				return self.findIndex(meta2 => meta.id === meta2.id) === pos
			})
			// only keep elements in the list that are within the selection area
			this.recentlySelected = this.recentlySelected.filter((meta, pos, self) => {
				return this.checkElementIsUnderSelection(meta.id, meta.element)
			})
		},
		mergeRecentlySelectedInToPreviouslySelected () {
			this.recentlySelected.forEach(meta => {
				this.previouslySelected.push(meta)
			})
			// deduplicate the list
			this.previouslySelected = this.previouslySelected.filter((meta, pos, self) => {
				return self.findIndex(meta2 => meta.id === meta2.id) === pos
			})
		},
		checkElementIsUnderSelection (id, element) {
			if (element === undefined) {
				console.error('checkElementIsUnderSelection: element is undefined')
				return false
			}

			let aLeft = this.mouseSelectionLeft
			let aRight = this.mouseSelectionLeft + this.mouseSelectionWidth
			let aTop = this.mouseSelectionTop
			let aBottom = this.mouseSelectionTop + this.mouseSelectionHeight

			let bPos = element.getBoundingClientRect()
			let bLeft = bPos.left
			let bRight = bPos.left + bPos.width
			let bTop = bPos.top
			let bBottom = bPos.top + bPos.height

			// if (bLeft > aRight) {
			// 	console.debug('bLeft > aRight (a too right)', 'bLeft', bLeft, 'aRight', aRight)
			// }
			// if (bRight < aLeft) {
			// 	console.debug('bRight < aLeft (b too left)', 'bRight', bRight, 'aLeft', aLeft)
			// }
			// if (bTop > aBottom) {
			// 	console.debug('bTop > aBottom (a too low)', 'bTop', bTop, 'aBottom', aBottom)
			// }
			// if (bBottom < aTop) {
			// 	console.debug('bBottom < aTop (b too high)', 'bBottom', bBottom, 'aTop', aTop)
			// }

			// http://tekpool.wordpress.com/2006/10/11/rectangle-intersection-determine-if-two-given-rectangles-intersect-each-other-or-not/
			let collide = !(bLeft > aRight
				|| bRight < aLeft
				|| bTop > aBottom
				|| bBottom < aTop
			)
			// console.debug(id, 'collide', collide)
			return collide
		},

		groupStatus (items) {
			let result = 'unknown'
			if (items !== undefined) {
				items.forEach(item => {
					switch (result) {
					case undefined:
					case 'unknown':
					case 'disabled':
					case 'good':
						switch (item.status) {
						case undefined:
						case 'unknown':
						case 'disabled':
							// ignore
							break
						default:
							result = item.status
						}
						break

					case 'unhappy':
						if (item.status === 'bad') {
							result = item.status
						}
						break
					}
				})
			}
			return result
		},

		activateFloatMenu (item, element) {
			// console.debug('activateFloatMenu', item.id)
			if (item.enableFloatMenu !== true) {
				return
			}
			if (this.floatMenuGenerator === undefined) {
				return
			}
			if (this.isSelected(item)) {
				return
			}
			this.showPreviousFloatMenuItems = false
			// this.activeFloatMenu.push({
			// 	'id': item.id,
			// 	'item': item,
			// 	'element': element
			// })
			this.activeFloatMenu = [{
				'id': item.id,
				'item': item,
				'element': element
			}]
			this.$nextTick(() => {
				setTimeout(() => {
					this.openFloatMenu.push({
						'id': item.id,
						'item': item,
						'element': element
					})
					this.buttonHelpReactHint++
					setTimeout(() => {
						this.buttonHelpReactHint++
					}, 200)
				}, 100)
			})
		},
		deactivateFloatMenu (ev, item) {
			// console.debug('deactivateFloatMenu', item.id)
			this.showPreviousFloatMenuItems = true
			this.$nextTick(() => {
				this.buttonHelpReactHint = 0
				this.openFloatMenu = this.openFloatMenu.filter(meta => {
					return meta.id !== item.id
				})
				setTimeout(() => {
					this.activeFloatMenu = this.activeFloatMenu.filter(meta => {
						return meta.id !== item.id
					})
				}, 250)
			})
		},
		deactivateAllFloatMenus () {
			// console.debug('deactivateAllFloatMenus')
			this.showPreviousFloatMenuItems = true
			this.$nextTick(() => {
				this.openFloatMenu = []
				if (this.activeFloatMenu.length > 0) {
					setTimeout(() => {
						this.activeFloatMenu = []
					}, 250)
				}
			})
		},
		hasActiveFloatMenu (item) {
			return this.activeFloatMenu.findIndex(meta => meta.id === item.id) > -1
		},
		hasOpenFloatMenu (item) {
			return this.openFloatMenu.findIndex(meta => meta.id === item.id) > -1
		},
		floatMenuContainerClasses (item) {
			let classes = []

			// This is always the container - important class for the scss
			classes.push('float-menu-container')

			if (this.hasActiveFloatMenu(item)) {
				classes.push('float-menu-active') // For debugging.
			}

			// Change the layout of the menu based on the number of items.
			let menu = this.callFloatMenuGenerator(item)
			let size = menu.length
			if (size >= 1) {
				if (size > 7) {
					size = 7
				}
				classes.push('float-menu-size-' + size)
			}

			// Indicate if the menu should be in the open state.
			// This is used to trigger the open/close animations.
			if (this.hasOpenFloatMenu(item)) {
				classes.push('float-menu-open')
			}

			// console.debug('floatMenuContainerClasses', classes)
			return classes
		},
		callFloatMenuGenerator (item) {
			if (this.showPreviousFloatMenuItems && this.previousFloatMenuItems !== undefined) {
				//
				// On close, we want to ensure that the animation has time to
				// complete before we stop returning the items. However, the
				// code which generates the items will probably have stopped
				// returning them already. So here we have a flag which allows
				// us to return the previously generated items (but with their
				// callbacks disabled).
				//
				// console.debug('returning previous float menu items')
				return this.previousFloatMenuItems.map(item => {
					item.callback = () => {
					} // disable the callback
					return item
				})
			}
			if (this.floatMenuGenerator !== undefined) {
				let items = this.floatMenuGenerator(item)
				this.previousFloatMenuItems = items
				return items
			}
			return []
		},
		floatMenuButtonClicked (ev, item, button) {
			this.deactivateAllFloatMenus()
			// console.debug('floatMenuButtonClicked', ev, item, button)
			if (button.callback !== undefined) {
				button.callback(item, button)
			} else {
				this.$emit('float-menu-button-clicked', item, button)
			}
		},

		showButtonHelp (item, button) {
			console.debug('showButtonHelp', button.id)
			this.buttonHelpId = button.id
			this.buttonHelpReactHint++
		},
		hideButtonHelp () {
			console.debug('buttonHelpId')
			this.buttonHelpId = undefined
			this.buttonHelpReactHint = 0
		},
		shouldShowButtonHelp (item, button) {
			return this.buttonHelpId === button.id
		},
		floatMenuElementId (item) {
			return String('float-menu-for-' + item.type + '-' + item.id).replaceAll('.', '-')
		},
		buttonElementId (item, button) {
			return String('float-menu-button-for-' + item.type + '-' + item.id + '-' + button.id).replaceAll('.', '-')
		},
		buttonHelpElementId (item, button) {
			return String('float-menu-help-for-' + item.type + '-' + item.id + '-' + button.id).replaceAll('.', '-')
		},
		buttonHelpClasses (item, button) {
			// console.debug('buttonHelpClasses', item, button)

			// Recalculate these classes every time we change the visibility
			// of the button help text. This is because the position of the
			// button may have changed, and we may need to reposition the text.

			// // eslint-disable-next-line no-unused-vars
			// let reactivityHint = this.buttonHelpId

			// eslint-disable-next-line no-unused-vars
			let hint = this.buttonHelpReactHint

			let classes = []
			classes.push('float-menu-help')

			let menuElement = this.$el.querySelector('#' + this.floatMenuElementId(item))
			let buttonElement = this.$el.querySelector('#' + this.buttonElementId(item, button))

			if (menuElement === null || buttonElement === null) {
				return classes
			}

			let menuPos = menuElement.getBoundingClientRect()
			let buttonPos = buttonElement.getBoundingClientRect()

			let menuHorizontalCenter = menuPos.left + (menuPos.width / 2)
			let buttonHorizontalCenter = buttonPos.left + (buttonPos.width / 2)

			let horizontal = ''
			if (buttonHorizontalCenter < (menuHorizontalCenter - 10)) {
				horizontal = '-left'
			} else if (buttonHorizontalCenter > (menuHorizontalCenter + 10)) {
				horizontal = '-right'
			}

			let menuVerticalCenter = menuPos.top + (menuPos.height / 2)
			let buttonVerticalCenter = buttonPos.top + (buttonPos.height / 2)

			let vertical = ''
			if (buttonVerticalCenter < (menuVerticalCenter - 10)) {
				vertical = '-top'
			} else if (buttonVerticalCenter > (menuVerticalCenter + 10)) {
				vertical = '-bottom'
			} else {
				// We are within the vertical margin, so we're probably side by
				// side. In that case we want the vertical alignment to be
				// different on each side of the menu. Assuming people will
				// work around the circle clockwise, we want the help text to
				// be "behind" them, otherwise it obscures the hit target
				// that they're most likely to want to click/hover next.
				if (horizontal === '-left') {
					vertical = '-bottom'
				} else if (horizontal === '-right') {
					vertical = '-top'
				} else {
					// In this scenario, we are in the center.
					// We don't want to show the help text at all in that case.
					vertical = ''
				}
			}

			if (vertical !== '' || horizontal !== '') {
				classes.push('float-menu-help' + vertical + horizontal)
			}

			// console.debug('buttonHelpClasses', button.id, vertical, horizontal)
			return classes
		},

		readSideHexes (item, index) {
			if (item.sideHexes === undefined) {
				return undefined
			}
			let hexes = item.sideHexes
			if (hexes.length <= index) {
				return undefined
			}
			return hexes[index]
		},

	}
}

</script>

<style lang="scss">

///////////////////////////////////////////////////////////////////////
// Trigonometry in Sass
//
// NB. Functions have to be defined before they are used, so they're up
//     here at the top of the style block.
//
///////////////////////////////////////////////////////////////////////

@function pi() {
	@return 3.14159265359;
}

@function pow($number, $exp) {
	$value: 1;
	@if $exp > 0 {
		@for $i from 1 through $exp {
			$value: $value * $number;
		}
	} @else if $exp < 0 {
		@for $i from 1 through -$exp {
			$value: $value / $number;
		}
	}
	@return $value;
}

@function fact($number) {
	$value: 1;
	@if $number > 0 {
		@for $i from 1 through $number {
			$value: $value * $i;
		}
	}
	@return $value;
}

@function rad($angle) {
	$unit: unit($angle);
	$unitless: $angle / ($angle * 0 + 1);
	// If the angle has 'deg' as unit, convert to radians.
	@if $unit == deg {
		$unitless: $unitless / 180 * pi();
	}
	@return $unitless;
}

@function sin($angle) {
	$sin: 0;
	$angle: rad($angle);
	// Iterate a bunch of times.
	@for $i from 0 through 10 {
		$sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
	}
	@return $sin;
}

@function cos($angle) {
	$cos: 0;
	$angle: rad($angle);
	// Iterate a bunch of times.
	@for $i from 0 through 10 {
		$cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
	}
	@return $cos;
}

@function tan($angle) {
	@return sin($angle) / cos($angle);
}


///////////////////////////////////////////////////////////////////////
// Hexagon Stuff
///////////////////////////////////////////////////////////////////////

.no-text-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.selection-container {
	padding: 0.25em 0 3em 0.5em; /* top, right, bottom, left */
	min-height: 60vh;

	background: linear-gradient(-90deg, rgba(0, 0, 0, .03) 1px, transparent 1px),
	linear-gradient(rgba(0, 0, 0, .03) 1px, transparent 1px),
	linear-gradient(-90deg, rgba(0, 0, 0, .01) 1px, transparent 1px),
	linear-gradient(rgba(0, 0, 0, .01) 1px, transparent 1px),
	linear-gradient(transparent 3px, #fafafa 3px, #fafafa 78px, transparent 78px),
	linear-gradient(-90deg, transparent 3px, #fafafa 3px, #fafafa 78px, transparent 78px),
	#fafafa;
	background-size: 8px 8px,
	8px 8px,
	160px 160px,
	160px 160px,
	160px 160px,
	160px 160px;
}

.selectable {
	cursor: crosshair;
}

.selecting {
	cursor: grabbing;
}

.mouse-selection {
	background: rgba(255, 255, 0, 0.15);
	border: 1px dashed gold;
	opacity: 1;
	z-index: 9000;
	position: fixed !important;
	cursor: grabbing;
	left: 0;
	top: 0;
	height: 1px;
	width: 1px;
}

.item-hex-grid .hex-group-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	padding: 0;
	margin: 0;
	margin-left: 1em;
}

.item-hex-grid .hex-group {
	margin: 0;
	padding: 0;
	width: auto;
}

.item-hex-grid .bordered {
	border: 2px dashed lightsteelblue;
	margin: 0.5em 1em;
	padding: 0.5em;
	width: auto;
}

.item-hex-grid .bordered.bad {
	border: 2px dashed lightsalmon;
}

.item-hex-grid .bordered.unhappy {
	border: 2px dashed #ffbf00;
}

.item-hex-grid .bordered.good {
	border: 2px dashed lightgreen;
}

.item-hex-grid .hex-group h3 {

	font-family: Roboto, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 400;
	letter-spacing: normal;
	text-decoration: inherit;
	text-transform: inherit;

	margin: 0.25em 0.5em 0 0.25em;
	padding: 0;
	color: lightsteelblue;
}

.item-hex-grid .hex-group h3.bad {
	color: lightsalmon;
}

.item-hex-grid .hex-group h3.unhappy {
	color: #ffbf00;
}

.item-hex-grid .hex-group h3.good {
	color: lightgreen;
}

.item-hex-grid .hex-grid {
	border: 0 dashed lightseagreen;
	padding: 0.25em 0.25em 2em;
	display: flex;
	justify-content: center;
	--s: 100px; /* size  */
	--m: 4px; /* margin */
	--f: calc(1.732 * var(--s) + 4 * var(--m) - 1px);
}

.item-hex-grid .hex-container {
	font-size: 0; /*disable white space between inline block element */
	text-align: left;
	//background-color: rgba(255, 215, 0, 0.25);
	//max-width: calc((var(--s) + (var(--m) * 2)) * 5);
}

.item-hex-grid .hex-container.extra-row {
	margin-bottom: var(--s);
}

.item-hex-grid .hex-container div.hex {
	width: var(--s);
	margin: var(--m);
	height: calc(var(--s) * 1.1547);
	display: inline-block;
	font-size: initial;
	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
	background-color: lightsteelblue;
	opacity: 0.60;
	margin-bottom: calc(var(--m) - var(--s) * 0.2885);
	position: relative;
	text-align: center;
	/*border: 4px solid transparent;*/
}

.item-hex-grid .hex-container div.hex a {
	cursor: inherit;
}

.item-hex-grid .hex-container div.hex.clickable {
	cursor: pointer;
}

.item-hex-grid .hex-container div.hex.disabled {
	background-color: lightsteelblue;
}

.item-hex-grid .hex-container div.hex.disabled::before {
	content: "";
	display: block;
	position: relative;
	top: 5%;
	left: 5%;
	height: 90%;
	width: 90%;
	background-color: lightsteelblue;
	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.item-hex-grid .hex-container div.hex.unhappy {
	background-color: #ffbf00;
}

.item-hex-grid .hex-container div.hex.good {
	background-color: lightgreen;
}

.item-hex-grid .hex-container div.hex.good::before {
	content: "";
	display: block;
	position: relative;
	top: 5%;
	left: 5%;
	height: 90%;
	width: 90%;
	background-color: lightgreen;
	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.item-hex-grid .hex-container div.hex.unhappy {
	background-color: #ffbf00;
}

.item-hex-grid .hex-container div.hex.unhappy::before {
	content: "";
	display: block;
	position: relative;
	top: 5%;
	left: 5%;
	height: 90%;
	width: 90%;
	background-color: #ffbf00;
	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.item-hex-grid .hex-container div.hex.bad {
	background-color: lightsalmon;
}

.item-hex-grid .hex-container div.hex.bad::before {
	content: "";
	display: block;
	position: relative;
	top: 5%;
	left: 5%;
	height: 90%;
	width: 90%;
	background-color: lightsalmon;
	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.item-hex-grid .hex-container div.hex.selected {
	background-color: yellow;
	opacity: 0.75;
}

.item-hex-grid .hex-container div.hex.active {
	background-color: rgba(98, 0, 238, 0.8);
	opacity: 1;
}

.item-hex-grid .hex-container div.hex:hover {
	opacity: 1;
}

.item-hex-grid .hex-container div.hex span {
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%)
}

.item-hex-grid .hex-container div.hex span a {
	color: inherit;
	text-decoration: none;
}

/* See the workaroundCssMinifyBug() method in the JS */
/*.item-hex-grid .hex-container::before {*/
/*	content: "B";*/
/*	width: calc(var(--s) / 2 + var(--m));*/
/*	float: left;*/
/*	height: 120%;*/
/*	shape-outside: repeating-linear-gradient(*/
/*		#0000 0 calc(var(--f) - 3px),*/
/*		#000 0 var(--f));*/
/*}*/

.item-hex-grid div.hex .icon-container {
	display: block;
	position: absolute;
	top: 90px;
	left: 50px;
	opacity: 0.75;
}

.item-hex-grid div.hex .icon-circle {
	height: 25px;
	width: 25px;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
	color: darkred;
}

.item-hex-grid div.hex .side-hexes {
	display: block;
	position: absolute;
	top: 28px;
	left: -6px;
	height: 75px;
	width: 10px;
	opacity: 0.5;
}

.item-hex-grid div.hex .side-hex {
	padding: 0;
	margin: 0;
	font-size: 15px;
	line-height: 15px;
}

.item-hex-grid div.hex .side-hex.bad {
	//color: lightsalmon;
	color: red;
}

.item-hex-grid div.hex .side-hex.unhappy {
	//color: #ffbf00;
	color: orange;
}

.item-hex-grid div.hex .side-hex.good {
	//color: lightgreen;
	color: green;
}

.item-hex-grid div.hex .side-hex.disabled {
	//color: lightsteelblue;
	color: cornflowerblue;
}

///////////////////////////////////////////////////////////////////////
// Circle Stuff
///////////////////////////////////////////////////////////////////////

.item-container {
	display: inline-block;
}

//config
$fm-primary: #6200ee;
$fm-bg: #FFFF00;
$fm-hl: #000000;
$fm-items: 6+1;
$fm-distance: 90px;
$fm-angle: pi()*2;

$fm-ch: 80px;

.float-menu-container {
	position: absolute;
	padding: 140px;
	text-align: center;
	transform: translateY(-174px) translateX(-86px);
	z-index: 9;
	opacity: 1;
	cursor: pointer;
	border-radius: 100%;
}

.debug .float-menu-container {
	//background-color: rgba(0, 0, 0, 0.1);
	border: 1px dashed rgba(0, 0, 0, 0.3); // DEBUG MARKER
}

.float-menu-element {
	//background: #ffffffee;
	//border: 1px solid #a9a9a955;
	background-color: rgba(98, 0, 238, 0.8);
	color: white;
	border-radius: 100%;
	width: 80px;
	height: 80px;
	margin-left: -40px;
	top: 20px;
	text-align: center;
	line-height: 80px;
	transform: translateY($fm-ch) translate3d(0, 0, 0);
	transition: transform ease-out 200ms;
	position: absolute;
	opacity: 1;
}

.float-menu-element .mdc-icon-button {
	transform: translateY(12px) scale(1.6);
}

.float-menu-element:hover {
	background-color: $fm-primary;
	color: white;
	opacity: 1;
}

.float-menu-element-active {
	z-index: 11;
}

// CLOSED STATE
.float-menu-element {
	opacity: 0;
	transition: opacity ease-in-out 100ms, transform ease-in-out 200ms;
	pointer-events: none;

	@for $i from 1 through $fm-items {
		&:nth-child(#{$i+2}) {
			transition-duration: 180ms;
		}
	}
}

// OPEN STATE (repeated for each supported list size)
@for $j from 1 through $fm-items {
	.float-menu-size-#{$j}.float-menu-open {

		.float-menu-element {
			transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
			opacity: 1;

			@for $i from 1 through $j {
				$angle: ((pi() - $fm-angle)/2)+
	      (($fm-angle/($j - 1)) * ($i - 1));

				&:nth-child(#{$i + 1}) {
					animation: disable-pointer-events 80ms+ (100ms * $i);
					transition-duration: 80ms+ (100ms * $i);
					opacity: 1;
					pointer-events: auto;
					transform: translateY($fm-ch) translate3d(
							cos($angle) * $fm-distance,
							sin($angle) * $fm-distance,
							0
					);
				}
			}
		}

	}
}

// This disable-pointer-events animation is used to prevent us from receiving
// hover events while the menu is animating open. This stops the help text tip
// from appearing because a button moved/transitioned under cursor.
// Note that it does not prevent click events.
@keyframes disable-pointer-events {
	0%, 99% {
		pointer-events: none;
		//background-color: rgba(255, 0, 0, 0.5); // unable to make this debug conditional :(
	}
}

$fm-debug: red;

.float-menu-help {
	transform: none;
	position: absolute;
	height: 5px;
	top: 0;
	margin-top: -8px;
	width: 5px;
	left: 35px;
	color: black;
	font-size: initial !important;
	line-height: initial !important;
	z-index: 11;
	border: 0;
	background-color: transparent;
}

.debug .float-menu-help {
	background-color: darkgray; // DEBUG MARKER
}

.float-menu-help .help-text {
	//visibility: hidden;
	width: 200px;
	background-color: white;
	color: black;
	text-align: center;
	padding: 0.75em;
	border-radius: 6px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	position: absolute;
	opacity: 0;
	transition: opacity 0.25s; // fade out when not visible
	pointer-events: none;
	z-index: 11;
}

.float-menu-help .help-text-visible {
	transition: opacity 0.25s; // fade in when visible
	transition-delay: 1s; // wait before fading in
	opacity: 1;
}

// TOP
.float-menu-help-top, .float-menu-help-top-right, .float-menu-help-top-left {
}

.debug .float-menu-help-top, .debug .float-menu-help-top-right, .debug .float-menu-help-top-left {
	border-top: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-top .help-text, .float-menu-help-top-right .help-text, .float-menu-help-top-left .help-text {
	width: 200px;
	bottom: 100%;
	left: 50%;
	margin-left: -110px; /* (200/2) + width of the marker (10) = center the tooltip */
}

.float-menu-help-top .help-text::after, .float-menu-help-top-right .help-text::after, .float-menu-help-top-left .help-text::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the helptip */
	left: 50%;
	margin-left: -10px;
	border-width: 10px;
	border-style: solid;
	border-color: white transparent transparent transparent;
}

// TOP RIGHT
.float-menu-help-top-right {
	right: 0;
	left: auto;
}

.debug .float-menu-help-top-right {
	border-right: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-top-right .help-text {
	right: 0;
	left: auto;
}

.float-menu-help-top-right .help-text::after {
	right: 25px;
	left: auto;
}

// TOP LEFT
.float-menu-help-top-left {
	left: 0
}

.debug .float-menu-help-top-left {
	border-left: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-top-left .help-text {
	//left: 0;
	margin-left: 0;
}

.float-menu-help-top-left .help-text::after {
	right: auto;
	left: 35px;
}

// BOTTOM
.float-menu-help-bottom, .float-menu-help-bottom-right, .float-menu-help-bottom-left {
	top: auto;
	bottom: 0;
	margin-bottom: -8px;
}

.debug .float-menu-help-bottom, .debug .float-menu-help-bottom-right, .debug .float-menu-help-bottom-left {
	border-bottom: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-bottom .help-text, .float-menu-help-bottom-right .help-text, .float-menu-help-bottom-left .help-text {
	width: 200px;
	top: 100%;
	left: 50%;
	margin-left: -110px; /* (200/2) + width of the marker (10) = center the tooltip */
}

.float-menu-help-bottom .help-text::after, .float-menu-help-bottom-right .help-text::after, .float-menu-help-bottom-left .help-text::after {
	content: " ";
	position: absolute;
	bottom: 100%; /* At the top of the tooltip */
	left: 50%;
	margin-left: -10px;
	border-width: 10px;
	border-style: solid;
	border-color: transparent transparent white transparent;
}

// BOTTOM RIGHT
.float-menu-help-bottom-right {
	right: 0;
	left: auto;
}

.debug .float-menu-help-bottom-right {
	border-right: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-bottom-right .help-text {
	right: 0;
	left: auto;
}

.float-menu-help-bottom-right .help-text::after {
	right: 25px;
	left: auto;
}

// BOTTOM LEFT
.float-menu-help-bottom-left {
	left: 0
}

.debug .float-menu-help-bottom-left {
	border-left: 2px solid $fm-debug; // DEBUG MARKER
}

.float-menu-help-bottom-left .help-text {
	//left: 0;
	margin-left: 0;
}

.float-menu-help-bottom-left .help-text::after {
	right: auto;
	left: 35px;
}


//.helptiptext button {
//	float: right;
//	margin-top: 3px;
//	margin-bottom: -4px;
//}

///* Show the helptip text when you mouse over the helptip container */
//.helptip:hover .helptiptext {
//	visibility: visible;
//}

///////////////////////////////////////////////////////////////////////

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
	transition: all .3s ease;
}

.slide-fade-leave-active {
	transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */
{
	transform: translateX(10px);
	opacity: 0;
}
</style>
