<script>

export default {
	name: 'FormCallbacksMixin',
	data () {
		return {
			formCallbacks: {},
		}
	},
	methods: {

		registerCallback (callback) {
			this.formCallbacks[callback.name] = callback
		},
		clearFormCallbacks () {
			this.formCallbacks = {}
		},
		getFormCallback (name) {
			let callback = this.formCallbacks[name]
			if (callback === undefined || callback === null) {
				return undefined
			}
			return callback
		},

		getFormValue (name) {
			let callback = this.getFormCallback(name)
			if (callback === undefined) {
				return undefined
			}
			return callback.get()
		},

		setFormValue (name, value, onlyIfEmpty) {
			let callback = this.getFormCallback(name)
			if (callback === undefined) {
				return undefined
			}
			if (onlyIfEmpty) {
				let oldValue = callback.get()
				if (oldValue !== undefined && String(oldValue).length > 0) {
					return false
				}
			}
			callback.set(value)
			return true
		},

		disableFormComponent (name) {
			let callback = this.getFormCallback(name)
			if (callback === undefined) {
				return undefined
			}
			if (callback.disable !== undefined) {
				callback.disable()
				return true
			}
			return false
		},
		enableFormComponent (name) {
			let callback = this.getFormCallback(name)
			if (callback === undefined) {
				return undefined
			}
			if (callback.enable !== undefined) {
				callback.enable()
				return true
			}
			return false
		},

	},
}

</script>
