<template>
	<div class="text-input max-width">
		<div class="input-field max-width">
			<div class="mdc-text-field mdc-text-field--outlined max-width"
				 data-mdc-auto-init="MDCTextField"
				 v-bind:class="{'mdc-text-field--disabled': isDisabled }">
				<input v-bind:id="htmlId"
					   v-model="contents"
					   class="mdc-text-field__input max-width"
					   v-bind:disabled="isDisabled"
				>
				<div class="mdc-notched-outline">
					<div class="mdc-notched-outline__leading"></div>
					<div class="mdc-notched-outline__notch">
						<label class="mdc-floating-label" v-bind:for="htmlId">{{ title }}</label>
					</div>
					<div class="mdc-notched-outline__trailing"></div>
				</div>
			</div>
			<div v-if="description !== undefined" class="mdc-text-field-helper-line">
				<div aria-hidden="true" class="mdc-text-field-helper-text">
					{{ description }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'TextInput',
	props: [
		'title',
		'description',
		'value',
		'disabled'
	],
	data () {
		return {
			contents: '',
			randomId: '',
		}
	},
	computed: {
		htmlId () {
			return 'app-input-text-' + this.randomId
		},
		isDisabled () {
			return this.disabled === true
		},
	},
	watch: {
		contents (value) {
			this.$emit('update:value', value)
		},
		value (value) {
			if (this.value !== undefined && this.value !== null) {
				this.contents = this.value
			}
		},
	},
	created () {
		this.randomId = this.makeid(5)
		if (this.value !== undefined && this.value !== null) {
			this.contents = this.value
		}
	},
	mounted () {
		autoInit()  // Material Design Components
	},
	updated () {
		autoInit()  // Material Design Components
	},
	methods: {
		makeid (length) {
			let result = ''
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			const charactersLength = characters.length
			let counter = 0
			while (counter < length) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength))
				counter += 1
			}
			return result
		},
	}
}

</script>

<style lang="scss" scoped>

.text-input {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;
	align-items: flex-start;
}

.max-width {
	width: 100%;
}

.text-input .input-field {
	flex: 0 1 auto;
}

.text-input .mdc-text-field {
	background-color: white;
}

.text-input .mdc-text-field__input {
	text-align: left;
}

.text-input button[type=submit] {
	visibility: hidden;
	display: none;
}

</style>
