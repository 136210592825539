<template>
	<div><!-- this template is not used --></div>
</template>

<script>

import PublicAPI from './PublicAPI'
import axios from 'axios'
import {mapGetters} from 'vuex'

const apiQueryResponseLimit = 100

export default {
	mixins: [
		PublicAPI,
	],
	// NB. Defining props in mixins is ok, but it prevents you from using the
	// mixin in a component which defines that name as a data field. So in
	// general, don't do it.
	//
	// props: [
	// 	'accountDetails',
	// 	'userDetails',
	// ],
	//
	computed: {
		...mapGetters([
			'appConfig',
			'ssoCredential',
		]),

		impersonateAccountUrl () {
			return this.impersonateAccountUrlForId(undefined)
		},
		impersonateUserUrl () {
			return this.impersonateUserUrlForId(undefined)
		},
		v1AdminUrl () {
			let uid = undefined
			if (this.userDetails !== undefined) {
				uid = this.userDetails.uid
			}
			if (!this.hasValue(uid) && this.accountDetails !== undefined) {
				uid = this.accountDetails.uid
			}
			return this.v1AdminUrlForUid(uid)
		},

	},
	methods: {
		//////////////////////////////////////////////////////////////////////

		openUrl (url, sameTab) {
			if (sameTab === true) {
				window.location.replace(url)
			} else {
				window.open(url)
			}
		},
		openPortal (sameTab, forceV2) {
			sameTab = (sameTab === true) // coerce into a useful boolean value
			forceV2 = (forceV2 === true) // coerce into a useful boolean value
			// console.debug('openPortal', sameTab)
			if (this.userDetails !== undefined) {
				return this.impersonateUser(
					undefined,
					true,
					this.userDetails,
					sameTab,
					forceV2,
				)
			}
			return this.impersonateAccount(
				undefined,
				true,
				sameTab,
			)
		},
		openV1Admin (sameTab) {
			this.openUrl(this.v1AdminUrl, sameTab)
		},

		impersonateAccount (accountId, openPortal, sameTab) {
			sameTab = (sameTab === true) // coerce into a useful boolean value
			console.debug('impersonateAccount', accountId, openPortal, sameTab)
			return new Promise((resolve, reject) => {
				if (!this.hasValue(accountId) && this.accountDetails !== undefined) {
					accountId = this.accountDetails._id
				}
				if (!this.hasValue(accountId)) {
					reject('account id not specified')
					return
				}

				const impersonateFromUsersList = usersList => {
					let selectedUser = undefined
					if (usersList !== undefined) {
						let fallbackUser = undefined
						usersList.forEach(user => {
							if (fallbackUser === undefined) {
								fallbackUser = user
							}
							if (selectedUser === undefined && user.status === 'ACTIVE') {
								// TODO check permissions?
								selectedUser = user
							}
						})
						if (selectedUser === undefined) {
							selectedUser = fallbackUser
						}
					}
					if (selectedUser !== undefined) {
						this.impersonateUser(
							selectedUser._id,
							openPortal,
							selectedUser,
							sameTab,
						).then(resolve).catch(reject)
						return true
					} else {
						return false
					}
				}

				console.info('Impersonating account', accountId)

				if (this.accountUsers !== undefined) {
					if (impersonateFromUsersList(this.accountUsers)) {
						return
					}
				}

				this.callPublicApi(
					`admin/users/?account=${accountId}&limit=${apiQueryResponseLimit}`
				).then(apiResponse => {
					console.debug(apiResponse)
					if (!impersonateFromUsersList(apiResponse.data)) {
						reject('unable to find an active user in the account')
					}
				}).catch(reject)

			})
		},

		impersonateUser (userId, openPortal, userDetails, sameTab, forceV2) {
			sameTab = (sameTab === true) // coerce into a useful boolean value
			forceV2 = (forceV2 === true) // coerce into a useful boolean value
			// console.debug('impersonateUser', userId, openPortal, userDetails, sameTab)
			return new Promise((resolve, reject) => {

				// If we don't have an ID or details, look for a prop ...
				if (!this.hasValue(userId) && !this.hasValue(userDetails) && this.hasValue(this.userDetails)) {
					// console.debug('no id or details, have do have a prop', userId, userDetails)
					userDetails = this.userDetails
				}

				// If we have an ID but no details, go fetch details, and restart ...
				if (!this.hasValue(userDetails) && this.hasValue(userId)) {
					// console.debug('no details but do have an id', userId, userDetails)
					return this.callPublicApi(
						`admin/users/${userId}/`
					).then(apiResponse => {
						console.debug(apiResponse)
						return this.impersonateUser(
							apiResponse._id,
							openPortal,
							apiResponse,
							sameTab,
							forceV2,
						).then(resolve).catch(reject)
					}).catch(reject)
				}

				if (!this.hasValue(userId) && this.hasValue(userDetails)) {
					// console.debug('no id but do have details', userId, userDetails)
					userId = userDetails._id
				}

				if (!this.hasValue(userId) || !this.hasValue(userDetails)) {
					reject('user not found')
					return
				}
				userId = userDetails._id // sanity

				if (userDetails.status !== 'ACTIVE') {
					reject(`user ${userId} is ${userDetails.status}`)
					return
				}

				console.info('Impersonating user', userId)

				// If openPortal was specified as true, see if we should login
				// to the old Portal instead of Portal 2.
				if (openPortal === true && forceV2 === false && userDetails.migrationStatus === 'MIGRATED' && !userDetails.loginEnabled) {
					let username = userDetails.username
					console.info('Opening Old Portal as user', username)
					let oldPortalUrl = `${this.appConfig('PORTAL1_ADMIN_URL')}cportal/go/username/${username}`
					this.openUrl(oldPortalUrl, sameTab)
					resolve()
					return
				}

				console.debug(`${this.appConfig('SSO_API_URL')}&impersonate=${userId}`)
				axios.get(
					`${this.appConfig('SSO_API_URL')}&impersonate=${userId}`,
					{
						withCredentials: true,
					}
				).then(apiResponse => {
					if (apiResponse.data !== undefined && typeof (apiResponse.data) === 'object') {
						//
						// A note about process here.
						//
						// The returned token is an "autologin token". This can
						// be given to the API, which then returns an API token.
						// Alternatively it can be passed to Portal 2 which will
						// use it to login as that given user (in the same way).
						//
						// Note that in both cases the token also carries the
						// information of the admin that generated it, this is
						// that the audit log can correctly attribute changes to
						// the appropriate person.
						//
						// console.debug(apiResponse.data)

						// If openPortal was specified as true, we open the
						// Portal 2 auto login url.
						if (openPortal === true) {
							console.info('Opening Portal 2 as user', userId)
							this.openUrl(apiResponse.data.url, sameTab)
							resolve()
							return
						}

						// Otherwise, we request an API token that we can use
						// to make calls on behalf of the target user.
						this.callPublicApi(
							`auth/autoLogin/${apiResponse.data.token}`,
							{
								method: 'POST',
								data: {},
							},
						).then(apiResponse => {
							// console.debug(apiResponse)
							console.info('Obtained API token for user', userId)
							resolve(apiResponse.token)
						}).catch(reject)

						// resolve(apiResponse.data)
					} else {
						reject('API returned invalid apiResponse')
					}
				}).catch(reject)
			})
		},

		//////////////////////////////////////////////////////////////////////

		impersonateAccountUrlForId (accountId) {
			if (!this.hasValue(accountId) && this.accountDetails !== undefined) {
				accountId = this.accountDetails._id
			}
			if (!this.hasValue(accountId)) {
				return '/accounts/impersonate/'
			}
			return `/accounts/impersonate/${accountId}/`
		},

		impersonateUserUrlForId (userId) {
			if (!this.hasValue(userId) && this.userDetails !== undefined) {
				userId = this.userDetails._id
			}
			if (!this.hasValue(userId)) {
				return '/users/impersonate/'
			}
			return `/users/impersonate/${userId}/`
		},

		v1AdminUrlForUid (uid) {
			if (!this.hasValue(uid)) {
				return this.appConfig('PORTAL1_ADMIN_URL')
			}
			return `${this.appConfig('PORTAL1_ADMIN_URL')}customer/details/${uid}`
		},

		//////////////////////////////////////////////////////////////////////

		hasValue (value) {
			if (value === undefined || value === null || value === '') {
				return false
			}
			if (typeof value === 'string' || value instanceof String) {
				value = value.trim()
			}
			// noinspection RedundantIfStatementJS
			if (value === 'Not Applicable') {
				return false
			}
			return true
		},

		//////////////////////////////////////////////////////////////////////
	},
}

</script>
