<template>
	<div v-if="false"></div>
</template>

<script>

// import actions from './Actions'

export default {
	name: 'ActionsFloatMenu',
	props: [
		'title',
		'open',
		'message',
		'actions',
		'wide',
	],
	created () {
		this.$emit('actions-callback', this.floatMenuGenerator)
	},
	methods: {
		floatMenuGenerator () {
			// console.debug('floatMenuGenerator called')
			let result = []
			result.push({ // the first item is in the middle
				id: 'home',
				hidden: true, // always hide it
				label: 'Home',
				icon: 'home',
				callback: () => {
				}
			})
			result = this.addActions(this.actions.filter(action => action.id === 'details'), result)
			result = this.addActions(this.actions.filter(action => action.id !== 'details'), result)
			// console.debug('floatMenuGenerator result:', result)
			return result
		},
		addActions (actions, result) {
			actions.forEach(action => {
				if (result.length >= 7) {
					return
				}
				if (!action.disabled && !action.outline) {
					result.push({
						id: action.id,
						hidden: false,
						label: action.label,
						icon: action.icon,
						callback: action.callback
					})
				} else {
					// console.debug('action disabled or outline', action.id)
				}
			})
			return result
		},
	},
}
</script>

<style>

</style>
