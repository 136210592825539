<template>
	<div class="results mdc-typography">
		<div v-if="!loading">
			<button v-show="hasValue(apiError)"
					class="mdc-button mdc-button--dense button-error button-right"
					@click.prevent="showApiError=!showApiError"
			>
				<i aria-hidden="true" class="material-icons mdc-button__icon">warning</i>
				<span class="mdc-button__label">API ERROR ({{ pollingCounter }})</span>
			</button>
		</div>
		<h2 class="mdc-typography--headline6 output-title">
			<span>Recently {{ titleCase(sort) }} Services</span>
		</h2>
		<div v-if="hasValue(apiError) && showApiError">
			<div class="error-api-box">
				<h3 class="mdc-typography--headline5">API Error</h3>
				<pre>{{ asString(apiError) }}</pre>
			</div>
		</div>
		<div v-else>
			<p>
				Showing the {{ max }} most recently {{ sort }} services
				<template v-if="withScriptConfig">, which are linked with a script config</template>
			</p>
			<div v-if="loading" class="loading">
				<ProgressSpinner
					v-show="loading"
					v-bind:md-diameter="55"
				/>
			</div>
			<div v-else>
				<component
					:is="tableComponent"
					:created="showCreated"
					:result="actualResult"
					:updated="showUpdated"
				/>
			</div>
		</div>
		<div v-if="false">
			<pre>loading={{ loading }} hasLoaded={{ hasLoaded }} hasError={{
					hasValue(apiError)
				}} refresh={{ pollingCounter }}</pre>
		</div>
	</div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'

import CachedProvServicesMixin from '../../common/CachedProvServicesMixin'
import ProvisioningAPI from '../../common/ProvisioningAPI'
import ProgressSpinner from '../../material/ProgressSpinner'
import SummaryTable from './SummaryTable'

const oneSecond = 1000 // milliseconds

export default {
	name: 'ServicesList',
	mixins: [
		CachedProvServicesMixin,
		ProvisioningAPI
	],
	components: {
		ProgressSpinner,
		SummaryTable,
	},
	inject: [
		'userPresence'
	],
	props: {
		'sort': {
			'type': String,
			'default': 'created'
		},
		'order': {
			'type': String,
			'default': 'descending'
		},
		'max': {
			type: Number,
			default: 100
		},
		'tableMode': {
			'type': String,
			'default': 'summary',
		},
		'withScriptConfig': {
			'type': Boolean,
			'default': false,
		},
	},
	data () {
		return {
			hasLoaded: false,
			showApiError: false,
			intervalId: undefined,
			pollingInterval: 60, // seconds
			pollingCounter: 60, // seconds
			result: [],
		}
	},
	computed: {
		...mapGetters([
			'appConfig', // this getter returns a function
			'websocketConnected',
		]),
		...mapGetters('cache', [
			'load', // this getter returns a function
		]),

		tableComponent () {
			return 'SummaryTable'
		},
		actualResult () {
			if (this.result === null || this.result === undefined) {
				return []
			}
			return this.result.slice(0, this.max)
		},
		showUpdated () {
			return this.sort === 'updated'
		},
		showCreated () {
			return this.sort === 'created'
		},
		apiUrl () {
			let url = 'services/?'
			url += '&max=' + this.max
			url += '&sort=' + this.sort
			url += '&order=' + this.order
			if (this.withScriptConfig === true) {
				url += '&withScriptConfig=true'
			}
			return url
		}
	},
	watch: {
		loading (value) {
			if (value === false) {
				this.hasLoaded = true
			}
		},
		hasLoaded (value) {
			if (value === true) {
				this.loading = false
			}
		},
		'userPresence.active' (active) {
			if (active) {
				console.debug('user is active - polling for updates - ', new Date())
				this.pollRestApi()
			} else {
				console.debug('user has become inactive - disabling polling - ', new Date())
				this.stopPollingRestApi()
				this.pollingCounter = 5 // refresh 5 seconds after user becomes active again
			}
		},
		'websocketConnected' (connected) {
			if (connected) {
				this.pollingCounter = 3 // refresh in 3 seconds
			}
		},
	},
	mounted () {
		this.$nextTick(this.loadServices)
	},
	beforeDestroy () {
		this.stopPollingRestApi()
	},
	methods: {
		...mapActions('cache', [
			'store',
		]),

		titleCase (str) {
			if (str === undefined || str === null) {
				return str
			}
			return str.toLowerCase()
				.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
				.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
		},
		loadServices () {
			if (this.loading) {
				return Promise.reject('already loading')
			}
			let quietly = this.hasLoaded
			if (!quietly) {
				this.loading = true
			}
			let url = this.apiUrl
			const cachedDataKey = 'provapi-services-list-' + btoa(url)
			return this.load(cachedDataKey).then(cachedData => { // load from cache
				if (cachedData !== undefined) {
					this.result = cachedData.value
					this.hasLoaded = true
				}

			}).then(() => {
				if (!this.websocketConnected) {
					this.showApiError = true
					return Promise.reject('websocket disconnected')
				}
				return this.callProvisioningApi(
					url,
					null,
					quietly
				).then(data => {
					if (data === undefined) {
						this.result = []
					} else {
						this.result = data
						this.$nextTick(() => {
							this.store({
								key: cachedDataKey,
								value: data,
							}).then(() => {
								data.forEach(service => {
									this.setCachedService(service)
								})
							})
						})
					}
					this.showApiError = false
					return data
				})

			}).catch(error => {
				console.error(error)
				if (!this.hasLoaded) {
					this.showApiError = true
				}

			}).finally(() => {
				this.pollRestApi()
			})
		},

		stopPollingRestApi () {
			if (this.intervalId !== undefined) {
				clearInterval(this.intervalId)
				this.intervalId = undefined
			}
		},
		pollRestApi () {
			this.stopPollingRestApi()
			this.intervalId = setInterval(() => {
				if (this.pollingCounter <= 0) {
					if (!this.websocketConnected || !this.userPresence.active) {
						return
					}
					this.pollingCounter = this.pollingInterval
					this.$nextTick(this.loadServices)
				} else {
					this.pollingCounter--
				}
			}, oneSecond)
		},

		asString (value) {
			if (typeof value === 'string' || value instanceof String) {
				return value
			}
			return JSON.stringify(value, null, 2)
		},

	}
}

</script>

<style lang="scss" scoped>

.output-title {
	display: flex;
	flex-direction: row;
}

.loading {
	margin: 2em;
	width: 100%;
	text-align: center;
}

.button-right {
	float: right;
}

.button-error {
	color: orangered;
	border-color: orangered;
}

.error-api-box {
	background-color: salmon;
	border: 5px darkred dashed;
	padding: 0.5em 1em;
	margin: 0;
	overflow: hidden;
}

</style>
