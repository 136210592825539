<template>
	<div>
		<slot>
			<!-- Container Contents Here -->
		</slot>
	</div>
</template>

<script>

export default {
	name: 'GenericContainer',
	data () {
		return {}
	},
}

</script>

<style lang="scss" scoped>


</style>
