<template>
	<div class="mdc-search mdc-typography">
		<div class="input-field">
			<div class="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon"
				 data-mdc-auto-init="MDCTextField">
				<i class="material-icons mdc-text-field__icon">search</i>
				<input v-bind:id="htmlId" v-model="inputText" class="mdc-text-field__input">
				<div class="mdc-notched-outline">
					<div class="mdc-notched-outline__leading"></div>
					<div class="mdc-notched-outline__notch">
						<label class="mdc-floating-label" v-bind:for="htmlId">{{ title }}</label>
					</div>
					<div class="mdc-notched-outline__trailing"></div>
				</div>
			</div>
			<div class="mdc-text-field-helper-line">
				<div id="username-helper-text" aria-hidden="true" class="mdc-text-field-helper-text">
					{{ description }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'
import {mapActions, mapState} from 'vuex'

export default {
	components: {},
	props: [
		'focusHint',
	],
	data () {
		return {
			textInputEl: undefined,
			inputText: '',
			loading: true,
			title: 'Search',
			description: 'Press Enter/Return to search'
		}
	},
	computed: {
		...mapState([]),
		htmlId () {
			return 'mdc-search-text'
		},
	},
	watch: {
		focusHint () {
			this.$nextTick(this.takeFocus)
		},
	},
	mounted () {
		autoInit()  // Material Design Components
		window.addEventListener('keypress', this.handleKeypress)
		this.$nextTick(this.takeFocus)
	},
	updated () {
		autoInit()  // Material Design Components
	},
	beforeDestroy () {
		window.removeEventListener('keypress', this.handleKeypress)
	},
	destroyed () {
		// this.unsubscribe('something')
	},
	methods: {
		...mapActions([
			// 'subscribe',
			// 'unsubscribe',
		]),
		takeFocus () {
			this.textInputEl = document.getElementById(this.htmlId)
			this.$nextTick(() => {
				this.textInputEl.focus()
			})
		},
		handleKeypress (event) {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				this.startNewQuery()
			}
		},
		// generateUUIDv4() {
		//     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		//         (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		//     )
		// },
		startNewQuery () {
			this.$nextTick(() => {
				if (this.inputText !== '') {
					let query = {
						query: this.inputText.trim(),
						// request_id: self.generateUUIDv4()
					}
					this.$emit('new-query', query)
					this.$set(self, 'inputText', '')
				}
			})
		}
	}
}

</script>

<style lang="scss">

.mdc-search {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;
	align-items: flex-start;
}

.mdc-search .input-actions {
	flex: 0 1 auto;
	padding: 0 0 0 .5em;
}

.mdc-search .input-field {
	flex: 0 1 auto;
	width: 100%;
}

.mdc-search .mdc-text-field {
	width: 100%;
	background-color: white;
}

.mdc-search button[type=submit] {
	visibility: hidden;
	display: none;
}

</style>
