<template>
	<div class="pagination-bar">
		<div class="mdc-layout-grid mdc-layout-grid--align-right">
			<div class="mdc-layout-grid__inner">

				<div :class="gridLayoutClasses"
					 v-if="pageSizeOptions.length <= 0"
				>
				</div>

				<div :class="gridLayoutClasses"
					 v-if="!this.hasValue(this.currentPagination)"
				>
				</div>

				<div :class="gridLayoutClasses"
					 v-if="pageSizeOptions.length > 0"
				>
					<Select
						label="Page Size"
						:options="pageSizeOptions"
						:desiredValue="desiredCurrentPageSize"
						v-on:update:selectedValue="changePageSize($event)"
					/>
				</div>

				<div :class="gridLayoutClasses" class="text-align-center"
					 v-if="this.hasValue(this.currentPagination)"
				>
					<span>{{ currentPagination }}</span>
				</div>

				<div :class="gridLayoutClasses" class="text-align-right">
					<PaginationButtons
						:currentPage="currentPage"
						:lastPage="lastPage"
						@switch-page="switchPage"
					/>
				</div>

			</div>
		</div>

	</div>
</template>

<script>

import PaginationButtons from './PaginationButtons.vue'
import Select from './Select.vue'

const gridLayoutClasses = 'mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--align-middle'

export default {
	components: {
		Select,
		PaginationButtons
	},
	props: [
		'pageSizes', // e.g. [10, 20, 50, 100]
		'currentPageSize',
		'currentPagination', // text
		'currentPage',
		'lastPage',
	],
	data () {
		return {
			desiredCurrentPageSize: '0',
		}
	},
	computed: {
		gridLayoutClasses () {
			return gridLayoutClasses
		},
		pageSizeOptions () {
			let result = []
			if (this.hasValue(this.pageSizes)) {
				this.pageSizes.forEach(pageSize => {
					result.push({
						text: pageSize,
						value: pageSize,
					})
				})
			}
			return result
		},
	},
	watch: {
		currentPageSize (value) {
			console.debug('[DEBUG] PaginationBar: NEW: currentPageSize', value)
			this.$nextTick(() => {
				this.desiredCurrentPageSize = String(value)
			})
		},
	},
	mounted () {
		this.desiredCurrentPageSize = String(this.currentPageSize)
	},
	methods: {

		hasValue (variable) {
			// noinspection RedundantIfStatementJS
			if (variable === undefined || variable === null) {
				return false
			}
			// noinspection RedundantIfStatementJS
			if (variable === 'undefined' || variable === 'null' || variable === '') {
				return false
			}
			return true
		},

		changePageSize (size) {
			size = parseInt(size)
			let changed = (size !== parseInt(this.currentPageSize))
			// console.debug('[DEBUG] PaginationBar: changePageSize', size, !changed ? 'NO CHANGE' : '')
			if (changed) {
				this.$emit('change-page-size', size)
			}
		},
		switchPage (page) {
			// console.debug('[DEBUG] PaginationBar: switchPage', page)
			this.$emit('switch-page', page)
		},
	}
}

</script>

<style scoped lang="scss">

.pagination-bar {
	width: 100%;
	max-width: 100%;
	margin: 0;
	padding: 0;
	user-select: none;
}

.mdc-layout-grid {
	margin: 0;
	padding: 0;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

</style>
