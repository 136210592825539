<template>
	<div><!-- This template is not used (because this is a mixin) --></div>
</template>

<script>

import {getCookie} from 'tiny-cookie'

export default {
	name: 'StorageMixin',
	computed: {
		storageKeyPrefix () {
			return 'cachefly-'
		}
	},
	data () {
		return {
			storageInstanceId: undefined,
			storageBroadcastChannel: undefined,
			watchedStoredValues: undefined,
			watchedStoredValuesCallbacks: undefined,
		}
	},
	created () {
		if (this.storageInstanceId === undefined) {
			this.storageInstanceId = Math.random().toString(36).substring(2, 10)
		}
		this.setupStorageEvents()
	},
	beforeDestroy () {
		if (this.watchedStoredValues === undefined) {
			this.cleanupStorageEventHandlers()
		}
		this.watchedStoredValues = undefined
		this.watchedStoredValuesCallbacks = undefined
	},
	methods: {

		hasValue (variable) {
			// noinspection RedundantIfStatementJS
			if (variable === undefined || variable === null) {
				return false
			}
			// noinspection RedundantIfStatementJS
			if (variable === 'undefined' || variable === 'null' || variable === '') {
				return false
			}
			return true
		},

		// --------------------------------------------------------------------

		loadStoredValue (key, defaultValue = undefined) {
			let prefixedKey = this.storageKeyPrefix + String(key)
			let storedValue = sessionStorage.getItem(prefixedKey)
			if (this.hasValue(storedValue)) {
				return JSON.parse(storedValue)
			}
			storedValue = localStorage.getItem(prefixedKey)
			if (this.hasValue(storedValue)) {
				return JSON.parse(storedValue)
			}
			return defaultValue
		},
		storePersistent (key, value) {
			key = this.storageKeyPrefix + key
			let storedValue = JSON.stringify(value)
			localStorage.setItem(key, storedValue)
			this.pushStorageChangeEvent(key, value)
		},
		storeTransient (key, value) {
			key = this.storageKeyPrefix + key
			let storedValue = JSON.stringify(value)
			sessionStorage.setItem(key, storedValue)
			this.pushStorageChangeEvent(key, value, false)
		},
		clearStoredValues (key) {
			key = this.storageKeyPrefix + key
			sessionStorage.removeItem(key)
			localStorage.removeItem(key)
			this.pushStorageChangeEvent(key, undefined, true)
		},

		clearAllTransientStorage () {
			sessionStorage.clear()
			this.pushStorageClearedEvent()
		},

		// --------------------------------------------------------------------

		loadCookieValue (key, defaultValue = undefined) {
			let storedValue = getCookie(key)
			if (this.hasValue(storedValue)) {
				try {
					return JSON.parse(storedValue)
				} catch (error) {
					// ignore the error and return the raw value
					return storedValue
				}
			}
			return defaultValue
		},

		// --------------------------------------------------------------------

		watchStoredValue (key, callback) {

			// Populate the reactive storage
			if (this.watchedStoredValues === undefined) {
				this.watchedStoredValues = {}
			}
			let currentValue = this.loadStoredValue(key, undefined)
			this.$set(this.watchedStoredValues, key, currentValue)

			// Set up and invoke the callback
			if (callback === undefined) {
				return
			}
			if (this.watchedStoredValuesCallbacks === undefined) {
				this.watchedStoredValuesCallbacks = {}
			}
			if (this.watchedStoredValuesCallbacks[key] === undefined) {
				this.watchedStoredValuesCallbacks[key] = []
			}
			this.watchedStoredValuesCallbacks[key].push(callback)
			let newValue = this.watchedStoredValues[key]
			let firstTime = true
			callback(key, newValue, firstTime)
		},

		// --------------------------------------------------------------------
		// INTERNAL API - you shouldn't need to call these directly

		setupStorageEvents () {
			if (this.storageBroadcastChannel === undefined) {
				this.storageBroadcastChannel = new BroadcastChannel('storage')

				// This receives event (generated by us) from inside the current browsing context.
				this.storageBroadcastChannel.addEventListener('message', this.handleStorageEvent)

				// This receives events from outside the current browsing context.
				window.addEventListener('storage', this.handleStorageEvent)
			}
		},
		cleanupStorageEventHandlers () {
			window.removeEventListener('storage', this.handleStorageEvent)
			if (this.storageBroadcastChannel !== undefined) {
				this.storageBroadcastChannel.close()
				this.storageBroadcastChannel = undefined
			}
		},
		pushStorageChangeEvent (prefixedKey, newValue, removed = false) {
			if (this.storageBroadcastChannel === undefined) {
				this.setupStorageEvents()
			}
			this.storageBroadcastChannel.postMessage({
				key: prefixedKey,
				newValue: newValue,
				removed: removed,
				sender: this.storageInstanceId,
			})
		},
		pushStorageClearedEvent () {
			if (this.storageBroadcastChannel === undefined) {
				this.setupStorageEvents()
			}
			this.storageBroadcastChannel.postMessage({
				cleared: true,
				sender: this.storageInstanceId,
			})
		},
		handleStorageEvent (event) {
			if (!event.isTrusted) {
				console.error('ERROR: Untrusted event received. Ignoring.', event)
				return
			}

			// If we're not watching anything, then do nothing.
			if (this.watchedStoredValues === undefined) {
				return
			}

			// console.debug('Storage event:', event)

			if (this.storageBroadcastChannel === undefined) {   // something has gone ...
				this.cleanupStorageEventHandlers()              // 1. run the cleanup
				return                                          // 2. ignore the event
			}

			if (event.type === 'message') {
				event = event.data
			}

			if (event.sender === this.storageInstanceId) { // ignore self events
				return
			}

			if (event.cleared === true) {
				// console.debug('>>>>>> STORAGE CLEARED EVENT RECEIVED <<<<<<')
				sessionStorage.clear()
				this.$nextTick(() => {
					let keys = Object.keys(this.watchedStoredValues)
					keys.forEach(key => {
						let previousValue = this.watchedStoredValues[key]
						let currentValue = this.loadStoredValue(key, undefined)
						if (previousValue === currentValue) {
							return
						}
						let prefixedKey = this.storageKeyPrefix + String(key)
						this.handleStorageEvent({
							isTrusted: true,
							key: prefixedKey,
							newValue: currentValue,
							removed: currentValue === undefined,
							sender: 'simulated',
						})
					})
				})
				return
			}

			// Check if this is a key that we're watching
			let key = event.key.slice(this.storageKeyPrefix.length) // key without the prefix
			if (!Object.keys(this.watchedStoredValues).includes(key)) {
				return
			}

			// Update the reactive storage
			let newValue = event.newValue
			if (this.hasValue(newValue) && newValue !== '') {
				if (typeof newValue === 'string') {
					newValue = JSON.parse(newValue)
				}
			}
			this.$set(this.watchedStoredValues, key, newValue)

			// Invoke the callbacks
			if (this.watchedStoredValuesCallbacks === undefined) {
				return
			}
			if (this.watchedStoredValuesCallbacks[key] === undefined) {
				return
			}
			let firstTime = false
			let callbacks = this.watchedStoredValuesCallbacks[key]
			callbacks.forEach(callback => {
				this.$nextTick(() => callback(key, newValue, firstTime))
			})

		},

	},
}

</script>
