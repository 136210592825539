<script>

import PublicAPI from '../common/PublicAPI'
import ProvisioningAPI from '../common/ProvisioningAPI'

import {mapActions, mapGetters, mapState} from 'vuex'

const tlsProfilesCacheKey = 'pubapi-tlsprofiles-aggregate'
const rvipsCacheKey = 'provapi-dns-rvips-aggregate'
const cacheRefreshAge = 15 // seconds

const now = () => Math.floor(Date.now() / 1000)

export default {
	name: 'TLSProfilesDataMixin',
	mixins: [
		PublicAPI,
		ProvisioningAPI,
	],
	mounted () {
		this.cacheWatch(tlsProfilesCacheKey)
		this.cacheWatch(rvipsCacheKey)
		this.$nextTick(this.checkTlsProfilesCacheLifetime)
	},
	beforeDestroy () {
		this.cacheUnwatch(tlsProfilesCacheKey)
		this.cacheUnwatch(rvipsCacheKey)
	},
	computed: {
		...mapState('cache', {
			'cachedWatchedItems': 'watchedItems',
		}),
		...mapGetters('cache', {
			'cacheLoad': 'load', // this getter returns a function
		}),

		tlsProfilesData () {
			if (this.cachedWatchedItems === undefined) {
				return undefined
			}
			let item = this.cachedWatchedItems[tlsProfilesCacheKey]
			if (item === undefined) {
				return undefined
			}
			return item.value
		},

		dnsGroupsData () {
			if (!this.hasValue(this.tlsProfilesData)) {
				return undefined
			}
			let groups = []
			this.tlsProfilesData.data.forEach(profile => {
				if (!groups.includes(profile.group)) {
					groups.push(profile.group)
				}
			})
			groups.sort()
			groups.sort(a => {
				if (a === 'SHARED') {
					return -1
				}
				return 0
			})
			return groups
		},

		rvipsDataRaw () {
			if (this.cachedWatchedItems === undefined) {
				return undefined
			}
			let item = this.cachedWatchedItems[rvipsCacheKey]
			if (item === undefined) {
				return undefined
			}
			return item.value
		},
		rvipsData () {
			if (!this.hasValue(this.rvipsDataRaw)) {
				return undefined
			}

			if (!this.hasValue(this.tlsProfilesData)) {
				return undefined
			}

			let result = this.rvipsDataRaw
			result = result.filter(rvip => rvip.id !== '*')
			result.forEach((rvip) => {
				rvip.annotations = {
					'assigned': false,
					'visible': false,
					'tlsprofiles': [],
					'comment': undefined,
				}
			})

			this.tlsProfilesData.data.forEach((profile) => {
				if (!this.hasValue(profile.metadata)) {
					return
				}

				let meta = JSON.parse(profile.metadata)
				if (!this.hasValue(meta['dns_rvip'])) {
					console.debug('missing dns_rvip in metadata', profile)
					return
				}
				let rvipCode = meta['dns_rvip']

				let rvip = result.find(rvip => rvip.id === rvipCode)
				if (rvip === undefined) {
					console.debug('unable to find data for rvip', rvipCode)
					return
				}
				rvip.annotations['assigned'] = true
				rvip.annotations['visible'] = (!profile.hidden) || rvip.annotations['visible']
				rvip.annotations['tlsprofiles'].push(profile._id)
				let comment
				if (rvip.annotations['tlsprofiles'].length === 1) {
					comment = 'assigned to ' + profile.group + '/' + profile.code
				} else {
					comment = 'assigned to multiple TLS profiles'
				}
				rvip.annotations['comment'] = comment
			})

			return result
		},

	}, // end computed
	methods: {
		...mapActions('cache', {
			'cacheWatch': 'watch',
			'cacheUnwatch': 'unwatch',
			'cacheStore': 'store',
		}),

		checkTlsProfilesCacheLifetime () {
			if (this.cachedWatchedItems === undefined) {
				return undefined
			}
			const shouldRefresh = (key) => {
				return this.cacheLoad(key).then(item => {
					if (item === undefined) {
						console.debug('[DEBUG] cached item', key, 'is missing')
						return true
					}
					let age = now() - item.stored
					console.debug('[DEBUG] cached item', key, 'has age', age, 'seconds')
					return (age >= cacheRefreshAge)
				})
			}
			shouldRefresh(tlsProfilesCacheKey).then(refresh => {
				if (refresh) {
					this.$nextTick(this.loadTlsProfilesData)
				}
			})
			shouldRefresh(rvipsCacheKey).then(refresh => {
				if (refresh) {
					this.$nextTick(this.loadRVipsData)
				}
			})
		},

		loadTlsProfilesData () {
			console.debug('[DEBUG] Loading TLS Profiles list from Public API')
			this.loading = true
			return this.callPublicApi('admin/tlsprofiles/?limit=128').then(apiResult => {


				// This sorts the keys within the objects
				apiResult.data.forEach((obj, idx) => {
					let keys = Object.keys(obj)
					keys.sort()
					let obj2 = {}
					keys.forEach(key => {
						obj2[key] = obj[key]
					})
					apiResult.data[idx] = obj2
				})

				// This sorts the objects within the array
				apiResult.data.sort((a, b) => {
					return a.code.localeCompare(b.code, undefined, {
						numeric: true,
						sensitivity: 'base'
					})
				})

				// Store the result in the cache (this is reactive)
				this.cacheStore({
					key: tlsProfilesCacheKey,
					value: apiResult,
				})

			}).catch(error => {
				console.error(error)
			}).finally(() => {
				this.loading = false
			})
		},

		loadRVipsData () {
			console.debug('[DEBUG] Loading RVIPs list from Provisioning API')
			this.loading = true
			return this.callProvisioningApi('dns/rvips').then(apiResult => {

				// This sorts the objects within the array
				apiResult.sort((a, b) => {
					return a.id.localeCompare(b.id, undefined, {
						numeric: true,
						sensitivity: 'base'
					})
				})

				// Store the result in the cache (this is reactive)
				this.cacheStore({
					key: rvipsCacheKey,
					value: apiResult,
				})

			}).catch(error => {
				console.error(error)
			}).finally(() => {
				this.loading = false
			})
		},

	}, // end methods
}

</script>
