<template>
	<div class="pagination-buttons">
		<button class="mdc-icon-button material-icons"
				:title="'Page ' + firstPage"
				:disabled="isFirstPage"
				@click="switchToFirstPage"
		>
			<div class="mdc-icon-button__ripple"></div>
			first_page
		</button>
		<button class="mdc-icon-button material-icons"
				:title="'Page ' + previousPage"
				:disabled="!hasPreviousPage"
				@click="switchToPreviousPage"
		>
			<div class="mdc-icon-button__ripple"></div>
			navigate_before
		</button>
		<button class="mdc-icon-button material-icons"
				:title="'Page ' + nextPage"
				:disabled="!hasNextPage"
				@click="switchToNextPage"
		>
			<div class="mdc-icon-button__ripple"></div>
			navigate_next
		</button>
		<button class="mdc-icon-button material-icons"
				:title="'Page ' + lastPage"
				:disabled="isLastPage"
				@click="switchToLastPage"
				v-show="!hasUnlimitedPages"
		>
			<div class="mdc-icon-button__ripple"></div>
			last_page
		</button>
	</div>
</template>

<script>

export default {
	name: 'PaginationButtons',
	props: [
		'currentPage',
		'lastPage',
	],
	data () {
		return {}
	},
	computed: {
		firstPage () {
			return 1
		},

		hasPreviousPage () {
			return this.currentPage > this.firstPage
		},
		hasUnlimitedPages () {
			return this.lastPage === undefined || (this.lastPage < this.firstPage)
		},
		hasNextPage () {
			if (this.hasUnlimitedPages) {
				return true
			}
			return this.currentPage < this.lastPage
		},

		isFirstPage () {
			return this.currentPage <= this.firstPage
		},
		isLastPage () {
			if (this.hasUnlimitedPages) {
				return false
			}
			return this.currentPage >= this.lastPage
		},

		previousPage () {
			if (this.hasPreviousPage) {
				return this.currentPage - 1
			}
			return this.currentPage
		},
		nextPage () {
			if (this.hasNextPage) {
				return this.currentPage + 1
			}
			return this.currentPage
		},
	},
	methods: {
		switchPage (page) {
			this.$emit('switch-page', page)
		},
		switchToFirstPage () {
			this.switchPage(this.firstPage)
		},
		switchToPreviousPage () {
			this.switchPage(this.previousPage)
		},
		switchToNextPage () {
			this.switchPage(this.nextPage)
		},
		switchToLastPage () {
			this.switchPage(this.lastPage)
		},
	}
}

</script>

<style scoped lang="scss">

.pagination-buttons {
	user-select: none;
}

</style>
