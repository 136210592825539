<template>
	<div class="results mdc-typography">
		<table v-if="result.length > 0" class="basic-table mdc-typography--body1">
			<thead>
			<tr>
				<th>
					<button :class="{'off': !showIds, 'on': showIds}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="displayIds"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">ID</span>
					</button>
					<button :class="{'off': !showSerials, 'on': showSerials}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="displaySerials"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Serial</span>
					</button>
					<button :class="{'off': !showHostnames, 'on': showHostnames}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="displayHostnames"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Hostnames</span>
					</button>
				</th>
				<th title="Key Algorithm">Key</th>
				<th v-if="updated">Updated</th>
				<th v-else>Expiry</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="resultItem in result"
				v-bind:key="resultItem.id"
				:class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }"
			>
				<td class="hostnames"
					v-on:click.prevent="openHostnamesDialog(resultItem)"
				>
					<transition-group class="sliding-group"
									  name="slide-fade"
									  tag="div"
					>
						<div v-show="showIds" key="id" class="sliding-group-item">
							<span v-bind:title="resultItem.id"
							>{{ resultItem.id }}</span>
						</div>
						<div v-show="showSerials" key="serial" class="sliding-group-item">
							<span v-bind:title="resultItem.attributes.serial"
							>{{ resultItem.attributes.serial.toUpperCase() }}</span>
						</div>
						<div v-show="showHostnames"
							 key="hostnames" class="sliding-group-item"
							 v-on:click="openHostnamesDialog(resultItem)"
						>
							<ul v-if="certHostnames(resultItem).length <= 2" class="subtle-list">
								<!-- Exactly 2 hostnames is a very common scenario -->
								<li v-for="hostname in certHostnames(resultItem)"
									v-bind:key="hostname"
									v-bind:title="hostname">
									{{ hostname }}
								</li>
							</ul>
							<ul v-else class="subtle-list">
								<!-- When there are more than 2 hostnames, display common name plus a count -->
								<li v-bind:title="resultItem.attributes['common-name']">
									{{ resultItem.attributes['common-name'] }}
								</li>
								<li v-if="certHostnames(resultItem).length > 1" key="truncated">
									<i>{{ otherHostnamesText(resultItem) }}</i>
								</li>
							</ul>
						</div>
					</transition-group>
				</td>
				<td class="algorithm">{{ resultItem.attributes['key-algorithm-name'] }}</td>
				<td v-if="updated" class="timestamp">
					<Timestamp
						v-bind:unixtime="toUnixtime(resultItem.attributes.updated)"
						v-bind:wide="false"
					/>
				</td>
				<td v-else class="timestamp">
					<Timestamp
						v-bind:unixtime="toUnixtime(resultItem.attributes.expiry)"
						v-bind:wide="false"
					/>
				</td>
				<td class="actions">
					<a class="mdc-button mdc-button--raised"
					   v-bind:href="detailsUrl(resultItem.id)"
					   v-on:click.prevent="$router.push({path: detailsUrl(resultItem.id)})"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Details</span>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
		<div v-else class="max-width">
			<p><i>No certificates to show</i></p>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import Timestamp from '../../material/Timestamp'

export default {
	name: 'TableSummary',
	components: {
		Timestamp,
	},
	props: [
		'result',
		'updated',
	],
	data () {
		return {
			showIds: false,
			showSerials: false,
			showHostnames: true,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
	},
	methods: {
		displayIds () {
			this.showIds = true
			this.showSerials = false
			this.showHostnames = false
		},
		displaySerials () {
			this.showIds = false
			this.showSerials = true
			this.showHostnames = false
		},
		displayHostnames () {
			this.showIds = false
			this.showSerials = false
			this.showHostnames = true
		},
		toUnixtime (timestamp) {
			return Math.round(new Date(timestamp).getTime() / 1000)
		},
		detailsUrl (certificateId) {
			return this.appConfig('BASE_URL') + 'certificates/' + certificateId
		},

		otherHostnamesText (item) {
			let hostnames = this.certHostnames(item)
			if (hostnames.length === 2) {
				return '... (plus 1 other hostname)'
			} else if (hostnames.length > 2) {
				return '... (plus ' + (hostnames.length - 1) + ' other hostnames)'
			}
			return ''
		},

		certHostnames (item) {
			if (this.hasValue(item)) {
				if (this.hasValue(item.attributes.hostnames)) {
					return item.attributes.hostnames
				}
			}
			return []
		},
		hasValue (variable) {
			// noinspection RedundantIfStatementJS
			if (variable === undefined || variable === null) {
				return false
			}
			// noinspection RedundantIfStatementJS
			if (variable === 'undefined' || variable === 'null' || variable === '') {
				return false
			}
			return true
		},

		openHostnamesDialog (item) {
			if (this.showHostnames) {
				this.$emit('open-hostnames-dialog', item, true, true)
			}
		},

	}
}

</script>

<style lang="scss" scoped>

.results .output-title {
	display: flex;
	flex-direction: row;
}

.results .output-title-inline-label,
.results .output-title-inline-input {
	font-weight: inherit;
	font-size: inherit;
	background-color: inherit;
	border: 0;
	padding: 5px;
}

.results .output-title-inline-label {
	flex: 0 1 auto;
	padding-left: 0;
}

.results .output-title-inline-input {
	flex: 0 1 auto;
	width: 100%;
}

.basic-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
}

.basic-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
}

.basic-table td, .basic-table th {
	border: 1px solid darkgrey;
	padding: .5em;
}

.basic-table tr {
	background-color: white;
}

.basic-table tr:hover {
	background-color: lightyellow;
}

.basic-table .actions {
	width: 5%;
	text-align: center;
}

/*noinspection SpellCheckingInspection*/
.basic-table .serveractions {
	text-align: right;
}

/*noinspection SpellCheckingInspection*/
.basic-table tr.actionsexpanded {
	visibility: visible;
	display: table-row;
}

/*noinspection SpellCheckingInspection*/
.basic-table td.actionsexpanded {
	visibility: visible;
	display: table-cell;
}

/*noinspection SpellCheckingInspection*/
.basic-table .actionscollapsed {
	visibility: hidden;
	display: none;
}

.basic-table .hostname {
	margin: 2px 0;
	padding: 0
}

td.hostnames, td.algorithm, td.timestamp {
	overflow: hidden;
	word-wrap: anywhere;
}

td.hostnames {
	width: 45%;
}

td.algorithm {
	width: 10%;
}

td.timestamp {
	width: 25%;
}

.max-width {
	width: 100%;
	text-align: center;
}

th .mdc-button {
	border: 1px solid #fff;
	color: #fff;
}

th .on {
	opacity: 1;
}

th .off {
	opacity: 0.8;
}

tr.warning {
	background-color: #FFD580;
}

tr.warning:hover {
	background-color: #fcc75d;
}

tr.expired {
	background-color: #ffcccb;
}

tr.expired:hover {
	background-color: #f8a7a6;
}

.subtle-list {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.sliding-group {
	position: relative;
	margin-top: -1.5em
}

.sliding-group-item {
	display: inline-block;
	position: absolute;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
	transition: all .3s ease;
}

.slide-fade-leave-active {
	transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */
{
	transform: translateX(20px);
	opacity: 0;
}

</style>
