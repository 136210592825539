<template>
	<div :class="cssClass"
		 :style="cssStyle"
		 @click="clickHandler"
		 @mouseleave="open = false"
		 @mouseover="open = true"
	>
		<div>
			<slot name="content"></slot>
		</div>
		<div class="tooltip-container mdc-menu-surface--anchor">
			<div class="tooltip mdc-menu-surface mdc-typography"
				 data-mdc-auto-init="MDCMenuSurface"
			>
				<slot name="popup">
					&nbsp;
				</slot>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'Tooltip',
	components: {},
	props: {
		toggleOnClick: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: '',
		},
		cssStyle: {
			type: String,
			default: '',
		},
	},
	data () {
		return {
			'menuSurfaceElement': undefined,
			'menuSurface': undefined,

			'open': false,
		}
	},
	computed: {
		isOpen () {
			if (this.menuSurface === undefined) {
				return false
			}
			return this.menuSurface.isOpen
		},
	},
	watch: {
		open (value) {
			if (value) {
				this.openActionMenu()
			} else {
				this.closeActionMenu()
			}
			this.$emit('update:open', value)
		},
		menuSurface () {
			this.listenForEvents()
		}
	},
	mounted () {
		this.initMenuSurface()
	},
	updated () {
		this.initMenuSurface()
	},
	methods: {
		clickHandler () {
			if (this.toggleOnClick) {
				this.open = !this.open
			}
		},
		mouseOver () {
			this.open = true
		},
		mouseLeave () {
			this.open = false
		},
		initMenuSurface () {
			autoInit()  // Material Design Components
			if (this.$el === undefined || this.$el.querySelector === undefined) {
				return
			}
			this.$nextTick(() => {
				this.menuSurfaceElement = this.$el.querySelector('.mdc-menu-surface')
				if (this.menuSurfaceElement !== undefined && this.menuSurfaceElement !== null) {
					this.menuSurface = this.menuSurfaceElement.MDCMenuSurface
					this.listenForEvents()
				}
			})
		},
		listenForEvents () {
			this.menuSurface.listen('MDCMenuSurface:opened', () => {
				this.$emit('update:open', true)
			})
			this.menuSurface.listen('MDCMenuSurface:closed', () => {
				this.$emit('update:open', false)
			})
		},
		openActionMenu () {
			this.menuSurface.open()
			this.$emit('update:open', true)
		},
		closeActionMenu () {
			this.menuSurface.close()
			this.$emit('update:open', false)
		},
	}
}
</script>

<style lang="scss" scoped>

.tooltip-container {
	position: absolute;
	width: 400px;
	max-width: 80vw;
}

.tooltip {
	//width: 260px;
	width: 100%;
	text-align: left;
	padding: 1em;
}

</style>
