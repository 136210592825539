<template>
	<div
		v-if="!inactive && !disabled && hasInterface('progress')"
		:class="{
			'activity-table-container': true,
			'disconnected': websocketConnected !== true,
			'sleepy': websocketConnected === true && countVisibleActivities <= 0,
		}"
	>
		<p class="mdc-typography--body1 error-box" v-if="websocketConnected !== true">
			Activity information unavailable without websocket connection.
		</p>
		<div v-else>

			<p class="mdc-typography--body1 none" v-if="countVisibleActivities <= 0">
				No activity to display <span v-show="showCompleted">(including all completed)</span>
			</p>

			<table class="embedded-table mdc-typography--body1">
				<thead v-show="false">
				<tr>
					<th class="hfirst">Activity</th>
					<th class="wide">Message</th>
					<th class="narrow hlast">Status</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="item in serverActivityProgress"
					:key="item.id"
					:class="{
						'good': isItemGood(item),
						'unhappy': isItemUnhappy(item),
						'bad': isItemBad(item),
					}"
					v-show="!item.hidden"
					@click="openDetailsDialog('Activity Details', item)"
				>
					<th class="hfirst"
						:class="{
						'last': item.isLastInList || item.isLastHeaderInList,
						'child': item.isChild,
						'good': isItemGood(item),
						'unhappy': isItemUnhappy(item),
						'bad': isItemBad(item),
					}"
						v-show="!item.isChild"
						:rowspan="item.countChildren + 1"
						:title="timestampText(item)"
					>
						{{ item.activity }}
					</th>
					<td class="wide"
						:class="{
						'last': item.isLastInList,
						'child': item.isChild,
						'good': isItemGood(item),
						'unhappy': isItemUnhappy(item),
						'bad': isItemBad(item),
					}"
						:title="item.activity + ': ' + item.message + childActivitiesText(item)"
					>
					<span v-show="false && item.isParent"
						  class="float-right"
						  :title="item.countChildren + ' child ' + pluralize(item.countChildren, 'activity', 'activities')"
					>
						{{ item.countChildren }}
					</span>
						<span v-show="item.forced" class="float-right red-engineer">
						<i class="inline-icon material-icons red-engineer"
						   :title="'Forced by ' + item.username"
						>engineering</i> &nbsp;
					</span>

						<span class="activity-status-message">
						<span v-show="item.isChild">{{ item.activity }}: </span>
						{{ item.message }}
					</span>
					</td>
					<td v-if="item.completed !== true"
						:class="{
						'narrow': true,
						'hlast': true,
						'last': item.isLastInList,
						//'child': item.isChild,
						'good': isItemGood(item),
						'unhappy': isItemUnhappy(item),
						'bad': isItemBad(item),
					}"
						title="Activity in progress (please wait)"
					>
					<span>
						<ProgressSpinner md-diameter="15"/>
					</span>
						<span v-if="hasValue(item.started)" class="activity-duration">
						<NanoSince :nanotime="item.started"/>
					</span>
					</td>
					<td v-else
						:class="{
						'narrow': true,
						'hlast': true,
						'last': item.isLastInList,
						//'child': item.isChild,
						'good': isItemGood(item),
						'unhappy': isItemUnhappy(item),
						'bad': isItemBad(item),
					}"
						title="Completed"
					>
						<i aria-hidden="true" class="material-icons">done</i>
						<span
							v-show="hasValue(item.duration)"
							class="activity-duration"
						>{{ item.duration }}</span>
					</td>

				</tr>
				</tbody>
			</table>

		</div>

		<pre v-if="false">{{ serverActivityProgress }}</pre>

	</div>
</template>

<script>

// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

import * as moment from 'moment'
import {mapGetters} from 'vuex'

import NanoSince from '../../../material/NanoSince'
import ProgressSpinner from '../../../material/ProgressSpinner'
import StorageMixin from '../../../common/StorageMixin'

export default {
	name: 'ActivityTable',
	mixins: [
		StorageMixin,
	],
	components: {
		NanoSince,
		ProgressSpinner,
	},
	props: [
		'configMode',
		'serverAttributes',
		'activeInterfaces',
		'showCompleted',
		'showDetails',
	],
	data: function () {
		return {}
	},
	mounted () {
		autoInit()  // Material Design Components
	},
	updated () {
		autoInit()  // Material Design Components
	},
	computed: {
		...mapGetters([
			'websocketConnected',
		]),

		// ------------------------------------------------------
		// Identity

		serverHostnameFull () {
			if (this.serverAttributes === undefined) {
				return undefined
			}
			return this.serverAttributes.hostname.FullName
		},
		serverGroups () {
			if (!this.hasValue(this.serverAttributes)) {
				return undefined
			}
			if (!this.hasValue(this.serverAttributes.groups)) {
				return []
			}
			return this.serverAttributes.groups
		},

		configModeName () {
			return this.configMode.charAt(0).toUpperCase() + this.configMode.slice(1)
		},

		// ------------------------------------------------------

		inactive () {
			return !this.loading && this.serverAttributes === undefined
		},
		disabled () {
			if (this.serverAttributes === undefined) {
				return false
			}
			return this.serverAttributes.disabled === true
		},
		connectedAgents () {
			if (this.serverAttributes === undefined) {
				return 0
			}
			return this.serverAttributes.connected
		},
		online () {
			return this.connectedAgents > 0
		},

		// ------------------------------------------------------

		serverActivityProgress () {
			if (this.serverAttributes === undefined) {
				return undefined
			}
			if (!this.hasValue(this.serverAttributes.progress)) {
				return undefined
			}
			let result = [...this.serverAttributes.progress]

			result = result.sort((a, b) => {
				if (this.hasValue(a.parentId)) {
					if (a.parentId === b.id) {
						return 1 // a should come after b
					}
				}
				if (this.hasValue(b.parentId)) {
					if (b.parentId === a.id) {
						return -1 // a should come before b
					}
				}
				// return b.started - a.started // sort by start time (descending / newest first)
				return a.started - b.started // sort by start time (ascending / oldest first)
			})

			result.forEach(item => {
				item.children = []
				item.isChild = false
				item.isParent = false
				item.isLastInList = false
				item.isLastHeaderInList = false
				item.hidden = false
				if (item.forced && !this.hasValue(item.username)) {
					item.username = 'admin'
				}
				item.status = this.itemStatus(item)
			})
			result.forEach(item => {
				if (this.hasValue(item.parentId)) {
					result.forEach(beta => {
						if (beta.id === item.parentId) {
							if (beta.children === undefined) {
								beta.children = []
							}
							beta.children.push(item.id)
							beta.isParent = true
							item.isChild = true
						}
					})
				}
			})
			const get = (id) => {
				return result.find(item => item.id === id)
			}
			const countChildren = (item) => {
				if (item.children === undefined) {
					return 0
				}
				let count = item.children.length
				item.children.forEach(childId => {
					let child = get(childId)
					if (child !== undefined) {
						count += countChildren(child)
					}
				})
				return count
			}
			const isFullyCompleted = (item) => {
				if (item.completed !== true) {
					return false
				}
				if (item.children === undefined) {
					return true
				}
				let completed = true
				item.children.forEach(childId => {
					let child = get(childId)
					if (child !== undefined) {
						if (!isFullyCompleted(child)) {
							completed = false
						}
					}
				})
				return completed
			}
			result.forEach((item, index) => {
				item.countChildren = countChildren(item)
				item.isFullyCompleted = isFullyCompleted(item)

				if (index + item.countChildren === result.length - 1 && !item.isChild) {
					item.isLastHeaderInList = true
				}

				if (index === result.length - 1) {
					item.isLastInList = true
					if (!item.isChild) {
						item.isLastHeaderInList = true
					}
				}

				if (!item.isChild && !this.showCompleted) {
					if (item.isFullyCompleted) {
						item.hidden = true
					}
				}
			})

			let ignoreHidden = 2
			for (let index = result.length - 1; index >= 0; index--) {
				const item = result[index]
				if (!item.isChild && item.hidden) {
					item.hidden = false
					ignoreHidden -= 1
					if (ignoreHidden <= 0) {
						break
					}
				}
			}

			const inheritHidden = (item) => {
				let parent = get(item.parentId)
				if (parent === undefined) {
					return item.hidden
				}
				return inheritHidden(parent)
			}
			result.forEach(item => {
				if (item.isChild) {
					item.hidden = inheritHidden(item)
				}
			})

			return result
		},

		countVisibleActivities () {
			if (this.websocketConnected !== true) {
				return 0
			}
			if (!this.hasValue(this.serverActivityProgress)) {
				return 0
			}
			return this.serverActivityProgress.filter(item => !item.hidden).length
		},

		// ------------------------------------------------------
	},
	methods: {
		hasInterface (name) {
			return this.activeInterfaces.includes(name)
		},

		openDetailsDialog (title, entry) {
			if (this.showDetails !== true) {
				return
			}
			this.$emit('request-dialog', {
				dialog: 'keys',
				title: title,
				data: [{
					title: this.timestampText(entry),
					key: entry
				}],
			})
		},

		nanoToUnix (timestamp) {
			const nanoSecondsToSeconds = ts => ts / 1000000000
			return Math.round(nanoSecondsToSeconds(timestamp))
		},
		nanoToHuman (timestamp) {
			return moment.unix(this.nanoToUnix(timestamp)).format(
				'dddd, MMMM Do YYYY, HH:mm:ss'
			) + ' UTC'
		},

		timestampText (item) {
			if (item.started === undefined || item.started <= 0) {
				return ''
			}
			return this.nanoToHuman(item.started)
		},
		childActivitiesText (item) {
			if (item.countChildren === 0) {
				return ''
			}
			return ' (' + item.countChildren + ' child ' + this.pluralize(
				item.countChildren, 'activity', 'activities'
			) + ')'
		},
		pluralize (count, singular, plural) {
			if (count === 1) {
				return singular
			}
			return plural
		},

		itemStatus (item) {
			if (item.completed !== true) {
				return 'pending'
			}
			if (item.message === 'SUCCESS') {
				return 'good'
			}
			if (String(item.message).includes('ERROR')) {
				return 'bad'
			}
			if (String(item.message).includes('FAIL')) {
				return 'bad'
			}
			return 'unhappy'
		},
		isItemGood (item) {
			return this.itemStatus(item) === 'good'
		},
		isItemUnhappy (item) {
			return this.itemStatus(item) === 'unhappy'
		},
		isItemBad (item) {
			return this.itemStatus(item) === 'bad'
		},

	}
}

</script>

<style lang="scss" scoped>

.activity-table-container {
	vertical-align: top;
	min-height: 4.5em;
}

.disconnected {
	opacity: 0.8;
}

.sleepy {
	background-image: url("../../../../images/sleeping-dog.png");
	background-position: right bottom; /*Positioning*/
	background-repeat: no-repeat; /*Prevent showing multiple background images*/
	background-size: 10%;
}

th {
	font-weight: normal;
	text-align: left;
	vertical-align: top;
	min-width: 15%;
	max-width: 25%;
	width: 20%;
}

td {
	min-width: 25%;
	max-width: 80%;
	width: 25%;
}

.embedded-table {
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.embedded-table tr {
	background-color: transparent;
}

.embedded-table th, .embedded-table td {
	padding: .5em;
	text-align: left;
	vertical-align: middle;
	line-height: 2em;
	border: 0;
	width: 49%;
	background-color: transparent;
	color: black;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.embedded-table th.wide, .embedded-table td.wide {
	width: 69%;
}

.embedded-table th.narrow, .embedded-table td.narrow {
	width: 20%;
}

.embedded-table th {
	border-right: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table th.hfirst, .embedded-table td.hfirst {
	border-left: 0;
}

.embedded-table th.hlast, .embedded-table td.hlast {
	border-right: 0;
}

.embedded-table td {
	border-left: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table .last {
	border-bottom: 0;
}

.embedded-table tr {
	opacity: 0.9;
}

.embedded-table tr:hover {
	background-color: lightgoldenrodyellow;
	opacity: 1;
}

.embedded-table th.child, .embedded-table td.child {
}

.embedded-table td.child::before {
	content: '➡️ ';
}

.material-icons {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}

ul {
	margin: 0;
}

p {
	margin: 0 0 1em;
}

.ok {
	color: darkgreen;
}

.good {
	background-color: lightgreen;
}

.embedded-table td.good {
	color: darkgreen !important;
}

.unhappy {
	background-color: #ffbf00;
}

.embedded-table td.unhappy {
	color: darkorange !important;
}

.bad {
	background-color: #f18787;
}

.embedded-table td.bad {
	color: darkred !important;
}

.warning {
	color: darkorange;
	//font-weight: bold;
}

.disabled {
	opacity: 0.5;
}

tr.disabled th {
	background-color: darkgray;
}

.none {
	font-style: italic;
	color: darkgray;
}

.yellow-star {
	color: #ffbf00;
}

.red-engineer {
	color: orangered;
}

.subtle-list {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.activity-duration {
	padding-left: 0.25em;
}

.activity-status-message {
	text-overflow: ellipsis;
}

.float-right {
	float: right;
	padding-left: 0.5em;
}

.error-box {
	clear: both;
	border: 1px dashed red;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	background-color: rgba(245, 230, 230, 0.5);
	color: darkred;
}

pre {
	padding: 0.25em;
	margin: 0.5em 0.5em 0.5em 0;
	background-color: #eeeeee;
	border: 1px solid lightgray;
}

</style>
