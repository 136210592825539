<template>
	<div class="code-container">
		<pre v-highlightjs="renderedCode"><code :class="className">{{ renderedCode }}</code></pre>
	</div>
</template>

<script>

import hljs from 'highlight.js'

export default {
	name: 'HighlightedCode',
	props: {
		code: {
			required: true
		},
		prefix: {
			type: String,
			default: ''
		},
		className: {
			type: String,
			default: ''
		}
	},
	computed: {
		renderedCode () {
			if (typeof this.code === 'string') {
				return this.prefix + this.code
			}
			return this.prefix + JSON.stringify(this.code, null, 2)
		}
	},
	directives: {
		// https://www.metachris.com/2017/02/vuejs-syntax-highlighting-with-highlightjs/
		'highlightjs': {
			deep: true,
			bind: (el, binding) => {
				// on first bind, highlight all targets
				let targets = el.querySelectorAll('code')
				targets.forEach((target) => {
					// if a value is directly assigned to the directive, use this
					// instead of the element content.
					if (binding.value) {
						target.textContent = binding.value
					}
					hljs.highlightElement(target)
				})
			},
			componentUpdated: (el, binding) => {
				// after an update, re-fill the content and then highlight
				let targets = el.querySelectorAll('code')
				targets.forEach((target) => {
					if (binding.value) {
						target.textContent = binding.value
						hljs.highlightElement(target)
					}
				})
			}
		},
	},
}
</script>

<style scoped lang="scss">

.code-container {
	max-width: 100%;
	overflow: hidden;
	overflow-wrap: break-word;
}

.code-container pre {
	white-space: pre-wrap;
	overflow-wrap: break-word;
	padding: 0;
	margin: 0.5em 0;
}

.code-container pre code {
	padding: 0.25em;
}

</style>
