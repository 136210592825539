<template>
	<div class="editable-list mdc-card mdc-card--outlined mdc-typography">
		<h3 v-if="titleText !== undefined && titleText.length > 0"
			class="mdc-typography--headline5">{{ titleText }}</h3>
		<ul class="mdc-list">
			<li v-for="item in items" :key="item" class="mdc-list-item">
				<span class="mdc-list-item__ripple"></span>
				<span v-if="editing === item" class="mdc-list-item__text">

					<div class="input-container">
						<label class="mdc-text-field mdc-text-field--filled" data-mdc-auto-init="MDCTextField">
						  <span class="mdc-text-field__ripple"></span>
						  <span id="edit-list-item" class="mdc-floating-label">{{ hintText }}</span>
						  <input id="edit-list-item-input" v-model="inputText" aria-labelledby="new-list-item"
								 class="mdc-text-field__input" type="text">
						  <span class="mdc-line-ripple"></span>
						</label>
					<div>
					<button class="mdc-button mdc-button--dense mdc-button--raised" @click.prevent="updateItem">
						<i aria-hidden="true" class="material-icons mdc-button__icon">{{ saveIcon }}</i>
						<span class="mdc-button__label">{{ saveText }}</span>
					</button>

				</span>
				<span v-if="editing !== item"
					  class="mdc-list-item__text"
					  @click.prevent="editItem(item)"
				>{{ item }}</span>
				<span v-if="editing !== item && additionOnly !== true" class="mdc-list-item__meta">
					<button class="mdc-icon-button material-icons"
							@click.prevent="deleteItem(item)"
					>delete</button>
				</span>
			</li>
			<li v-if="!adding" class="mdc-list-item">
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
					<button :disabled="editing !== false"
							class="mdc-button mdc-button--dense mdc-button--raised"
							@click.prevent="addItem"
					>
						<i aria-hidden="true" class="material-icons mdc-button__icon">add</i>
						<span class="mdc-button__label">{{ addText }}</span>
					</button>
				</span>
			</li>
			<li v-if="adding" class="mdc-list-item">
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
					<div class="input-container">
						<label class="mdc-text-field mdc-text-field--filled" data-mdc-auto-init="MDCTextField">
						  <span class="mdc-text-field__ripple"></span>
						  <span id="new-list-item" class="mdc-floating-label">{{ hintText }}</span>
						  <input id="new-list-item-input" v-model="inputText" aria-labelledby="new-list-item"
								 class="mdc-text-field__input" type="text">
						  <span class="mdc-line-ripple"></span>
						</label>
					<div>
					<button class="mdc-button mdc-button--dense mdc-button--raised" @click.prevent="saveItem">
						<i aria-hidden="true" class="material-icons mdc-button__icon">{{ saveIcon }}</i>
						<span class="mdc-button__label">{{ saveText }}</span>
					</button>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import autoInit from '@material/auto-init'

export default {
	name: 'EditableList',
	props: [
		'items',
		'titleText',
		'addText',
		'hintText',
		'additionOnly'
	],
	data () {
		return {
			adding: false,
			editing: false,

			inputText: '',

			saveIcon: 'close',
			saveText: 'Cancel'
		}
	},
	watch: {
		inputText (value) {
			if (this.adding === true) {
				if (value.length > 0) {
					this.saveIcon = 'save'
					this.saveText = 'Save'
				} else {
					this.saveIcon = 'close'
					this.saveText = 'Cancel'
				}
			} else {
				if (value === this.editing) {
					this.saveIcon = 'close'
					this.saveText = 'Cancel'
				} else {
					this.saveIcon = 'save'
					this.saveText = 'Save'
				}
			}
		},
		adding (value) {
			if (value === true) {
				if (this.inputText.length > 0) {
					this.saveIcon = 'save'
					this.saveText = 'Save'
				} else {
					this.saveIcon = 'close'
					this.saveText = 'Cancel'
				}
			}
		},
	},
	computed: {},
	mounted () {
		autoInit()
	},
	updated () {
		this.$nextTick(autoInit)
	},
	methods: {
		handleKeypress (event) {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				this.saveItem()
			}
		},
		addItem () {
			this.adding = true
			this.$nextTick(() => {
				let textInputEl = this.$el.querySelector('#new-list-item-input')
				textInputEl.addEventListener('keypress', this.handleKeypress)
				textInputEl.focus()
			})
		},
		saveItem () {
			if (this.inputText.length > 0) {
				this.$emit('update:items', this.items.concat([this.inputText]))
			}
			this.adding = false
			this.inputText = ''
		},
		deleteItem (item) {
			this.$emit('update:items', this.items.filter(i => i !== item))
		},
		editItem (item) {
			console.debug('editItem', item)
			if (this.adding) {
				return
			}
			if (this.additionOnly === true) {
				return
			}
			this.inputText = item
			this.editing = item
		},
		updateItem () {
			if (this.additionOnly !== true) {
				if (this.inputText.length > 0) {
					this.$emit('update:items', this.items.map(i => i === this.editing ? this.inputText : i))
				}
			}
			this.editing = false
			this.inputText = ''
		}
	},
}
</script>

<style lang="scss" scoped>

.mdc-typography--headline5 {
	margin: 0;
	padding: 0.5em 0.5em 0;
}

.mdc-list-item__text {
	width: 100%;
}

.input-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.input-container .input-field {
	width: 100%;
}

.input-container .mdc-text-field {
	width: 100%;
	background-color: white;
}

.input-container .mdc-button {
	margin-bottom: 1em;
	margin-left: 1em;
}

</style>
