<template>
	<div>
		<div v-if="certificatesMeta !== undefined && (certificatesMeta.limit <= certificatesMeta.count)">
			<div class="error-card mdc-card mdc-card--outlined mdc-typography">
				<p class="mdc-typography--body1">
					This list has been limited to {{ certificatesMeta.limit }} certificates.
					Not all certificates are shown.
				</p>
			</div>
		</div>
		<div v-show="showOrphanedMessage && !helpHidden" class="orphaned-message">
			<div class="instructions-card mdc-card mdc-card--outlined mdc-typography">
				<p class="mdc-typography--body1">
					The names embedded within a certificate are used to connect
					that certificate with one or more services. For a certificate
					to be used within a configuration it is essential for it to
					be connected to a service. Certificates which are not
					connected to a service are still stored and are available
					for future. To use these certificates you must create a
					service which has a domain that it covers. It will then be
					connected automatically.
				</p>
				<p class="mdc-typography--body1">
					This is a list of Certificates within the Public API v2
					database for this account, but are not currently connected
					to any Service.
				</p>
				<p class="mdc-typography--body2">
					Certificates which are not connected to a service are not
					synchronised to the backend provisioning API and might not
					show up in search results.
				</p>
				<div class="max-width">
					<button class="mdc-button close-button" v-on:click="helpHidden=true">
						<span class="mdc-button__label">Dismiss</span>
					</button>
				</div>
			</div>
		</div>
		<table v-if="certificates !== undefined"
			   class="account-table mdc-typography--body1"
		>
			<thead>
			<tr>
				<th>Serial</th>
				<th>Hostnames</th>
				<th>Valid From</th>
				<th>Expires</th>
				<th>Services</th>
				<th>Domains</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			<tr v-if="certificates.length === 0">
				<td class="empty-table" colspan="7">There are no certificates to display.</td>
			</tr>
			<tr v-for="certificate in certificates">
				<td>{{ certificate.serialNumber.toUpperCase() }}</td>
				<td>
					<template v-for="hostname in uniqueHostnames(certificate)">
						<p>{{ hostname }}</p>
					</template>
				</td>
				<td>
					<Timestamp v-bind:unixtime="toUnixtime(certificate.notBefore)" v-bind:wide="false"/>
				</td>
				<td v-bind:class="{ red: isExpired(certificate) }">
					<Timestamp v-bind:unixtime="toUnixtime(certificate.notAfter)" v-bind:wide="false"/>
				</td>
				<td>
					{{ certificate.services.length }}
					<!--                    <a class="mdc-button"-->
					<!--                       v-bind:href="servicesUrl(certificate._id)"-->
					<!--                       v-on:click.prevent="$router.push({path: servicesUrl(certificate._id)})"-->
					<!--                       v-if="certificate.domains.length > 0"-->
					<!--                    >-->
					<!--                        <div class="mdc-button__ripple"></div>-->
					<!--                        <span class="mdc-button__label">Show</span>-->
					<!--                    </a>-->
				</td>
				<td>
					{{ certificate.domains.length }}
					<!--                    <a class="mdc-button"-->
					<!--                       v-bind:href="servicesUrl(certificate._id)"-->
					<!--                       v-on:click.prevent="$router.push({path: servicesUrl(certificate._id)})"-->
					<!--                       v-if="certificate.domains.length > 0"-->
					<!--                    >-->
					<!--                        <div class="mdc-button__ripple"></div>-->
					<!--                        <span class="mdc-button__label">Show</span>-->
					<!--                    </a>-->
				</td>
				<td class="actions">
					<a class="mdc-button mdc-button--raised"
					   v-bind:href="certificateDetailsUrl(certificate._id)"
					   v-on:click.prevent="$router.push({path: certificateDetailsUrl(certificate._id)})"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">View&nbsp;Details</span>
					</a>
					<a v-if="certificate.services.length > 0"
					   class="mdc-button mdc-button--raised"
					   v-bind:href="servicesUrl(certificate._id)"
					   v-on:click.prevent="$router.push({path: servicesUrl(certificate._id)})"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">View&nbsp;Services</span>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import Timestamp from '../../material/Timestamp'
import StickyList from '../../material/StickyList'
import * as _ from 'lodash'
import * as moment from 'moment'

export default {
	name: 'CertificatesList',
	components: {
		Timestamp,
		StickyList,
	},
	props: [
		'accountId',
		'certificatesMeta',
		'certificates',
		'showOrphanedMessage',
	],
	data () {
		return {
			helpHidden: false,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		certificatesOrdered () {
			return _.orderBy(this.certificates, 'notAfter')
		},
	},
	methods: {
		toUnixtime (timestamp) {
			return Math.round(new Date(timestamp).getTime() / 1000)
		},
		isExpired (pubCertificate) {
			let timeObject = moment.default(pubCertificate.notAfter).utc()
			return timeObject.isBefore(moment.default().utc())
		},
		uniqueHostnames (cert) {
			if (cert === undefined) {
				return []
			}
			let result = []
			cert.subjectNames.forEach(item => result.push(item))
			result.push(cert.subjectCommonName)
			result = result.filter((value, index, self) => {
				return self.indexOf(value) === index
			})
			result.sort()
			return result
		},
		certificateDetailsUrl (certificateId) {
			return this.appConfig('BASE_URL') + 'certificates/' + certificateId
		},
		servicesUrl (certificateId) {
			return this.appConfig('BASE_URL') + 'services/accounts/' + this.accountId + '/services/?filter_by_certificate=' + certificateId
		},
	}
}

</script>

<style lang="scss">

.account-certificates .orphaned-message {
	margin: 1em 0;
}

.account-certificates .orphaned-message .instructions-card {
	width: 100%;
	padding: 0.5em 1em;
}

.account-certificates .account-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
}

.account-certificates .account-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
}

.account-certificates .account-table td, .account-certificates .account-table th {
	border: 1px solid darkgrey;
	padding: .5em;
	vertical-align: top;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.account-certificates .account-table tr {
	background-color: white;
}

.account-certificates .account-table tr:hover {
	background-color: lightyellow;
}

.account-certificates td p {
	margin: 0;
}

.account-certificates .empty-table {
	text-align: center;
}

.account-certificates .actions {
	width: 120px;
	text-align: center;
}

.account-certificates .red {
	background-color: salmon;
}

.account-certificates .amber {
	background-color: #ffbf00;
}

.account-certificates .green {
	background-color: lightgreen;
}

.account-certificates .actions .mdc-button {
	margin: 4px;
}

.account-certificates .close-button {
	float: right;
}

</style>
