<template>
	<div class="mdc-select"
		 data-mdc-auto-init="MDCSelect">
		<i class="mdc-select__dropdown-icon"></i>
		<select class="mdc-select__native-control" v-bind:disabled="isDisabled">
			<template v-if="groupedOptions !== undefined">
				<template v-for="group in groupedOptions">
					<template v-if="group.label === undefined || group.label.length === 0">
						<option v-for="option in group.options"
								v-if="option.hidden !== true"
								v-bind:disabled="option.disabled || isDisabled"
								v-bind:value="option.value"
						>
							{{ option.text }}
						</option>
					</template>
					<template v-else>
						<optgroup
							v-bind:label="group.label"
						>
							<option v-for="option in group.options"
									v-if="option.hidden !== true"
									v-bind:disabled="option.disabled || isDisabled"
									v-bind:value="option.value"
							>
								{{ option.text }}
							</option>
						</optgroup>
					</template>
				</template>
			</template>
			<template v-else-if="options !== undefined">
				<option
					v-for="option in options"
					v-if="option.hidden !== true"
					v-bind:disabled="option.disabled || isDisabled"
					v-bind:value="option.value"
				>
					{{ option.text }}
				</option>
			</template>
			<template v-else>
				<option disabled selected value=""></option>
			</template>
		</select>
		<label class="mdc-floating-label">{{ label }}</label>
		<div class="mdc-line-ripple"></div>
	</div>
</template>

<script>

import autoInit from '@material/auto-init'

export default {
	components: {},
	props: [
		'label',
		'options',
		'groupedOptions',
		'defaultIndex',
		'desiredValue',
		'disabled',
		'monospaced',
	],
	data () {
		return {
			mdcSelect: undefined,
			selectedIndex: undefined,
			selectedValue: undefined
		}
	},
	computed: {
		isDisabled () {
			if (this.disabled !== undefined) {
				return this.disabled
			}
			return false
		},
		isMonospaced () {
			if (this.monospaced !== undefined) {
				return this.monospaced
			}
			return false
		},
	},
	watch: {
		selectedIndex () {
			this.emitSelected()
		},
		selectedValue () {
			this.emitSelected()
		},
		desiredValue () {
			let self = this
			this.$nextTick(() => {
				self.selectDesiredValue()
			})
		},
		options () {
			this.$nextTick(() => {
				this.selectDesiredValue()
			})
		},
		groupedOptions () {
			this.$nextTick(() => {
				this.selectDesiredValue()
			})
		},
		isDisabled () {
			console.debug(this.mdcSelect)
			this.mdcSelect.disabled = this.isDisabled
		},
	},
	mounted () {
		this.setup()
		if (this.defaultIndex !== undefined) {
			this.mdcSelect.selectedIndex = this.defaultIndex
		}
		// console.debug('select', this.defaultIndex, this.desiredValue)
	},
	updated () {
		this.setup()
	},
	methods: {
		setup () {
			try {
				autoInit()  // Material Design Components
			} catch (error) {
				console.error(error)
			}
			this.mdcSelect = this.$el.MDCSelect
			this.mdcSelect.disabled = this.isDisabled
			this.mdcSelect.listen('MDCSelect:change', () => {
				this.$set(this, 'selectedIndex', this.mdcSelect.selectedIndex)
				this.$set(this, 'selectedValue', this.mdcSelect.value)
			})
			if (this.desiredValue !== undefined) {
				this.$nextTick(() => {
					this.selectDesiredValue()
				})
			}
		},
		findIndexOfValue (value) {
			value = String(value)
			let index = 0
			if (this.defaultIndex !== undefined) {
				index = this.defaultIndex
			}
			if (this.options !== undefined) {
				this.options.forEach((option, optionIndex) => {
					if (String(option.value) === value) {
						index = optionIndex
					}
				})
			}
			if (this.groupedOptions !== undefined) {
				let count = 0
				this.groupedOptions.forEach(group => {
					group.options.forEach(option => {
						if (String(option.value) === value) {
							index = count
						}
						count++
					})
				})
			}
			return index
		},
		selectDesiredValue () {
			if (this.desiredValue !== undefined) {
				this.mdcSelect.selectedIndex = this.findIndexOfValue(this.desiredValue)
			}
		},
		emitSelected () {
			this.$nextTick(() => {
				this.$emit('update:selectedIndex', this.selectedIndex)
				this.$emit('update:selectedValue', this.selectedValue)
			})
		},
	}
}

</script>

<style lang="scss" scoped>

.mdc-select {
	width: 100%;
	margin: 0.25em 0;
}

.monospaced select, .monospaced option {
	font-family: monospace;
}

</style>
