<template>
	<div id="snackbar"
		 class="mdc-snackbar"
		 data-mdc-auto-init="MDCSnackbar"
	>
		<div class="mdc-snackbar__surface">
			<div aria-live="polite"
				 class="mdc-snackbar__label"
				 role="status">
				{{ message }}
			</div>
		</div>
	</div>
</template>

<script>
import autoInit from '@material/auto-init'

export default {
	components: {},
	props: [
		'open',
		'message',
	],
	data: function () {
		return {
			'snackbarElement': undefined,
			'snackbar': undefined,
			'timeoutMs': 10000,
		}
	},
	computed: {
		isOpen () {
			return this.snackbar.isOpen
		}
	},
	watch: {
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openSnackbar()
				} else {
					this.closeSnackbar()
				}
			}
			this.$emit('update:open', value)
		}
	},
	mounted () {
		let self = this
		autoInit()
		this.snackbarElement = document.querySelector('#snackbar')
		this.snackbar = this.snackbarElement.MDCSnackbar
		this.snackbar.listen('MDCSnackbar:opened', function () {
			self.snackbarElement.setAttribute('aria-hidden', 'true')
			self.$emit('update:open', true)
		})
		this.snackbar.listen('MDCSnackbar:closed', function () {
			self.snackbarElement.removeAttribute('aria-hidden')
			self.$emit('update:open', false)
		})
		this.snackbar.timeoutMs = this.timeoutMs
		if (this.isOpen !== this.open) {
			if (this.open) {
				this.openSnackbar()
			} else {
				this.closeSnackbar()
			}
		}
	},
	updated () {
		autoInit()
		this.snackbar.timeoutMs = this.timeoutMs
	},
	methods: {
		openSnackbar () {
			if (!this.isOpen) {
				this.snackbar.labelText = this.message
				this.snackbar.open()
			}
		},
		closeSnackbar () {
			if (this.isOpen) {
				this.snackbar.close()
			}
		},
	},
}
</script>

<style lang="scss">

</style>
