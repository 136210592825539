<template>
	<div class="error-body">
		<!-- 404 Error Page, created by Brian Wojtczak, based on a template created by Saransh Sinha -->
		<svg height="500px" version="1.1" viewBox="0 0 837 1045" width="380px" xmlns="http://www.w3.org/2000/svg"
			 xmlns:xlink="http://www.w3.org/1999/xlink">
			<g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
				<path id="Polygon-1"
					  d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
					  stroke="#795D9C" stroke-width="6"></path>
				<path id="Polygon-2"
					  d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
					  stroke="#EF4A5B" stroke-width="6"></path>
				<path id="Polygon-3"
					  d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
					  stroke="#F2773F" stroke-width="6"></path>
				<path id="Polygon-4"
					  d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
					  stroke="#007FB2" stroke-width="6"></path>
				<path id="Polygon-5"
					  d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
					  stroke="#36B455" stroke-width="6"></path>
			</g>
		</svg>
		<div class="message-box mdc-typography">
			<h1 class="mdc-typography--headline1">Error 404</h1>
			<p class="mdc-typography--body1"><strong>Page not found</strong></p>
			<p class="mdc-typography--body2">
				If you believe this error is the result of a bug, please report it
				so that can ensure it gets fixed.
			</p>
			<a v-if="bugReportingUrl !== undefined"
			   :href="bugReportingUrl"
			   class="mdc-button mdc-button--raised"
			   target="_blank"
			>
				<div class="mdc-button__ripple"></div>
				<span class="mdc-button__label">Report Bug</span>
			</a>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
	name: 'NotFound',
	computed: {
		...mapGetters([
			'appConfig',
		]),
		bugReportingUrl () {
			return this.appConfig('BUGS_URL')
		}
	},
}

</script>

<style lang="scss" scoped>

.error-body {
	width: 100%;
}

svg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -250px;
	margin-left: -400px
}

.message-box {
	height: 300px;
	width: 300px;
	position: absolute;
	top: 40%;
	left: 50%;
	margin-top: -100px;
	margin-left: 50px;
	font-family: Roboto, sans-serif;
	font-weight: 300
}

.message-box h1 {
	font-size: 60px;
	line-height: 46px
}

.message-box p {
	margin-top: 40px
}

.message-box a {
	text-decoration: none
}

#Polygon-1, #Polygon-2, #Polygon-3, #Polygon-4, #Polygon-5 {
	animation: float 1s infinite ease-in-out alternate
}

#Polygon-2 {
	animation-delay: .2s
}

#Polygon-3 {
	animation-delay: .4s
}

#Polygon-4 {
	animation-delay: .6s
}

#Polygon-5 {
	animation-delay: .8s
}

@keyframes float {
	100% {
		transform: translateY(20px)
	}
}

@media (max-width: 850px) {
	svg {
		display: none;
		visibility: hidden
	}

	.message-box {
		top: 40%;
		left: 50%;
		margin-top: -100px;
		margin-left: -190px;
		text-align: center
	}
}

</style>
