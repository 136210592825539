<template>
	<div v-bind:class="{ 'indented': indent === true, 'noselect': true }">
		<a v-if="!hidden"
		   v-show="!hidden"
		   class="mdc-list-item"
		   v-bind:class="{ 'mdc-list-item--activated': active, 'opened': open === true, }"
		   v-bind:href="'#'"
		   v-on:click="clickHandler"
		>
			<i aria-hidden="true" class="mdc-list-item__graphic" v-bind:class="iconClass">{{ iconText }}</i>
			<span class="mdc-list-item__text">{{ title }}</span>
			<span v-if="trailingIcon !== undefined" aria-hidden="true"
				  class="mdc-list-item__meta material-icons">{{ trailingIcon }}</span>
		</a>
	</div>
</template>

<script>

export default {
	name: 'DrawerLink',
	props: [
		'id',
		'title',
		'icon',
		'hidden',
		'indent',
		'expandable',
		'open',
		'active',
		'callback',
		'iconFont',
	],
	computed: {
		iconType () {
			switch (this.iconFont) {
			case 'fab': // Brands
				return 'fab'
			case 'far': // Regular
				return 'far'
			case 'fas': // Solid
				return 'fas'
			case undefined:
			case null:
			default:
				return 'material-icons'
			}
		},
		iconClass () {
			switch (this.iconType) {
			case 'material-icons':
				return 'material-icons'
			case 'fab': // Brands
				return 'material-icons fab ' + this.icon
			case 'far': // Regular
				return 'material-icons far ' + this.icon
			case 'fas': // Solid
				return 'material-icons fas ' + this.icon
			default:
				return ''
			}
		},
		iconText () {
			switch (this.iconType) {
			case 'material-icons':
				return this.icon
			default:
				return ''
			}
		},
		trailingIcon () {
			if (this.expandable === true) {
				if (this.open === true) {
					return 'expand_circle_down'
					// return 'expand_less'
				}
				return 'expand_more'
			}
			return undefined
		}
	},
	methods: {
		clickHandler (event) {
			event.preventDefault()
			if (this.callback === undefined) {
				this.$emit('drawer-click', [event, this])
			} else {
				this.callback([event, this])
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.opened {
	background-color: ghostwhite;
}

.indented {
	background-color: ghostwhite;
	padding-left: 2em !important;
	margin: 2px 8px 0;
}

.indented a {
	margin-top: 0;
	margin-right: 0;
}

.noselect {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

</style>
