<template>
	<div id="app" :class="{
		'app-container': true,
		'light': colorScheme === 'light',
		'dark': colorScheme === 'dark'
	}">
		<TopAppBar
			v-if="authenticated && !authRedirectCall && !viewingTestingModeWarning"
			v-bind:actions="topAppBarActions"
			v-bind:autoswitch-status="autoswitchStatus"
			v-bind:show-nav="true"
			v-bind:title="title"
			v-on:top-nav-click="toggleNav"
			v-on:profile-click="profileCallback"
			v-on:reauthenticate="reauthenticate"
		>
			<Drawer
				v-bind:drawerItems="drawerItems"
				v-bind:drawerOpen="drawerOpen"
				v-bind:navigate="true"
				v-bind:selectedId="selectedId"
				v-bind:limitContentWidth="this.contentWidthMode !== 'full'"
			>
				<div>
					<div v-if="loading || !appConfigLoaded">
						<Loading v-show="loading || !appConfigLoaded"
								 :message="loadingStage"/>
					</div>
					<div v-else>
						<router-view
							v-bind:userPresence="userPresence"
							v-on:reauthenticate="reauthenticate"
						/>
					</div>
				</div>
				<div>
					<Snackbar
						v-bind:message="snackmsg"
						v-bind:open.sync="snackopen"
					/>
				</div>
				<div>
					<ToastNotifications
						v-bind:notice="toastNotice"
					/>
				</div>
			</Drawer>
		</TopAppBar>
		<div v-if="!authenticated || authRedirectCall">
			<div v-if="loading || authRedirectCall">
				<Loading :delay="500"
						 :message="loadingStage"/>
			</div>
			<div v-if="!authenticated &&!loading">
				<div v-show="confirmDialogOpen">
					<Cat/>
				</div>
				<div>
					<img
						alt="CacheFly Logo"
						class="logo-image"
						src="../images/cachefly-logo-2021.png"
					>
				</div>
			</div>
			<div>
				<PageDialog
					width="90"
					height="90"
					:withoutPadding="true"
					:open.sync="authLoginOpen"
				>
					<div class="auth-container" v-if="authLoginOpen">
						<iframe
							id="auth-frame"
							src="about:blank"
							loading="lazy"
							class="auth-iframe"
						></iframe>
					</div>
				</PageDialog>
			</div>
		</div>
		<div v-show="!authLoginOpen">
			<ConfirmDialog
				v-bind:open.sync="confirmDialogOpen"
				v-bind:question="confirmQuestion"
				v-bind:showCancel="confirmShowCancel"
				v-bind:title="confirmTitle"
				v-bind:useCaptcha="confirmUseCaptcha"
				v-on:cancelled="confirmDialogCancelled"
				v-on:closed="confirmDialogClosed"
				v-on:confirmed="confirmDialogConfirmed"
			/>
		</div>
		<div v-if="loadProfileDialog">
			<Profile
				:open.sync="showProfileDialog"
				:showLoading="loading"
				@requestSwitchEnvironment="switchEnvironment"
				@reauthenticate="reauthenticate"
			/>
		</div>
	</div>
</template>

<!--suppress JSUnresolvedFunction -->
<script>

// Material Design Components (load here = available everywhere)
// noinspection ES6UnusedImports
import * as mdc from 'material-components-web'

// Material Design Auto Initialize (load here = available everywhere)
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

// vuex
import {mapActions, mapGetters} from 'vuex'

// Code highlight styles (global)
import 'highlight.js/styles/intellij-light.css'

// App Internal
import ConfirmDialogMixin from './common/ConfirmDialogMixin'
import Drawer from './material/Drawer'
import EncryptedStorageMixin from './common/EncryptedStorageMixin'
import Loading from './Loading'
import PageDialog from './common/PageDialog.vue'
import Snackbar from './material/Snackbar'
import ToastNotifications from './material/ToastNotifications'
import TopAppBar from './material/TopAppBar'

// Datadog
import {datadogRum} from '@datadog/browser-rum'

// Axios
import axios from 'axios'

// Moment
import * as moment from 'moment'

// Gravatar
// TODO replace library with internal code
import gravatar from 'gravatar'

const defaultTitle = 'CacheFly Config Provisioning'

const appPreferencesStorageKey = 'app-preferences'

const oneSecond = 1000
const oneMinute = 60 * oneSecond
const credentialRenewalThreshold = 5 * oneMinute

const websocketConnectionTimeout = 8 * oneSecond
const websocketReconnectInterval = 10 // seconds

const notAuthenticatedTitle = 'Not Authenticated'

// noinspection JSUnresolvedFunction
export default {
	name: 'App',
	mixins: [
		ConfirmDialogMixin,
		EncryptedStorageMixin,
	],
	components: {
		Drawer,
		Loading,
		PageDialog,
		Snackbar,
		ToastNotifications,
		TopAppBar,

		Profile: () => import('./profile/Profile'),
		Cat: () => import('./material/Cat'),
	},
	data () {
		return {
			selectedId: 'notimplemented', // OLD TODO REFACTOR
			selectedPOP: undefined, // OLD TODO REFACTOR

			autoswitchRef: undefined,
			autoswitchStatus: true, // Assume true by default
			autoswitchLoading: false,

			datadogInitialized: false,
			datadogDisabled: false,

			appPreferences: undefined, // generic user preferences container
			preferredEnvironment: undefined,

			// Visual Fields
			title: defaultTitle,
			loading: true,
			loadingStage: undefined,
			drawerOpen: true,
			showAccountsMenu: true,
			showCertificatesMenu: true,
			showConfigurationMenu: true,
			showInfrastructureMenu: true,
			showSystemMenu: true,
			showDevToolsMenu: false,
			loadProfileDialog: false,
			showProfileDialog: false,
			showFeaturesMenu: false,
			showSettingsMenu: false,
			showConfigModesMenu: false,
			contentWidthMode: 'full',
			monitoringSystemColorScheme: false,
			preferredColorScheme: 'auto',
			systemColorScheme: 'light',
			colorScheme: 'light',

			// Snackbar Notifications
			snackopen: false,
			snackmsg: '',

			// Toast Notifications
			toastNotice: undefined,

			wsFailedTime: '',
			wsReconnectRef: undefined,
			wsReconnectCount: websocketReconnectInterval,

			// Configuration Promise
			configLoader: undefined,
			configLoaderResolve: undefined,
			configLoaderReject: undefined,

			// Authentication
			credentialLoader: undefined, // a pending promise
			authenticationChecker: undefined, // setInterval reference
			authenticated: false,
			gravatarProfile: undefined,
			authLoginOpen: false,       // Show the SSO login page in an iframe
			authRedirectCall: false,    // Redirect from the SSO login page
			viewingTestingModeWarning: false,

			// User Presence and Idle Detection
			hasIdleDetection: undefined,
			listeningToVisibility: false,
			userPresence: {
				status: 'active', // ENUM: active, hidden, idle
				active: true,   // Indicates that the app is actively being used.
				hidden: false,  // Indicates that the app can't be seen (e.g. switched tab).
				idle: false,    // Indicates the user is not using the computer (any app).
				locked: false,  // Indicates the user has locked the computer.
			},
			networkOnline: true,

			unsubscribeMethods: [],
		}
	},
	provide () {
		return {
			userPresence: this.userPresence,
			colorScheme: this.colorScheme,
			networkOnline: this.networkOnline,
		}
	},
	computed: {
		...mapGetters([

			'appConfigEnvironment',
			'appConfig',
			'appConfigLoaded',
			'appConfigError',

			'ssoCredential', // CFSSO Public API v2 credential

			'websocketConnected',
			'websocketFailure',
		]),

		isProduction () {
			if (!this.appConfigLoaded) {
				return true
			}
			return this.appConfig('APP_ENV') === 'production'
		},
		isLocked () {
			return this.userPresence.locked
		},

		topAppBarActions () {
			let result = []

			if (!this.authenticated) {
				return result
			}

			if (!this.appConfigLoaded) {
				return result
			}

			// location.host.startsWith('provisioning-webui.tools-internal.cachefly.com')
			if (!location.host.startsWith('provisioning-webui.tools-internal.cachefly.com')) {
				let datadogIcon = 'pets'
				if (this.datadogDisabled === true) {
					datadogIcon = 'gps_off'
				}
				result.push({
					id: 'datadog',
					kind: 'button',
					arialabel: 'Toggle DataDog',
					icon: datadogIcon,
					external: true,
					callback: () => {
						this.datadogDisabled = !this.datadogDisabled
						this.snackmsg = 'Datadog ' + (this.datadogDisabled ? 'Disabled' : 'Enabled')
					}
				})
			}

			// we show the icon for the mode that you will go to when you
			// click the button (not the icon for the current mode)
			const contentWidthLimitedModeIcon = 'width_normal'  // limited mode
			const contentWidthFluidModeIcon = 'width_full'      // fluid mode
			let widthIcon = undefined
			let widthTooltip = undefined
			switch (this.contentWidthMode) {
			case 'full':
				widthIcon = contentWidthLimitedModeIcon
				widthTooltip = 'Limit Content Width'
				break
			case 'limited':
				widthIcon = contentWidthFluidModeIcon
				widthTooltip = 'Allow Full Content Width'
				break
			}
			if (widthIcon !== undefined) {
				result.push({
					id: 'width',
					kind: 'button',
					arialabel: widthTooltip,
					icon: widthIcon,
					external: true,
					callback: () => {
						if (this.contentWidthMode !== 'full') {
							this.contentWidthMode = 'full'
						} else {
							this.contentWidthMode = 'limited'
						}
					}
				})
			}

			if (this.profileImage !== undefined) {
				result.push({
					id: 'profile',
					kind: 'image',
					image: this.profileImage,
					text: this.profileText,
					callback: this.profileCallback
				})
			}

			//
			// For consistency with other admin interfaces,
			//  the order of these icons should not be changed.
			//

			if (this.appConfig('DOCS_URL') !== undefined) {
				result.push({
					id: 'docs',
					href: this.appConfig('DOCS_URL'),
					arialabel: 'Documentation',
					icon: 'local_library',
					external: true,
				})
			}
			if (this.appConfig('BUGS_URL') !== undefined) {
				result.push({
					id: 'bugs',
					href: this.appConfig('BUGS_URL'),
					arialabel: 'Report Bug',
					icon: 'bug_report',
					external: true,
				})
			}

			//
			// For consistency with other admin interfaces,
			//  the following three icons should always be present.
			//
			if (this.appConfig('WEBSITE_URL') !== undefined) {
				result.push({
					id: 'website',
					href: this.appConfig('WEBSITE_URL'),
					arialabel: 'Website',
					icon: 'business',
					external: true,
				})
			}
			if (this.appConfig('ADMIN_URL') !== undefined) {
				result.push({
					id: 'admin',
					href: this.appConfig('ADMIN_URL'),
					arialabel: 'Admin',
					icon: 'apps',
					external: true,
				})
			}
			if (this.appConfig('LOGOUT_URL') !== undefined) {
				result.push({
					id: 'logout',
					arialabel: 'Logout',
					icon: 'lock',
					callback: () => {
						this.showLogoutDialog()
					},
				})
			}

			return result
		},
		drawerItems () {
			let result = []

			if (!this.authenticated) {
				return result
			}

			if (!this.appConfigLoaded) {
				return result
			}

			const urlPrefix = this.appConfig('BASE_URL')

			//
			// Public API
			//
			if (this.appConfig('PORTAL2_ADMIN_URL') !== undefined) {
				result.push({
					id: 'public',
					component: 'DrawerExpandable',
					kind: 'firstSection',
					title: 'Accounts',
					hidden: false,
					open: this.showAccountsMenu,
					items: [
						{
							id: 'accounts',
							component: 'DrawerLink',
							title: 'Portal 2 Admin',
							icon: 'exit_to_app',
							callback: () => {
								window.open(this.appConfig('PORTAL2_ADMIN_URL'))
							}
						},

					]
				})
			}

			//
			// Provisioning API - Configuration
			//

			//
			// Provisioning API - Infrastructure
			//
			result.push({
				id: 'configuration',
				component: 'DrawerExpandable',
				kind: 'section',
				title: 'Configuration',
				hidden: false,
				open: this.showConfigurationMenu,
				items: [
					{
						id: 'services',
						component: 'DrawerLink',
						title: 'Services',
						icon: 'account_tree',
						// callback: (data) => this.$router.push({path: urlPrefix + data[1].id}),
					},
					{
						id: 'config-hostname-search',
						component: 'DrawerLink',
						title: 'Hostnames',
						icon: 'dns',
						// callback: (data) => this.$router.push({path: urlPrefix + 'config/' + data[1].id}),
					},
					{ // Certificates sub-menu
						id: 'certificates',
						component: 'DrawerExpandable',
						title: 'Certificates',
						icon: 'card_membership',
						open: this.showCertificatesMenu,
						items: [
							{
								id: 'certificates-search',
								title: 'Search',
								icon: 'search',
							},
							{
								id: 'certificates-expires',
								title: 'Expiry List',
								icon: 'event_busy',
							},
							{
								id: 'certificates-upload',
								title: 'Upload',
								icon: 'publish',
							},
							{
								id: 'certificates-issue',
								title: 'Issue',
								icon: 'note_add',
							},

						]
					},
					{ // Features sub-menu
						id: 'features',
						component: 'DrawerExpandable',
						title: 'Features',
						icon: 'dataset',
						hidden: !this.showConfigurationMenu,
						open: this.showFeaturesMenu,
						items: [
							{
								id: 'options',
								title: 'Dynamic Options',
								icon: 'settings_applications',
							},
							{
								id: 'scripts',
								title: 'Script Configs',
								icon: 'code',
							},
							{
								id: 'tlsprofiles',
								title: 'TLS Profiles',
								icon: 'https',
							},
							{
								id: 'plugins',
								title: 'Rules Plugins',
								icon: 'extension',
							},

						]
					},
					{ // Settings sub-menu
						id: 'settings',
						component: 'DrawerExpandable',
						title: 'Settings',
						icon: 'settings',
						hidden: !this.showConfigurationMenu,
						open: this.showSettingsMenu,
						items: [
							{
								id: 'settings-groups',
								title: 'Groups',
								icon: 'group_work',
							},
							{
								id: 'settings-dns',
								title: 'DNS',
								icon: 'dns',
							},
							{
								id: 'settings-certificates',
								title: 'Certificates',
								icon: 'card_membership',
							},
							{
								id: 'settings-variables',
								title: 'Variables',
								icon: 'list_alt',
							},
							{
								id: 'settings-templates',
								title: 'Templates',
								icon: 'content_copy',
							},
							{
								id: 'settings-custom-configs',
								title: 'Custom Configs',
								icon: 'file_copy',
							},
							{
								id: 'settings-nginx',
								title: 'Nginx',
								icon: 'web',
							},

						]
					}
				]
			})

			//
			// Provisioning API - Infrastructure
			//
			result.push({
				id: 'infrastructure',
				component: 'DrawerExpandable',
				kind: 'section',
				title: 'Infrastructure',
				hidden: false,
				open: this.showInfrastructureMenu,
				items: [
					{
						id: 'pops',
						component: 'DrawerLink',
						title: 'Points Of Presence',
						icon: 'public', // 'share_location',
						callback: (data) => this.$router.push({path: urlPrefix + data[1].id}),
					},
					{
						id: 'servers',
						component: 'DrawerLink',
						title: 'Servers',
						icon: 'router', // 'developer_board',
						callback: (data) => this.$router.push({path: urlPrefix + data[1].id}),
					},
					{
						id: 'modes',
						component: 'DrawerExpandable',
						title: 'Modes',
						icon: 'app_settings_alt',
						open: this.showConfigModesMenu,
						callback: () => this.$router.push({path: urlPrefix + 'config/modes'}),
						items: [
							{
								id: 'cacheflySoftwareLabel',
								component: 'DrawerLabel',
								title: 'CacheFly Software',
							},
							{
								id: 'agents',
								title: 'Agents',
								icon: 'device_hub',
							},
							{
								id: 'scripting',
								title: 'Scripting',
								icon: 'developer_mode',
							},
							{
								id: 'warming',
								title: 'Warming',
								icon: 'local_fire_department',
							},
							// {
							// 	id: 'modesSeparator',
							// 	component: 'DrawerSeparator',
							// },
							{
								id: 'thirdPartySoftwareLabel',
								component: 'DrawerLabel',
								title: 'Third Party Software',
							},
							{
								id: 'haproxy',
								title: 'HAProxy',
								icon: 'web_asset',
							},
							{
								id: 'nginx',
								title: 'Nginx',
								icon: 'web',
							},
							{
								id: 'varnish',
								title: 'Varnish',
								icon: 'cruelty_free',
							},
							{
								id: 'logstash',
								title: 'Logstash',
								icon: 'receipt_long',
							},
							{
								id: 'vector',
								title: 'Vector',
								icon: 'receipt',
							},

						]
					},
				]
			})

			//
			// Provisioning API - System
			//
			result.push({
				id: 'system',
				component: 'DrawerExpandable',
				kind: 'section',
				title: 'System',
				hidden: false,
				open: this.showSystemMenu,
				items: [
					{
						id: 'system-metrics',
						title: 'Metrics',
						icon: 'multiline_chart',
					},
					{
						id: 'tools-admin',
						title: 'Admin Tools',
						icon: 'construction',
					},
					{
						id: 'agents-update',
						title: 'Agents Update',
						icon: 'system_update',
						hidden: true,
					},

				]
			})

			//
			// Development Tools
			//
			result.push({
				id: 'devMenu',
				component: 'DrawerExpandable',
				kind: 'section',
				title: 'Dev Tools',
				hidden: this.isProduction,
				open: this.showDevToolsMenu,
				items: [
					{
						id: 'loremipsum',
						title: 'Lorem Ipsum',
						icon: 'spellcheck',
					},
					{
						id: 'python',
						title: 'Python',
						icon: 'code',
					},
				]
			})

			//
			// Information
			//
			result.push(...[
				{
					id: 'separatorInformation',
					component: 'DrawerSeparator',
					footer: true,
				},
				{
					id: 'labelUser',
					component: 'DrawerLabel',
					title: this.ssoCredential['fullName'],
					footer: true,
					callback: this.profileCallback,
				},
				{
					id: 'labelEnvironment',
					component: 'DrawerLabel',
					title: this.appConfig('APP_ENV_NAME') + ' Environment',
					footer: true,
					callback: this.profileCallback,
				}
			])

			return result
		},

		// Returns a promise which resolves depending on the values of the
		// appConfigLoaded and appConfigError getters from vuex.
		configLoaded () {
			if (this.appConfigError) {
				return Promise.reject(this.appConfigError)
			}
			if (this.appConfigLoaded) {
				return Promise.resolve()
			}
			if (this.configLoader === undefined) {
				this.configLoader = new Promise((resolve, reject) => {
					this.loading = true
					this.loadingStage = 'Retrieving Configuration ...'
					this.configLoaderResolve = resolve
					this.configLoaderReject = reject
				})
			}
			return this.configLoader
		},

		profileImage () {
			if (this.gravatarProfile !== undefined) {
				return this.gravatarProfile.thumbnailUrl
			}
			return undefined
		},
		profileText () {
			if (this.hasValue(this.ssoCredential)) {
				return this.ssoCredential.fullName
			}
			if (this.gravatarProfile !== undefined) {
				if (this.gravatarProfile.name !== undefined) {
					if (this.gravatarProfile.name.formatted !== undefined) {
						return this.gravatarProfile.name.formatted
					}
				}
				if (this.gravatarProfile.displayName !== undefined) {
					return this.gravatarProfile.displayName
				}
				if (this.gravatarProfile.preferredUsername !== undefined) {
					return this.gravatarProfile.preferredUsername
				}
			}
			return ''
		},

	},
	watch: {
		autoswitchStatus (value) {
			if (!this.autoswitchStatus) {
				document.title = '!!! ' + this.title
			} else {
				document.title = this.title
			}
		},
		title (value) {
			if (!this.autoswitchStatus) {
				document.title = '!!! ' + this.title
			} else {
				document.title = this.title
			}
		},

		appPreferences (preferences) {
			if (preferences.datadogDisabled !== undefined) {
				this.$set(this, 'datadogDisabled', Boolean(preferences.datadogDisabled))
			}
			if (preferences.drawerOpen !== undefined) {
				this.$set(this, 'drawerOpen', Boolean(preferences.drawerOpen))
			}
			if (preferences.contentWidthMode !== undefined) {
				this.$set(this, 'contentWidthMode', String(preferences.contentWidthMode))
			}
			if (preferences.colorScheme !== undefined) {
				this.$set(this, 'preferredColorScheme', String(preferences.colorScheme))
			}
			if (preferences.environment !== undefined) {
				this.$set(this, 'preferredEnvironment', String(preferences.environment))
			}
		},
		datadogDisabled (value) {
			this.saveAppPreference('datadogDisabled', value)
			if (value === true) {
				if (this.datadogInitialized === true) {
					datadogRum.stopSessionReplayRecording()
					datadogRum.stopSession()
					datadogRum.clearUser()
					datadogRum.clearGlobalContext()
				}
			}
		},
		drawerOpen (value) {
			this.saveAppPreference('drawerOpen', value)
		},
		contentWidthMode (value) {
			this.saveAppPreference('contentWidthMode', value)
		},
		preferredColorScheme (value) {
			if (value === 'auto') {
				this.$set(this, 'colorScheme', this.systemColorScheme)
			} else {
				this.$set(this, 'colorScheme', value)
			}
			this.saveAppPreference('colorScheme', value)
		},
		systemColorScheme (value) {
			if (this.preferredColorScheme !== 'auto') {
				return
			}
			this.$set(this, 'colorScheme', value)
		},

		preferredEnvironment (value) {
			if (value === undefined) {
				return
			}
			if (value === this.appConfigEnvironment) {
				return
			}
			this.switchEnvironment(value)
		},

		'$route.meta.showConfigModesMenu': function (value) {
			this.$set(this, 'showConfigModesMenu', value)
		},
		'$route.meta.selectedId': function (value) {
			this.$set(this, 'selectedId', value)
			this.$set(this, 'showConfigModesMenu', this.$route.meta.showConfigModesMenu)
		},
		snackmsg: function (value) {
			console.debug('snackmsg: ' + value)
			this.$set(this, 'snackopen', true)
		},

		websocketFailure (value) {
			if (value !== undefined && value !== null) {
				this.wsFailedTime = new Date().toLocaleTimeString()
				if (this.wsReconnectRef === undefined) {
					this.wsReconnectRef = setInterval(() => {
						if (this.websocketConnected || this.websocketFailure === undefined) {
							this.$set(this, 'toastNotice', {
								id: 'websocketFailure',
							})
							clearInterval(this.wsReconnectRef)
							this.wsReconnectRef = undefined
							return

						} else if (!this.networkOnline || !this.userPresence.active) {
							this.$set(this, 'toastNotice', {
								id: 'websocketFailure',
								message: [
									'Websocket Disconnected',
									this.websocketFailure + ' @' + this.wsFailedTime,
								],
							})
							return

						} else {
							let count = '(' + this.wsReconnectCount + ') '
							if (this.wsReconnectCount <= 0) {
								count = '...'
							}
							this.$set(this, 'toastNotice', {
								id: 'websocketFailure',
								message: [
									'Websocket Disconnected',
									this.websocketFailure + ' @' + this.wsFailedTime,
									'Reconnecting ' + count,
								],
							})
						}
						if (this.wsReconnectCount <= 0) {
							this.setupSyncConnection()
							this.wsReconnectCount = websocketReconnectInterval
							return
						}
						this.wsReconnectCount--
					}, oneSecond)
				}
			}
		},

		networkOnline () {
			if (!this.networkOnline) {
				this.$set(this, 'toastNotice', {
					id: 'networkOffline',
					message: [
						'You are offline',
						'Please check your network connection',
					],
				})
			} else {
				this.$set(this, 'toastNotice', {
					id: 'networkOffline',
				})
			}
		},

		appConfigError (value) {
			this.$set(this, 'snackmsg', value)
			if (this.configLoaderReject !== undefined) {
				this.configLoaderReject(value)
				this.configLoaderResolve = undefined
				this.configLoaderReject = undefined
			}
		},
		appConfigLoaded (value) {
			if (!value) {
				console.debug('[DEBUG] App config unloaded')
				return
			}
			console.debug('[DEBUG] App config loaded - environment:', this.appConfigEnvironment)
			if (this.configLoaderResolve !== undefined) {
				this.configLoaderResolve()
				this.configLoaderResolve = undefined
				this.configLoaderReject = undefined
			}
			this.displayTestingModeDialog()
			this.startDatadog()
			this.checkAutoSwitch()
			this.callCacheFlySingleSignOn().finally(() => {
				this.loading = false
				this.loadingStage = undefined
			})
		},
		ssoCredential (value) {
			if (value !== null && value !== undefined) {
				let message = ''
				message += 'Welcome ' + value['fullName'] + ' <' + value['email'] + '>' + '\n'
				message += 'Authenticated with ' + this.normaliseEnvironmentCode(value['env']) + ' environment at ' + value['url'] + ' via ' + value['dataSource'] + '\n'
				message += 'Authentication expires at ' + value['expiresAt'] + '\n'
				console.log(message)
				if (value.dataSource === 'API') {
					this.snackmsg = 'Authenticated with ' + this.appConfig('APP_ENV_NAME') + ' environment'
				}
				this.authenticated = true
				if (this.datadogInitialized === true && this.hasValue(this.ssoCredential)) {
					datadogRum.setUser({
						id: this.ssoCredential.userId,
						username: this.ssoCredential.sso,
						name: this.ssoCredential.fullName,
						email: this.ssoCredential.email,
						ssoEnv: this.ssoCredential.env,
					})
					console.debug('Datadog RUM user updated')
				}
			}
		},
		authenticated () {
			if (this.authenticated) {
				this.$nextTick(() => {
					this.loadAppPreferences()
					this.checkAutoSwitch()
					this.setupSyncConnection()
					this.loadGravatarProfile()
				})
				this.$nextTick(() => {
					if (this.confirmTitle === notAuthenticatedTitle && this.confirmDialogOpen === true) {
						this.resetConfirmDialog()
					}
					this.authLoginOpen = false
					this.displayTestingModeDialog()
				})
			}
		},
		showProfileDialog (value) {
			if (!value && !this.authenticated) {
				this.$nextTick(this.profileCallback)
			}
		},

	},
	created () {
		const queryParams = new URLSearchParams(window.location.search)
		if (queryParams.has('qReceiveAuthRedirect')) {
			this.authRedirectCall = true
			this.$router.replace({query: {}})
			console.log('[INFO] Loaded as an authentication successful redirect')
			// We now expect this window (iframe) to be closed after a
			// couple of sections.
			// If it isn't closed within a few seconds then we want
			// to break out of the iframe and reload the main window.
			const oneSecond = 1000
			const expectToBeClosedTimeout = 10 * oneSecond
			setTimeout(() => {
				if (window.top.location !== window.location) {
					window.top.location = window.location.href
				} else {
					window.location.reload()
				}
			}, expectToBeClosedTimeout)
			return
		}

		document.title = this.title
		this.loadingStage = 'Initialising State ...'

		this.deleteObsoleteStoredValues()

		this.loadAppPreferences()
		let preferredEnvironment = this.appPreferences['env']
		if (this.hasValue(preferredEnvironment)) {
			console.debug('Preferred Environment:', preferredEnvironment)
			this.appConfigSwitchEnvironment({
				environment: preferredEnvironment,
			})
		}

		this.pollAutoSwitch()
	},
	mounted () {
		if (this.authRedirectCall) {
			return
		}

		this.monitorNetworkStatus()
		this.monitorSystemColorScheme()
		this.initialiseMaterialDesignComponents()
		this.$set(this, 'showCertificatesMenu', this.$route.meta.showCertificatesMenu === true)
		this.$set(this, 'showFeaturesMenu', this.$route.meta.showFeaturesMenu === true)
		this.$set(this, 'showSettingsMenu', this.$route.meta.showSettingsMenu === true)
		this.$set(this, 'showConfigModesMenu', this.$route.meta.showConfigModesMenu === true)
		this.watchLocalStorageForCredentials()
		this.callCacheFlySingleSignOn().then(() => {
			this.$nextTick(this.monitorUserPresenceStatus)
		}).catch(error => {
			console.error(error)
		}).finally(() => {
			this.loading = false
			this.loadingStage = undefined
		})
	},
	updated () {
		autoInit()  // Material Design Components
		this.$set(this, 'showCertificatesMenu', this.$route.meta.showCertificatesMenu === true)
		this.$set(this, 'showFeaturesMenu', this.$route.meta.showFeaturesMenu === true)
		this.$set(this, 'showSettingsMenu', this.$route.meta.showSettingsMenu === true)
		this.$set(this, 'showConfigModesMenu', this.$route.meta.showConfigModesMenu === true)
	},
	beforeDestroy () {
		this.unsubscribeMethods.forEach(
			item => item()
		)
	},
	methods: {
		...mapActions([
			'appConfigSwitchEnvironment',
			'appConfigReload',
			'syncConnect',
			'syncDisconnect',
		]),
		...mapActions('cache', [
			'setEncryptionKey',
			'clearEncryptionKey',
		]),

		displayTestingModeDialog () {
			if (this.appConfig('APP_TESTING_MODE') === 'true') {
				console.debug('[DEBUG] App testing mode is enabled')
				this.viewingTestingModeWarning = true

				this.confirmTitle = 'Testing Mode'
				this.confirmQuestion = 'This system is currently in testing mode. Some functionality made not work. \n' +
					'If you see this message on production it means that an update is currently in progress. \n' +
					'Contact Brian Wojtczak if you have an ongoing operational matter which requires the use of this tool to resolve. \n' +
					'Alternatively, please wait a while and check back again later. '
				this.confirmShowCancel = false
				this.confirmAction = undefined
				this.confirmCancelAction = undefined
				this.confirmCloseAction = () => {
					this.viewingTestingModeWarning = false
				}
				this.confirmDialogOpen = true
			}
		},

		initialiseMaterialDesignComponents () {
			try {
				autoInit()  // Material Design Components
			} catch (error) {
				console.error(error)
			}
		},

		deleteObsoleteStoredValues () {
			this.clearStoredValues('provisioning-webui-environment') // TODO remove this
			this.clearStoredValues('drawer-open') // TODO remove this
		},

		switchEnvironment (env) {
			if (env === this.appConfigEnvironment) {
				// console.debug('switchEnvironment:', env, 'current environment')
				return // nothing to do
			}
			// console.debug('switchEnvironment:', env)
			if (this.preferredEnvironment !== env) {
				this.saveAppPreference('environment', env)
			}

			this.loading = true
			this.loadingStage = 'Switching environment ...'

			this.authenticated = false
			this.syncDisconnect()

			if (this.datadogInitialized === true) {
				datadogRum.addAction({
					name: 'switchEnvironment',
					context: {
						from: this.appConfigEnvironment,
						to: env,
					}
				})

				datadogRum.stopSessionReplayRecording()
				datadogRum.stopSession()
				datadogRum.clearUser()
				datadogRum.clearGlobalContext()
			}

			this.$nextTick(() => {
				this.clearAllTransientStorage()
				this.$store.commit('SSO_CREDENTIAL', undefined)

				this.$nextTick(() => {
					this.appConfigSwitchEnvironment({
						environment: env
					})
				})
			})

		},

		loadAppPreferences () {
			const defaultAppPreferences = {}
			this.appPreferences = this.loadStoredValue(appPreferencesStorageKey, defaultAppPreferences)
		},
		saveAppPreference (key, value) {
			// console.debug('saveAppPreference', key, '=', value)
			if (this.appPreferences === undefined) {
				this.loadAppPreferences()
			}
			if (this.appPreferences[key] === value) {
				return
			}
			this.appPreferences[key] = value
			this.saveAppPreferences()
		},
		saveAppPreferences () {
			if (this.appPreferences === undefined) {
				return
			}
			this.storePersistent(appPreferencesStorageKey, this.appPreferences)
		},

		monitorSystemColorScheme () {
			if (!this.monitoringSystemColorScheme) {
				window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
					this.systemColorScheme = event.matches ? 'dark' : 'light'
				})
			}
			if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				this.systemColorScheme = 'dark'
			} else {
				this.systemColorScheme = 'light'
			}
		},

		toggleNav () {
			this.drawerOpen = !this.drawerOpen
		},

		startDatadog () {
			if (!this.isProduction) {
				return
			}
			if (!this.appConfigLoaded) {
				return
			}
			if (this.datadogDisabled === true) {
				return
			}

			let datadogEnv = 'development'
			if (location.host.startsWith('devbriantest.cachefly.com')) {
				datadogEnv = 'staging'

			} else if (location.host.startsWith('provisioning-webui.tools-internal.cachefly.com')) {
				datadogEnv = 'production'

			} else {
				return // no datadog on unexpected hostnames please
			}
			console.debug('Starting Datadog RUM - environment:', datadogEnv)

			datadogRum.init({
				applicationId: '4bdef53b-392e-4bb1-b81b-47706cb5ccb7',
				clientToken: 'pube763b7ec00d085b79ece822c136193a7',
				site: 'datadoghq.com',
				service: 'cachefly-admin-provisioning-webui',
				env: datadogEnv,
				version: window.appVersion,
				sessionSampleRate: 100,
				sessionReplaySampleRate: 0,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
				defaultPrivacyLevel: 'allow'
			})
			datadogRum.startSessionReplayRecording()
			this.datadogInitialized = true
			if (this.hasValue(this.ssoCredential)) {
				datadogRum.setUser({
					id: this.ssoCredential.userId,
					username: this.ssoCredential.sso,
					name: this.ssoCredential.fullName,
					email: this.ssoCredential.email,
					ssoEnv: this.ssoCredential.env,
				})
				console.debug('Datadog RUM user updated')
			}
		},

		// ----------------------------------------------------------------- //
		//
		// Methods for: Authentication and User Profile
		//
		redirectToLogin () {
			let loginUrl = this.getLoginUrl(false)
			if (loginUrl !== undefined) {
				console.log('Redirecting to', loginUrl)
				window.location = loginUrl
			}
		},
		showLoginDialog (attempt = 1) {
			if (attempt >= 10) {
				this.redirectToLogin()
				return
			}
			if (this.authenticated) {
				this.showNotAuthenticated()
				setTimeout(() => this.showLoginDialog(attempt + 1), 10) // start again
				return
			}
			this.authLoginOpen = false
			setTimeout(() => {
				this.authLoginOpen = true
				setTimeout(() => {
					let frame = window.document.querySelector('#auth-frame')
					if (frame === null) {
						setTimeout(() => this.showLoginDialog(attempt + 1), 250) // start again
						return
					}
					frame.src = this.getLoginUrl(true)
				}, 100 + (100 * attempt))
			}, 50 * attempt)
		},
		showLogoutDialog () {
			if (this.authenticated) {
				this.showNotAuthenticated()
				this.$nextTick(this.showLogoutDialog)
				return
			}
			this.authLoginOpen = false
			let frame = this.$el.querySelector('iframe')
			if (frame === null) {
				setTimeout(this.showLogoutDialog, 250)
				return
			}
			frame.src = this.getLogoutUrl(true)
			this.$nextTick(() => {
				this.authLoginOpen = true
			})
		},
		getLoginUrl (tagRedirect) {
			return this.getUrlWithBackParameter(this.appConfig('LOGIN_URL'), tagRedirect)
		},
		getLogoutUrl (tagRedirect) {
			return this.getUrlWithBackParameter(this.appConfig('LOGOUT_URL'), tagRedirect)
		},
		getUrlWithBackParameter (targetUrl, tagRedirect) {
			if (targetUrl !== undefined) {
				let ownUrl = String(window.location)
				if (tagRedirect === true) {
					ownUrl = this.addQueryParameter(ownUrl, 'qReceiveAuthRedirect', 'true')
				}
				targetUrl = this.addQueryParameter(targetUrl, 'back', ownUrl)
			}
			return targetUrl
		},
		addQueryParameter (targetUrl, key, value) {
			if (targetUrl.includes('?')) {
				targetUrl += '&'
			} else {
				targetUrl += '?'
			}
			targetUrl += key + '=' + encodeURIComponent(value)
			return targetUrl
		},
		watchLocalStorageForCredentials () {
			this.watchStoredValue('sso-credential', (key, newValue, firstTime) => {
				if (key !== 'sso-credential') {
					console.error('sso credential storage callback called with incorrect key', key)
					return
				}
				if (firstTime === true) {
					return
				}
				console.log('cached sso credential changed')

				if (!this.hasValue(newValue)) {
					this.showNotAuthenticated()
					return
				}

				this.callCacheFlySingleSignOn(false)
			})
		},
		callCacheFlySingleSignOn (ignoreCache) {
			console.debug('callCacheFlySingleSignOn', 'ignoreCached=', ignoreCache)
			///////////////////////////////////////////////////////////////////
			//
			// For testing and development purposes I run this code on
			//     https://devbriantest.cachefly.com/
			// which is mapped on my (Brian) local tailscale IP address and
			// has a copy of the cachefly wildcard certificate installed.
			//
			// This allows me to avoid CORS issues and still be able to make
			//  rapid changes with local hot module reload :D
			//
			///////////////////////////////////////////////////////////////////

			// If we have a stored a promise, then return that because this
			// indicates that we're still waiting for loading to complete.
			if (this.credentialLoader !== undefined) {
				return this.credentialLoader
			}

			// Create a promise which resolves when the credentials are loaded.
			this.credentialLoader = new Promise((resolve, reject) => {

				// Internal callback for success
				const success = (newCredential) => {

					// Configure the cache encryption key (cek)
					if (newCredential['cek'] !== undefined) {
						// console.debug('Received cache encryption key from ' + newCredential['dataSource'])
						this.setEncryptionKey(newCredential['cek'])
						delete newCredential['cek'] // but don't keep it in vuex
					} else {
						console.warn('No cache encryption key in SSO credential')
					}

					// Store the credential for use elsewhere in the app
					this.$store.commit('SSO_CREDENTIAL', newCredential)

					// Setup process to refresh the credential before it expires.
					if (this.authenticationChecker !== undefined) {
						clearInterval(this.authenticationChecker)
					}
					this.authenticationChecker = setInterval(
						this.checkAuthentication,
						credentialRenewalThreshold / 2,
					)

					// Are you waiting for me? I'm done now!
					this.$nextTick(resolve) // read the value from the getter
				}

				// Internal callback for failure
				const failed = (error, details) => {
					if (details !== undefined) {
						console.error('CFSSO Error:', error, details)
					} else {
						console.error('CFSSO Error:', error)
					}
					this.$set(this, 'snackmsg', 'Single Sign On Failed')
					this.$nextTick(this.showNotAuthenticated)
					this.$nextTick(reject)
				}

				this.configLoaded.then(() => {

					// I use a credential cache here to speed up initial page on
					// when you do a refresh while holding a valid credential.
					const storageKey = 'sso-credential'
					if (ignoreCache !== true) {
						let loadedCredential = this.loadEncrypted(storageKey)
						if (loadedCredential !== undefined) {
							if (this.checkCredential(loadedCredential)) {

								// Trigger the check early so that we make sure that
								// the API is still called.
								setTimeout(this.checkAuthentication, 1000)

								// Use the cached credential in the meantime.
								success(loadedCredential)
								return Promise.resolve()
							}
						}
					}

					this.loadingStage = 'Authenticating ...'
					return axios.get(
						this.appConfig('SSO_API_URL'),
						{
							withCredentials: true,
						},
					).then((response) => {
						if (response.data !== undefined && typeof (response.data) === 'object') {

							let receivedCredential = response.data
							if (this.checkCredential(receivedCredential)) {

								// Store in cache for use on reload
								receivedCredential.dataSource = 'CACHE'
								this.storeEncrypted(storageKey, receivedCredential)

								// Consume for use in current session
								receivedCredential.dataSource = 'API'
								success(receivedCredential)

							} else {
								failed('API returned invalid credential')
							}

						} else {
							failed('API returned invalid response', response)
						}

					}).catch((error) => {
						failed(error)

					})

				}).finally(() => {
					this.credentialLoader = undefined
				})
			})
			return this.credentialLoader
		},
		normaliseEnvironmentCode (environment) {
			environment = String(environment).trim().toLowerCase()
			switch (environment) {
			case 'prod':
				return 'production'
			case 'prod-direct':
				return 'production'
			case 'stage':
				return 'staging'
			case 'dev':
				return 'development'
			case '':
				return 'development'
			}
			return environment
		},
		reauthenticate () {
			return this.callCacheFlySingleSignOn(true)
		},
		checkAuthentication () {
			let tokenRequired = false
			if (!this.appConfigLoaded) {
				tokenRequired = true

			} else if (this.ssoCredential === undefined) {
				tokenRequired = true

			} else if (!this.checkCredential(this.ssoCredential, true)) {
				tokenRequired = true

			}
			if (tokenRequired) {
				this.callCacheFlySingleSignOn(true)
			}
		},
		checkCredential (credential, verbose) {
			if (credential !== undefined) {
				let expectedEnvironment = this.normaliseEnvironmentCode(this.appConfigEnvironment)
				let actualEnvironment = this.normaliseEnvironmentCode(credential['env'])
				if (expectedEnvironment === actualEnvironment) {
					const now = moment.default().utc()
					const expires = moment.default(credential['expiresAt']).utc()
					const timeRemaining = expires.diff(now, 'seconds')
					if (timeRemaining > 0) {
						if (verbose === true) {
							console.log(
								'CFSSO credential expires in',
								moment.duration(timeRemaining, 'seconds').humanize()
							)
						}
						if ((timeRemaining * oneSecond) > credentialRenewalThreshold) {
							return true // credential has sufficient time remaining
						}
					}
				} else {
					console.error('[ERROR] checkCredential: environment mismatch:',
						'expected=' + expectedEnvironment,
						'actual=' + actualEnvironment
					)
				}
			}
			return false // invalid credential
		},
		showNotAuthenticated () {
			this.syncDisconnect() // stop receiving updates from the server
			this.clearAllTransientStorage() // clear all transient values
			this.clearStoredValues('gravatar-profile')
			this.$store.commit('SSO_CREDENTIAL', undefined) // clear the stored credentials
			this.showProfileDialog = false
			this.authenticated = false
			this.loading = false
			this.$nextTick(() => {
				this.confirmTitle = notAuthenticatedTitle
				this.confirmShowCancel = true
				if (this.appConfig('LOGIN_URL') === undefined) {
					this.confirmQuestion = 'Please login, then click OK to try again'
				} else {
					this.confirmQuestion = 'Not currently authenticated with ' + this.appConfigEnvironment + ' environment.\nLogin now?'
					this.confirmAction = () => {
						this.callCacheFlySingleSignOn(false).finally(() => {
							if (!this.authenticated) {
								this.showLoginDialog()
							}
						})
					}
				}
				this.confirmCancelAction = () => {
					this.profileCallback()
				}
				this.confirmCloseAction = () => {
					if (this.showProfileDialog === false) {
						this.$nextTick(this.showNotAuthenticated)
					}
				}
				this.confirmDialogOpen = true
				if (this.authenticationChecker !== undefined) {
					clearInterval(this.authenticationChecker)
				}
			})
		},
		loadGravatarProfile () {
			if (this.gravatarProfile !== undefined) {
				// Nothing to do
				return Promise.resolve()
			}
			const storageKey = 'gravatar-profile'
			let loadedProfile = this.loadStoredValue(storageKey)
			if (loadedProfile !== undefined) {
				if (loadedProfile.request.email === this.ssoCredential.email) {
					this.gravatarProfile = loadedProfile
					return Promise.resolve()
				}
			}
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					axios.get(
						gravatar.profile_url(
							this.ssoCredential.email,
							{protocol: 'https'},
						),
						{withCredentials: false},
					).then((response) => {
						if (response.data !== undefined) {
							if (typeof (response.data) === 'object') {
								if (response.data.entry !== undefined) {
									if (response.data.entry.length >= 1) {
										this.gravatarProfile = response.data.entry[0]
										this.gravatarProfile.request = {
											timestamp: ~~(+new Date() / 1000), // https://stackoverflow.com/a/29295210
											email: this.ssoCredential.email
										}
										this.storeTransient(storageKey, this.gravatarProfile)
										resolve()
										return
									}
								}
							}
						}
						console.error('unrecognised response from gravatar', response.data)
						reject()
					}).catch(function (error) {
						console.error('gravatar error', error)
						reject()
					})
				}, 10)
			})
		},
		profileCallback () {
			this.loadProfileDialog = true
			this.showProfileDialog = true
		},

		// ----------------------------------------------------------------- //
		//
		// Methods for: Websocket
		//

		setupSyncConnection () {
			if (this.websocketConnected === true) {
				// Already connected
				return
			}
			if (this.ssoCredential === undefined) {
				return
			}
			this.syncConnect({
				url: this.appConfig('API_BASE_URL'),
				prefix: '/',
				identity: {
					username: this.ssoCredential.sso,
					fullName: this.ssoCredential.fullName,
					email: this.ssoCredential.email,
					env: this.ssoCredential.env,
					userId: this.ssoCredential.userId,
				},
				timeout: websocketConnectionTimeout,
			}).then(() => {
				// This there anything we need to do here?

			}).catch((error) => {
				console.error('syncConnect error', error)
			})
		},

		// ----------------------------------------------------------------- //
		//
		// Methods for: User Presence and Idle Detection
		//
		setUserPresence (hidden, idle, locked) {
			if (hidden === undefined) {
				hidden = document.hidden
			}
			this.userPresence.hidden = hidden
			if (idle !== undefined) {
				this.userPresence.idle = idle
			}
			if (locked !== undefined) {
				this.userPresence.locked = locked
			}
			if (this.userPresence.idle || this.userPresence.locked) {
				this.userPresence.active = false
				this.userPresence.idle = true
				this.userPresence.status = 'idle'
			} else {
				if (this.userPresence.hidden) {
					this.userPresence.active = false
					this.userPresence.status = 'hidden'
				} else {
					this.userPresence.active = true
					this.userPresence.status = 'active'
				}
			}
			if (!this.userPresence.active) {
				this.$set(this, 'toastNotice', {
					id: 'userPresence',
					message: [
						'Inactive user; using fewer resources.'
					],
				})
			} else {
				this.$set(this, 'toastNotice', {
					id: 'userPresence',
				})
			}
			console.log(
				'User Presence:',
				'active:', this.userPresence.active,
				'hidden:', this.userPresence.hidden,
				'idle:', this.userPresence.idle,
				'locked:', this.userPresence.locked
			)
		},
		monitorUserPresenceStatus () {
			if (!this.listeningToVisibility) {
				this.listeningToVisibility = true
				document.addEventListener('visibilitychange', () => {
					this.setUserPresence(document.hidden)

					if (this.userPresence.idle) {
						// How did visibility change when they are idle???
						const appVisibility = document.hidden ? 'hidden' : 'visible'
						console.debug('visibility changed to', appVisibility, 'while user is idle')
					}
				})
			}
			if (this.hasIdleDetection === undefined) {
				this.requestPermissions(false)
				return
			}
			if (this.hasIdleDetection === true) {
				try {
					// const controller = new AbortController()
					// const signal = controller.signal
					// eslint-disable-next-line no-undef
					const idleDetector = new IdleDetector()
					idleDetector.addEventListener('change', () => {
						this.setUserPresence(
							document.hidden,
							idleDetector.userState === 'idle',
							idleDetector.screenState === 'locked'
						)

						// const userState = idleDetector.userState
						// const screenState = idleDetector.screenState
						// const appVisibility = document.hidden ? 'hidden' : 'visible'
						// console.debug(`User Presence: ${userState}, ${screenState}, ${appVisibility}`)
					})
					idleDetector.start({
						threshold: 15 * 60000, // 15 minutes
						// signal,
					})
				} catch (error) {
					console.error('Idle Detector API:', error)
				}
			}
		},
		requestPermissions (clicked) {
			if (!('IdleDetector' in window)) {
				console.log('NOTICE: Idle Detector API is not available')
				this.hasIdleDetection = false
				return
			}
			if (!clicked) {
				navigator.permissions.query(
					{name: 'idle-detection'}
				).then(permission => {
					// console.debug(permission)
					this.hasIdleDetection = permission.state === 'granted'
					if (!this.hasIdleDetection) {
						if (permission.state === 'denied') {
							// The user has actively chosen to block/deny us this
							// permission, so don't ask again. User Presence
							// detection will be limited.
							console.log('NOTICE: Idle Detector API has been blocked')
							return
						}
						this.confirmTitle = 'Permissions Required'
						this.confirmQuestion = 'Please click OK to continue'
						this.confirmShowCancel = false
						this.confirmAction = undefined
						this.confirmCancelAction = undefined
						this.confirmCloseAction = () => {
							this.requestPermissions(true)
						}
						this.confirmDialogOpen = true
					}
					if (this.hasIdleDetection) {
						this.monitorUserPresenceStatus()
					}
				})
			} else {
				window.IdleDetector.requestPermission().then((state) => {
					this.hasIdleDetection = state === 'granted'
					if (this.hasIdleDetection) {
						this.monitorUserPresenceStatus()
					}
				})
			}
		},
		monitorNetworkStatus () {
			window.addEventListener('online', () => this.networkOnline = true)
			window.addEventListener('offline', () => this.networkOnline = false)
		},

		// ----------------------------------------------------------------- //
		// ....

		pollAutoSwitch () {
			if (this.autoswitchRef !== undefined) {
				clearInterval(this.autoswitchRef)
			}
			this.autoswitchRef = setInterval(this.checkAutoSwitch, 1000 * 60)
			this.checkAutoSwitch()
		},
		checkAutoSwitch () {
			if (this.appConfig('API_BASE_URL') === undefined) {
				return
			}
			if (this.autoswitchLoading) {
				return
			}
			this.autoswitchLoading = true
			let url = this.appConfig('API_BASE_URL') + 'api/autoswitch/'
			axios.get(
				url,
				{withCredentials: true},
			).then(response => {
				let data = response.data.data[0].attributes
				if (data.enabled) {
					if (this.autoswitchStatus !== data.status) {
						console.log('autoswitch status changed to', data.status)
						this.autoswitchStatus = data.status
						this.snackmsg = 'Auto Switch is ' + data.status
					}
				} else {
					// If autoswitch is disabled, stop polling it.
					console.log('autoswitch is not enabled - ignoring it')
					if (this.autoswitchRef !== undefined) {
						clearInterval(this.autoswitchRef)
					}
				}
			}).catch(() => {
				// silently ignore errors here
			}).finally(() => {
				this.autoswitchLoading = false
			})
		},

	}, // end methods
}

</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss">

body {
	height: 100%;
	background-color: #fafafa;
	overflow-x: hidden;
	overflow-y: scroll;
}

#app, .app-container {
	display: flex;
	flex: auto;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

.max-width {
	width: 100%;
}

/* disable the scroll lock behaviour, because it is buggy when navigation is
   triggered from a dialog */
.mdc-dialog-scroll-lock {
	overflow-x: hidden;
	overflow-y: scroll;
}

.logo-image {
	width: 30vw;
	max-width: 300px;
	position: fixed;
	top: 2em;
	left: 2em;
}

.auth-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	//padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.auth-iframe {
	border: 0;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

</style>
