<template>
	<div>
		<Select
			:desiredValue="value"
			:disabled="disabled"
			:label="label"
			:options="options"
			v-on:update:selectedValue="updated"
		/>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import Select from '../../material/Select'

export default {
	name: 'YesNoEither',
	mixins: [],
	components: {
		Select,
	},
	props: [
		'value',
		'label',
		'yesText',
		'noText',
		'eitherText',
		'disabled'
	],
	data () {
		return {
			actualValue: false,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		options () {
			let result = []
			if (this.eitherText === '') {
				result.push({
					'text': '',
					'value': ''
				})
			} else {
				result.push({
					'text': this.eitherText,
					'value': 'either'
				})
			}
			result.push({
				'text': this.yesText,
				'value': 'yes'
			})
			result.push({
				'text': this.noText,
				'value': 'no'
			})
			return result
		},
	},
	watch: {
		actualValue (value) {
			if (value === '') {
				value = 'either'
			}
			console.debug(this.label, value)
			this.$emit('update:value', value)
		},
	},
	methods: {
		updated (ev) {
			this.$set(this, 'actualValue', ev)
		},
	}

}

</script>

<style lang="scss" scoped>

.results .output-title {
	display: flex;
	flex-direction: row;
}

</style>
