<template>
	<button
		:class="{
			'mdc-tab': true,
			'mdc-tab--active': active || (nested !== true),
			'narrow-tab': narrow,
			'nested-tab': nested,
			'nested-active-tab': nested && active
		}"
		aria-selected="true"
		role="tab"
		v-bind:tabindex="index"
		v-on:click="clickHandler"
	>
          <span class="mdc-tab__content" :style="textStyle">
            <span aria-hidden="true" class="mdc-tab__icon material-icons" v-if="hasIcon" :style="textStyle">{{
					icon
				}}</span>
            <span class="mdc-tab__text-label" :style="textStyle">{{ label }}</span>
            <span aria-hidden="true" class="mdc-tab__icon tab-post-icon material-icons" v-if="hasPostIcon"
				  :style="textStyle">{{ postIcon }}</span>
          </span>
		<span class="mdc-tab-indicator " v-bind:class="{ 'mdc-tab-indicator--active': active }">
            <span v-if="nested !== true"
				  class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
            <span v-else
				  class="mdc-tab-indicator__content nested-tab-indicator"></span>
          </span>
		<span class="mdc-tab__ripple"></span>
	</button>
</template>

<script>

export default {
	components: {},
	props: [
		'index',
		'id',
		'icon',
		'postIcon',
		'label',
		'selectedTabId',
		'callback',
		'active',
		'routeParams',
		'narrow',
		'nested',
		'color',
	],
	computed: {
		hasIcon () {
			return this.icon !== null && this.icon !== undefined && this.icon !== ''
		},
		hasPostIcon () {
			return this.postIcon !== null && this.postIcon !== undefined && this.postIcon !== ''
		},
		textStyle () {
			if (this.color !== undefined) {
				return 'color: ' + this.color + ';'
			}
			return ''
		},
	},
	methods: {
		clickHandler (event) {
			// event.preventDefault();
			if (this.callback === undefined) {
				this.$emit('tab-click', [event, this])
			} else {
				this.callback([event, this])
			}
		}
	}
}
</script>

<style lang="scss">

.narrow-tab {
	padding: 0;
	border-right: 1px dashed #e9ddf9;
}

.narrow-tab:first-of-type {
	border-left: 1px dashed #e9ddf9;
}

.nested-tab .mdc-tab__text-label, .nested-active-tab .mdc-tab__text-label {
	color: black;
	font-weight: normal;
}

// TODO add ripple effect to nested tabs
.nested-tab-indicator {
	background-color: #e9ddf9;
	width: 100%;
}

.tab-post-icon {
	margin-left: 8px;
	margin-right: 0;
}

</style>
