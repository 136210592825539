<template>
	<div v-if="!closed" v-show="!closed" class="max-width max-height">
		<div class="instructions-card mdc-card mdc-card--outlined mdc-typography">
			<div class="button-right">
				<button v-if="preventMinimise !== true"
						class="mdc-icon-button material-icons"
						v-on:click="toggleMinimised">
					{{ buttonIcon }}
				</button>
				<button v-if="preventClose !== true"
						class="mdc-icon-button material-icons"
						v-on:click="setClosed">
					close
				</button>
			</div>
			<p class="mdc-typography--body1" v-for="line in introductionLines">
				{{ line }}
			</p>
			<div v-if="!minimised">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>

import StorageMixin from './StorageMixin'

export default {
	name: 'InstructionsCard',
	mixins: [
		StorageMixin,
	],
	props: [
		'name',
		'introduction',
		'preventMinimise',
		'preventClose'
	],
	data () {
		return {
			minimised: false,
			closed: false,
		}
	},
	computed: {
		hasIntroduction () {
			return this.introduction !== undefined && this.introduction !== null && this.introduction !== ''
		},
		introductionLines () {
			if (this.hasIntroduction) {
				return String(this.introduction).split('\n')
			}
			return []
		},
		buttonIcon () {
			if (this.minimised) {
				return 'expand_more'
			}
			return 'expand_less'
		}
	},
	mounted () {
		this.$nextTick(this.loadState)
	},
	methods: {
		loadState () {
			if (this.name !== undefined) {
				let storedValue = this.loadStoredValue(this.name, {
					'minimised': false,
					'closed': false,
				})
				if (storedValue.minimised !== undefined) {
					this.minimised = storedValue.minimised
				}
				if (storedValue.closed !== undefined) {
					this.closed = storedValue.closed
				}
			}
		},
		storeState () {
			if (this.name !== undefined) {
				this.storePersistent(this.name, {
					'minimised': this.minimised,
					'closed': this.closed,
				})
			}
		},
		toggleMinimised () {
			this.minimised = !this.minimised
			this.storeState()
		},
		setClosed () {
			this.closed = true
			this.storeState()
		}
	}
}

</script>

<style lang="scss" scoped>

.max-width {
	width: 100%;
}

.instructions-card {
	margin: 0;
	padding: 1em;
	position: relative;
	min-height: 4.5em;
	text-align: left;
}

.instructions-card .mdc-typography--body1 {
	margin: 0.5em 0;
}

.button-right {
	display: inline-block;
	position: absolute;
	right: 0.5em;
	top: 0.75em;
	width: auto;
	opacity: 0.7;
}

</style>
