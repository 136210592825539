<script>

/*
 * QueueProcessorMixin
 *
 * This mixin provides a queue processor which can be used to process a queue of
 * work items in a controlled manner. It is intended to avoid overloading the
 * browser and the backend APIs by limiting the number of concurrent requests.
 *
 * This functionality has already been implemented many times in this code base.
 * This mixin is an attempt to consolidate these into a single implementation.
 *
 * A work item consists of an id and a function. The function will be called
 * with the id as an argument. The function should return a promise if it does
 * not complete its work immediately, any other return value will be ignored.
 *
 * The queue processor will start the work items in the order they were added
 * and will process up to three items in parallel. A tiny delay is imposed
 * between each item to prevent the browser from locking up.
 *
 */

// In most browsers, a delay of less than 10ms is equivalent to approx 16ms.
// However, a delay of 0ms behaves differently as it does not yield to the event
// loop. This can cause the browser to lock up. So we use a delay of 5ms to
// trigger the yield and don't really care if it's actually a little longer.
const queueDelay = 5 // millisecond value to pass to setTimeout

export default {
	name: 'QueueProcessorMixin',
	data () {
		return {

			// queueWaiting is a list of work which has not yet started
			'queueWaiting': [], // array of {id, work}

			// queueRunning is a list of work which is currently running
			'queueRunning': [], // array of id

			// queueProcessing tracks state of the queue processor
			'queueProcessing': false, // false or a promise object

			// queueAdded is the number of work items which have been added
			// to the queue. This resets when the queue processing restarts.
			'queueAdded': 0,
		}
	},
	mounted () {
		// Reset the queue processor when the vue component is mounted.
		// This commonly occurs during a page navigation.
		this.queueReset()
	},
	beforeDestroy () {
		// Stop the queue processor when the vue component is destroyed.
		this.queueReset()
	},
	computed: {

		// queueProgress returns a number between 0 and 100 representing the
		// percentage of work items which have been processed.
		queueProgress () {
			return this.queueCalculateProgress(this.queueAdded)
		},

	},
	methods: {

		// queueAdd adds a work item to the queue and returns a promise which
		// resolves when all items in the queue have been processed.
		// - id is a string identifier for the work item
		// - work is a function which accepts the id as an argument and optionally returns a promise
		queueAdd (id, work) {
			if (typeof (id) !== 'string' || id.length <= 0) {
				throw new Error('QueueProcessor: id must be a non-empty string')
			}
			if (typeof (work) !== 'function') {
				throw new Error('QueueProcessor: work must be a function')
			}

			// check if this id is already in the queue
			if (this.queueRunning.includes(id) || this.queueWaiting.some((x) => x.id === id)) {
				return this.queueProcess()
			}

			this.queueAdded++
			this.queueWaiting.push({
				id: id,
				work: work
			})
			return this.queueProcess()
		},

		// queueReset stops the queue processor and rejects any pending promises.
		// After calling this method, it is ready to start processing a new queue.
		queueReset () {
			// this will cause the async queue processor to stop and reject
			this.queueWaiting = [] // forget about anything not yet started
			this.queueRunning = [] // forget about everything already running
			this.queueProcessing = false // signal existing processor to reject
		},

		// queueProcess starts the queue processor and returns a promise which
		// resolves when all items in the queue have been processed. It is not
		// necessary to call this method directly, as it is called automatically
		// by queueAdd.
		queueProcess () {
			if (this.queueProcessing !== false) {
				return this.queueProcessing // return the existing promise
			}

			// start a new async queue processor (multiple workers)
			this.queueProcessing = new Promise((resolve, reject) => {
				this.queueAdded = this.queueWaiting.length // reset the added counter

				let rejected = false
				const shouldAbort = () => {
					// Check if we should stop early and reject.
					if (this.queueProcessing === false) {
						if (!rejected) { // only call reject() once
							rejected = true
							reject()
						}
						return true // true = don't do any more work
					}
					return false // false = please continue as normal
				}

				const worker = () => {
					if (shouldAbort()) {
						return
					}
					if (this.queueWaiting.length <= 0) {
						return // nothing to do!
					}

					let {
						id,
						work
					} = this.queueWaiting.shift()
					this.queueRunning.push(id)

					Promise.resolve(
						work(id)
					).then(() => {
						// no action required

					}).catch((error) => {
						console.error('QueueProcessor: Error:', id, error)

					}).finally(() => {
						if (shouldAbort()) {
							return
						}

						// remove id from queueRunning
						this.queueRunning = this.queueRunning.filter((x) => x !== id)

						if (this.queueWaiting.length >= 1) {
							// process next item
							setTimeout(worker, queueDelay)

						} else if (this.queueRunning.length <= 0) {
							// Both waiting and running are empty ...
							// So, we're done!
							this.queueProcessing = false
							resolve()
						}

					})
				}

				// start the workers in the next tick
				this.$nextTick(() => {

					// first worker
					setTimeout(worker, queueDelay)

					// second worker
					if (this.queueWaiting.length >= 2) {
						setTimeout(worker, queueDelay * 2)
					}

					// third worker
					if (this.queueWaiting.length >= 3) {
						setTimeout(worker, queueDelay * 3)
					}

				})
			})

			return this.queueProcessing // return the promise
		},

		// queueCalculateProgress returns a number between 0 and 100
		// representing the percentage of work items which have been processed.
		// It accepts an optional total parameter which is the total number of
		// work items to calculate the progress against. If total is not
		// provided, then the number of work items which have been added to the
		// queue is used.
		queueCalculateProgress (total) {
			if (typeof (total) !== 'number' || total <= 0) {
				total = this.queueAdded
			}
			if (total <= 0) {
				total = 1
			}

			let incomplete = this.queueWaiting.length + this.queueRunning.length
			let complete = total - incomplete
			return Math.round((complete / total) * 100)
		},

	},
}

</script>
