<template>
	<div class="labeled-checkbox" role="checkbox" v-bind:aria-checked="checkedWord">
		<div class="mdc-checkbox" data-mdc-auto-init="MDCCheckbox">
			<input v-bind:id="htmlId"
				   v-bind:disabled="disabled === true"
				   v-model="checked"
				   class="mdc-checkbox__native-control"
				   type="checkbox"
			/>
			<div class="mdc-checkbox__background">
				<svg class="mdc-checkbox__checkmark"
					 viewBox="0 0 24 24">
					<path class="mdc-checkbox__checkmark-path"
						  d="M1.73,12.91 8.1,19.28 22.79,4.59"
						  fill="none"/>
				</svg>
				<div class="mdc-checkbox__mixedmark"></div>
			</div>
		</div>
		<label v-bind:for="htmlId" :class="{'label-disabled': disabled === true}">{{ label }}</label>
	</div>
</template>

<script>

import autoInit from '@material/auto-init'

export default {
	props: [
		'id',
		'label',
		'checkedDesired', // bool
		'disabled', // bool
	],
	data () {
		return {
			checked: true,
		}
	},
	computed: {
		htmlId () {
			return 'list-checkbox-' + this.id
		},
		checkedWord () {
			if (this.checked) {
				return 'true'
			} else {
				return 'false'
			}
		}
	},
	watch: {
		checked () {
			this.$emit('checked-changed', this.id, this.checked)
		},
		checkedDesired () {
			this.checked = this.checkedDesired
		},
	},
	created () {
		this.checked = this.checkedDesired
	},
	mounted () {
		autoInit()  // Material Design Components
		this.$emit(
			'set-value-callback',
			this.setValue,
		)
	},
	updated () {
		autoInit()  // Material Design Components
	},
	methods: {
		setValue (value) {
			// console.debug('LabelledCheckbox.setValue', value)
			this.checked = value
		},
	},
}

</script>

<style lang="scss">

.labeled-checkbox {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;
	align-items: center;
}

.label-disabled {
	color: rgba(0, 0, 0, 0.25);
}

</style>
