<template>
	<div v-show="!hidden">
		<DrawerItems
			:hidden="hidden"
			:items="augmentedItems"
			:selectedId="selectedId"
			@drawer-click="clickHandler"
		/>
	</div>
</template>

<script>

export default {
	name: 'DrawerExpandable',
	components: {
		DrawerItems: () => import('./DrawerItems'),
	},
	props: [
		'selectedId',

		'kind', // ENUM: 'submenu', 'section', 'firstSection'
		'items',

		// Shared
		'id',
		'title',
		'icon',
		'hidden',
		'callback',

		// Links
		'open',
		'active',
		'iconFont',
	],
	data () {
		return {
			openOverride: undefined,
		}
	},
	computed: {
		isSection () {
			return this.kind === 'section' || this.kind === 'firstSection'
		},
		isFirstSection () {
			return this.kind === 'firstSection'
		},
		isSubmenu () {
			return !this.isSection
		},
		titleComponent () {
			return this.isSection ? 'DrawerLabel' : 'DrawerLink'
		},

		isHidden () {
			return this.hidden === true
		},
		isOpen () {
			let itemOpen = this.open
			let overrideOpen = this.openOverride
			if (overrideOpen !== undefined) {
				return overrideOpen === true
			}
			return itemOpen === true
		},

		indent () {
			return false
		},
		expandable () {
			return true
		},

		augmentedItems () {
			let result = []
			let icon = this.icon
			let callback = this.callback
			if (this.isSection) {
				if (!this.isFirstSection) {
					result.push({
						id: this.id + '-separator',
						component: 'DrawerSeparator',
						hidden: this.isHidden,
					})
				}
				if (!this.isOpen) {
					icon = 'visibility_off'
				}
				if (this.callback === undefined) {
					callback = () => {
						if (this.openOverride === undefined) {
							this.openOverride = !this.isOpen
						} else {
							this.openOverride = undefined
						}
					}
				}
			}
			result.push({
				id: this.id,
				component: this.titleComponent,
				title: this.title,
				icon: icon,
				hidden: this.isHidden,
				expandable: true,
				open: this.isOpen,
				callback: callback,
				active: this.active,
				iconFont: this.iconFont,
			})
			this.items.forEach(originalItem => {
				let item = {...originalItem} // clone the item
				item.hidden = this.isHidden || !this.isOpen || (item.hidden === true)
				if (item.component === undefined) {
					item.component = 'DrawerLink'
				}
				item.indent = this.isSubmenu
				result.push(item)
			})
			return result
		},
	},
	methods: {
		clickHandler ([event, sender]) {
			// console.debug('DrawerExpandable: clickHandler', event, sender)
			event.preventDefault()
			this.$emit('drawer-click', [event, sender, this])
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
