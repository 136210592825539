<template>
	<div v-show="!hidden" :class="{ 'indented': indent === true }">
		<div v-if="hasCallback"
			 v-bind:class="{ 'mdc-list-item': true, 'mdc-list-item--activated': false, 'noselect': true, }"
			 v-on:click="clickHandler"
		>
			<span class="mdc-list-item__ripple"></span>
			<span class="mdc-list-item__text">{{ title }}</span>
			<span v-if="trailingIcon !== undefined" aria-hidden="true"
				  class="mdc-list-item__meta material-icons">{{ trailingIcon }}</span>
		</div>
		<h6 v-else class="mdc-list-group__subheader noselect">{{ title }}</h6>
	</div>
</template>

<script>

export default {
	name: 'DrawerLabel',
	props: [
		'id',
		'title',
		'icon',
		'hidden',
		'indent',
		'callback',
	],
	computed: {
		hasCallback () {
			return this.callback !== undefined
		},
		trailingIcon () {
			if (this.icon !== undefined) {
				return this.icon
			}
			return undefined
		}
	},
	methods: {
		clickHandler (event) {
			event.preventDefault()
			// console.debug('drawerItem clickHandler', event, this);
			if (this.callback === undefined) {
				this.$emit('drawer-click', [event, this])
			} else {
				this.callback([event, this])
			}
		},
	},
}
</script>

<style scoped lang="scss">

.mdc-list-item__text {
	font-family: Roboto, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 0.0178571429em;
	text-decoration: inherit;
	text-transform: inherit;
	display: block;
	line-height: normal;
	color: rgba(0, 0, 0, 0.6);

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

h6 {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.indented {
	background-color: ghostwhite;
	padding-left: 2em !important;
	margin: 2px 8px 0;
}

</style>
