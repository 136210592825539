<template>
	<div class="results mdc-typography">
		<table v-if="result.length > 0" class="basic-table mdc-typography--body1">
			<thead>
			<tr>
				<th>
					<button :class="{'off': identityColumn!=='id', 'on': identityColumn==='id'}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="identityColumn='id'"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">ID</span>
					</button>
					<button :class="{'off': identityColumn!=='serial', 'on': identityColumn==='serial'}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="identityColumn='serial'"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Serial</span>
					</button>
					<button :class="{'off': identityColumn!=='common', 'on': identityColumn==='common'}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="identityColumn='common'"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">CN</span>
					</button>
				</th>
				<th>Key Algorithm</th>
				<th>
					Hostnames
					&nbsp;
					<button :class="{'off': showOnlyLinkedHostnames, 'on': !showOnlyLinkedHostnames}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="showOnlyLinkedHostnames = false"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Cert</span>
					</button>
					<button :class="{'off': !showOnlyLinkedHostnames, 'on': showOnlyLinkedHostnames}"
							class="mdc-button mdc-button--dense mdc-button--unelevated"
							@click.prevent="showOnlyLinkedHostnames = true"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Linked</span>
					</button>
				</th>
				<th>Expiry</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="resultItem in result"
				v-bind:key="resultItem.id"
				:class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }">
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }">

					<transition-group class="sliding-group"
									  name="slide-fade"
									  tag="div"
					>
						<div
							v-show="identityColumn==='id'"
							key="id"
							class="sliding-group-item"
							v-bind:title="resultItem.id"
						>{{ resultItem.id }}
						</div>
						<div
							v-show="identityColumn==='serial'"
							key="serial"
							class="sliding-group-item"
							v-bind:title="resultItem.attributes.serial"
						>{{ resultItem.attributes.serial.toUpperCase() }}
						</div>
						<div
							v-show="identityColumn==='common'"
							key="common"
							class="sliding-group-item"
							v-bind:title="resultItem.attributes['common-name']"
						>{{ resultItem.attributes['common-name'] }}
						</div>
					</transition-group>

				</td>
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }">
					{{ resultItem.attributes['key-algorithm-name'] }}
				</td>
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }"
					v-on:click="openHostnamesDialog(resultItem)"
				>
					<div class="floating-stats">
						<ul class="subtle-list">
							<li>cert: {{ certHostnames(resultItem).length }}</li>
							<li>linked: {{ linkedHostnames(resultItem).length }}</li>
						</ul>
					</div>
					<transition-group class="subtle-list"
									  name="slide-fade"
									  tag="ul">
						<li v-for="hostname in displayedHostnames(resultItem).slice(0,5)"
							v-bind:key="hostname"
							v-bind:title="hostname">
							{{ hostname }}
						</li>
						<li v-if="displayedHostnames(resultItem).length > 5" key="truncated">
							<i>... (list truncated - click for more)</i>
						</li>
						<li v-if="displayedHostnames(resultItem).length === 0" key="empty">
							<i>(empty list)</i>
						</li>
					</transition-group>
				</td>
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'] }">
					<Timestamp
						v-bind:unixtime="toUnixtime(resultItem.attributes.expiry)"
						v-bind:wide="false"
					/>
				</td>
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'], 'status': true }">
						<span v-if="resultItem.attributes['archived']" class="archived">
							Archived
						</span>
					<span v-else-if="resultItem.attributes['linked']" class="linked">
							Linked
						</span>
					<span v-else>
							Unlinked
						</span>
					<span v-if="resultItem.attributes['expired']" class="expired">
							Expired
						</span>

					<div v-if="resultItem.attributes['expired'] && resultItem.attributes['linked']"
						 class="warning"
						 title="WARNING - Expired certificate is still in use"
					>
						<span class="material-icons inline-icon">warning_amber</span>&nbsp;WARNING&nbsp;<span
						class="material-icons inline-icon">warning_amber</span>
					</div>
				</td>
				<td :class="{ 'warning': resultItem.attributes['warning'], 'expired': resultItem.attributes['expired'], 'actions': true }">
					<a class="mdc-button mdc-button--raised"
					   v-bind:href="detailsUrl(resultItem.id)"
					   v-on:click.prevent="$router.push({path: detailsUrl(resultItem.id)})"
					>
						<div class="mdc-button__ripple"></div>
						<span class="mdc-button__label">Details</span>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
		<div v-else class="max-width">
			<p><i>No certificates to show</i></p>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import Timestamp from '../../material/Timestamp'

export default {
	name: 'TableDetailed',
	components: {
		Timestamp,
	},
	props: [
		'result',
	],
	data () {
		return {
			identityColumn: 'serial', // TODO persist this in local storage
			showOnlyLinkedHostnames: true, // TODO persist this in local storage
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
	},
	methods: {
		toUnixtime (timestamp) {
			return Math.round(new Date(timestamp).getTime() / 1000)
		},
		detailsUrl (certificateId) {
			return this.appConfig('BASE_URL') + 'certificates/' + certificateId
		},

		certHostnames (item) {
			if (this.hasValue(item)) {
				if (this.hasValue(item.attributes.hostnames)) {
					return item.attributes.hostnames
				}
			}
			return []
		},
		linkedHostnames (item) {
			if (this.hasValue(item)) {
				if (this.hasValue(item.attributes.links)) {
					if (this.hasValue(item.attributes.links.hostnames)) {
						return item.attributes.links.hostnames
					}
				}
			}
			return []
		},
		displayedHostnames (item) {
			if (this.showOnlyLinkedHostnames) {
				return this.linkedHostnames(item)
			}
			return this.certHostnames(item)
		},
		hasValue (variable) {
			// noinspection RedundantIfStatementJS
			if (variable === undefined || variable === null) {
				return false
			}
			// noinspection RedundantIfStatementJS
			if (variable === 'undefined' || variable === 'null' || variable === '') {
				return false
			}
			return true
		},

		openHostnamesDialog (item) {
			this.$emit('open-hostnames-dialog', item, !this.showOnlyLinkedHostnames, this.showOnlyLinkedHostnames)
		},

	}
}

</script>

<style lang="scss" scoped>

.basic-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
}

.basic-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
}

.basic-table td, .basic-table th {
	border: 1px solid darkgrey;
	padding: .5em;
	transition: height 4s ease;
	transition-delay: 1s;
	//max-width: 20%;
}

.basic-table tr {
	background-color: white;
}

.basic-table tr:hover {
	background-color: lightyellow;
}

.basic-table .actions {
	text-align: center;
	width: 10%;
}

.basic-table .status {
	text-align: center;
	width: 10%;
}

/*noinspection SpellCheckingInspection*/
.basic-table .serveractions {
	text-align: right;
}

/*noinspection SpellCheckingInspection*/
.basic-table tr.actionsexpanded {
	visibility: visible;
	display: table-row;
}

/*noinspection SpellCheckingInspection*/
.basic-table td.actionsexpanded {
	visibility: visible;
	display: table-cell;
}

/*noinspection SpellCheckingInspection*/
.basic-table .actionscollapsed {
	visibility: hidden;
	display: none;
}

.max-width {
	width: 100%;
	text-align: center;
}

.subtle-list {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.floating-stats {
	float: right;
	text-align: right;
	color: #757575;
}

span.archived {
	color: darkblue;
}

span.linked {
	color: darkgreen;
}

span.expired {
	color: red;
}

tr.warning {
	background-color: #FFD580;
}

tr.warning:hover {
	background-color: #fcc75d;
}

tr.expired {
	background-color: #ffcccb;
}

tr.expired:hover {
	background-color: #f8a7a6;
}

.inline-icon {
	font-size: inherit;
	line-height: inherit;
	vertical-align: bottom;
}

div.warning {
	display: block;
	margin-top: 0.5em;
	background-color: darkred;
	color: darkorange;
	padding: 0.5em;
}

th .mdc-button {
	border: 1px solid #fff;
	color: #fff;
}

th .on {
	opacity: 1;
}

th .off {
	opacity: 0.8;
}

.sliding-group {
	position: relative;
	margin-top: -1.5em
}

.sliding-group-item {
	display: inline-block;
	position: absolute;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
	transition: all .3s ease;
}

.slide-fade-leave-active {
	transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */
{
	transform: translateX(20px);
	opacity: 0;
}

</style>
