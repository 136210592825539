<!--suppress JSUnresolvedVariable, JSUnusedGlobalSymbols -->
<template>
	<div></div>
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'

const supportedInterfaces = [
	'identity',
	'groups',
	'agent',
	'status',
	'statusHistory',
	'nagios',
	'configfiles',
	'variables',
	'disableable',
	'deletable',
]

export default {
	name: 'ActionsBase',
	computed: {
		...mapGetters([
			'appConfig',
		]),
	},
	methods: {

		availableActions (
			callback,
			server,
			agent,
			agentFocus,
			activeInterfaces,
			detailsUrl,
			detailsUrlIcon,
			detailsUrlLabel,
			canRefresh,
			whilePending,
		) {
			if (activeInterfaces === undefined) {
				activeInterfaces = supportedInterfaces
			}
			let actions = []
			if (callback === undefined) {
				return actions
			}
			if (server === undefined && agent === undefined) {
				return actions
			}
			if (agent === undefined && agentFocus) {
				agent = server
			}
			if (canRefresh === undefined) {
				canRefresh = false
			}
			if (whilePending === undefined) {
				whilePending = false
			}
			if (server !== undefined) {
				if (canRefresh === true) {
					actions.push({
						id: 'refresh',
						icon: 'refresh',
						label: 'Refresh',
						disabled: whilePending,
						outline: true,
						url: detailsUrl,
						target: this.getTargetId(server, agent),
						callback: () => callback(server, agent, 'refresh')
					})
				}
				if (this.checkIfRegistered(agent)) {
					actions.push({
						id: 'ping',
						icon: 'speed',
						label: 'Ping',
						disabled: this.checkIfOffline(server, agent) || whilePending,
						outline: false,
						url: detailsUrl + '#action-ping',
						target: this.getTargetId(server, agent),
						callback: () => callback(server, agent, 'ping')
					})
				}
				if (agentFocus !== true) {
					if (this.checkIfDisabled(server)) {
						actions.push({
							id: 'enable',
							icon: 'wifi_tethering',
							label: 'Enable',
							disabled: whilePending,
							outline: false,
							url: detailsUrl + '#action-enable',
							target: this.getTargetId(server, agent),
							callback: () => callback(server, agent, 'enable')
						})
						if (activeInterfaces.includes('deletable')) {
							actions.push({
								id: 'delete',
								icon: 'delete_forever',
								label: 'Delete',
								disabled: whilePending,
								outline: false,
								url: detailsUrl + '#action-delete',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'delete')
							})
						}
					} else {
						if (activeInterfaces.includes('configfiles')) {
							// TODO ADD GENERATE-STORE AND APPLY-STORED
							actions.push({
								id: 'reconfigure',
								icon: 'sync',
								label: 'Reconfigure',
								disabled: this.checkIfDisabled(server) || this.checkIfOffline(server, agent) || whilePending,
								outline: false,
								url: detailsUrl + '#action-reconfigure',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'reconfigure')
							})
							actions.push({
								id: 'reload',
								icon: 'refresh',
								label: 'Reload',
								disabled: this.checkIfDisabled(server) || this.checkIfOffline(server, agent) || whilePending,
								outline: false,
								url: detailsUrl + '#action-reload',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'reload')
							})
						}
						if (activeInterfaces.includes('disableable')) {
							actions.push({
								id: 'disable',
								icon: 'wifi_tethering_off',
								label: 'Disable',
								disabled: whilePending,
								outline: false,
								url: detailsUrl + '#action-disable',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'disable')
							})
						}
					}
				} else {
					if (this.checkIfDisabled(agent)) {
						actions.push({
							id: 'enable',
							icon: 'wifi_tethering',
							label: 'Enable',
							disabled: whilePending,
							outline: false,
							url: detailsUrl + '#action-enable',
							target: this.getTargetId(server, agent),
							callback: () => callback(server, agent, 'enable')
						})
					} else {
						if (this.checkIfApproved(agent) === undefined || this.checkIfApproved(agent) === true) {
							actions.push({
								id: 'sleep',
								icon: 'timer',
								label: 'Sleep',
								disabled: this.checkIfOffline(server, agent) || whilePending,
								outline: false,
								url: detailsUrl + '#action-sleep',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'sleep')
							})
							actions.push({
								id: 'exit',
								icon: 'stop',
								label: 'Kill',
								disabled: this.checkIfOffline(server, agent) || whilePending,
								outline: false,
								url: detailsUrl + '#action-exit',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'exit')
							})
							actions.push({
								id: 'update',
								icon: 'system_update',
								label: 'Update',
								disabled: !this.checkIfUpdatable(agent) || this.checkIfOffline(server, agent) || whilePending,
								outline: false,
								url: detailsUrl + '#action-update',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'update')
							})
						} else if (this.checkIfApproved(agent) === false) {
							actions.push({
								id: 'approve',
								icon: 'assignment_turned_in',
								label: 'Approve',
								disabled: whilePending || !this.checkIfCanApprove(agent),
								outline: false,
								url: detailsUrl + '#action-approve',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'approve')
							})
						}
						if (activeInterfaces.includes('disableable')) {
							actions.push({
								id: 'disable',
								icon: 'wifi_tethering_off',
								label: 'Disable',
								disabled: whilePending,
								outline: false,
								url: detailsUrl + '#action-disable',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'disable')
							})
						}
						if (activeInterfaces.includes('deletable')) {
							actions.push({
								id: 'deregister',
								icon: 'delete_forever',
								label: 'Deregister',
								disabled: whilePending || !this.checkIfRegistered(agent),
								outline: false,
								url: detailsUrl + '#action-deregister',
								target: this.getTargetId(server, agent),
								callback: () => callback(server, agent, 'deregister')
							})
						}
					}
				}
				if (detailsUrl !== undefined) {
					let btnDetailsUrlIcon = detailsUrlIcon
					if (btnDetailsUrlIcon === undefined || btnDetailsUrlIcon.length === 0) {
						btnDetailsUrlIcon = 'topic'
					}
					let btnDetailsUrlLabel = detailsUrlLabel
					if (btnDetailsUrlLabel === undefined || btnDetailsUrlLabel.length === 0) {
						btnDetailsUrlLabel = 'Details'
					}
					actions.push({
						id: 'details',
						icon: btnDetailsUrlIcon,
						label: btnDetailsUrlLabel,
						disabled: whilePending,
						outline: false,
						url: detailsUrl,
						target: this.getTargetId(server, agent),
						callback: () => callback(server, agent, 'details')
					})
				}
			}
			return actions
		},

		sortActions (actions) {
			const preferredOrder = [
				'refresh',
				'ping',
				'generate-and-store',
				'apply-stored',
				'reconfigure',
				'reload',
				'approve',
				'enable',
				'update',
				'sleep',
				'exit',
				'disable',
				'deregister',
				'delete',
			]
			actions.sort((a, b) => {
				return preferredOrder.indexOf(a.id) - preferredOrder.indexOf(b.id)
			})
			return actions
		},

		getTargetId (server, agent) {
			if (server !== undefined) {
				return server.id
			}
			if (agent !== undefined) {
				return agent.label
			}
			return undefined
		},
		checkIfOffline (server, agent) {
			if (server !== undefined && server.attributes !== undefined) {
				return server.attributes.connected <= 0
			}
			if (agent !== undefined && agent.attributes !== undefined) {
				return agent.attributes.connected <= 0
			}
			return true
		},
		checkIfDisabled (server) {
			if (server !== undefined && server.attributes !== undefined) {
				return server.attributes.disabled === true
			}
			return false
		},
		checkIfRegistered (agent) {
			if (agent !== undefined && agent.attributes !== undefined) {
				return agent.attributes.registered
			}
			return undefined
		},
		checkIfApproved (agent) {
			if (agent !== undefined && agent.attributes !== undefined) {
				return agent.attributes.approved
			}
			return undefined
		},
		checkIfCanApprove (agent) {
			if (agent !== undefined && agent.attributes !== undefined) {
				return (agent.attributes['can_approve'] === true)
			}
			return undefined
		},
		checkIfUpdatable (agent) {
			if (agent !== undefined && agent.canUpdate !== undefined) {
				return agent.canUpdate === true
			}
			return true
		},

		////////////////////////////////////////////////////////////////////////

		// Performs the requested action with the API. This helper method just
		// selects the appropriate other method to actually perform the call.
		performAction: function (serverType, serverId, action, refreshCallback, abortCallback, additional = {}) {
			switch (action) {

			case 'ping':
				return this.performAgentAction(serverId, action, additional)
			case 'sleep':
				return this.performAgentAction(serverId, action, additional)
			case 'exit':
				return this.performAgentAction(serverId, action, additional)
			case 'approve':
				return this.performAgentAction(serverId, action, additional)
			case 'deregister':
				return this.performAgentAction(serverId, action, additional)
			case 'update':
				return this.performAgentAction(serverId, action, additional)

			case 'generate-and-store':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'apply-stored':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)

			case 'reconfigure':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'validate':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'reload':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'disable':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'enable':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)
			case 'delete':
				return this.performServerAction(serverType, serverId, action, refreshCallback, abortCallback, additional)

			case 'refresh':
				if (refreshCallback === undefined) {
					// Without the refreshCallback loadServerData will load
					// the data and then return it to the caller; who isn't
					// expected to be listening for it; this is not useful,
					// and so an error is returned instead.
					return Promise.reject('refreshCallback is required but not defined')
				}
				return this.loadServerData(serverType, serverId, refreshCallback)

			default:
				// NB. Some actions are UI only (i.e. details), they can't
				// be supported here because this is all about API; not UI.
				return Promise.reject('Unsupported API action: ' + action)
			}
		},

		// Performs the requested action against the requested agent.
		// Returns a promise which is resolved to the updated agent data.
		performAgentAction (serverId, action, additional = {}) {
			return new Promise((resolve, reject) => {
				console.log('sending agent action', serverId, action)
				axios.post(
					this.appConfig('API_BASE_URL') + 'api/agents/' + serverId + '/',
					Object.assign(additional, {
						'id': serverId,
						'action': action
					}),
					{withCredentials: true}
				).then(response => {
					// console.debug(response)
					if (response.data.jsonapi !== undefined) {
						if (response.data.data !== undefined) {
							resolve(response.data.data[0])
						}
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		// Performs the requested action against the requested server.
		// Returns a promise which is resolved to the  updated server data.
		// The promise only resolves after the action has completed. The abort
		// callback can be used to cause the promise to be rejected early.
		// The callbacks are optional (but preferred).
		performServerAction: function (serverType, serverId, action, refreshCallback, abortCallback, additional = {}) {
			let abortPolling = false
			return new Promise((resolve, reject) => {

				// list of promises which must complete before we send the call
				let dependencies = [
					// eslint-disable-next-line vue/valid-next-tick
					this.$nextTick()
				]

				// if we need to wait for reload, then we'll need to find out
				// the old reload timestamp before we send our call.
				let serverReloadRequired = this.doesServerActionRequireReload(serverType, serverId, action)
				let initialServerData = undefined
				if (serverReloadRequired) {
					if (abortCallback !== undefined && abortCallback('loadingInitialServerData')) {
						reject('aborted', undefined)
						return
					}
					let loadingPromise = this.loadServerData(serverType, serverId, refreshCallback)
					loadingPromise = loadingPromise.then(result => {
						initialServerData = result
					})
					dependencies.push(loadingPromise)
				}

				// if we need to send a token then we need to generate it first.
				let payload = this.getServerActionPayload(serverType, serverId, action)
				payload = Object.assign(payload, additional) // add additional fields to the payload
				if (payload.token !== undefined) { // if a token is needed
					if (abortCallback !== undefined && abortCallback('generatingToken')) {
						reject('aborted', undefined)
						return
					}
					dependencies.push(this.generateApiToken(serverId).then(token => {
						if (token !== undefined) {
							payload.token = token
						}
					}))
				}

				// call the server and handle the response
				Promise.all(dependencies).then(() => {
					if (abortCallback !== undefined && abortCallback()) {
						return Promise.reject('aborted')
					}
					return axios.post(
						this.getServerActionUrl(serverType, serverId, action),
						payload,
						{withCredentials: true}
					) // axios returns a promise, which chains into the next then ...
				}).then(response => {
					if (response.data.jsonapi === undefined || response.data.data === undefined) {
						reject('Invalid API response', response)
						return
					}
					if (this.doesServerActionRequireReload(serverType, serverId, action)) {
						// We have to wait for the server to have reloaded
						// before we resolve our promise ...
						this.waitForNextServerReload(
							serverType,
							serverId,
							refreshCallback,
							() => { // enhanced abort callback
								return abortPolling || (abortCallback !== undefined && abortCallback())
							},
							initialServerData // data from before our api call
						).then(resolve).catch(reject)
						return
					}
					if (abortCallback !== undefined && abortCallback()) {
						reject('aborted', response.data.data[0])
						return
					}
					resolve(response.data.data[0])
				}).catch(error => {
					console.error(error)
					if (error !== undefined
						&& error.response !== undefined
						&& error.response.data !== undefined
						&& error.response.data.errors !== undefined) {
						error = JSON.parse(JSON.stringify(error.response.data.errors))
						if (Array.isArray(error) && error.length === 1) {
							error = error[0]
						}
						console.error(error)
					}
					reject(error)
				})
			}).finally(() => abortPolling = true)
		},

		getServerActionUrl (serverType, serverId, action) {
			if (action === 'enable') {
				action = 'disable'
			}
			return this.appConfig('API_BASE_URL') + 'api/' + serverType + '/' + serverId + '/' + action + '/'
		},

		getServerActionPayload (serverType, serverId, action) {
			switch (action) {
			case 'enable':
				return {
					'id': serverId,
					'action': action,
					'token': '',
					'disabled': false
				}
			case 'disable':
				return {
					'id': serverId,
					'action': action,
					'token': '',
					'disabled': true
				}
			case 'delete':
				return {
					'id': serverId,
					'action': action,
					'token': '',
					'deleted': false
				}
			default:
				return {
					'id': serverId,
					'action': action
				}
			}
		},

		doesServerActionRequireReload (serverType, serverId, action) {
			console.debug('doesServerActionRequireReload', serverType, serverId, action)
			return action === 'reload' || action === 'reconfigure' || action === 'apply-stored'
		},

		// Returns a promise which resolves to a newly generated token for use
		// with the API endpoints which require a token. The value of resource
		// is usually the serverId but depends on the API endpoint which is
		// being called. The colon character is not permitted in the resource.
		// Note that token generation can take a few seconds (by design).
		generateApiToken (resource) {
			return new Promise(resolve => {
				import(this.appConfig('API_BASE_URL') + 'js/hashcash/hashcash.min.mjs').then(exports => {
					let hashcash = exports.hashcash
					hashcash(resource, 'cachefly', 16).then(resolve)
				})
			})
		},

		// Returns a promise which resolves to an updated copy of the data for
		// the requested server. The refreshCallback is optional, but is used
		// (if provided) in preference to the internal implementation.
		// eslint-disable-next-line no-unused-vars
		loadServerData (serverType, serverId, refreshCallback) {
			// TODO the response from the refreshCallback needs to be the same as the response from this function.
			// if (refreshCallback !== undefined && refreshCallback !== this.loadServerData) {
			// 	return refreshCallback(serverType, serverId)
			// }
			return new Promise((resolve, reject) => {
				axios.get(
					this.appConfig('API_BASE_URL') + 'api/' + serverType + '/' + serverId + '/',
					{withCredentials: true}
				).then(response => {
					if (response.data.jsonapi !== undefined) {
						if (response.data.data !== undefined) {
							resolve(response.data.data[0])
							return
						}
					}
					// console.debug(response)
					reject('Bad API response')
				}).catch(reject)
			})
		},

		// Returns a promise which is resolved once the server has reloaded.
		// Fields serverType and ServerId are mandatory.
		// Fields refreshCallback, abortCallback, and initialServerData are optional.
		// If set refreshCallback is for collecting updated data instead of internal method.
		// Polling loop is aborted if abortCallback returns true.
		// Field initialServerData is populated with first poll if not provided.
		// Functions best when all fields are provided.
		waitForNextServerReload (serverType, serverId, refreshCallback, abortCallback, initialServerData) {
			// console.debug('waitForNextServerReload', serverType, serverId, refreshCallback, abortCallback, initialServerData)
			const getLastReloadTime = serverData => {
				if (serverData !== undefined && serverData.attributes !== undefined) {
					if (serverData.attributes.reload !== undefined && serverData.attributes.reload.last !== undefined) {
						return serverData.attributes.reload.last // correct place for the timestamp
					}
					return serverData.attributes.last_reload // backwards compatibility - old place for the timestamp
				}
				return undefined
			}
			let intervalRef = undefined
			return new Promise((resolve, reject) => {
				let initialReloadTimestamp = undefined
				if (initialServerData !== undefined) {
					initialReloadTimestamp = getLastReloadTime(initialServerData)
				}
				let pending = false
				let updatedServerData = undefined
				let startedTime = ~~(+new Date() / 1000) // https://stackoverflow.com/a/29295210
				let timeWarningIssued = false
				intervalRef = setInterval(() => {
					if (pending) return
					pending = true
					if (abortCallback !== undefined && abortCallback()) {
						reject('aborted', updatedServerData)
						return
					}
					this.loadServerData(serverType, serverId, refreshCallback).then(result => {

						updatedServerData = result

						// if we weren't given any initial data then the result
						// of our first poll becomes our initial starting point.
						if (initialServerData === undefined) {
							initialServerData = updatedServerData
							if (initialServerData !== undefined && initialServerData.attributes !== undefined) {
								initialReloadTimestamp = getLastReloadTime(initialServerData)
							} else {
								console.error('unexpected condition encountered', updatedServerData, initialServerData)
							}
							return
						}

						// check if reload has occurred
						if (updatedServerData !== undefined && updatedServerData.attributes !== undefined) {
							let updatedReloadTimestamp = getLastReloadTime(updatedServerData)
							if (updatedReloadTimestamp <= initialReloadTimestamp) {
								// reload has not occurred yet

								let now = ~~(+new Date() / 1000) // https://stackoverflow.com/a/29295210
								if (now - startedTime > 15 && !timeWarningIssued) {
									timeWarningIssued = true
									console.warn(serverType, serverId, 'been waiting for reload for ' + (now - startedTime) + 'seconds')
								}
								if (now - startedTime > 180) {
									clearInterval(intervalRef)
									reject('Request timed out (please try again)', updatedServerData)
									return
								}

								return
							}
						}

						// reload has occurred!
						clearInterval(intervalRef)
						resolve(updatedServerData)

					}).catch(reject).finally(() => pending = false)
				}, 500)
			}).finally(() => {
				if (intervalRef !== undefined) {
					clearInterval(intervalRef)
				}
			})
		},

		////////////////////////////////////////////////////////////////////////

	},
}
</script>

<style>
</style>
