<template>
	<div class="server-action-disable-dialog">
		<PageDialog
			:open="open"
			@update:open="$emit('update:open', $event)"
			@closed="$emit('closed', $event)"
			:title="dialogTitle"
			:add-close="addClose !== false"
			:min-height="45"
			:max-height="85"
			:width="75"
		>
			<div v-show="pending" class="pending-container">
				<div class="pending">
					<div>
						<ProgressSpinner
							v-if="pending"
							v-show="pending"
							md-diameter="75"
						/>
					</div>
					<div class="pending-message">
						<p class="mdc-typography--body2">{{ pendingMessage }}</p>
					</div>
				</div>
			</div>
			<div v-show="!pending && hasValue(pendingMessage)">
				<pre>{{ pendingMessage }}</pre>
			</div>
			<div v-show="!pending" class="table-container">
				<ActivityTable
					:configMode="configMode"
					:serverAttributes="serverAttributes"
					:activeInterfaces="activeInterfaces"
					:showCompleted="false"
					:showDetails="true"
					@refresh="requestRefresh"
					@request-action="requestAction($event)"
					@request-dialog="requestDialog($event)"
				/>
			</div>
		</PageDialog>
	</div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'

import ActivityTable from '../config/server/details/ActivityTable'
import PageDialog from '../common/PageDialog'
import ProgressSpinner from '../material/ProgressSpinner'
import ProvisioningAPI from '../common/ProvisioningAPI'

const rfcToUnix = (timestamp) => {
	if (isNaN(timestamp)) {
		return Math.round(new Date(timestamp).getTime() / 1000)
	}
	return timestamp
}

export default {
	name: 'ActivityDialog',
	mixins: [
		ProvisioningAPI,
	],
	components: {
		ActivityTable,
		PageDialog,
		ProgressSpinner,
	},
	props: [
		'selectedServer',
		'configMode',
		'open',
		'addClose',
		'title',
		'message',
	],
	data: function () {
		return {
			pending: true,
			pendingWatches: true,
			pendingMessage: undefined,

			activeWatches: [],
			serverByMode: {},
		}
	},
	watch: {
		open: function () {
			if (this.open) {
				this.dialogOpened()
			} else {
				this.dialogClosed()
			}
		},
		message: function (value) {
			this.pendingMessage = value
		},
		selectedServer: function () {
			if (this.selectedServer === undefined) {
				return
			}
			if (this.activeWatches.length) {
				this.stopWatchingForUpdates()
				this.startWatchingForUpdates()
			}
		},
		watchedServers: function (value) {
			if (this.hasValue(value) && value.length > 0) {
				this.receiveServerDataFromAPI(value, 'websocket')
				this.$nextTick(() => {
					if (this.pendingWatches) {
						this.pendingWatches = false
						this.pendingMessage = this.message
					}
				})
			}
		},
		pendingWatches: function (value) {
			if (value === false) {
				this.$nextTick(() => {
					this.pending = false
				})
			}
		},
		saveStatus: function (value) {
			if (value === undefined) {
				return
			}
			this.pendingMessage = value
		},
	},
	computed: {
		...mapGetters([
			'appConfig',
			'topicResources',
		]),

		selectedServerLowercase () {
			if (this.selectedServer === undefined) {
				return undefined
			}
			return this.selectedServer.toLowerCase()
		},

		dialogTitle () {
			if (this.selectedServer === undefined) {
				if (this.hasValue(this.title)) {
					return this.title
				}
				return undefined
			}
			let pop = this.popFromHostname(this.selectedServer)
			let name = this.nameFromHostname(this.selectedServer)
			let title = name + '.' + pop + ' - ' + this.configMode
			if (this.hasValue(this.title)) {
				title += ' - ' + this.title
			} else {
				title += ' - Activity'
			}
			return title.trim()
		},

		server () {
			if (!this.hasValue(this.serverByMode)) {
				return undefined
			}
			if (!this.hasValue(this.serverByMode[this.configMode])) {
				return undefined
			}
			return this.serverByMode[this.configMode]
		},
		serverAttributes () {
			if (!this.hasValue(this.server) || !this.hasValue(this.server.attributes)) {
				return undefined
			}
			return this.server.attributes
		},

		watchedServers () {
			let result = []
			this.activeWatches.forEach((watch) => {
				let servers = this.topicResources(watch.topic)
				if (servers !== undefined) {
					// console.debug('watchedServers', watch.topic, servers)
					let server = servers[watch.id]
					if (server !== undefined) {
						result.push(server)
					}
				}
			})
			return result
		},

		activeInterfaces () {
			return ['progress']
		},

	},
	beforeDestroy () {
		this.reset()
	},
	methods: {
		...mapActions([
			'watch',
			'rewatch',
			'unwatch',
		]),

		reset () {
			this.stopWatchingForUpdates()
			this.pending = true
			this.pendingWatches = true
			this.pendingMessage = undefined
			this.serverByMode = {}
		},

		dialogOpened () {
			console.debug('[DEBUG] ActivityDialog: opened')
			this.reset()
			if (this.selectedServer === undefined) {
				console.error('[ERROR] ActivityDialog: dialogOpened: selectedServer is undefined')
				return
			}
			this.startWatchingForUpdates()
		},
		dialogClosed () {
			console.debug('[DEBUG] ActivityDialog: closed')
			this.reset()
		},

		topicToMode (topic) {
			let mode = topic.replace('v1-', '')
			if (mode.includes('-')) {
				mode = mode.split('-')[0]
			}
			return mode
		},
		modeToTopic (mode) {
			switch (mode) {
			case 'agent':
			case 'haproxy':
			case 'nginx':
				return 'v1-' + mode
			default:
				return 'v1-' + mode + '-server'
			}
		},

		startWatchingForUpdates () {
			if (this.selectedServerLowercase === undefined) {
				console.error('[ERROR] ActivityDialog: startWatchingForUpdates: selectedServerLowercase is undefined')
				return
			}
			this.pendingWatches = true
			this.pendingMessage = 'Loading...'
			let mode = this.configMode
			let desiredWatch = {
				'topic': this.modeToTopic(mode),
				'id': this.selectedServerLowercase,
			}
			console.error('[ERROR] ActivityDialog: startWatchingForUpdates: desiredWatch:', desiredWatch)
			let existingIndex = this.activeWatches.findIndex(watch => {
				return watch.topic === desiredWatch.topic && watch.id === desiredWatch.id
			})
			if (existingIndex === -1) {
				this.activeWatches.push(desiredWatch)
				this.watch(desiredWatch)
			} else {
				this.rewatch(desiredWatch)
			}
		},
		stopWatchingForUpdates () {
			let previousWatches = this.activeWatches
			this.$set(this, 'activeWatches', [])
			previousWatches.forEach(watch => {
				this.unwatch(watch)
			})
		},

		receiveServerDataFromAPI (serversList, method) {
			console.debug('[DEBUG] ActivityDialog: receiveServerDataFromAPI', method, serversList.length, 'servers')
			let found = false
			serversList.forEach(server => {
				if (server.id === this.selectedServerLowercase) {
					found = true

					// Thaw frozen objects so we can modify them as needed
					if (Object.isFrozen(server)) {
						server = Object.assign({}, server)
					}

					// Extract configMode from the type field
					server.configMode = this.topicToMode(server.type)

					// if (server.configMode === 'nginx') {
					// 	console.debug('received',
					// 		this.nameFromHostname(server.id) + '.' + this.popFromHostname(server.id),
					// 		server.configMode, 'data via', method,
					// 		'for', server.modified,
					// 	)
					// }

					// discard older server data
					let existingServer = this.serverByMode[server.configMode]
					if (existingServer !== undefined) {
						let existingLastModified = rfcToUnix(existingServer.modified)
						let newLastModified = rfcToUnix(server.modified)
						if (newLastModified < existingLastModified) {
							// console.warn('received', server.configMode, 'data via', method, 'is older than existing data')
							return
						}
					}

					// Store the server data
					this.$set(this.serverByMode, server.configMode, server)

				}
			})
			if (!found) {
				return 'data not found for ' + this.selectedServerLowercase // ERROR - server not found
			}
			return undefined
		},


		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		shortPopCode (pop) {
			return pop.replace(/[0-9]/g, '')
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},

		requestRefresh () {
			console.error('[ERROR] ActivityDialog: requestRefresh not implemented')
		},
		requestAction (args) {
			console.error('[ERROR] ActivityDialog: requestAction not implemented:', args)
		},
		requestDialog (args) {
			console.error('[ERROR] ActivityDialog: requestDialog not implemented:', args)
		},

	}

}

</script>

<style scoped lang="scss">

.pending-container {
	min-height: 10vh;
}

.pending {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pending-message {
	margin-top: 2em;
	display: block;
	clear: both;
}

.table-container {
	display: block;
	min-height: 30vh;
	height: 100%;
}

</style>
