<template>
	<div><!-- This template is not used (because this is a mixin) --></div>
</template>

<script>

import StorageMixin from './StorageMixin.vue'

export default {
	name: 'RecentlyAccessedMixin',
	mixins: [
		StorageMixin
	],
	methods: {

		loadRecentlyAccessed (pluralNoun) {
			let key = 'recently-accessed-' + pluralNoun
			let storedList = this.loadStoredValue(key, [])

			let cookieJson = this.$cookie.get(this.storageKeyPrefix + key)
			if (this.hasValue(cookieJson)) {
				let cookieList = JSON.parse(cookieJson)
				cookieList.forEach(cookieItem => {
					let index = storedList.indexOf(cookieItem)
					if (index !== -1) {
						storedList.splice(index, 1)
					}
				})
				storedList = cookieList.concat(storedList)
			}

			return storedList
		},
		updateRecentlyAccessed (pluralNoun, entityId) {
			let key = 'recently-accessed-' + pluralNoun
			let storedList = this.loadRecentlyAccessed(pluralNoun)

			if (entityId !== undefined) {
				let index = storedList.indexOf(entityId)
				if (index > -1) {
					storedList.splice(index, 1)
				}
				storedList.unshift(entityId)
			}

			// store last 25 in persistent storage
			storedList = storedList.slice(0, 25)
			this.storePersistent(key, storedList)

			// store last 10 in cookies
			storedList = storedList.slice(0, 10)
			this.$cookie.set(
				this.storageKeyPrefix + key,
				JSON.stringify(storedList),
				{
					expires: 1,
					domain: '.cachefly.com'
				},
			)
		},

		loadRecentlyAccessedAccounts () {
			return this.loadRecentlyAccessed('accounts')
		},
		updateRecentlyAccessedAccounts () {
			let accountId = undefined
			if (this.accountId !== undefined) {
				console.debug('ACCESSED account', this.accountId)
				accountId = this.accountId
			}
			this.updateRecentlyAccessed('accounts', accountId)
		},

		loadRecentlyAccessedServices () {
			return this.loadRecentlyAccessed('services')
		},
		updateRecentlyAccessedServices () {
			let serviceId = undefined
			if (this.serviceId !== undefined) {
				console.debug('ACCESSED service', this.serviceId)
				serviceId = this.serviceId
			}
			this.updateRecentlyAccessed('services', serviceId)
		},

	},
}

</script>
