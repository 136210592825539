<template>
	<div class="config-explore mdc-typography">
		<div class="max-width">
			<div v-if="apiError !== undefined && apiError !== ''" class="loading api-error">
				<p>{{ apiError }}</p>
			</div>
			<div v-else-if="firstLoading" v-show="firstLoading" class="loading">
				<ProgressSpinner v-bind:mdDiameter="75"/>
			</div>
		</div>
		<div v-if="!firstLoading">
			<div class="side-by-side-container">
				<div class="side-by-side-left">

					<TreeList
						v-bind:parentSelected="treeSelect"
						v-bind:treeData="treeData"
						v-on:tree-list-click="clickHandler"
					/>

				</div>
				<div class="side-by-side-drag"
					 draggable="true"
					 v-on:drag.prevent="handleResizeBar"
				>
				</div>
				<div class="side-by-side-right">
					<div v-show="fileError !== undefined" class="file-error">
						<div class="error-card mdc-card mdc-card--outlined mdc-typography">
							<div class="card-primary-content">
								<table class="error-table error-table-with-actions mdc-typography--body1">
									<tbody>
									<tr>
										<th>File</th>
										<td>{{ fileName }}</td>
									</tr>
									<tr>
										<th>Error</th>
										<td>{{ fileError }}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div v-show="fileContents !== undefined && fileError === undefined" class="file-contents">
						<label class="mdc-text-field mdc-text-field--outlined mdc-text-field--textarea"
							   data-mdc-auto-init="MDCTextField"
						>
                                <span class="mdc-notched-outline">
                                    <span class="mdc-notched-outline__leading"></span>
                                    <span class="mdc-notched-outline__notch">
                                        <span class="mdc-floating-label">{{ fileName }}</span>
                                    </span>
                                    <span class="mdc-notched-outline__trailing"></span>
                                </span>
							<textarea
								v-model="fileContents"
								class="mdc-text-field__input"
								readonly
							></textarea>
						</label>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import * as _ from 'lodash'
import autoInit from '@material/auto-init'

import PublicAPI from '../common/PublicAPI'
import ProvisioningAPI from '../common/ProvisioningAPI'
import ProgressSpinner from '../material/ProgressSpinner'
import TreeList from '../services/accounts/TreeList'

export default {
	mixins: [
		PublicAPI,
		ProvisioningAPI
	],
	components: {
		ProgressSpinner,
		TreeList,
	},
	props: [
		'serverType',   // e.g. nginx
		'dataCategory', // e.g. data
		'apiEndpoint',  // e.g. templates
	],
	data () {
		return {
			loading: true,
			firstLoading: true,
			treeData: undefined,
			treeSelect: undefined,
			fileAttributes: undefined,
			resizeBar: undefined,
		}
	},
	watch: {
		serverType () {
			this.reset()
		},
		dataCategory () {
			this.reset()
		},
		apiEndpoint () {
			this.reset()
		},
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		apiUrlPrefix () {
			if (this.apiEndpoint === undefined) {
				console.error('apiEndpoint property is undefined')
				return undefined
			}
			if (this.serverType !== undefined) {
				return this.serverType + '/' + this.apiEndpoint
			} else {
				return this.apiEndpoint
			}
		},
		fileContents () {
			if (this.fileAttributes !== undefined) {
				if (this.fileAttributes.contents !== undefined) {
					return this.fileAttributes.contents
				}
			}
			return undefined
		},
		fileError () {
			if (this.fileAttributes !== undefined) {
				if (this.fileAttributes.error !== undefined && this.fileAttributes.error !== '') {
					return this.fileAttributes.error
				}
			}
			return undefined
		},
		fileName () {
			if (this.fileAttributes !== undefined) {
				return this.fileBaseName(this.fileAttributes.path)
			}
			return undefined
		}
	},
	mounted () {
		this.loading = true
		this.$nextTick(() => {
			this.loadPath('/')
		})
		autoInit()  // Material Design Components
	},
	updated () {
		autoInit()  // Material Design Components
		this.$nextTick(() => {
			let element = this.$el.querySelector('.mdc-text-field__input')
			if (element !== undefined && element !== null) {
				element.focus()
				element.scrollTop = 0
				element.selectionEnd = 0
			} else {
				this.$el.focus()
			}
		})
	},
	methods: {
		reset () {
			// this.loading = false
			this.treeData = undefined
			this.fileAttributes = undefined
			this.$nextTick(() => {
				this.loadPath('/')
			})
		},
		loadPath (path) {
			if (this.apiUrlPrefix === undefined) {
				console.error('unable to load as url prefix is undefined')
				return
			}
			console.log('Loading ' + path)
			this.$nextTick(() => {

				this.callProvisioningApi(this.apiUrlPrefix + path, {
					headers: {'Accept': 'application/json'} // Prevent html rendering from API server
				}).then(data => {
					if (data !== undefined) {
						if (data.length === 1) {
							if (data[0].type.endsWith('-file')) {
								this.fileAttributes = data[0].attributes
								return
							}
						}
						this.calculateTreeData(data)
						this.firstLoading = false
					} else {
						console.log('undefined response from API - result is empty')
						this.calculateTreeData([])
						this.firstLoading = false
					}
				}).catch((error) => {
					console.error(error)
					this.firstLoading = true
				})

			})

		},
		calculateTreeData (apiData) {
			let root = {
				id: '/',
				list: []
			}
			apiData.forEach(item => {
				let baseName = this.fileBaseName(item.attributes.path)
				if (baseName === '' || baseName.startsWith('.')) {
					return
				}

				let pathParts = item.attributes.path.split('/')
				// console.debug(pathParts);

				let parent = root
				pathParts.forEach(segment => {
					if (segment.id === '') {
						parent = root
					}
					parent.list.forEach(child => {
						if (child.title === segment) {
							parent = child
						}
					})
				})
				// console.debug(item.attributes.path, 'parent', parent.id)

				if (item.type.endsWith('-directory')) {
					parent.list.push({
						id: item.attributes.path + '/',
						icon: 'folder',
						title: baseName,
						text: item.attributes.modified,
						list: []
					})
				} else if (item.type.endsWith('-file')) {
					parent.list.push({
						id: item.attributes.path,
						icon: 'insert_drive_file',
						title: baseName,
						text: item.attributes.modified,
						list: []
					})
				} else {
					console.error('unrecognised type', item)
				}
				parent.list = _.orderBy(parent.list, 'icon', 'title')

			})

			let tree = root.list
			tree = _.orderBy(tree, 'icon', 'title')
			this.treeData = tree
		},
		fileBaseName (path) {
			let base = String(path).substring(path.lastIndexOf('/') + 1)
			// if (base.lastIndexOf(".") !== -1) {
			//     base = base.substring(0, base.lastIndexOf("."));
			// }
			return base
		},
		clickHandler (event) {
			// console.debug('DEBUG', 'clickHandler', event)
			this.fileAttributes = undefined
			event.forEach(element => {
				if (element.charAt(element.length - 1) !== '/') {
					this.loadPath(element)
				}
			})
		},
		handleResizeBar (event) {
			if (event.offsetX < -200 || event.offsetX > 200) {
				console.debug('ignoring offsetX that is out of bounds')
				return
			}

			let left = this.$el.querySelector('.side-by-side-left')
			let right = this.$el.querySelector('.side-by-side-right')

			let total = left.clientWidth + right.clientWidth
			let newLeft = left.clientWidth + event.offsetX
			let newRight = total - newLeft

			// console.debug(
			//     'x', event.offsetX,
			//     'left', {old: left.clientWidth, new: newLeft},
			//     'right', {old: right.clientWidth, new: newRight},
			// );

			left.style.width = newLeft + 'px'
			right.style.width = newRight + 'px'

		}
	}
}

</script>

<style lang="scss" scoped>

.config-explore .max-width {
	width: 100%;
}

.config-explore .loading {
	text-align: center;
	vertical-align: middle;
}

.config-explore .api-error {
	text-align: center;
	vertical-align: middle;
	background-color: darkred;
	color: white;
	font-size: 130%;
	padding: 1em;
	border: 3px dashed red;
	height: 3em;
}

.config-explore h5 {
	margin: 0.5em 0 0;
}

.config-explore .variables-message {
	margin: 8px 0 32px;
}

.config-explore .variables-table {
	margin: 8px 0 32px;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
}

.config-explore .variables-table td, .config-explore .variables-table th {
	border: 1px solid darkgrey;
	padding: 0 5px;
	text-align: left;
	vertical-align: top;
}

.config-explore .variables-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
	width: 30%;
}

.config-explore .variables-table pre {
	margin: 0;
	padding: 0;
	display: inline-block;
	max-width: 100%;
	overflow-y: auto;
	overflow-x: auto;
	white-space: pre;
}

.config-explore .delimiter {
	opacity: 0;
}

.config-explore .variables-table tr {
	background-color: white;
}

.config-explore .variables-table tr:hover {
	background-color: lightyellow;
}

.config-explore .hostname {
	margin: 2px 0;
}

.config-explore .actions {
	max-width: 300px;
	width: 120px;
}

.config-explore .file-contents {
	width: 100%;
	background-color: white;
}

.config-explore .file-contents .mdc-text-field {
	width: 100%
}

.config-explore .file-contents textarea {
	width: 100%;
	height: 60vh;
	resize: none;
	font-family: monospace;
	white-space: pre;
	margin-top: 8px;
	margin-left: 2px;
	margin-right: 2px;
	margin-bottom: 2px;
}

.config-explore .error-card {
	padding: 0 0.5em;
	margin-bottom: 6px;
}


.config-explore .error-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.config-explore .error-table-with-actions {
	margin: 8px 0 8px;
}

.config-explore .error-table th {
	text-align: left;
	vertical-align: top;
	font-weight: normal;
}

.config-explore .error-table td, .config-explore .error-table th {
	border: 1px solid #e0e0e0;
	border-left: 0;
	border-right: 0;
	padding: .5em;
}

.config-explore .error-table tr {
	background-color: white;
}

.config-explore .error-table tr:hover {
	background-color: lightyellow;
}

.config-explore .side-by-side-container {
	display: flex;
	margin: 0.25em;
	max-width: 100%;
}

.config-explore .side-by-side-drag {
	background-color: lightgray;
	opacity: 0.1;
	cursor: col-resize;
	width: 1%;
}

.config-explore .side-by-side-left, .config-explore .side-by-side-right {
	resize: none;
	width: 49.5%;
	overflow: hidden;
	padding-top: 5px;
}

</style>
