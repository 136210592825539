<template>
	<div id="toasts" class="toasts">
		<div v-for="notice in notices" :key="notice.id" v-show="!hidden">
			<div
				:style="cardStyle(notice)"
				class="mdc-card mdc-card--outlined toast-card mdc-typography"
			>
				<p v-for="(line, index) in messageLines(notice)" :key="index">{{ line }}</p>
			</div>
		</div>
		<div id="toastActions" class="toastActions" v-if="notices.length > 0">
			<button class="mdc-icon-button material-icons button-right dense-icon-button"
					title="Toggle visibility of all toast notifications"
					@click.prevent="toggleToastsHidden"
			>
				<div class="mdc-icon-button__ripple"></div>
				{{ visibilityIcon }}
			</button>
		</div>
	</div>
</template>

<script>

import StorageMixin from '../common/StorageMixin'

export default {
	name: 'ToastNotifications',
	mixins: [
		StorageMixin
	],
	props: [
		'notice',
	],
	data () {
		return {
			notices: [],
			hidden: false,
		}
	},
	computed: {
		visibilityIcon () {
			return this.hidden ? 'expand_more' : 'expand_less'
		},
	},
	watch: {
		notice (newNotice) {
			if (newNotice === undefined) {
				return
			}
			let index = this.notices.findIndex((notice) => {
				return notice.id === newNotice.id
			})
			if (index !== -1) {
				if (newNotice.message === undefined) {
					this.notices.splice(index, 1)
				} else {
					this.$set(this.notices, index, newNotice)
				}
			} else {
				if (newNotice.message !== undefined) {
					this.notices.push(newNotice)
				}
			}
		},
	},
	mounted () {
		this.hidden = this.loadStoredValue('toasts-hidden', false)
	},
	methods: {
		cardStyle (notice) {
			let style = {}
			if (notice === undefined) {
				return style
			}
			if (notice.backgroundColor !== undefined) {
				style['background-color'] = notice.backgroundColor
				style['border-color'] = notice.backgroundColor
			}
			if (notice.textColor !== undefined) {
				style['color'] = notice.textColor
			}
			return style
		},
		messageLines (notice) {
			if (notice === undefined || notice.message === undefined) {
				return []
			}
			if (Array.isArray(notice.message)) {
				return notice.message
			}
			return notice.message.split('\n')
		},
		toggleToastsHidden () {
			this.hidden = !this.hidden
			this.storeTransient('toasts-hidden', this.hidden)
		},
	},
}

</script>

<style lang="scss" scoped>

#toasts {
	position: fixed;
	top: 1em;
	right: 0;
	z-index: 1000;
	padding: 1em;
}

.toast-card {
	padding: 1em;
	margin-bottom: 1em;

	box-shadow: 0 0 1em #888888;
	min-width: 400px;

	background-color: #333333;
	border-color: #333333;
	color: rgba(255, 255, 255, 0.87);
}

.toast-card p {
	margin: 0;
	margin-bottom: 1em;
}

.toast-card p:last-child {
	margin: 0;
}

#toastActions {
	float: right;
}

</style>
