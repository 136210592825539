<template>
	<div class="results mdc-typography">
		<div v-if="loading || result === undefined" class="loading">
			<ProgressSpinner
				v-show="loading || result === undefined"
				v-bind:md-diameter="45"
			/>
		</div>
		<table v-else class="basic-table mdc-typography--body1">
			<tr>
				<th colspan="2">{{ title }}</th>
			</tr>
			<tr v-for="stat in stats" v-bind:key="stat.name">
				<th>{{ formatTitle(stat.name) }}</th>
				<td>{{ stat.value }}</td>
			</tr>
		</table>
	</div>
</template>

<script>

/*
result = {
  "id": "status",
  "type": "v1-pubapi-service-statistics",
  "attributes": {
    "ACTIVE": {
      "kind": "status",
      "name": "ACTIVE",
      "value": 2152
    },
    "DEACTIVATED": {
      "kind": "status",
      "name": "DEACTIVATED",
      "value": 4555
    },
    "DELETED": {
      "kind": "status",
      "name": "DELETED",
      "value": 7
    }
  }
}
 */

import ProgressSpinner from '../../material/ProgressSpinner'

export default {
	name: 'StatsTable',
	components: {
		ProgressSpinner
	},
	props: [
		'result',
		'loading',
		'prefix'
	],
	data () {
		return {
			showIds: false,
			showSerials: false,
			showHostnames: true,
		}
	},
	computed: {
		stats () {
			if (this.result === undefined) {
				return undefined
			}
			return this.result.attributes
		},
		title () {
			if (this.result === undefined) {
				return ''
			}
			let text = this.result.id
			if (this.prefix !== undefined) {
				text = this.prefix + '-' + text
			}
			return this.formatTitle(text)
		}
	},
	methods: {
		formatTitle (text) {
			text = text.replaceAll('-', ' ')
			text = text.replaceAll('_', ' ')
			text = this.titleCase(text)
			text = text.replace('Api', 'API')
			return text
		},
		titleCase (str) {
			if (str === undefined || str === null) {
				return str
			}
			return str.toLowerCase()
				.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
				.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
		},
	}
}

</script>

<style lang="scss" scoped>

.basic-table {
	margin: 8px 0 32px;
	width: 100%;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
}

.basic-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
	text-align: left;
}

.basic-table td, .basic-table th {
	border: 1px solid darkgrey;
	padding: .5em;
}

.basic-table td {
	width: 35%;
}

.basic-table tr {
	background-color: white;
}

.basic-table tr:hover {
	background-color: lightyellow;
}

.loading {
	width: 100%;
	text-align: center;
	padding-top: 2em;
}

</style>
