<template>
	<div>
		<div v-if="!hidden"
			 v-show="!hidden"
			 class="drawer-select-container"
		>
			<Select v-bind:defaultIndex="item.defaultIndex"
					v-bind:desiredValue="item.desiredValue"
					v-bind:label="title"
					v-bind:options="item.options"
					v-on:update:selectedIndex="selfEmit('update:selectedIndex', $event)"
					v-on:update:selectedValue="selfEmit('update:selectedValue', $event); clickHandler($event)"
			/>
		</div>
	</div>
</template>

<script>

import Select from './Select'

export default {
	components: {
		Select,
	},
	props: [
		'id',
		'title', // aka label
		'hidden',
		'item',
		'callback',
		// 'options',
		// 'defaultIndex',
		// 'desiredValue',
	],
	data () {
		return {
			selectedIndex: undefined,
			selectedValue: undefined,
		}
	},
	methods: {
		selfEmit (key, value) {
			this.$emit(key, value)
		},
		clickHandler (event) {
			// console.debug('drawerItem clickHandler', event, this)
			if (this.callback === undefined) {
				this.$emit('drawer-click', [event, this])
			} else {
				this.callback([event, this])
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.drawer-select-container {
	/* padding: 0 16 px; */
}

.drawer-select-container * {
	width: 100%;
}

</style>
