<template>
	<div><!-- this template is not used --></div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
	computed: {
		...mapGetters([
			'appConfigEnvironment',
			'appConfig',
			'appConfigLoaded',
			'appConfigError',
		]),

		// Code for the currently active environment.
		appEnvironment () {
			return this.appConfigEnvironment
		},

		// Name for the currently active environment.
		appEnvironmentName () {
			return this.getAppEnvironmentName(this.appConfigEnvironment)
		},

		// Name for the currently active environment, formatted as a suffix.
		appEnvironmentSuffix () {
			if (!String(this.appEnvironmentName).startsWith('Production')) {
				return ' (' + this.appEnvironmentName + ' Environment)'
			}
			return ''
		},

		// Boolean indicating if the currently active environment is production.
		prodEnv () {
			switch (this.appConfigEnvironment) {
			case 'production':
				return true
			case 'production-direct':
				return true
			default:
				return false
			}
		},

		// Boolean indicating if the currently active environment is staging.
		stageEnv () {
			switch (this.appConfigEnvironment) {
			case 'staging':
				return true
			default:
				return false
			}
		},

		// Boolean indicating if the currently active environment is development.
		devEnv () {
			switch (this.appConfigEnvironment) {
			case 'development':
				return true
			case 'localhost':
				return true
			default:
				return false
			}
		},

		availableAppEnvironments () {
			let result = []

			// read from config
			if (this.appConfigLoaded) {
				let configuredEnvironments = this.appConfig('ENVIRONMENTS')
				if (configuredEnvironments !== undefined) {

					configuredEnvironments = String(configuredEnvironments)
					if (configuredEnvironments.length > 0) {
						configuredEnvironments = configuredEnvironments.split(',')
						configuredEnvironments.forEach(env => {
							result.push(this.normaliseEnvironmentCode(env))
						})
					}
				}
			}

			// deduplicate
			result = result.filter(function (item, pos, self) {
				return self.indexOf(item) === pos
			})

			// fallback
			if (result.length === 0) {
				result = [
					'production',
					'staging',
					'development',
				]
			}

			return result
		}

	},
	methods: {

		// Converts input into an environment name
		getAppEnvironmentName (environment) {
			switch (this.normaliseEnvironmentCode(environment)) {
			case 'production':
				return 'Production'

			case 'production-direct':
				return 'Production (bypass CDN)'

			case 'staging':
				return 'Staging'

			case 'development':
				return 'Development'

			default:
				return 'Unknown'
			}
		},

		// Coerces recognised input into a preferred code
		normaliseEnvironmentCode (environment) {
			environment = String(environment).trim().toLowerCase()
			switch (environment) {
			case 'prod':
			case 'production':
				return 'production'

			case 'prod-direct':
			case 'production-direct':
			case 'production (bypass cdn)':
				return 'production-direct'

			case 'stage':
			case 'staging':
				return 'staging'

			case 'dev':
			case 'develop':
			case 'development':
				return 'development'

			case '': // default when no input specified
				return 'development'

			default: // unknown environment
				return environment
			}
		},

	}
}

</script>
