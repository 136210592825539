<template>
	<span v-bind:class="{'timestamp-invisible': !isVisible}"
		  v-bind:title="timeFormatted"
	>{{ renderedText }}</span>
</template>

<script>

// Moment
import * as moment from 'moment'

export default {
	name: 'Timestamp',
	props: [
		'unixtime',
		'wide'
	],
	data () {
		return {
			format: 'dddd, MMMM Do YYYY, HH:mm:ss', // 'YYYY-MM-DD HH:mm:ss'
			now: undefined,
			timeObject: undefined,
			timeFormatted: undefined,
			timeFromNow: undefined,
			intervalTime: 10000,  // 10 seconds
			intervalHandler: undefined,
			intersectionObserver: undefined,
			isVisible: false
		}
	},
	computed: {
		renderedText () {
			if (this.wide && String(this.timeFromNow).length > 0) {
				return this.timeFormatted + ' (' + this.timeFromNow + ')'
			} else {
				return this.timeFromNow
			}
		}
	},
	watch: {
		intervalTime (value) {
			this.setupInterval()
		},
		unixtime (value) {
			this.updateTimeObject()
			this.updateTimeFormatted()
			this.updateTimeFromNow()
		},
		format (value) {
			this.updateTimeFormatted()
		},
		now (value) {
			this.updateTimeFromNow()
		}
	},
	created () {
		this.updateTimeFormatted()
		this.getNow()
	},
	mounted () {
		this.setupIntersectionObserver()
	},
	beforeDestroy () {
		this.stopIntersectionObserver()
		this.stopInterval()
	},
	// beforeDestroyed () {
	// 	this.stopIntersectionObserver()
	// 	this.stopInterval()
	// },
	methods: {
		setupIntersectionObserver () {
			if (this.intersectionObserver === undefined) {
				this.intersectionObserver = new window.IntersectionObserver(([entry]) => {
					this.isVisible = entry.isIntersecting
					if (this.isVisible) {
						this.getNow()
						this.setupInterval()
					} else {
						this.stopInterval()
					}
				}, {
					root: null,
					threshold: 0.2, // set offset 0.2 means trigger if at least 20% of element in viewport
				})
				this.intersectionObserver.observe(this.$el)
			}
		},
		setupInterval () {
			this.stopInterval()
			if (this.intervalTime < 500) {
				this.intervalTime = 500
			}
			this.intervalHandler = setInterval(
				this.getNow,
				this.intervalTime
			)
		},
		stopIntersectionObserver () {
			if (this.intersectionObserver !== undefined) {
				this.intersectionObserver.unobserve(this.$el)
				this.intersectionObserver.disconnect()
			}
		},
		stopInterval () {
			if (this.intervalHandler !== undefined) {
				clearInterval(this.intervalHandler)
				this.intervalHandler = undefined
			}
		},
		getNow () {
			this.now = moment.unix(moment.default().unix())
		},
		updateTimeObject () {
			if (this.unixtime >= 1) {
				this.timeObject = moment.unix(this.unixtime).utc()
			}
		},
		updateTimeFormatted () {
			if (this.timeObject === undefined) {
				this.updateTimeObject()
			}
			if (this.timeObject !== undefined) {
				this.timeFormatted = this.timeObject.format(this.format)
			} else {
				this.timeFormatted = ''
			}
		},
		updateTimeFromNow () {
			if (this.timeObject === undefined) {
				this.updateTimeObject()
			}
			if (this.now === undefined) {
				this.getNow()
			}
			if (this.timeObject !== undefined && this.now !== undefined) {
				this.timeFromNow = this.timeObject.from(this.now)
			} else {
				this.timeFromNow = ''
			}
		}
	}
}
</script>

<style>

.timestamp-invisible {
	opacity: 0.35;
}

</style>
