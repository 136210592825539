<template>
	<div class="ellipsis-container">
		<span class="ellipsis"></span>
	</div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

export default {
	name: 'Ellipsis'
}

</script>

<style scoped lang="scss">

.ellipsis-container {
	margin: 2em;
}

.ellipsis {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	display: block;
	margin: 15px auto;
	position: relative;
	color: #448aff;
	box-sizing: border-box;
	animation: anim-ellipsis 2s linear infinite;
}

@keyframes anim-ellipsis {
	0% {
		box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
	}
	25% {
		box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
	}
	50% {
		box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
	}
	75% {
		box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
	}
	100% {
		box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
	}
}

</style>
