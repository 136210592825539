<template>
	<div><!-- this template is not used --></div>
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'

const apiVersion = '2.5'

export default {
	computed: {
		...mapGetters([
			'appConfig',
			'ssoCredential',
		]),
	},
	data () {
		return {
			loading: false,
			apiError: '',
		}
	},
	methods: {

		// --------------------------------------------------------------------

		addPublicApiAuthentication (config, viaProxy = false, authToken = undefined) {
			if (config.baseURL === undefined) {

				// Ensure that we have an authToken to use ...
				if (authToken === undefined) {
					// This token is specific to the currently active user,
					// which means that our audit trial is annotated with
					// administrator identity info for each change made using
					// this token.
					authToken = this.ssoCredential['token']
				}

				// Check if we wish to use the Provisioning API to Public API proxy
				if (viaProxy) {
					// This proxy is the older way of doing things. It provides access to the
					// Public API without needing your own Public API credentials/user; instead
					// (as long as you are authenticated with the Provisioning API) you can just
					// piggyback of the Provisioning API's credentials for access the Public API.
					//
					// Note that this proxy filters the responses to remove secrets. Therefore,
					// access to secrets is only available through your own dedicated credentials.

					config.baseURL = this.appConfig('API_BASE_URL') + 'api/services/pubapi/'
					config.withCredentials = true

					// If you have credentials you may pass these to the proxy and the proxy will
					// use them instead of its own credentials. However, the filtering out of
					// secrets is still performed.
					if (authToken !== undefined) {
						config.headers['X-Proxy-Authorization'] = 'Bearer ' + authToken
					}

				} else {

					// Use the URL which the SSO script told us to use ...
					config.baseURL = this.ssoCredential['url'] + 'api/2.5/'

					// Unless it didn't give us one, in that case use our local config ...
					if (config.baseURL === undefined || config.baseURL === null || config.baseURL === '') {
						config.baseURL = this.appConfig('PUB_API_URL') + 'api/2.5/'
					}

					// All requests must be authenticated with a token.
					config.headers['Authorization'] = 'Bearer ' + authToken
				}
			}
			return config
		},
		callPublicApi (url, config, quietly = undefined, authToken = undefined) {
			if (quietly !== undefined && quietly === false) {
				this.loading = true
			}
			this.apiError = ''
			return new Promise((resolve, reject) => {
				if (!(typeof config === 'object' && config !== null)) {
					config = {method: 'GET'}
				}
				if (config.headers === undefined) {
					config.headers = {}
				}
				if (config.headers['Accept'] === undefined) {
					config.headers['Accept'] = 'application/json'
				}
				config = this.addPublicApiAuthentication(config, false, authToken)
				config.url = url
				return axios.request(
					config
				).then(response => {
					// console.debug(response)
					return resolve(response.data)

				}).catch(error => {
					// console.debug(error)
					if (error.response) {
						// The request was made and the server responded with a
						// status code that falls out of the range of 2xx.
						console.error('API ERROR', JSON.stringify(error.response.data))
						if (error.response.data.message !== undefined) {
							this.apiError = '' +
								'API: ' + error.response.status +
								': ' + error.response.data.message
						} else if (typeof (error.response.data) === 'string' && error.response.data.length < 128) {
							this.apiError = '' +
								'API: ' + error.response.status +
								': ' + error.response.data
						} else {
							// This message might get big :(
							this.apiError = '' +
								'API: ' + error.response.status +
								': ' + JSON.stringify(error.response.data, null, 2)
						}

					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in
						// the browser and an instance of http.ClientRequest
						// in node.js
						this.apiError = 'No response from API'

					} else {
						// Something happened in setting up the request.
						this.apiError = 'Error calling API: ' + error.message
					}

					console.error(this.apiError)
					return reject(this.apiError)
				}).finally(() => {
					this.loading = false

				})

			}) // end new promise

		},

		callPublicApiExpanded (url, config, quietly) {

			const maxCalls = 100 // recursive call limit
			let countCalls = 0

			if (quietly !== undefined && quietly === false) {
				this.loading = true
			}

			if (url.includes('offset=')) {
				return Promise.reject('callPublicApiExpanded: url with an offset is not permitted')
			}

			let mergedResponse = {
				'meta': {
					'count': 0,
					'limit': 0,
				},
				'data': [],
			}

			const handleResponse = apiResponse => {
				apiResponse.data.forEach(item => mergedResponse.data.push(item))

				countCalls++
				if (countCalls > maxCalls) {
					throw ('too many calls')
				}

				let count = apiResponse.meta.count
				let limit = apiResponse.meta.limit
				let offset = apiResponse.meta.offset

				mergedResponse.meta.count = count
				mergedResponse.meta.limit = limit

				if (offset + limit >= count) {
					return mergedResponse
				}

				let nextPageOffset = offset + limit
				let nextPageUrl = url
				if (nextPageUrl.includes('?')) {
					if (!nextPageUrl.includes('limit=')) {
						nextPageUrl += '&limit=' + limit
					}
					nextPageUrl += '&offset=' + nextPageOffset
				} else {
					nextPageUrl += '?limit=' + limit + '&offset=' + nextPageOffset
				}

				return this.callPublicApi(nextPageUrl, config, quietly).then(handleResponse)
			}

			return this.callPublicApi(url, config, quietly).then(handleResponse)
		},

		// --------------------------------------------------------------------

	}
}

</script>
