<script>

import {mapActions, mapGetters} from 'vuex'

import CacheWatchMixin from './CacheWatchMixin.vue'
import ProvisioningAPI from './ProvisioningAPI'
import QueueProcessorMixin from './QueueProcessorMixin'

const airportsQueuePrefix = 'airports-'
const provApiAirportType = 'v1-geo-airport'
const provApiAirportItemKeyPrefix = 'provapi-' + provApiAirportType + '-'
const provApiAirportTypeTag = 'provapi-' + provApiAirportType
const provApiAirportItemTags = [
	'provapi',
	provApiAirportTypeTag,
]

export default {
	name: 'CachedProvAirportsMixin',
	mixins: [
		CacheWatchMixin,
		ProvisioningAPI,
		QueueProcessorMixin,
	],
	data () {
		return {
			cachedAirportsWatched: false,
		}
	},
	computed: {
		...mapGetters('cache', {
			'cacheLoad': 'load', // this getter returns a function
		}),

		geo () {
			const provApiAirportTypeTag = 'provapi-v1-geo-airport'
			if (this.watchedItems === undefined) {
				return {}
			}
			let result = {}
			Object.values(this.watchedItems).forEach(item => {
				if (item.tags.includes(provApiAirportTypeTag)) {
					result[item.value.id] = item.value.attributes
				}
			})
			return result
		},
		geoPending () {
			let pending = []
			this.queueRunning.forEach(id => {
				if (id.startsWith(airportsQueuePrefix)) {
					let airportId = id.substr(airportsQueuePrefix.length)
					if (!pending.includes(airportId)) {
						pending.push(airportId)
					}
				}
			})
			this.queueWaiting.forEach(item => {
				if (item.id.startsWith(airportsQueuePrefix)) {
					let airportId = item.id.substr(airportsQueuePrefix.length)
					if (!pending.includes(airportId)) {
						pending.push(airportId)
					}
				}
			})
			return pending
		},

	},
	methods: {
		...mapActions('cache', {
			'cacheStore': 'store',
		}),

		normaliseAirportId (airportId) {
			return airportId.toUpperCase().replace(/[^A-Z]/g, '')
		},

		watchAirports () {
			return this.watchCachedItem(provApiAirportTypeTag).then(() => {
				this.cachedAirportsWatched = true
			})
		},
		watchAirportId (airportId) {
			airportId = this.normaliseAirportId(airportId)
			return this.watchCachedItem(provApiAirportItemKeyPrefix + airportId)
		},
		getCachedAirport (airportId) {
			airportId = this.normaliseAirportId(airportId)
			// console.debug('getCachedAirport:', airportId)
			let key = provApiAirportItemKeyPrefix + airportId
			let item = this.watchedItems[key]
			if (item === undefined) {
				return item
			}
			return item.value
			// return this.getCachedItemValue(provApiAirportItemKeyPrefix + airportId)
		},
		setCachedAirport (airport, airportId) {
			if (airport === undefined) {
				return
			}
			if (airportId === undefined) {
				airportId = airport.id
			} else {
				airport.id = airportId
			}
			this.cacheStore({
				key: provApiAirportItemKeyPrefix + airportId,
				tags: provApiAirportItemTags,
				skipEncryption: true,
				value: airport,
			})
		},

		cachedAirportsQueueWorker (airportId) {
			airportId = airportId.substr(airportsQueuePrefix.length)
			airportId = this.normaliseAirportId(airportId)
			return this.watchAirports().then(() => {
				if (this.getCachedAirport(airportId) !== undefined) {
					return
				}
				return this.cacheLoad(
					provApiAirportItemKeyPrefix + airportId
				).then(airport => {
					if (airport === undefined) {
						return this.downloadAirportFromProvisioningApi(airportId)
					}
				})
			}).catch(error => {
				console.debug('RecentlyAccessedAirports:', 'queueWorker:', 'error:', error)
				return this.downloadAirportFromProvisioningApi(airportId)
			})
		},
		downloadAirportFromProvisioningApi (airportId) {
			// NB. This is the replacement for callProvisioningApiForGeoData
			airportId = this.normaliseAirportId(airportId)
			// console.debug('downloading:', airportId)
			return this.callProvisioningApi('geo/airports/' + airportId + '/').then(apiResponse => {
				apiResponse.forEach(airport => {
					if (airport.type === provApiAirportType) {
						this.setCachedAirport(airport)

						// Historically, we have used the wrong airport ID in some places. This saves
						// the data we received (under a different ID) to the ID that we queried for.
						// This allows us to continue using the wrong ID but still have useful data.
						// e.g. TKO is replaced with NRT
						if (airport.id !== airportId) {
							this.setCachedAirport(airport, airportId)
						}
					}
				})
			})
		},
		lookupAirportAttributes (query) {
			// NB. This is the replacement for lookupGeoData
			if (query === 'CACHENETWORKS') {
				return this.lookupAirportAttributes('ORD')
			}
			query = this.normaliseAirportId(query)
			let cachedData = this.getCachedAirport(query)
			if (cachedData === undefined) {
				this.watchAirports().then(() => {
					if (this.getCachedAirport(query) === undefined) {
						this.queueAdd(airportsQueuePrefix + query, this.cachedAirportsQueueWorker)
					}
				})
				return undefined
			}
			if (cachedData.attributes === undefined) {
				return undefined
			}
			return cachedData.attributes
		},

	}
}

</script>
