<template>
	<div>
		<!--
			 This template is not used (because this is a mixin).
			 When using this mixin, add the following to your component's template:
			 -->
		<div>
			<FormDialog
				:open.sync="smFormOpen"
				:pending.sync="smFormPending"
				:title="smFormTitle"
				:actions="smFormActions"
				:items="smFormItems"
			/>
		</div>
		<!-- You also need the template from the ConfirmDialogMixin -->
	</div>
</template>

<script>

import ConfirmDialogMixin from './ConfirmDialogMixin'
import FormCallbacksMixin from './forms/FormCallbacksMixin'
import FormDialog from './forms/FormDialog.vue'
import PublicAPI from './PublicAPI'
import StorageMixin from './StorageMixin'
import TLSProfilesDataMixin from '../tlsprofiles/TLSProfilesDataMixin'

const configurationModes = [
	{
		code: 'MANUAL',
		name: 'Manual',
		explanation: 'Configured manually by CacheFly Engineering Team (probably via Ansible)',
		icon: 'front_hand',
		deprecated: false,
		alpha: false,
		beta: false,
		selectableOption: false,
		supportsOldPortal: false,
		supportsRules: false,
		supportsOptions: false,
		supportsPlugin: false,
		rulesOptional: false,
		provisioningModes: [
			'DO_NOT_CONFIGURE',
			'DNS_ONLY',
		],
	},
	{
		code: 'OLD_PORTAL',
		name: 'Old Portal',
		explanation: 'Configured via the old Portal',
		icon: 'looks_one',
		deprecated: true,
		alpha: false,
		beta: false,
		selectableOption: false,
		supportsOldPortal: true,
		supportsRules: false,
		supportsOptions: false,
		supportsPlugin: false,
		rulesOptional: false,
		provisioningModes: [
			'DO_NOT_CONFIGURE',
		],
	},
	{
		code: 'API_RULES',
		name: 'Rules',
		explanation: 'Configured in Portal 2 using V2 Rules',
		icon: 'looks_two',
		deprecated: false,
		alpha: false,
		beta: true,
		selectableOption: true,
		supportsOldPortal: false,
		supportsRules: true,
		supportsOptions: false,
		supportsPlugin: false,
		rulesOptional: false,
		provisioningModes: [
			'MANAGED_BACKEND',
			'EXPERIMENTAL',
		],
	},
	{
		code: 'API_OPTIONS',
		name: 'Options',
		explanation: 'Configured in Portal 2 using V1 Options',
		icon: 'filter_1',
		deprecated: false,
		alpha: false,
		beta: false,
		selectableOption: true,
		supportsOldPortal: false,
		supportsRules: false,
		supportsOptions: true,
		supportsPlugin: false,
		rulesOptional: false,
		provisioningModes: [
			'HYBRID_FALLBACK',
		],
	},
	{
		code: 'API_RULES_AND_OPTIONS',
		name: 'Rules & Options',
		explanation: 'Configured in Portal 2 with both Rules and Options',
		icon: 'filter_2',
		deprecated: false,
		alpha: false,
		beta: true,
		selectableOption: true,
		supportsOldPortal: false,
		supportsRules: true,
		supportsOptions: true,
		supportsPlugin: false,
		rulesOptional: true,
		provisioningModes: [
			'HYBRID_FALLBACK',
			'EXPERIMENTAL',
		],
	},
	{
		code: 'API_SERVICE_PLUGIN_NO_CONFIG',
		name: 'Service Plugin',
		explanation: 'Configured in Portal 2 using a Service Plugin with no configuration',
		icon: 'extension',
		deprecated: false,
		alpha: true,
		beta: false,
		selectableOption: false,
		supportsOldPortal: false,
		supportsRules: false,
		supportsOptions: false,
		supportsPlugin: true,
		rulesOptional: false,
		provisioningModes: [
			'EXPERIMENTAL',
		],
	},
]

const provisioningModes = [
	{
		code: 'DO_NOT_CONFIGURE',
		name: 'Do Not Configure',
		explanation: 'Ignored by V2 Automated Config Provisioning',
		icon: 'block',
		deprecated: false,
		alpha: false,
		beta: false,
		selectableOption: false,
		neverSelectable: false,
		configuresCdn: false,
		configuresDns: false,
	},
	{
		code: 'DNS_ONLY',
		name: 'DNS Only',
		explanation: 'Only DNS is configured by V2',
		icon: 'dns',
		deprecated: false,
		alpha: false,
		beta: false,
		selectableOption: false,
		neverSelectable: false,
		configuresCdn: false,
		configuresDns: true,
	},
	{
		code: 'MANAGED_BACKEND',
		name: 'Managed Backend',
		explanation: 'Use V2 exclusively',
		icon: 'published_with_changes',
		deprecated: false,
		alpha: false,
		beta: true,
		selectableOption: true,
		neverSelectable: false,
		configuresCdn: true,
		configuresDns: true,
	},
	{
		code: 'HYBRID_FALLBACK',
		name: 'Hybrid Fallback',
		explanation: 'Use V2 then fallback to V1 if necessary',
		icon: 'flaky',
		deprecated: false,
		alpha: false,
		beta: false,
		selectableOption: true,
		neverSelectable: false,
		configuresCdn: true,
		configuresDns: true,
	},
	{
		code: 'EXPERIMENTAL',
		name: 'Experimental',
		explanation: 'Use V2 with experimental features enabled',
		icon: 'auto_mode',
		deprecated: false,
		alpha: false,
		beta: true,
		selectableOption: true,
		neverSelectable: false,
		configuresCdn: true,
		configuresDns: true,
	},
	{
		code: 'SERVICE_DISABLED',
		name: 'Service Disabled',
		explanation: 'Enforced service disablement in nginx config',
		icon: 'delete_forever',
		deprecated: false,
		alpha: true,
		beta: false,
		selectableOption: false,
		neverSelectable: true,
		configuresCdn: true,
		configuresDns: true,
	},
]

const rulesAlwaysVisible = 'Always show Rules'
const rulesHiddenWhileNotInUse = 'Hide Rules when not in use'
const rulesAlwaysHidden = 'Always hide Rules'

export default {
	name: 'ServiceModesMixin',
	mixins: [
		ConfirmDialogMixin,
		FormCallbacksMixin,
		PublicAPI,
		StorageMixin,
		TLSProfilesDataMixin,
	],
	components: {
		FormDialog,
	},

	// NB. Defining props in mixins works, but it prevents you from using the
	// mixin in a component which defines that name as a data field. So in
	// general, don't do it.
	//
	// props: [
	// 	'accountData',              // Details of currently selected account
	// 	'serviceData',              // Details of currently selected service (if any)
	// ],
	//

	data () {
		return {
			smFormOpen: false,
			smFormPending: false,
			smFormTitle: undefined,

			smFormIncludeModes: true,
			smFormIncludeTlsProfile: true,
			smFormIncludeAllowInvalid: true,
			smFormServiceContext: false,
			smFormAllowAll: false,

			smFormConfigurationModeTitle: 'Configuration Mode',
			smFormConfigurationModeOptions: undefined,
			smFormConfigurationModeDesired: undefined,

			smFormHideRulesHiddenText: rulesHiddenWhileNotInUse,
			smFormHideRulesDesired: false,

			smFormProvisioningModeTitle: 'Provisioning Mode',
			smFormProvisioningModeOptions: undefined,
			smFormProvisioningModeDesired: undefined,

			smFormDnsGroupTitle: 'DNS Group',
			smFormDnsGroupOptions: undefined,
			smFormDnsGroupDesired: undefined,
			smFormDnsGroupSelected: undefined,

			smFormTlsProfileTitle: 'TLS Profile',
			smFormTlsProfileOptions: undefined,
			smFormTlsProfileDesired: undefined,

			smFormShowOnlyDoNotConfigureWarning: false,
			smFormShowOnlyDnsWarning: false,
			smFormAllowInvalidCombinations: false,

			smAccountChangeCallbacks: [],
			smServiceChangeCallbacks: [],

		}
	},
	watch: {
		smFormOpen (value) {
			// Always reset pending when dialog is opened or closed
			this.smFormPending = false

			// Reset the form callbacks when the dialog is closed
			if (value === false) {
				this.clearFormCallbacks()
			}
		},

		accountData () {
			let callbacks = this.smAccountChangeCallbacks
			this.smAccountChangeCallbacks = []
			this.$nextTick(() => {
				callbacks.forEach(callback => {
					this.$nextTick(() => {
						try {
							callback()
						} catch (e) {
							console.error('Error in accountData change callback', e)
						}
					})
				})
			})
		},
		serviceData () {
			let callbacks = this.smServiceChangeCallbacks
			this.smServiceChangeCallbacks = []
			this.$nextTick(() => {
				callbacks.forEach(callback => {
					this.$nextTick(() => {
						try {
							callback()
						} catch (e) {
							console.error('Error in serviceData change callback', e)
						}
					})
				})
			})
		},

	}, // end of watch
	computed: {

		///////////////////////////////////////////////////////////////
		// Specific to the platform

		// An array of all the configuration mode codes (ENUM possible value)
		allConfigurationModes () {
			let result = []
			configurationModes.forEach(meta => {
				result.push(meta.code)
			})
			return result
		},

		// An array of all the provisioning mode codes (ENUM possible value)
		allProvisioningModes () {
			let result = []
			provisioningModes.forEach(meta => {
				result.push(meta.code)
			})
			return result
		},

		///////////////////////////////////////////////////////////////
		// Specific to the account

		accountId () {
			if (this.accountData === undefined) {
				return undefined
			}
			return this.accountData._id
		},
		accountUid () {
			if (this.accountData === undefined) {
				return undefined
			}
			return this.accountData.uid
		},

		defaultConfigurationMode () { // Default Configuration Mode on this account
			if (this.accountData === undefined) {
				return undefined
			}
			return this.accountData.defaultConfigurationMode
		},
		defaultHideRules () { // Default Hide Rules on this account
			if (this.accountData === undefined) {
				return false
			}
			return this.accountData.defaultHideRules
		},
		defaultProvisioningMode () { // Default Provisioning Mode on this account
			if (this.accountData === undefined) {
				return undefined
			}
			return this.accountData.defaultProvisioningMode
		},

		defaultTlsProfileId () { // Default TLS Profile on this account
			if (this.accountData === undefined) {
				return undefined
			}
			return this.objectId(this.accountData.defaultTlsProfile)
		},
		defaultTlsProfileName () {
			return this.getTlsProfileName(this.defaultTlsProfileId)
		},
		defaultTlsProfileCode () {
			return this.getTlsProfileCode(this.defaultTlsProfileId)
		},

		defaultDnsGroup () { // Default DNS Group on this account
			return this.getDnsGroup(this.defaultTlsProfileId)
		},
		defaultDnsGroupText () {
			return this.getDnsGroupText(this.defaultTlsProfileId)
		},

		///////////////////////////////////////////////////////////////
		// Specific to a service

		serviceId () {
			if (this.serviceData === undefined) {
				return undefined
			}
			return this.serviceData._id
		},
		serviceUid () {
			if (this.serviceData === undefined) {
				return undefined
			}
			return this.serviceData.uid
		},
		serviceIsApiConfigured () {
			if (this.serviceData === undefined) {
				return false
			}
			return this.serviceData.provisioningMode !== 'DO_NOT_CONFIGURE' && this.serviceData.provisioningMode !== 'DNS_ONLY'
		},
		serviceBackendConfiguresOnlyDns () {
			if (this.serviceData === undefined) {
				return false
			}
			return this.serviceData.provisioningMode === 'DNS_ONLY'
		},

		currentConfigurationMode () {
			if (this.hasValue(this.serviceData)) {
				if (this.hasValue(this.serviceData.configurationMode)) {
					return this.serviceData.configurationMode
				}
			}
			return this.defaultConfigurationMode
		},

		currentHideRules () {
			if (this.hasValue(this.serviceData)) {
				if (this.hasValue(this.serviceData.hideRules)) {
					let hideRules = this.serviceData.hideRules
					hideRules = (hideRules === true) || hideRules === this.trueFalseWords(true)
					return hideRules
				}
			}
			return this.defaultHideRules
		},

		currentProvisioningMode () {
			if (this.hasValue(this.serviceData)) {
				if (this.hasValue(this.serviceData.provisioningMode)) {
					return this.serviceData.provisioningMode
				}
			}
			return this.defaultProvisioningMode
		},

		currentTlsProfileId () {
			if (this.hasValue(this.serviceData)) {
				if (this.hasValue(this.serviceData.tlsProfile)) {
					return this.objectId(this.serviceData.tlsProfile)
				}
				if (this.hasValue(this.serviceData.currentTlsProfile)) {
					return this.objectId(this.serviceData.currentTlsProfile)
				}
				if (this.hasValue(this.serviceData.defaultTlsProfile)) {
					return this.objectId(this.serviceData.defaultTlsProfile)
				}
			}
			return this.defaultTlsProfileId
		},
		currentTlsProfileName () {
			return this.getTlsProfileName(this.currentTlsProfileId)
		},
		currentTlsProfileCode () {
			return this.getTlsProfileCode(this.currentTlsProfileId)
		},

		currentDnsGroup () {
			return this.getDnsGroup(this.currentTlsProfileId)
		},
		currentDnsGroupText () {
			return this.getDnsGroupText(this.currentTlsProfileId)
		},

		///////////////////////////////////////////////////////////////

		smFormActions () {
			return [
				{
					id: 'cancel',
					label: 'Cancel',
					disabled: this.smFormPending,
					callback: () => {
						this.smFormOpen = false
					},
				},
				{
					id: 'save',
					label: 'Save',
					disabled: this.smFormPending,
					callback: () => {
						this.smFormPending = true
						return new Promise(resolve => {
							if (this.smFormServiceContext) {
								return this.saveServiceModeChanges().then(resolve)
							} else {
								return this.saveDefaultServiceModes().then(resolve)
							}
						}).then(() => {
							this.smFormOpen = false
						}).catch(error => {
							console.error('[ERROR] Error saving service mode changes', error)
						}).finally(() => {
							this.smFormPending = false
						})
					}
				},
			]
		},
		smFormItems () {
			let result = []
			if (!this.smFormOpen) {
				return result
			}
			if (!this.smFormServiceContext) {
				result.push(
					{
						id: 'formIntro',
						component: 'FormCard',
						hidden: false,
						text: 'This is the configuration which will be used for newly created services within this account.' +
							' Existing services will not be affected by changes to these defaults.',
					})
			}
			if (this.smFormIncludeModes) {
				result.push(...this.getModesFormItems())
			}
			if (this.smFormIncludeTlsProfile) {
				result.push(...this.getTlsProfileFormItems())
			}
			if (this.smFormIncludeAllowInvalid) {
				result.push(...this.getAllowInvalidFormItems())
			}
			return result
		},

		///////////////////////////////////////////////////////////////

	}, // end of computed
	methods: {

		///////////////////////////////////////////////////////////////
		// Public API Interactions

		changePublicApiEntity (entityType, entityId, updateRequest) {
			console.debug('[DEBUG] Public API entity update', entityType, entityId, updateRequest)
			return this.callPublicApi(
				'admin/' + entityType + '/' + entityId + '/',
				{
					method: 'PUT',
					data: updateRequest
				}
			).then(data => {
				console.debug(entityType, entityId, 'updated', data)
				return data

			}).then(() => {
				return this.refreshPublicApiEntity(entityType, entityId)
			})
		},
		changeAccount (updateRequest) {
			return this.changePublicApiEntity('accounts', this.accountId, updateRequest)
		},
		changeService (updateRequest) {
			return this.changePublicApiEntity('services', this.serviceId, updateRequest)
		},
		refreshPublicApiEntity (entityType, entityId) {
			return new Promise(resolve => {
				switch (entityType) {
				case 'accounts':
					this.smAccountChangeCallbacks.push(resolve)
					this.$emit('refresh-account', entityId)
					break
				case 'services':
					this.smServiceChangeCallbacks.push(resolve)
					this.$emit('refresh-service', entityId)
					break

				default:
					resolve()
				}
			})
		},

		saveDefaultServiceModes () {
			if (this.smFormServiceContext) { // Called the wrong method for this mode
				return Promise.reject('incorrect save call')
			}
			this.adoptSmFormValuesAsDesired()
			return this.changeAccount({
				defaultConfigurationMode: this.smFormConfigurationModeDesired,
				defaultHideRules: this.smFormHideRulesDesired,
				defaultProvisioningMode: this.smFormProvisioningModeDesired,
				defaultTlsProfile: this.smFormTlsProfileDesired,
			})
		},
		saveServiceModeChanges () {
			if (!this.smFormServiceContext) { // Called the wrong method for this mode
				return Promise.reject('incorrect save call')
			}
			this.adoptSmFormValuesAsDesired()
			return this.changeService({
				configurationMode: this.smFormConfigurationModeDesired,
				hideRules: this.smFormHideRulesDesired,
				provisioningMode: this.smFormProvisioningModeDesired,
				tlsProfile: this.smFormTlsProfileDesired,
			})
		},

		///////////////////////////////////////////////////////////////
		// Small Helper Methods

		titleCase (str) {
			if (str === undefined || str === null) {
				return str
			}
			return str.toLowerCase()
				.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
				.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
		},
		trueFalseWords (value) {
			return value ? 'TRUE' : 'FALSE'
		},

		objectId (obj) {
			if (typeof obj === 'string') {
				return obj
			}
			if (typeof obj === 'object') {
				if (obj === null) {
					return null
				}
				if (obj._id !== undefined) {
					return obj._id
				}
			}
			return undefined
		},

		modeStatusText (meta, isDefault = false) {
			let info = ''
			let hasStatus = false
			if (meta.deprecated) {
				info += '\nStatus:\nDeprecated'
				hasStatus = true
			} else if (meta.alpha) {
				info += '\nStatus:\nAlpha'
				hasStatus = true
			} else if (meta.beta) {
				info += '\nStatus:\nBeta'
				hasStatus = true
			}
			if (isDefault) {
				if (!hasStatus) {
					info += '\nStatus:\nAccount default'
				} else {
					info += ', Account default'
				}
			}
			return info
		},

		///////////////////////////////////////////////////////////////
		// Configuration Mode

		getConfigurationModeMeta (configurationMode) {
			return configurationModes.find(mode => mode.code === configurationMode)
		},

		getConfigurationModeName (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? undefined : meta.name
		},
		getConfigurationModeExplanation (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			if (meta === undefined) {
				return 'Contact development team for more details'
			}
		},
		getConfigurationModeIcon (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? 'incomplete_circle' : meta.icon
		},
		getConfigurationModeCompatibleProvisioningModes (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? [] : meta.provisioningModes
		},
		getConfigurationModeOptions (serviceContext = false, allowAll = false) {
			let result = []
			configurationModes.forEach(meta => {
				let text = meta.name
				if (meta.deprecated) {
					text += ' (deprecated)'
				} else if (meta.alpha) {
					text += ' (alpha)'
				} else if (meta.beta) {
					text += ' (beta)'
				}
				if (serviceContext) {
					if (this.isDefaultConfigurationMode(meta.code)) {
						text += ' (account default)'
					}
					if (this.isCurrentConfigurationMode(meta.code)) {
						text += ' (current)'
					}
				}
				result.push({
					text: text,
					value: meta.code,
					disabled: !allowAll && !meta.selectableOption,
				})
			})
			return result
		},
		updateConfigurationModeOptions (serviceContext = false, allowAll = false) {
			allowAll = allowAll || this.smFormAllowInvalidCombinations
			this.updateSmFormOptionsData(
				'ConfigurationMode',
				this.smFormConfigurationModeTitle,
				undefined,
				() => {
					return this.getConfigurationModeOptions(serviceContext, allowAll)
				},
				'configurationMode',
			)
		},

		isDefaultConfigurationMode (configurationMode) {
			return configurationMode === this.defaultConfigurationMode
		},
		isCurrentConfigurationMode (configurationMode) {
			return configurationMode === this.currentConfigurationMode
		},
		isDeprecatedConfigurationMode (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.deprecated
		},
		isAlphaConfigurationMode (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? true : meta.alpha
		},
		isBetaConfigurationMode (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? true : meta.beta
		},

		doesConfigurationModeSupportOldPortal (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.supportsOldPortal
		},
		doesConfigurationModeSupportRules (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.supportsRules
		},
		doesConfigurationModeSupportOptions (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.supportsOptions
		},
		doesConfigurationModeSupportPlugin (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.supportsPlugin
		},
		areRulesOptionalForConfigurationMode (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			return meta === undefined ? false : meta.rulesOptional
		},

		showConfigurationModeInfo (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			let name = 'Configuration Mode: ' + configurationMode
			let info = ''
			if (meta !== undefined) {
				name = 'Configuration Mode: ' + meta.name
				info = 'Explanation:\n' + meta.explanation + '\n' +
					'Code:\n' + meta.code
				info += this.modeStatusText(meta, this.isDefaultConfigurationMode(meta.code))
			} else {
				info = 'Code: ' + configurationMode + '\n' +
					'Unknown mode. Please contact development team.'
			}
			this.openConfirmDialog(name, info)
			this.confirmShowCancel = false
		},

		///////////////////////////////////////////////////////////////
		// Provisioning Mode

		getProvisioningModeMeta (provisioningMode) {
			return provisioningModes.find(mode => mode.code === provisioningMode)
		},
		getProvisioningModeName (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			return meta === undefined ? undefined : meta.name
		},
		getProvisioningModeExplanation (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			if (meta === undefined) {
				return 'Contact development team for more details'
			}
			return meta.explanation
		},
		getProvisioningModeIcon (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			return meta === undefined ? 'incomplete_circle' : meta.icon
		},
		getProvisioningModeCompatibleConfigurationModes (provisioningMode) {
			let result = []
			configurationModes.forEach(meta => {
				if (meta.provisioningModes.includes(provisioningMode)) {
					result.push(meta.code)
				}
			})
			return result
		},
		getProvisioningModeOptions (serviceContext = false, allowAll = false) {
			let result = []
			provisioningModes.forEach(meta => {
				let text = meta.name
				if (meta.deprecated) {
					text += ' (deprecated)'
				} else if (meta.alpha) {
					text += ' (alpha)'
				} else if (meta.beta) {
					text += ' (beta)'
				}
				if (serviceContext) {
					if (this.isDefaultProvisioningMode(meta.code)) {
						text += ' (account default)'
					}
					if (this.isCurrentProvisioningMode(meta.code)) {
						text += ' (current)'
					}
				}
				result.push({
					text: text,
					value: meta.code,
					disabled: meta.neverSelectable || (!allowAll && !meta.selectableOption),
				})
			})
			return result
		},
		updateProvisioningModeOptions (serviceContext = false, allowAll = false) {
			allowAll = allowAll || this.smFormAllowInvalidCombinations
			this.updateSmFormOptionsData(
				'ProvisioningMode',
				this.smFormProvisioningModeTitle,
				undefined,
				() => {
					return this.getProvisioningModeOptions(serviceContext, allowAll)
				},
				'provisioningMode',
			)
		},

		isDefaultProvisioningMode (provisioningMode) {
			return provisioningMode === this.defaultProvisioningMode
		},
		isCurrentProvisioningMode (provisioningMode) {
			return provisioningMode === this.currentProvisioningMode
		},
		isDeprecatedProvisioningMode (ProvisioningMode) {
			let meta = this.getProvisioningModeMeta(ProvisioningMode)
			return meta === undefined ? false : meta.deprecated
		},
		isAlphaProvisioningMode (ProvisioningMode) {
			let meta = this.getProvisioningModeMeta(ProvisioningMode)
			return meta === undefined ? true : meta.alpha
		},
		isBetaProvisioningMode (ProvisioningMode) {
			let meta = this.getProvisioningModeMeta(ProvisioningMode)
			return meta === undefined ? true : meta.beta
		},

		doesProvisioningModeConfigureCdn (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			return meta === undefined ? false : meta.configuresCdn
		},
		doesProvisioningModeConfigureDns (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			return meta === undefined ? false : meta.configuresDns
		},

		getModesFormItems () {
			return [
				{
					id: 'configurationMode',
					component: 'FormBasicSelect',
					title: this.smFormConfigurationModeTitle,
					help: 'This describes how the customer may configure the service.',
					monospaced: false,
					hidden: false,
					options: this.smFormConfigurationModeOptions,
					desiredValue: this.smFormConfigurationModeDesired,
					valueHandler: text => {
						console.debug('[DEBUG] FORM VALUE: configurationMode', text)
						return new Promise(resolve => {
							if (!this.smFormAllowInvalidCombinations) {
								this.$nextTick(() => this.confineProvisioningMode(text))
							}
							resolve()
						})
					},
				},
				{
					id: 'hideRules',
					component: 'FormBasicSelect',
					title: 'Display Rules in Portal',
					monospaced: false,
					hidden: false,
					options: this.getHideRulesOptions(), // These options don't change
					desiredValue: this.smFormHideRulesDesired,
					valueHandler: text => {
						console.debug('[DEBUG] FORM VALUE: hideRules', text)
						return new Promise(resolve => {
							resolve()
						})
					},
				},
				{
					id: 'provisioningMode',
					component: 'FormBasicSelect',
					title: this.smFormProvisioningModeTitle,
					help: 'This describes how the backend automation should provision the service.',
					monospaced: false,
					hidden: false,
					options: this.smFormProvisioningModeOptions,
					desiredValue: this.smFormProvisioningModeDesired,
					valueHandler: text => {
						console.debug('[DEBUG] FORM VALUE: provisioningMode', text)
						return new Promise(resolve => {
							this.$nextTick(() => {

								let doesNotConfigureWarning = false
								let configuresOnlyDnsWarning = false
								if (!this.doesProvisioningModeConfigureCdn(text)) {
									if (this.doesProvisioningModeConfigureDns(text)) {
										configuresOnlyDnsWarning = true
										this.dnsIsBeingConfigured()
									} else {
										doesNotConfigureWarning = true
										this.dnsIsNotBeingConfigured()
									}
								} else {
									this.dnsIsBeingConfigured()
								}

								this.smFormShowOnlyDoNotConfigureWarning = doesNotConfigureWarning
								this.smFormShowOnlyDnsWarning = configuresOnlyDnsWarning

								if (!this.smFormAllowInvalidCombinations) {
									this.confineConfigurationMode(text)
								}
							})
							resolve()
						})
					},
				}
			]
		},

		showProvisioningModeInfo (provisioningMode) {
			let meta = this.getProvisioningModeMeta(provisioningMode)
			let name = 'Provisioning Mode: ' + provisioningMode
			let info = ''
			if (meta !== undefined) {
				name = 'Provisioning Mode: ' + meta.name
				info = 'Explanation:\n' + meta.explanation + '\n' +
					'Code:\n' + meta.code
				info += this.modeStatusText(meta, this.isDefaultProvisioningMode(meta.code))
			} else {
				info = 'Code: ' + provisioningMode + '\n' +
					'Unknown mode. Please contact development team.'
			}
			this.openConfirmDialog(name, info)
			this.confirmShowCancel = false
		},

		///////////////////////////////////////////////////////////////
		// TLS Profiles

		getDnsGroup (tlsProfileId) {
			return this.getTlsProfileField(tlsProfileId, 'group')
		},
		getDnsGroupText (tlsProfileId) {
			return this.titleCase(this.getDnsGroup(tlsProfileId))
		},
		getDnsGroupOptions (serviceContext = false) {
			let result = undefined
			if (this.hasValue(this.tlsProfilesData)) {
				result = []
				let groups = []
				this.tlsProfilesData.data.forEach(profile => {
					if (!groups.includes(profile.group)) {
						groups.push(profile.group)
					}
				})
				groups = groups.sort()
				groups.forEach(group => {
					let label = this.titleCase(group)
					if (serviceContext) {
						if (this.isDefaultDnsGroup(group)) {
							label += ' (account default)'
						}
						if (this.isCurrentDnsGroup(group)) {
							label += ' (current)'
						}
					}
					result.push({
						text: label,
						value: group,
					})
				})
				result.sort((a, b) => {
					if (a.text < b.text) {
						return -1
					}
					if (a.text > b.text) {
						return 1
					}
					return 0
				})
			}
			return result
		},
		updateDnsGroupOptions (serviceContext = false) {
			this.updateSmFormOptionsData(
				'DnsGroup',
				this.smFormDnsGroupTitle,
				undefined,
				() => {
					return this.getDnsGroupOptions(serviceContext)
				},
				'dnsGroup',
			)
		},

		isDefaultDnsGroup (dnsGroup) {
			return dnsGroup === this.defaultDnsGroup
		},
		isCurrentDnsGroup (dnsGroup) {
			return dnsGroup === this.currentDnsGroup
		},

		getTlsProfileData (tlsProfileId) {
			let result = undefined
			if (this.hasValue(this.tlsProfilesData)) {
				this.tlsProfilesData.data.forEach(profile => {
					if (profile._id === tlsProfileId) {
						result = profile
					}
				})
			}
			return result
		},
		getTlsProfileField (tlsProfileId, field) {
			let profile = this.getTlsProfileData(tlsProfileId)
			if (profile !== undefined) {
				return profile[field]
			}
			return undefined
		},
		getTlsProfileName (tlsProfileId) {
			return this.getTlsProfileField(tlsProfileId, 'name')
		},
		getTlsProfileCode (tlsProfileId) {
			return this.getTlsProfileField(tlsProfileId, 'code')
		},
		getTlsProfileOptions (targetDnsGroup, serviceContext = false) {
			let result = undefined
			if (this.hasValue(this.tlsProfilesData)) {
				result = []
				this.tlsProfilesData.data.forEach(profile => {
					if (profile.group === targetDnsGroup) {
						let text = profile.name
						if (serviceContext) {
							if (this.isDefaultTlsProfile(profile._id)) {
								text += ' (account default)'
							}
							if (this.isCurrentTlsProfile(profile._id)) {
								text += ' (current)'
							}
						}
						result.push({
							text: text,
							value: profile._id,
						})
					}
				})
			}
			return result
		},
		updateTlsProfileOptions (group = undefined, serviceContext = false) {
			if (group !== undefined) {
				this.smFormDnsGroupSelected = group
			}
			if (this.smFormDnsGroupSelected === undefined) {
				return
			}
			this.updateSmFormOptionsData(
				'TlsProfile',
				'TLS Profile',
				'TLS Profile (' + this.smFormDnsGroupSelected + ')',
				() => {
					return this.getTlsProfileOptions(this.smFormDnsGroupSelected, serviceContext)
				},
				'tlsProfile',
			)
		},

		isDefaultTlsProfile (tlsProfileId) {
			return tlsProfileId === this.defaultTlsProfileId
		},
		isCurrentTlsProfile (tlsProfileId) {
			return tlsProfileId === this.currentTlsProfileId
		},

		getTlsProfileFormItems () {
			return [
				{
					id: 'dnsGroup',
					component: 'FormBasicSelect',
					title: this.smFormDnsGroupTitle,
					monospaced: false,
					hidden: false,
					options: this.getDnsGroupOptions(this.smFormServiceContext), // These options are loaded from the API
					desiredValue: this.smFormDnsGroupDesired,
					valueHandler: text => {
						console.debug('[DEBUG] FORM VALUE: dnsGroup', text)
						return new Promise(resolve => {
							this.updateTlsProfileOptions(text, this.smFormServiceContext)
							resolve()
						})
					},
				},
				{
					id: 'tlsProfile',
					component: 'FormBasicSelect',
					title: this.smFormTlsProfileTitle,
					monospaced: false,
					hidden: false,
					options: this.smFormTlsProfileOptions, // The options change depending on DNS Group
					desiredValue: this.currentTlsProfileId,
					valueHandler: text => {
						console.debug('[DEBUG] FORM VALUE: tlsProfile', text)
						return new Promise(resolve => {
							resolve()
						})
					},
				},
				{
					id: 'doNotConfigureWarning',
					component: 'FormCard',
					hidden: !this.smFormShowOnlyDoNotConfigureWarning,
					text: 'Service specific configuration will not be automatically generated or deployed.' +
						' Configuration must be managed manually.' +
						'\n\n' +
						'DNS will follow default fallback behaviour (unless you override manually).'
				},
				{
					id: 'dnsOnlyWarning',
					component: 'FormCard',
					hidden: !this.smFormShowOnlyDnsWarning,
					text: 'Only DNS configuration will be updated automatically.' +
						'\n\n' +
						'Other service specific configuration will not be automatically generated or deployed.'
				},
			]
		},

		///////////////////////////////////////////////////////////////
		// Rules Visibility and Confinement

		getHideRulesOptions () {
			return [
				{
					text: rulesAlwaysVisible,
					value: this.trueFalseWords(false),
					disabled: false,
				},
				{
					text: this.smFormHideRulesHiddenText,
					value: this.trueFalseWords(true),
					disabled: false,
				},
			]
		},
		setHideRulesHiddenText (text) {
			this.$set(this, 'smFormHideRulesHiddenText', text)
		},

		rulesShouldBeHidden () {
			if (this.smFormAllowInvalidCombinations) {
				console.warn('[WARNING] Service Modes: Invalid combinations allowed')
				this.rulesVisibilityIsOptional()
				return
			}
			console.debug('rulesShouldBeHidden')
			this.setFormValue('hideRules', this.trueFalseWords(true))
			this.setHideRulesHiddenText(rulesAlwaysHidden)
			this.disableFormComponent('hideRules')
		},
		rulesShouldBeVisible () {
			if (this.smFormAllowInvalidCombinations) {
				console.warn('[WARNING] Service Modes: Invalid combinations allowed')
				this.rulesVisibilityIsOptional()
				return
			}
			console.debug('rulesShouldBeVisible')
			this.setFormValue('hideRules', this.trueFalseWords(false))
			this.setHideRulesHiddenText(rulesHiddenWhileNotInUse)
			this.disableFormComponent('hideRules')
		},
		rulesVisibilityIsOptional () {
			console.debug('rulesVisibilityIsOptional')
			this.setHideRulesHiddenText(rulesHiddenWhileNotInUse)
			this.enableFormComponent('hideRules')
		},

		dnsIsBeingConfigured () {
			console.debug('dnsIsBeingConfigured')
			this.enableFormComponent('dnsGroup')
			this.enableFormComponent('tlsProfile')
		},
		dnsIsNotBeingConfigured () {
			if (this.smFormAllowInvalidCombinations) {
				console.warn('[WARNING] Service Modes: Invalid combinations allowed')
				this.dnsIsBeingConfigured()
				return
			}
			console.debug('dnsIsNotBeingConfigured')
			this.disableFormComponent('dnsGroup')
			this.disableFormComponent('tlsProfile')
		},

		confineProvisioningMode (configurationMode) {
			let meta = this.getConfigurationModeMeta(configurationMode)
			if (meta === undefined) {
				console.warn('[WARNING] Service Modes: No configuration mode meta data for', configurationMode)
				return // Unknown configuration mode
			}
			let allowedModes = meta.provisioningModes
			let provisioningMode = this.getFormValue('provisioningMode')
			provisioningMode = this.selectMode(provisioningMode, allowedModes)
			this.setFormValue('provisioningMode', provisioningMode)

			if (!meta.supportsRules) {	     // Not Supported = Always hidden
				this.rulesShouldBeHidden()
			} else if (meta.rulesOptional) { // Optional = Optional
				this.rulesVisibilityIsOptional()
			} else {	                     // Required = Always visible
				this.rulesShouldBeVisible()
			}

		},
		confineConfigurationMode (provisioningMode) {
			let allowedModes = this.getProvisioningModeCompatibleConfigurationModes(provisioningMode)
			if (allowedModes.length === 0) {
				console.warn('[WARNING] Service Modes: No confinement data for provisioning mode', provisioningMode)
				return
			}
			let configurationMode = this.getFormValue('configurationMode')
			configurationMode = this.selectMode(configurationMode, allowedModes)
			this.setFormValue('configurationMode', configurationMode)
		},

		selectMode (currentMode, allowedModes) {
			if (this.smFormAllowInvalidCombinations) {
				console.warn('[WARNING] Service Modes: Invalid combinations allowed')
				return currentMode
			}
			if (allowedModes.includes(currentMode) || allowedModes.length === 0) {
				return currentMode
			}
			return allowedModes[0]
		},

		getAllowInvalidFormItems () {
			return [
				{
					id: 'allowInvalid',
					component: 'FormCheckbox',
					label: 'Allow Invalid Combinations (DANGER)',
					checkedDesiredValue: this.smFormAllowInvalidCombinations,
					valueHandler: value => {
						return new Promise(resolve => {
							console.log('allow invalid combinations was set to', value)
							this.$nextTick(() => {
								if (value === true) {
									let handled = false
									this.confirmCloseAction = () => {
										if (handled) {
											return
										}
										this.setFormValue('allowInvalid', false)
										this.confirmCloseAction = undefined
									}
									this.openConfirmDialog(
										'Allow Invalid Combinations',
										'This disables protections which ensure correct operation.' +
										' If you proceed you may cause unexpected behavior and prevent this service from working correctly.\n\n' +
										'Are you sure that you want to allow invalid combinations?',
										() => {
											this.$set(this, 'smFormAllowInvalidCombinations', true)
											this.rulesVisibilityIsOptional()
											this.$nextTick(this.updateAllSmFormOptions)
											handled = true
										},
										() => {
											this.setFormValue('allowInvalid', false)
											handled = true
										}
									)
								} else {
									let provisioningMode = this.getFormValue('provisioningMode')
									this.$set(this, 'smFormAllowInvalidCombinations', false)
									console.debug('confining to ', provisioningMode)
									this.confineConfigurationMode(provisioningMode)
									this.$nextTick(this.updateAllSmFormOptions)
								}
							})
							resolve()
						})
					},
				}
			]
		},

		///////////////////////////////////////////////////////////////
		// Form

		getSmFormValues () {
			return {
				'configurationMode': this.smFormConfigurationModeDesired,
				'hideRules': this.smFormHideRulesDesired,
				'provisioningMode': this.smFormProvisioningModeDesired,
				'dnsGroup': this.smFormDnsGroupDesired,
				'tlsProfile': this.smFormTlsProfileDesired,
			}
		},

		startEditDefaultServiceModes () {
			console.debug('[DEBUG] Edit Default Service Modes for Account', this.accountId, this.accountUid)

			this.smFormIncludeModes = true
			this.smFormIncludeTlsProfile = true
			this.smFormIncludeAllowInvalid = true
			this.smFormServiceContext = false       // << SERVICE CONTEXT = FALSE
			this.smFormAllowAll = false

			this.smFormTitle = 'New Service Defaults'
			this.smFormConfigurationModeDesired = this.defaultConfigurationMode
			this.smFormHideRulesDesired = this.defaultHideRules
			this.smFormProvisioningModeDesired = this.defaultProvisioningMode
			this.smFormDnsGroupDesired = this.getDnsGroup(this.defaultTlsProfileId)
			this.smFormTlsProfileDesired = this.defaultTlsProfileId

			console.debug('[DEBUG] New Service Defaults', this.getSmFormValues())

			this.smFormOpen = true
			this.$nextTick(this.updateAllSmFormOptions)
		},
		startEditServiceModesForService () {
			console.debug('[DEBUG] Edit Service Modes for Service', this.serviceId, this.serviceUid)

			this.smFormIncludeModes = true
			this.smFormIncludeTlsProfile = true
			this.smFormIncludeAllowInvalid = true
			this.smFormServiceContext = true       // << SERVICE CONTEXT = TRUE
			this.smFormAllowAll = true

			this.smFormTitle = 'Service Modes for ' + this.serviceData.name + ' (' + this.serviceUid + ')'
			this.smFormConfigurationModeDesired = this.currentConfigurationMode
			this.smFormHideRulesDesired = this.currentHideRules
			this.smFormProvisioningModeDesired = this.currentProvisioningMode
			this.smFormDnsGroupDesired = this.getDnsGroup(this.currentTlsProfileId)
			this.smFormTlsProfileDesired = this.currentTlsProfileId

			console.debug('[DEBUG] Service Modes', this.getSmFormValues(), this.serviceId, this.serviceUid)

			this.smFormOpen = true
			this.$nextTick(this.updateAllSmFormOptions)
		},

		updateSmFormOptionsData (key, title, detailedTitle, optionsCallback, formId) {
			this.$set(this, 'smForm' + key + 'Title', title)
			this.$set(this, 'smForm' + key + 'Options', [])
			setTimeout(() => {
				let options = optionsCallback()
				if (detailedTitle === undefined) {
					detailedTitle = title
				}
				this.$set(this, 'smForm' + key + 'Title', detailedTitle)
				this.$set(this, 'smForm' + key + 'Options', options)

				setTimeout(() => {
					if (formId !== undefined) {
						let updated = false
						let desired = this['smForm' + key + 'Desired']
						if (desired !== undefined) {
							options.forEach(option => {
								if (option.value === desired) {
									updated = true
									this.setFormValue(formId, desired)
								}
							})
						}
						if (!updated && options.length > 0) {
							updated = true
							desired = options[0].value
							this.setFormValue(formId, desired)
							this.$nextTick(() => {
								this.$set(this, 'smForm' + key + 'Desired', desired)
							})
						}
					}
				}, 100)

			}, 400)
		},

		adoptSmFormValuesAsDesired () {
			const adopt = (vueId, formId, isBool = false) => {
				let value = this.getFormValue(formId)
				if (this.hasValue(value)) {
					if (isBool) {
						value = value === this.trueFalseWords(true) || value === true
					}
					this.$set(this, vueId, value)
				}
			}
			adopt('smFormProvisioningModeDesired', 'provisioningMode')
			adopt('smFormConfigurationModeDesired', 'configurationMode')
			adopt('smFormHideRulesDesired', 'hideRules', true)
			adopt('smFormDnsGroupDesired', 'dnsGroup')
			adopt('smFormTlsProfileDesired', 'tlsProfile')
		},
		updateAllSmFormOptions () {
			this.adoptSmFormValuesAsDesired()
			if (this.smFormIncludeModes) {
				this.updateProvisioningModeOptions(this.smFormServiceContext, this.smFormAllowAll)
				this.updateConfigurationModeOptions(this.smFormServiceContext, this.smFormAllowAll)
			}
			if (this.smFormIncludeTlsProfile) {
				this.updateDnsGroupOptions(this.smFormServiceContext)
				this.updateTlsProfileOptions(this.smFormDnsGroupSelected, this.smFormServiceContext)
			}
		}

		///////////////////////////////////////////////////////////////

	}, // end of methods
}

</script>
