<template>
	<div class="mdc-typography">
		<ul class="mdc-list mdc-list--two-line" data-mdc-auto-init="MDCList" role="listbox">
			<li v-for="item in listdata"
				v-show="!item.hidden"
				class="mdc-list-item"
				:key="item.key"
				:class="{ 'mdc-list-item--activated': item.active }"
				@click="clickHandler(item)"
			>
				<i aria-hidden="true" class="material-icons mdc-list-item__graphic">{{ itemIcon(item) }}</i>
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
	              <span class="mdc-list-item__primary-text">{{ item.title }}</span>
	              <span class="mdc-list-item__secondary-text"> {{ item.text }}</span>
	            </span>
			</li>
		</ul>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'TwoLineList',
	props: [
		'listdata',
	],
	data: function () {
		return {
			'dialog': undefined
		}
	},
	computed: {},
	watch: {},
	mounted () {
		this.setup()
	},
	updated () {
		this.setup()
	},
	methods: {
		setup () {
			autoInit()  // Material Design Components
		},
		itemIcon (item) {
			if (item.hidden) {
				return 'visibility_off'
			}
			return item.icon
		},
		clickHandler (item) {
			if (item.callback !== undefined) {
				item.callback()
			} else {
				this.$emit('click', item)
			}
		},
	},
}
</script>

<style lang="scss">

</style>
