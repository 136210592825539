//
// Vue Router.
// @see https://routerjs.org
//
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '../components/NotFound'

Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',  // alternatively you can use hash
	scrollBehavior (to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	},
	routes: [
		{
			path: '/',
			redirect: '/services/summary',
		},
		{
			path: '/',
			redirect: '/notimplemented',
		},

		// Tools
		{
			path: '/tools/admin/',
			name: 'tools-admin',
			component: () => import('../components/tools/AdminTools'),
			meta: { selectedId: 'admin' },
		},

		// Agents
		{
			path: '/agents/update',
			name: 'agents-update',
			component: () => import('../components/agents/AgentsUpdate'),
			meta: { selectedId: 'agents' },
		},
		{
			path: '/agents',
			name: 'agents',
			component: () => import('../components/agents/Main'),
			meta: { selectedId: 'agents' },
			redirect: '/agents/grid',
			children: [
				{
					path: 'summary',
					redirect: '/agents/grid',
				},
				{
					path: 'grid',
					component: () => import('../components/agents/AgentsGrid'),
					meta: {
						selectedId: 'agents',
						selectedTabId: 'grid',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'list',
					component: () => import('../components/agents/AgentsList'),
					meta: {
						selectedId: 'agents',
						selectedTabId: 'list',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'online',
					redirect: '/agents/list',
				},
				{
					path: 'offline',
					redirect: '/agents/list',
				},
				{
					path: 'active',
					redirect: '/agents/list',
				},
				{
					path: 'alive',
					redirect: '/agents/list',
				},
				{
					path: 'registered',
					redirect: '/agents/list',
				},
				{
					path: 'registering',
					redirect: '/agents/list',
				},
			]
		},

		// Points of Presence
		{
			path: '/pops',
			name: 'pops',
			component: () => import('../components/pops/Main'),
			meta: { selectedId: 'pops' },
			props: true,
			redirect: '/pops/grid',
			children: [
				{
					path: 'grid',
					name: 'pops-grid',
					component: () => import('../components/pops/PopHexGrid'),
					meta: {
						selectedId: 'pops',
					},
				},
				{
					path: 'map',
					name: 'pops-map',
					component: () => import('../components/pops/WorldMap'),
					meta: {
						selectedId: 'pops',
					},
				},
				{
					path: 'list',
					name: 'pops-list',
					component: () => import('../components/pops/PopList'),
					meta: {
						selectedId: 'pops',
					},
				},
			]
		},
		{
			path: '/servers',
			name: 'servers',
			component: () => import('../components/pops/Main'),
			meta: { selectedId: 'servers' },
			props: true,
			redirect: '/servers/grid',
			children: [
				{
					path: 'grid',
					name: 'servers-grid',
					component: () => import('../components/pops/PopHexGrid'),
					meta: {
						selectedId: 'servers',
					},
				},
				{
					path: 'map',
					name: 'servers-map',
					component: () => import('../components/pops/WorldMap'),
					meta: {
						selectedId: 'servers',
					},
				},
			]
		},
		// Nginx
		{
			path: '/nginx',
			name: 'nginx',
			component: () => import('../components/servers/Main'),
			meta: { selectedId: 'nginx' },
			props: { serverType: 'nginx' },
			redirect: '/nginx/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'nginx',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'nginx',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'nginx',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/nginx/list',
				},
				{
					path: 'offline',
					redirect: '/nginx/list',
				},
				{
					path: 'config',
					redirect: '/config/server/nginx',
				},
			]
		},

		// Varnish
		{
			path: '/varnish',
			name: 'varnish',
			component: () => import('../components/servers/Main'),
			meta: { selectedId: 'varnish' },
			props: { serverType: 'varnish' },
			redirect: '/varnish/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'varnish',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'varnish',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'varnish',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/varnish/list',
				},
				{
					path: 'offline',
					redirect: '/varnish/list',
				},
				{
					path: 'config',
					redirect: '/config/server/varnish',
				},
			]
		},

		// HAProxy
		{
			path: '/haproxy',
			name: 'haproxy',
			component: () => import('../components/servers/Main'),
			props: { serverType: 'haproxy' },
			redirect: '/haproxy/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'haproxy',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'haproxy',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'haproxy',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/haproxy/list',
				},
				{
					path: 'offline',
					redirect: '/haproxy/list',
				},
				// {
				//     path: ':selectedServer/variables',
				//     component: () => import('../components/config/server/Variables'),
				//     meta: {},
				//     props: {serverType: 'haproxy'},
				// },
				{
					path: 'config',
					redirect: '/config/server/haproxy',
				},
			]
		},

		// Logstash
		{
			path: '/logstash',
			name: 'logstash',
			component: () => import('../components/servers/Main'),
			props: {serverType: 'logstash'},
			redirect: '/logstash/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'logstash',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'logstash',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'logstash',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/logstash/list',
				},
				{
					path: 'offline',
					redirect: '/logstash/list',
				},
				// {
				//     path: ':selectedServer/variables',
				//     component: () => import('../components/config/server/Variables'),
				//     meta: {},
				//     props: {serverType: 'logstash'},
				// },
				{
					path: 'config',
					redirect: '/config/server/logstash',
				},
			]
		},

		// Vector
		{
			path: '/vector',
			name: 'vector',
			component: () => import('../components/servers/Main'),
			props: {serverType: 'vector'},
			redirect: '/vector/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'vector',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'vector',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'vector',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/vector/list',
				},
				{
					path: 'offline',
					redirect: '/vector/list',
				},
				// {
				//     path: ':selectedServer/variables',
				//     component: () => import('../components/config/server/Variables'),
				//     meta: {},
				//     props: {serverType: 'vector'},
				// },
				{
					path: 'config',
					redirect: '/config/server/vector',
				},
			]
		},

		// Cache Warming
		{
			path: '/warming',
			name: 'warming',
			component: () => import('../components/servers/Main'),
			props: { serverType: 'warming' },
			redirect: '/warming/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'warming',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'warming',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'warming',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
			]
		},

		// Scripting
		{
			path: '/scripting',
			name: 'scripting',
			component: () => import('../components/servers/Main'),
			props: { serverType: 'scripting' },
			redirect: '/scripting/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/servers/ServersSummary'),
					meta: {
						selectedId: 'scripting',
						selectedTabId: 'summary',
						showConfigModesMenu: true,

					},
				},
				{
					path: 'grid',
					component: () => import('../components/servers/ServerGrid'),
					meta: {
						selectedId: 'scripting',
						selectedTabId: 'grid',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'list',
					component: () => import('../components/servers/ServerList'),
					meta: {
						selectedId: 'scripting',
						selectedTabId: 'list',
						showConfigModesMenu: true,
					},
				},
				{
					path: 'online',
					redirect: '/scripting/list',
				},
				{
					path: 'offline',
					redirect: '/scripting/list',
				},
				// {
				//     path: ':selectedServer/variables',
				//     component: () => import('../components/config/server/Variables'),
				//     meta: {},
				//     props: {serverType: 'scripting'},
				// },
				{
					path: 'config',
					redirect: '/config/server/scripting',
				},
			]
		},

		// Features
		{
			path: '/features',
			name: 'features',
			component: () => import('../components/features/Introduction'),
			meta: {
				selectedId: 'features',
				showFeaturesMenu: true
			}
		},

		// Dynamic Options
		{
			path: '/options',
			name: 'options',
			component: () => import('../components/options/Main'),
			redirect: '/options/introduction',
			children: [
				{
					path: 'introduction',
					name: 'dynamic-options-introduction',
					component: () => import('../components/options/Introduction'),
					meta: {
						selectedId: 'options',
						selectedTabId: 'dynamic-options-introduction',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'list',
					name: 'dynamic-options-list',
					component: () => import('../components/options/List'),
					meta: {
						selectedId: 'options',
						selectedTabId: 'dynamic-options-list',
						showFeaturesMenu: true
					},
					props: {},
				},

			]
		},

		// Config :: Hostname
		{
			path: '/config/hostname',
			name: 'config',
			component: () => import('../components/config/Main'),
			meta: { selectedId: 'config' },
			redirect: '/config/hostname/search',
			children: [
				// Config :: Hostname :: Search
				{
					path: 'search',
					name: 'config-hostname-search',
					component: () => import('../components/config/hostname/Search'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'search'
					},
					props: true,
				},

			]
		},

		// Config :: Hostname :: Details
		{
			path: '/config/hostname/:selectedHostname',
			component: () => import('../components/config/hostname/Hostname'),
			meta: {
				selectedId: 'hostname',
				selectedTabId: 'hostname',
				selectedSubTabId: 'hostname'
			},
			props: true,
			redirect: '/config/hostname/:selectedHostname/summary',
			children: [
				{
					path: 'summary',
					component: () => import('../components/config/hostname/Summary'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'summary'
					},
					props: true,
				},
				{
					path: 'servers',
					component: () => import('../components/config/hostname/Servers'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'servers'
					},
					props: true,
				},
				{
					path: 'variables',
					component: () => import('../components/config/hostname/Variables'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'variables'
					},
					props: true,
					children: [],
				},
				{
					path: 'certificates',
					component: () => import('../components/config/hostname/Certificates'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'certificates'
					},
					props: true,
				},
				{
					path: 'customconfs',
					component: () => import('../components/config/hostname/CustomConfs'),
					meta: {
						selectedId: 'hostname',
						selectedTabId: 'hostname',
						selectedSubTabId: 'customconfs'
					},
					props: true,
				},
			]
		},

		// Config
		{
			path: '/config',
			redirect: '/config/hostname/search',
		},

		// Plugins
		{
			path: '/plugins',
			name: 'plugins',
			component: () => import('../components/plugins/Main'),
			redirect: '/plugins/introduction',
			children: [
				{
					path: 'introduction',
					name: 'plugins-introduction',
					component: () => import('../components/plugins/Introduction'),
					meta: {
						selectedId: 'plugins',
						selectedTabId: 'plugins-introduction',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'list',
					name: 'plugins-list',
					component: () => import('../components/NotImplemented'),
					meta: {
						selectedId: 'plugins',
						selectedTabId: 'plugins-list',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'create',
					name: 'plugins-create',
					component: () => import('../components/NotImplemented'),
					meta: {
						selectedId: 'plugins',
						selectedTabId: 'plugins-create',
						showFeaturesMenu: true
					},
					props: {},
				},
			]
		},

		// TLS Profiles
		{
			path: '/tlsprofiles',
			name: 'tlsprofiles',
			component: () => import('../components/tlsprofiles/Main'),
			redirect: '/tlsprofiles/introduction',
			children: [
				{
					path: 'introduction',
					name: 'tlsprofiles-introduction',
					component: () => import('../components/tlsprofiles/Introduction'),
					meta: {
						selectedId: 'tlsprofiles',
						selectedTabId: 'tlsprofiles-introduction',
						showFeaturesMenu: true
					},
					props: {
						'introduction': true,
					},
				},
				{
					path: 'create',
					name: 'tlsprofiles-create',
					component: () => import('../components/tlsprofiles/CreateTLSProfile'),
					meta: {
						selectedId: 'tlsprofiles',
						selectedTabId: 'tlsprofiles-create',
						showFeaturesMenu: true
					},
					props: {
						'create': true,
					},
				},
				{
					path: 'list',
					name: 'tlsprofiles-list',
					redirect: '/tlsprofiles/list/all',
				},
				{
					path: 'list/:group',
					name: 'tlsprofiles-list-filtered',
					component: () => import('../components/tlsprofiles/ProfilesListPage'),
					meta: {
						selectedId: 'tlsprofiles',
						selectedTabId: 'tlsprofiles-list',
						showFeaturesMenu: true
					},
					props: {
						'filtered': true,
					},
				},
				{
					path: ':profileId',
					name: 'tlsprofiles-details',
					component: () => import('../components/tlsprofiles/ProfileMain'),
					redirect: '/tlsprofiles/:profileId/details',
					props: true,
					children: [
						{
							path: 'details',
							name: 'tlsprofiles-details-details',
							component: () => import('../components/tlsprofiles/ProfileDetails'),
							meta: {
								selectedId: 'tlsprofiles',
								selectedTabId: 'tlsprofiles-details-details',
								showFeaturesMenu: true
							},
							props: true,
							children: [],
						},
						{
							path: 'usage',
							name: 'tlsprofiles-details-usage',
							component: () => import('../components/tlsprofiles/ProfileUsage'),
							meta: {
								selectedId: 'tlsprofiles',
								selectedTabId: 'tlsprofiles-details-usage',
								showFeaturesMenu: true
							},
							props: true,
							children: [
								{
									path: 'accounts',
									name: 'tlsprofiles-details-usage-accounts',
									component: () => import('../components/tlsprofiles/ProfileUsageAccounts'),
									meta: {
										selectedId: 'tlsprofiles',
										selectedTabId: 'tlsprofiles-details-usage-accounts',
										showFeaturesMenu: true
									},
									props: true,
								},
								{
									path: 'services',
									name: 'tlsprofiles-details-usage-services',
									component: () => import('../components/tlsprofiles/ProfileUsageServices'),
									meta: {
										selectedId: 'tlsprofiles',
										selectedTabId: 'tlsprofiles-details-usage-services',
										showFeaturesMenu: true
									},
									props: true,
								},
							],
						}


					],
				},
			]
		},

		// Scripts
		{
			path: '/scripts',
			name: 'scripts',
			component: () => import('../components/scripts/Main'),
			redirect: '/scripts/introduction',
			children: [
				{
					path: 'introduction',
					name: 'scripts-introduction',
					component: () => import('../components/scripts/Introduction'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-introduction',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'summary',
					name: 'scripts-summary',
					component: () => import('../components/scripts/Summary'),
					redirect: '/scripts/summary/configs',
					children: [
						{
							path: 'definitions',
							name: 'scripts-summary-definitions',
							component: () => import('../components/scripts/SummaryDefinitions'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-summary-definitions',
								showFeaturesMenu: true,
							},
							props: {},
						},
						{
							path: 'configs',
							name: 'scripts-summary-configs',
							component: () => import('../components/scripts/SummaryConfigs'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-summary-configs',
								showFeaturesMenu: true,
							},
							props: {},
						},
						{
							path: 'accounts',
							name: 'scripts-summary-accounts',
							component: () => import('../components/scripts/SummaryAccounts'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-summary-accounts',
							},
							props: {},
						},
						{
							path: 'services',
							name: 'scripts-summary-services',
							component: () => import('../components/scripts/SummaryServices'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-summary-services',
								showFeaturesMenu: true,
							},
							props: {},
						},
					]
				},
				{
					path: 'search',
					name: 'scripts-search',
					component: () => import('../components/scripts/configs/Search'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-search',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'list',
					name: 'scripts-list',
					redirect: '/scripts/list/available',
				},
				{
					path: 'list/available',
					name: 'scripts-list-available',
					component: () => import('../components/scripts/definitions/DefinitionsListFiltered'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-list',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'list/unavailable',
					name: 'scripts-list-unavailable',
					component: () => import('../components/scripts/definitions/DefinitionsListFiltered'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-list',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'list/all',
					name: 'scripts-list-all',
					component: () => import('../components/scripts/definitions/DefinitionsListFiltered'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-list',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'create',
					name: 'scripts-create',
					component: () => import('../components/scripts/definitions/CreateDefinition'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-create',
						showFeaturesMenu: true
					},
					props: {},
				},
				{
					path: 'configs/definitions/:definitionId',
					redirect: '/scripts/definitions/:definitionId/details',
					props: true,
				},
				{
					path: 'definitions/',
					redirect: '/scripts/list/',
					props: true,
				},
				{
					path: 'definitions/:definitionId',
					name: 'scripts-definition',
					component: () => import('../components/scripts/definitions/Main'),
					redirect: '/scripts/definitions/:definitionId/details',
					props: true,
					children: [
						{
							path: 'details',
							name: 'scripts-definition-details',
							component: () => import('../components/scripts/definitions/DefinitionDetails'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-definition-details',
								showFeaturesMenu: true
							},
							props: true,
						},
						{
							path: 'value',
							name: 'scripts-definition-value',
							component: () => import('../components/scripts/definitions/DefinitionValue'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-definition-value',
								showFeaturesMenu: true
							},
							props: true,
						},
						{
							path: 'usage',
							name: 'scripts-definition-usage',
							component: () => import('../components/scripts/definitions/DefinitionUsage'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'scripts-definition-usage',
								showFeaturesMenu: true
							},
							props: true,
							children: [
								{
									path: 'accounts',
									name: 'scripts-definition-usage-accounts',
									component: () => import('../components/scripts/definitions/DefinitionUsageAccounts'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'scripts-definition-usage-accounts',
										showFeaturesMenu: true
									},
									props: true,
								},
								{
									path: 'configs',
									name: 'scripts-definition-usage-configs',
									component: () => import('../components/scripts/definitions/DefinitionUsageConfigs'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'scripts-definition-usage-configs',
										showFeaturesMenu: true
									},
									props: true,
								},
								{
									path: 'services',
									name: 'scripts-definition-usage-services',
									component: () => import('../components/scripts/definitions/DefinitionUsageServices'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'scripts-definition-usage-services',
										showFeaturesMenu: true
									},
									props: true,
								},
							],
						}
					],
				},
				{
					path: 'configs/:configId',
					name: 'scripts-config-details',
					component: () => import('../components/scripts/configs/ConfigDetails'),
					meta: {
						selectedId: 'scripts',
						selectedTabId: 'scripts-config-details',
						showFeaturesMenu: true
					},
					props: true,
				},
			]
		},

		// SSL Certificates
		{
			path: '/certificates',
			name: 'certificates',
			component: () => import('../components/certificates/Main'),
			redirect: '/certificates/summary',
			children: [
				{
					path: 'summary',
					name: 'certificates-summary',
					component: () => import('../components/certificates/search/Summary'),
					meta: {
						selectedId: 'certificates',
						selectedTabId: 'certificates-summary',
						showCertificatesMenu: true,
					},
					props: {},
				},
				{
					path: 'search',
					name: 'certificates-search',
					component: () => import('../components/certificates/search/Search'),
					meta: {
						selectedId: 'certificates-search',
						selectedTabId: 'certificates-search',
						showCertificatesMenu: true,
					},
					props: {},
				},
				{
					path: 'expires',
					name: 'certificates-expires',
					component: () => import('../components/certificates/search/List'),
					meta: {
						selectedId: 'certificates-expires',
						selectedTabId: 'certificates-expires',
						showCertificatesMenu: true,
					},
					props: {
						maxResults: 100,
						controllable: true
					},
				},
				{
					path: 'upload',
					name: 'certificates-upload',
					component: () => import('../components/services/accounts/UploadCertificate'),
					meta: {
						selectedId: 'certificates-upload',
						selectedTabId: 'certificates-upload',
						showCertificatesMenu: true,
					},
					props: true,
				},
				{
					path: 'issue',
					name: 'certificates-issue',
					component: () => import('../components/services/accounts/IssueCertificate'),
					meta: {
						selectedId: 'certificates-issue',
						selectedTabId: 'certificates-issue',
						showCertificatesMenu: true,
					},
					props: true,
				},
				{
					path: 'check/:serial/:hostname',
					name: 'certificates-check-serial-hostname',
					component: () => import('../components/certificates/view/SslCheck'),
					meta: {
						selectedId: 'certificates-check',
						selectedTabId: 'certificates-check',
						showCertificatesMenu: true,
					},
					props: true,
				},
				{
					path: 'check',
					name: 'certificates-check',
					component: () => import('../components/certificates/view/SslCheck'),
					meta: {
						selectedId: 'certificates-check',
						selectedTabId: 'certificates-check',
						showCertificatesMenu: true,
					},
					props: true,
				},
				{
					path: ':selectedCertificate',
					name: 'certificates-view',
					component: () => import('../components/certificates/view/Certificate'),
					meta: {
						selectedId: 'certificates',
						selectedTabId: 'certificate',
						showCertificatesMenu: true,
					},
					props: true,
					redirect: ':selectedCertificate/details',
					children: [
						{
							path: 'details',
							name: 'certificates-view-details',
							component: () => import('../components/certificates/view/Details'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'certificate',
								selectedSubTabId: 'details',
								showCertificatesMenu: true,
							},
							props: true,
						},
						{
							path: 'bundle',
							name: 'certificates-view-bundle',
							component: () => import('../components/certificates/view/BundleViewer'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'certificate',
								selectedSubTabId: 'bundle',
								showCertificatesMenu: true,
							},
							props: true,
						},
						{
							path: 'hostnames',
							name: 'certificates-view-hostnames',
							component: () => import('../components/certificates/view/Hostnames'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'certificate',
								selectedSubTabId: 'hostnames',
								showCertificatesMenu: true,
							},
							props: true,
						},
						{
							path: 'check/:hostname',
							name: 'certificates-view-check-hostname',
							component: () => import('../components/certificates/view/SslCheck'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'certificate',
								selectedSubTabId: 'check',
								showCertificatesMenu: true,
							},
							props: true,
						},
						{
							path: 'check',
							name: 'certificates-view-check',
							component: () => import('../components/certificates/view/SslCheck'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'certificate',
								selectedSubTabId: 'check',
								showCertificatesMenu: true,
							},
							props: true,
						},
					],
				},
			]
		},

		// Accounts - Public API Data (Services)
		{
			path: '/accounts',
			name: 'accounts',
			component: () => import('../components/services/Main'),
			redirect: '/accounts/search',
			children: [
				{
					path: 'search',
					name: 'accounts-search',
					component: () => import('../components/services/search/Search'),
					meta: {
						selectedId: 'accounts',
						selectedTabId: 'accounts-search',
						selectedSubTabId: 'accounts'
					},
				},
				{
					path: 'summary',
					redirect: '/services/summary',
				},
				{
					path: ':selectedAccount/',
					component: () => import('../components/services/accounts/AccountMain'),
					meta: { selectedId: 'accounts' },
					props: true,
					redirect: ':selectedAccount/details',
					children: [
						{
							path: 'details',
							name: 'account-details',
							component: () => import('../components/services/accounts/details/Details.vue'),
							meta: {
								selectedId: 'accounts',
								selectedTabId: 'account-details'
							},
							props: true,
						},
						{
							path: 'services',
							name: 'account-services',
							component: () => import('../components/services/accounts/Services'),
							meta: {
								selectedId: 'services',
								selectedTabId: 'account-services'
							},
							props: true,
						},
						{
							path: 'services/:selectedService',
							redirect: '/services/:selectedService',
						},
						{
							path: 'certificates/upload',
							name: 'account-certificates-upload',
							component: () => import('../components/services/accounts/UploadCertificate'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'account-certificates'
							},
							props: true,
						},
						{
							path: 'certificates/issue',
							name: 'account-certificates-issue',
							component: () => import('../components/services/accounts/IssueCertificate'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'account-certificates'
							},
							props: true,
						},
						{
							path: 'certificates',
							name: 'account-certificates',
							component: () => import('../components/services/accounts/Certificates'),
							meta: {
								selectedId: 'certificates',
								selectedTabId: 'account-certificates'
							},
							props: true,
							children: [
								{
									path: 'orphaned',
									name: 'account-certificates-orphaned',
									component: () => import('../components/services/accounts/CertificatesList'),
									meta: {
										selectedId: 'certificates',
										selectedTabId: 'account-certificates',
										selectedCertsTabId: 'account-certificates-orphaned'
									},
									props: true,
								},
								{
									path: 'connected',
									name: 'account-certificates-connected',
									component: () => import('../components/services/accounts/CertificatesList'),
									meta: {
										selectedId: 'certificates',
										selectedTabId: 'account-certificates',
										selectedCertsTabId: 'account-certificates-connected'
									},
									props: true,
								},
								{
									path: 'expired',
									name: 'account-certificates-expired',
									component: () => import('../components/services/accounts/CertificatesList'),
									meta: {
										selectedId: 'certificates',
										selectedTabId: 'account-certificates',
										selectedCertsTabId: 'account-certificates-expired'
									},
									props: true,
								},
								{
									path: 'all',
									name: 'account-certificates-all',
									component: () => import('../components/services/accounts/CertificatesList'),
									meta: {
										selectedId: 'certificates',
										selectedTabId: 'account-certificates',
										selectedCertsTabId: 'account-certificates-all'
									},
									props: true,
								},
							]
						},
						{
							path: 'origins',
							name: 'account-origins',
							component: () => import('../components/services/accounts/AccountOrigins'),
							meta: {
								selectedId: 'accounts',
								selectedTabId: 'account-origins',
								selectedSubTabId: 'origins'
							},
							props: true,
						},
						{
							path: 'origins/:originId',
							name: 'account-origins-details',
							component: () => import('../components/origins/OriginDetails'),
							meta: {
								selectedId: 'accounts',
								selectedTabId: 'account-origins',
								selectedSubTabId: 'origins'
							},
							props: true,
						},
						{
							path: 'scripts/',
							name: 'account-scripts',
							component: () => import('../components/services/accounts/Scripts'),
							meta: {
								selectedId: 'scripts',
								selectedTabId: 'account-scripts',
								selectedSubTabId: 'account-scripts'
							},
							props: true,
							children: [
								{
									path: 'definitions',
									name: 'account-script-definitions',
									component: () => import('../components/services/accounts/ScriptDefinitionsList'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'account-scripts',
										selectedSubTabId: 'account-script-definitions'
									},
									props: true,
								},
								// {
								// 	path: 'definitions/enable',
								// 	name: 'account-script-definitions-enable',
								// 	component: () => import('../components/services/accounts/EnableScriptDefinitions'),
								// 	meta: {
								// 		selectedId: 'scripts',
								// 		selectedTabId: 'account-scripts',
								// 		selectedSubTabId: 'account-script-definitions'
								// 	},
								// 	props: true,
								// },
								{
									path: 'configs/create',
									name: 'account-script-config-create',
									component: () => import('../components/services/accounts/CreateScriptConfig'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'account-scripts',
										selectedSubTabId: 'account-script'
									},
									props: true,
								},
								{
									path: 'configs',
									name: 'account-script',
									component: () => import('../components/services/accounts/ScriptConfigsList'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'account-scripts',
										selectedSubTabId: 'account-script'
									},
									props: true,
								},
								{
									path: 'configs/:configId',
									name: 'account-scripts-config-details',
									component: () => import('../components/services/accounts/ScriptConfigDetails'),
									meta: {
										selectedId: 'scripts',
										selectedTabId: 'account-scripts',
										selectedSubTabId: 'account-script'
									},
									props: true,
								},

							],
						},
					]
				},
			]
		},

		// Services - Public API Data
		{
			path: '/services',
			name: 'services',
			component: () => import('../components/services/Main'),
			redirect: '/services/summary',
			children: [
				{
					path: 'summary',
					name: 'services-summary',
					component: () => import('../components/services/search/Summary'),
					meta: {
						selectedId: 'services',
						selectedTabId: 'services-summary'
					},
				},
				{
					path: 'search',
					name: 'services-search',
					component: () => import('../components/services/search/Search'),
					meta: {
						selectedId: 'services',
						selectedTabId: 'services-search'
					},
				},
				{
					path: 'accounts/:selectedAccount/services/:selectedService',
					redirect: '/services/:selectedService',
				},
				{
					path: 'accounts/:selectedAccount/details',
					redirect: '/accounts/:selectedAccount/details',
				},
				{
					path: 'accounts/:selectedAccount/services',
					redirect: '/accounts/:selectedAccount/services',
				},
				{
					path: 'accounts/:selectedAccount',
					redirect: '/accounts/:selectedAccount',
				},
				{
					path: ':selectedService',
					name: 'service-details',
					component: () => import('../components/services/accounts/AccountMain'),
					meta: {
						selectedId: 'services',
						selectedTabId: 'service-details'
					},
					props: true,
				},
				{
					path: ':selectedService/rules',
					name: 'service-rules',
					component: () => import('../components/services/accounts/AccountMain'),
					meta: {
						selectedId: 'services',
						selectedTabId: 'service-details',
						selectedSubTabId: 'rules'
					},
					props: true,
				},
			]
		},

		// Settings
		{
			path: '/settings',
			name: 'settings',
			component: () => import('../components/settings/Main'),
			redirect: '/settings/introduction',
			children: [
				{
					path: 'introduction',
					name: 'settings-introduction',
					component: () => import('../components/settings/Introduction'),
					meta: {
						selectedId: 'settings',
						selectedTabId: 'settings-introduction',
						showSettingsMenu: true,
					},
					props: true,
				},
				{
					path: 'groups',
					name: 'settings-groups',
					component: () => import('../components/config/data/DataFiles'),
					meta: {
						selectedId: 'settings-groups',
						selectedTabId: 'data',
						selectedSubTabId: 'groups',
						showSettingsMenu: true,
					},
					props: {
						serverType: 'config',
						dataCategory: 'data',
						apiEndpoint: 'data/groups'
					},
				},
				{
					path: 'certificates',
					name: 'settings-certificates',
					component: () => import('../components/config/data/DataFiles'),
					meta: {
						selectedId: 'settings-certificates',
						selectedTabId: 'data',
						selectedSubTabId: 'certificates',
						showSettingsMenu: true,
					},
					props: {
						serverType: 'config',
						dataCategory: 'data',
						apiEndpoint: 'data/certs'
					},
				},
				{
					path: 'templates',
					name: 'settings-templates',
					component: () => import('../components/config/data/DataFiles'),
					meta: {
						selectedId: 'settings-templates',
						selectedTabId: 'data',
						selectedSubTabId: 'templates',
						showSettingsMenu: true,
					},
					props: {
						serverType: 'config',
						dataCategory: 'data',
						apiEndpoint: 'data/templates'
					},
				},
				{
					path: 'variables',
					name: 'settings-variables',
					component: () => import('../components/config/data/DataFiles'),
					meta: {
						selectedId: 'settings-variables',
						selectedTabId: 'data',
						selectedSubTabId: 'variables',
						showSettingsMenu: true,
					},
					props: {
						serverType: 'config',
						dataCategory: 'data',
						apiEndpoint: 'data/variables'
					},
				},
				{
					path: 'custom-configs',
					name: 'settings-custom-configs',
					component: () => import('../components/config/data/DataFiles'),
					meta: {
						selectedId: 'settings-custom-configs',
						selectedTabId: 'data',
						selectedSubTabId: 'custom-configs',
						showSettingsMenu: true,
					},
					props: {
						serverType: 'config',
						dataCategory: 'data',
						apiEndpoint: 'data/configs'
					},
				},
				{
					path: 'dns',
					name: 'settings-dns',
					component: () => import('../components/settings/dns/Main'),
					redirect: '/settings/dns/introduction',
					children: [
						{
							path: 'introduction',
							name: 'settings-dns-introduction',
							component: () => import('../components/settings/dns/Introduction'),
							meta: {
								selectedId: 'settings-dns',
								selectedTabId: 'settings-dns-introduction',
								showSettingsMenu: true,
							},
							props: true,
						},
						{
							path: 'config',
							name: 'settings-dns-config',
							component: () => import('../components/config/data/DataFiles'),
							meta: {
								selectedId: 'settings-dns',
								selectedTabId: 'data',
								selectedSubTabId: 'settings-dns-config',
								showSettingsMenu: true,
							},
							props: {
								serverType: 'dns',
								dataCategory: 'config',
								apiEndpoint: 'data'
							},
						},
						{
							path: 'rvips',
							name: 'settings-dns-rvips',
							redirect: '/settings/dns/rvips/all',
						},
						{
							path: 'rvips/:state',
							name: 'settings-dns-rvips-filtered',
							component: () => import('../components/settings/dns/RVIPsListPage.vue'),
							meta: {
								selectedId: 'settings-dns',
								selectedTabId: 'data',
								selectedSubTabId: 'settings-dns-rvips-filtered',
								showSettingsMenu: true,
							},
							props: true
						},
					]

				},
				{
					path: 'nginx',
					name: 'settings-nginx',
					redirect: '/settings/nginx/base-configs',
					component: () => import('../components/settings/nginx/Main'),
					meta: {
						selectedId: 'settings-nginx',
						showSettingsMenu: true,
					},
					children: [
						{
							path: 'templates',
							name: 'settings-nginx-templates',
							component: () => import('../components/config/data/DataFiles'),
							meta: {
								selectedId: 'settings-nginx',
								selectedTabId: 'data',
								selectedSubTabId: 'templates',
								showSettingsMenu: true,
							},
							props: {
								serverType: 'nginx',
								dataCategory: 'data',
								apiEndpoint: 'data/templates'
							},
						},
						{
							path: 'variables',
							name: 'settings-nginx-variables',
							component: () => import('../components/config/data/DataFiles'),
							meta: {
								selectedId: 'settings-nginx',
								selectedTabId: 'data',
								selectedSubTabId: 'variables',
								showSettingsMenu: true,
							},
							props: {
								serverType: 'nginx',
								dataCategory: 'data',
								apiEndpoint: 'data/variables'
							},
						},
						{
							path: 'custom-configs',
							name: 'settings-nginx-custom-configs',
							component: () => import('../components/config/data/DataFiles'),
							meta: {
								selectedId: 'settings-nginx',
								selectedTabId: 'data',
								selectedSubTabId: 'custom-configs',
								showSettingsMenu: true,
							},
							props: {
								serverType: 'nginx',
								dataCategory: 'data',
								apiEndpoint: 'data/configs'
							},
						},
						{
							path: 'base-configs',
							name: 'settings-nginx-base-configs',
							component: () => import('../components/settings/nginx/BaseEditor'),
							meta: {
								selectedId: 'settings-nginx',
								selectedTabId: 'base-configs',
								showSettingsMenu: true,
							},
							props: true,
						},
						{
							path: 'base-configs/*', // NB. This has a wildcard
							name: 'settings-nginx-base-configs-file',
							component: () => import('../components/settings/nginx/BaseEditor'),
							meta: {
								selectedId: 'settings-nginx',
								selectedTabId: 'base-configs',
								showSettingsMenu: true,
							},
							props: true,
						},
					],
				},
			],
		},

		// Config :: Data
		{
			path: '/config/data',
			redirect: '/settings',
		},
		{
			path: '/config/data/groups',
			redirect: '/settings/groups',
		},
		{
			path: '/config/data/certificates',
			redirect: '/settings/certificates',
		},
		{
			path: '/config/data/templates',
			redirect: '/settings/nginx/templates',
		},
		{
			path: '/config/data/variables',
			redirect: '/settings/nginx/variables',
		},
		{
			path: '/config/data/customconfs',
			redirect: '/settings/nginx/custom-configs',
		},

		// Config :: Apps
		{
			path: '/config/modes',
			component: () => import('../components/config/server/SelectType'),
			meta: {
				selectedId: 'modes',
				showConfigModesMenu: true,
			},
			props: true,
		},
		{
			path: '/config/server',
			redirect: '/config/modes',
		},

		// Config :: Server
		{
			path: '/config/server/:configMode',
			name: 'config-server-search',
			component: () => import('../components/config/server/Server'),
			meta: {
				selectedId: ':configMode',
				selectedTabId: 'server',
			},
			props: true,
		},

		// Server :: Type
		{
			path: '/server/:selectedServer/:configMode/',
			name: 'server-config-notab',
			component: () => import('../components/config/server/Server'),
			meta: {
				// selectedId: ':configMode',
				selectedId: 'servers',
			},
			props: true,
			// redirect: '/server/:selectedServer/:configMode/details',
			children: [
				{
					path: 'inactive',
					name: 'server-config-inactive',
					component: () => import('../components/config/server/Inactive'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'disabled',
					name: 'server-config-disabled',
					component: () => import('../components/config/server/Disabled'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'details',
					name: 'server-config-details',
					component: () => import('../components/config/server/details/Main'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
					redirect: '/server/:selectedServer/:configMode/details/configuration',
					children: [
						{
							path: 'configuration',
							name: 'server-config-details-configuration',
							component: () => import('../components/config/server/details/Configuration'),
							meta: {
								selectedId: 'servers',
							},
						},
						{
							path: 'validated',
							name: 'server-config-details-validated',
							component: () => import('../components/config/server/details/Validated'),
							meta: {
								selectedId: 'servers',
							},
						},
						{
							path: 'reloaded',
							name: 'server-config-details-reloaded',
							component: () => import('../components/config/server/details/Reloaded'),
							meta: {
								selectedId: 'servers',
							},
						},
						{
							path: 'verified',
							name: 'server-config-details-verified',
							component: () => import('../components/config/server/details/Verified'),
							meta: {
								selectedId: 'servers',
							},
						},
						{
							path: 'nagios',
							name: 'server-config-details-nagios',
							component: () => import('../components/config/server/details/Nagios'),
							meta: {
								selectedId: 'servers',
							},
						},
					]
				},
				{
					path: 'agent',
					name: 'server-config-agent',
					component: () => import('../components/config/server/Agent'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'keys',
					name: 'server-config-agent-keys',
					component: () => import('../components/config/server/AgentKeys'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'errors',
					name: 'server-config-agent-errors',
					component: () => import('../components/config/server/Errors'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'utilities',
					name: 'server-config-agent-utilities',
					component: () => import('../components/config/server/Utilities'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
				},
				{
					path: 'variables',
					name: 'server-config-variables',
					component: () => import('../components/config/server/Variables'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
					children: [
						{
							path: ':selectedFile',
							name: 'server-config-variables-file',
							meta: {
								selectedId: 'servers',
								selectedTabId: 'server-config-variables',
							},
							props: true,
						}
					],
				},
				{
					path: 'hostnames',
					name: 'server-config-hostnames',
					component: () => import('../components/config/server/Hostnames'),
					meta: {
						selectedId: 'servers',
					},
					props: true,
					redirect: '/server/:selectedServer/:configMode/hostnames/included',
					children: [
						{
							path: 'included',
							name: 'server-config-hostnames-included',
							component: () => import('../components/config/server/HostnamesList'),
							meta: {
								selectedId: 'servers',
								selectedTabId: 'server-config-hostnames',
								selectedHostnamesTab: 'included'
							},
							props: true,
						},
						{
							path: 'only',
							name: 'server-config-hostnames-only',
							component: () => import('../components/config/server/HostnamesList'),
							meta: {
								selectedId: 'servers',
								selectedTabId: 'server-config-hostnames',
								selectedHostnamesTab: 'only'
							},
							props: true,
						},
						{
							path: 'exclude',
							name: 'server-config-hostnames-exclude',
							component: () => import('../components/config/server/HostnamesList'),
							meta: {
								selectedId: 'servers',
								selectedTabId: 'server-config-hostnames',
								selectedHostnamesTab: 'exclude'
							},
							props: true,
						},
					],
				},
				{
					path: 'files',
					name: 'server-config-files',
					component: () => import('../components/config/server/GeneratedFiles'),
					meta: {
						selectedId: 'server',
						// selectedSubTabId: 'files'
					},
					props: true,
				},
			]
		},

		// Metrics
		{
			path: '/system-metrics/',
			name: 'system-metrics',
			component: () => import('../components/metrics/Main'),
			meta: { selectedId: 'metrics' },
			props: true,
		},

		// Misc
		{
			path: '/admin',
			redirect: process.env.ADMIN_URL,
		},
		{
			path: '/logout',
			name: 'logout',
			redirect: process.env.LOGOUT_URL,
		},
		{
			path: '/notimplemented',
			name: 'notimplemented',
			component: () => import('../components/NotImplemented'),
			meta: { selectedId: 'notimplemented' }
		},
		{
			path: '/loremipsum',
			name: 'loremipsum',
			component: () => import('../components/material/LoremIpsum'),
			meta: {selectedId: 'loremipsum'}
		},
		{
			path: '/python',
			name: 'python',
			component: () => import('../components/material/PythonScript'),
			meta: {selectedId: 'python'}
		},
		{
			path: '*',
			component: NotFound,
			meta: { selectedId: '404' }
		},

	]
})
