/** @type LanguageFn */
export default function nginxlog (hljs) {
	const regex = hljs.regex
	return {
		name: 'Nginx Log Output',
		contains: [
			hljs.QUOTE_STRING_MODE,

			{
				scope: 'strong',
				begin: /\[debug\]/,
				end: /$/,
				illegal: /\n/,
			},
			{
				scope: 'strong',
				begin: /\[info\]/,
				end: /$/,
				illegal: /\n/,
			},
			{
				scope: 'strong',
				begin: /\[notice\]/,
				end: /$/,
				illegal: /\n/,
			},

			{
				scope: 'addition',
				begin: /\[warn\]/,
				end: /$/,
				illegal: /\n/,
				// relevance: 5,

				// contains: [
				// 	hljs.QUOTE_STRING_MODE,
				// 	PATH,
				// ]
			},

			{
				scope: 'deletion',
				begin: /\[error\]/,
				end: /$/,
				illegal: /\n/,
			},
			{
				scope: 'deletion',
				begin: /\[crit\]/,
				end: /$/,
				illegal: /\n/,
			},
			{
				scope: 'deletion',
				begin: /\[alert\]/,
				end: /$/,
				illegal: /\n/,
			},
			{
				scope: 'deletion',
				begin: /\[emerg\]/,
				end: /$/,
				illegal: /\n/,
			},

			// PATH,
		]
	}
}
