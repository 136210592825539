<template>
	<div class="padded-control">
		<div v-if="!ready" v-show="!ready">
			<ProgressSpinner/>
		</div>
		<div v-else>
			<EditableList
				:add-text="addText"
				:addition-only="item.additionOnly === true"
				:hint-text="hintText"
				:items="value"
				:title-text="titleText"
				v-on:update:items="setValue($event)"
			/>
		</div>
	</div>
</template>

<script>

import EditableList from '../../common/EditableList'
import ProgressSpinner from '../../material/ProgressSpinner'

export default {
	components: {
		EditableList,
		ProgressSpinner,
	},
	props: [
		'id',
		'item',
	],
	data () {
		return {
			ready: false,
			customError: undefined,

			value: [],
			defaultValue: [],
			titleText: '',
			addText: 'Add',
			hintText: '',
		}
	},
	watch: {
		value (newValue) {
			// console.debug('FormEditableList.vue: value changed to', newValue)
			this.changed = Date.now()
			this.value = newValue
			if (this.item.valueHandler !== undefined) {
				this.$nextTick(() => {
					Promise.resolve(this.item.valueHandler(this.value, this)).then(newValue => {
						if (newValue !== undefined && this.value !== newValue) {
							this.value = newValue
						}
						if (this.customError !== undefined) {
							this.customError = undefined
						}
					}).catch((error) => {
						this.customError = error
					})
				})
			}
		},
	},
	computed: {
		name () {
			if (this.item.name !== undefined) {
				return this.item.name
			}
			return this.item.id
		},
	},
	created () {
		this.defaultPropsFromItem()
	},
	mounted () {
		this.defaultPropsFromItem()
		this.ready = true
		this.$nextTick(() => {
			this.defaultPropsFromItem()
			if (this.item.desiredValue !== undefined) {
				this.setValue(this.item.desiredValue)
			}
			this.$emit(
				'form-callback',
				{
					name: this.name,
					set: this.setValue,
					get: this.getValue,
					// disable: this.disableField,
					// enable: this.enableField,
					component: this.self,
				},
			)
		})
	},
	methods: {
		defaultPropsFromItem () {
			if (this.item.defaultValue !== undefined) {
				this.value = this.item.defaultValue
				this.defaultValue = this.item.defaultValue
			}
			if (this.item.titleText !== undefined) {
				this.titleText = this.item.titleText
			}
			if (this.item.addText !== undefined) {
				this.addText = this.item.addText
			}
			if (this.item.hintText !== undefined) {
				this.hintText = this.item.hintText
			}
		},
		setValue (value) {
			if (value !== this.value) {
				// console.debug('setValue', this.name, value, 'was:', this.value)
				this.$set(this, 'value', value)
			} else {
				// console.debug('setValue', this.name, value, ' << IGNORED')
			}
		},
		getValue () {
			return this.value
		},
		self () {
			return this
		},
		disableField () {
			console.debug('FormEditableList: disableField - not implemented')
		},
		enableField () {
			console.debug('FormEditableList: enableField - not implemented')
		},
	},
}

</script>

<style lang="scss" scoped>

.padded-control {
	padding: 0.5em 1em;
}

.padded-control .mdc-select {
	margin-bottom: 1em;
	width: 100%;
}

</style>
