<script>

import {mapGetters} from 'vuex'
import axios from 'axios'

import StorageMixin from './StorageMixin.vue'

const geoDataKey = 'pop-geo-data'

export default {
	mixins: [
		StorageMixin,
	],
	computed: {
		...mapGetters([
			'appConfig', // this getter returns a function
			'ssoCredential',
		]),

	},
	data () {
		return {
			loading: false,
			apiError: '',
		}
	},
	created () {
		// clean up old way of storing geo data
		this.clearStoredValues(geoDataKey)
	},
	methods: {

		// --------------------------------------------------------------------

		callProvisioningApi (url, config, quietly, withMeta) {
			if (quietly !== undefined && quietly === false) {
				this.loading = true
			}
			this.apiError = ''
			return new Promise((resolve, reject) => {
				if (!(typeof config === 'object' && config !== null)) {
					config = {method: 'GET'}
				}
				if (config.baseURL === undefined) {
					config.baseURL = this.appConfig('API_BASE_URL') + 'api/'
				}
				if (config.withCredentials === undefined) {
					config.withCredentials = true
				}
				config.url = url
				return axios.request(
					config
				).then(response => {
					// console.debug(response)
					if (response.data.jsonapi !== undefined && response.data.errors === undefined) {
						if (response.data.data !== undefined) {
							if (withMeta === true) {
								return resolve({
									meta: response.data.meta,
									data: response.data.data,
								})
							} else {
								return resolve(response.data.data)
							}
						} else {
							if (withMeta === true) {
								return resolve({
									meta: undefined,
									data: undefined,
								})
							} else {
								return resolve(undefined)
							}
						}
					}
					console.debug('Invalid API response', response)
					let error = new Error('Invalid API response')
					error.response = response
					throw error

				}).catch(error => {
					// console.debug(error)
					if (error.response === undefined) {
						this.apiError = error
					} else {
						// The request was made and the server responded with a
						// status code that falls out of the range of 2xx.
						let message = 'API: ' + error.response.status
						// noinspection PointlessBooleanExpressionJS
						if (error !== undefined
							&& error.response.data !== undefined
							&& error.response.data.jsonapi !== undefined
							&& error.response.data.errors !== undefined
						) {
							error = JSON.parse(JSON.stringify(error.response.data.errors))
							if (Array.isArray(error) && error.length === 1) {
								error = error[0]
							}
						}
						this.apiError = message + ': ' + JSON.stringify(error, null, 2)
					}
					console.error(this.apiError)
					return reject(this.apiError)
				}).finally(() => {
					if (quietly !== undefined && quietly === false) {
						this.loading = false
					}
				})

			}) // end new promise

		},

		// --------------------------------------------------------------------

		callProvisioningApiForLatestAgentVersion () {
			return new Promise((resolve, reject) => {
				axios.get(
					this.appConfig('API_BASE_URL') + 'agents/dist/provisioning-agent.json',
					{withCredentials: true}
				).then((response) => {
					// console.debug('latest agent version', response.data.version)
					resolve(response.data)
				}).catch((error) => {
					if (error !== undefined && error.response !== undefined) {
						reject(error.response.data)
					} else {
						reject(error)
					}
				})
			})
		},

		// --------------------------------------------------------------------

		callProvisioningApiForGeoData () { // previously returned a promise
			console.error(
				'DEPRECATED',
				'callProvisioningApiForGeoData',
				'use downloadAirportFromProvisioningApi instead'
			)
			return Promise.reject('method deprecated')
		},
		lookupGeoData () { // previously returned an object
			console.error(
				'DEPRECATED',
				'lookupGeoData',
				'use lookupAirportAttributes instead'
			)
			return undefined
		},

	}
}

</script>
