<script>

import {mapActions, mapGetters} from 'vuex'

import CacheWatchMixin from './CacheWatchMixin.vue'
import ProvisioningAPI from './ProvisioningAPI'
import QueueProcessorMixin from './QueueProcessorMixin'

const provApiDataType = 'v1-pubapi-service'
const provApiServiceItemKeyPrefix = 'provapi-' + provApiDataType + '-'
const provApiServiceItemTags = [
	'provapi',
	'provapi-' + provApiDataType,
]

const twentyFourHoursInSeconds = 86400 // one day

export default {
	name: 'CachedProvServicesMixin',
	mixins: [
		CacheWatchMixin,
		ProvisioningAPI,
		QueueProcessorMixin,
	],
	data () {
		return {
			cacheServiceIds: [],
			cacheIgnoredServiceIds: [],
		}
	},
	computed: {
		...mapGetters('cache', [
			'load', // this getter returns a function
		]),
		cacheRefreshBeforeTimestamp () {
			return Math.floor(Date.now() / 1000) - twentyFourHoursInSeconds
		}
	},
	methods: {
		...mapActions('cache', [
			'store',
		]),

		watchServiceId (serviceId) {
			return this.watchCachedItem(provApiServiceItemKeyPrefix + serviceId)
		},
		getCachedService (serviceId) {
			let item = this.getCachedItem(provApiServiceItemKeyPrefix + serviceId)
			if (item === undefined) {
				return undefined
			}
			if (item.stored === undefined || item.stored < this.cacheRefreshBeforeTimestamp) {
				this.queueAdd(
					provApiServiceItemKeyPrefix + serviceId,
					this.cachedServicesQueueWorker
				)
			}
			return item.value
		},
		setCachedService (service) {
			if (service === undefined) {
				return
			}
			this.store({
				key: provApiServiceItemKeyPrefix + service.id,
				tags: provApiServiceItemTags,
				value: service,
			})
		},
		deleteCachedService (serviceId) {
			this.store({
				key: provApiServiceItemKeyPrefix + serviceId,
				value: null,
			})
		},

		loadServicesIntoCache () {
			for (let index = 0; index < this.cacheServiceIds.length; index++) {
				let serviceId = this.cacheServiceIds[index]
				if (!this.hasValue(serviceId)) {
					continue
				}

				// check if ignored
				if (this.cacheIgnoredServiceIds.includes(serviceId)) {
					continue
				}

				// check if already cached
				if (this.getCachedService(serviceId) !== undefined) {
					continue
				}

				// add to queue (to populate the cache)
				this.queueAdd(
					provApiServiceItemKeyPrefix + serviceId,
					this.cachedServicesQueueWorker
				)
			}
			return this.queueProcess()
		},
		cachedServicesQueueWorker (serviceId) {
			serviceId = serviceId.substr(provApiServiceItemKeyPrefix.length)
			return this.load(provApiServiceItemKeyPrefix + serviceId).then(item => {
				if (item === undefined ||
					item.stored === undefined ||
					item.stored < this.cacheRefreshBeforeTimestamp
				) {
					return this.downloadServiceFromProvisioningApi(serviceId)
				}
			}).catch(error => {
				console.warn('CachedProvServicesMixin:', 'queueWorker:', 'error:', error)
				return this.downloadServiceFromProvisioningApi(serviceId)
			})
		},
		downloadServiceFromProvisioningApi (serviceId) {
			// console.debug('CachedProvServicesMixin:', 'downloading service', serviceId)
			return this.callProvisioningApi('services/' + serviceId + '/').then(apiResponse => {
				apiResponse.forEach(service => {
					if (service.type === provApiDataType) {
						this.setCachedService(service)
					}
				})
			}).catch(error => {
				if (String(error).indexOf('404') > -1) {
					// console.debug('CachedProvServicesMixin:', serviceId, 'not found (deleted?)')
					this.deleteCachedService(serviceId)
				}

				// Stop from repeatedly trying to load this service (and annoying the API too much)
				// NB. this only persists until the component reloads
				console.log('CachedProvServicesMixin:', 'ignoring service', serviceId, 'due to error', error)
				this.cacheIgnoredServiceIds.push(serviceId)
			})
		},

		makeServieDataShallow (service) {
			if (!this.hasValue(service) || typeof (service) !== 'object') {
				return undefined
			}
			return {
				id: service.id,
				type: service.type,
				modified: service.modified,
				attributes: {
					uid: service.attributes.uid,
					account: service.attributes.account,
					service: service.attributes.service,
					name: service.attributes.name,
					status: service.attributes.status,
					created: service.attributes.created,
					updated: service.attributes.updated,
				}
			}
		},

	}
}

</script>
