<template>
	<div class="tree-list">
		<div v-show="treeData !== undefined && treeData.length > 0"
			 class="scrolling-wrapper"
		>
			<div v-for="n in numberLists"
				 v-bind:id="htmlScrollId(n)"
				 class="scrolling-item"
				 v-on:mouseenter="handleScroll(n)"
				 v-on:scroll="handleScroll(n)"
			>
				<div class="max-width scroll-button scroll-button-top scroll-button-hidden" style="top: 0;">
					<button class="mdc-button max-width" v-on:click="scrollUp(n)">
						<div class="mdc-button__ripple"></div>
						<i aria-hidden="true" class="material-icons mdc-button__icon">expand_less</i>
						<span class="mdc-button__label"></span>
					</button>
				</div>
				<div>
					<TreeListInner
						v-bind:listData="dataForList(n)"
						v-bind:listHeight="listHeight"
						v-on:tree-list-click="clickHandler(n, $event)"
					/>
				</div>
				<div class="max-width scroll-button scroll-button-bottom scroll-button-hidden" style="bottom: 0;">
					<button class="mdc-button max-width" v-on:click="scrollDown(n)">
						<div class="mdc-button__ripple"></div>
						<i aria-hidden="true" class="material-icons mdc-button__icon">expand_more</i>
						<span class="mdc-button__label"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Vue from 'vue'
import TreeListInner from './TreeListInner'

const itemHeight = 74
const itemWidth = 240

export default {
	components: {
		TreeListInner
	},
	props: [
		'treeData',
		'parentSelected'
	],
	data () {
		return {
			uid: undefined,
			selected: [],
			positions: [],
			intref: undefined,
		}
	},
	watch: {
		parentSelected (value) {
			// console.debug('parentSelected changed', value)
			if (value !== undefined && value.length >= 2) {
				this.clickHandler(1, value)
			} else {
				Vue.nextTick(() => {
					this.clickHandler(1, this.selected[0])
				})
			}
		},
		// treeData(value) {
		//     console.debug('treeData changed');
		// },
	},
	computed: {
		maxNumberLists () {
			return this.countDepth(this.treeData) - 1
		},
		numberLists () {
			if (this.treeData === undefined) {
				// console.debug("numberLists", "tree=" + undefined)
				return 0
			}
			if (this.selected.length === 0) {
				// console.debug("numberLists", "selected=" + this.selected)
				return 1
			}

			let list = this.selectedSublistFor(this.selected.length)

			// if (list !== undefined) {
			//     console.debug("numberLists", "selected=" + this.selected.length, "list=" + list.length)
			// } else {
			//     console.debug("numberLists", "selected=" + this.selected.length, "list=" + undefined)
			// }

			if (list !== undefined && list.length >= 1) {
				return this.selected.length + 1
			} else {
				return this.selected.length
			}
		},
		maxListItems () {
			if (this.treeData === undefined) {
				return 0
			}
			return this.findMaxItems(this.treeData)
		},
		listHeight () {
			return this.maxListItems * itemHeight + 'px'
		}
	},
	created () {
		this.uid = (new Date()).getTime()
	},
	mounted () {
		// this.intref = setInterval(this.refreshScroll, 1000);
		Vue.nextTick(this.refreshScroll)
	},
	updated () {
		// this.refreshScroll();
		Vue.nextTick(this.refreshScroll)
	},
	beforeDestroy () {
		if (this.intref !== undefined) {
			clearInterval(this.intref)
		}
	},
	methods: {
		countDepth (list) {
			let self = this
			if (list === undefined) {
				return 0
			}
			let depth = 0
			list.forEach(item => {
				if (item.list !== undefined) {
					let itemDepth = self.countDepth(item.list)
					if (itemDepth > depth) {
						depth = itemDepth
					}
				}
			})
			return depth + 1
		},
		findMaxItems (list) {
			let self = this
			if (list === undefined) {
				return 0
			}
			let items = list.length
			list.forEach(item => {
				if (item.list !== undefined) {
					let itemItems = self.findMaxItems(item.list)
					if (itemItems > items) {
						items = itemItems
					}
				}
			})
			return items
		},
		clickHandler (n, id) {
			let self = this
			let i = n - 1
			// console.debug("selected", i, "=", id)
			Vue.set(this.selected, i, id)
			Vue.set(this, 'selected', this.selected.slice(0, i + 1))

			Vue.nextTick(function () {
				let scrollEl = self.$el.querySelector('.scrolling-wrapper')
				if (i <= 0) {
					scrollEl.scrollLeft = 0
				} else if (i > 0) {
					scrollEl.scrollLeft = itemWidth * i
				}
			})
			this.$emit('tree-list-click', this.selected)
		},
		selectedSublistFor (n) {
			let i = n - 1
			if (i < 0) {
				return undefined
			}
			let list = []
			if (i === 0) {
				list = this.treeData
			} else {
				list = this.selectedSublistFor(n - 1)
			}
			if (list === undefined) {
				return undefined
			}
			if (this.selected[i] !== undefined) {
				for (let j = 0; j < list.length; j++) {
					if (list[j].id === this.selected[i]) {
						return list[j].list
					}
				}
			}
			return undefined
		},
		dataForList (n) {
			if (this.treeData === undefined) {
				return undefined
			}
			let i = n - 1
			let list = []
			if (i === 0) {
				list = this.treeData
			} else {
				list = this.selectedSublistFor(n - 1)
			}
			if (list === undefined) {
				return undefined
			}
			let items = []
			for (let j = 0; j < list.length; j++) {
				let item = {
					id: list[j].id,
					icon: list[j].icon,
					title: list[j].title,
					text: list[j].text,
					active: list[j].id === this.selected[i],
					hidden: false,
				}
				if (list[j].hidden !== undefined && typeof list[j].hidden === 'boolean') {
					item.hidden = list[j].hidden
				}
				if (!item.hidden && list[j].active && this.selected[i] === undefined) {
					Vue.set(this.selected, i, list[j].id)
				}
				if (item.active && item.hidden) {
					item.active = false
					Vue.set(this.selected, i, undefined)
					Vue.set(this, 'selected', this.selected.slice(0, i))
				}
				items.push(item)
			}
			// console.debug("dataForList", n, items)
			return items
		},
		htmlScrollId (n) {
			return 'scrolling-item-' + this.uid + '-' + n
		},
		refreshScroll () {
			// console.debug('refreshScroll');
			for (let n = 1; n <= this.maxNumberLists; n++) {
				this.handleScroll(n)
			}
		},
		handleScroll (n) {
			// console.debug('handleScroll', n);

			let scrollEl = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (scrollEl === undefined || scrollEl === null) {
				return
			}
			let btnTop = scrollEl.querySelector('.scroll-button-top')
			let btnBottom = scrollEl.querySelector('.scroll-button-bottom')
			let pos = scrollEl.scrollTop
			let max = scrollEl.scrollHeight - scrollEl.clientHeight

			// keep the buttons in the correct place
			btnTop.style.top = pos + 'px'
			btnBottom.style.bottom = (-1 * pos) + 'px'

			// show/hide the up button
			if (pos <= 0) {
				btnTop.classList.add('scroll-button-hidden')
			} else {
				btnTop.classList.remove('scroll-button-hidden')
			}

			// show/hide the down button
			if (pos >= max) {
				btnBottom.classList.add('scroll-button-hidden')
			} else {
				btnBottom.classList.remove('scroll-button-hidden')
			}

			// console.debug("n=" + n, "pos=" + pos, "max=" + max, "top=" + btnTop.style.top, "bottom=" + btnBottom.style.bottom);
		},
		scrollUp (n) {
			let scrollEl = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (scrollEl === undefined || scrollEl === null) {
				return
			}
			scrollEl.scrollTop = scrollEl.scrollTop - itemHeight
		},
		scrollDown (n) {
			let scrollEl = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (scrollEl === undefined || scrollEl === null) {
				return
			}
			scrollEl.scrollTop = scrollEl.scrollTop + itemHeight
		},
	}
}

/*
	example: [
	{
		id: "one",
		icon: "person",
		title: "Title1",
		text: "text1",
		list: []
	},
	{
		id: "two",
		icon: "web",
		title: "Title2",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: [
					{
						id: "two",
						icon: "web",
						title: "Title2",
						text: "text2",
						list: [
							{
								id: "alpha",
								icon: "person",
								title: "Alpha",
								text: "text",
								list: []
							},
							{
								id: "bravo",
								icon: "person",
								title: "Bravo",
								text: "text",
								list: []
							},

						]
					},

				]
			},

		]
	},
	{
		id: "three",
		icon: "lock",
		title: "Title2",
		text: "text2",
		list: []
	},
	{
		id: "four",
		icon: "lock",
		title: "Four",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: []
			},
			{
				id: "charlie",
				icon: "person",
				title: "Charlie",
				text: "text",
				list: []
			},

		]
	},
	{
		id: "five",
		icon: "lock",
		title: "Five",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: []
			},
			{
				id: "charlie",
				icon: "person",
				title: "Charlie",
				text: "text",
				list: []
			},
			{
				id: "delta",
				icon: "person",
				title: "Delta",
				text: "text",
				list: []
			},
			{
				id: "Echo",
				icon: "person",
				title: "Echo",
				text: "text",
				list: []
			},
			{
				id: "foxtrot",
				icon: "person",
				title: "Foxtrot",
				text: "text",
				list: []
			},

		]
	},
],
 */

</script>

<style lang="scss">

.tree-list {
	border-left: 1px solid #e0e0e0;
}

.tree-list .scrolling-wrapper {
	position: relative;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	vertical-align: top;
}

.tree-list .scrolling-wrapper .scrolling-item {
	position: relative;
	vertical-align: top;
	display: inline-block;
	width: 240px;
	background-color: white;
	border: 1px solid #e0e0e0;
	border-left: 0;
	height: 60vh;
	overflow-x: hidden;
	overflow-y: scroll;
}

/* ----------------------------------------------------------------
   this makes the scrollbar completely invisible
   ---------------------------------------------------------------- */
.tree-list .scrolling-wrapper::-webkit-scrollbar, .tree-list .scrolling-wrapper .scrolling-item::-webkit-scrollbar {
	display: none;
}

.tree-list .scroll-button {
	background-color: white;
	opacity: 0.95;
	float: left;
	display: block;
	position: absolute;
	z-index: 5;
}

.tree-list .scroll-button-hidden {
	display: none;
	visibility: hidden;
}

.tree-list .scroll-button-top {
	top: 0;
}

.tree-list .scroll-button-bottom {
	bottom: 0;
}

</style>
