<template>
	<div class="text-input max-width">
		<div class="input-field max-width">
			<label
				:class="{
						'mdc-text-field': true,
						'mdc-text-field--filled': true,
						'max-width': true,
				}"
				data-mdc-auto-init="MDCTextField">
				<span class="mdc-text-field__ripple"></span>
				<span :id="htmlId" class="mdc-floating-label">{{ title }}</span>
				<input v-bind:id="htmlId"
					   v-model="contents"
					   :aria-labelledby="htmlId"
					   class="mdc-text-field__input"
					   type="number"
					   v-bind:max="max"
					   v-bind:min="min"
				>
				<span class="mdc-line-ripple"></span>
			</label>
			<div v-if="description !== undefined" class="mdc-text-field-helper-line">
				<div aria-hidden="true" class="mdc-text-field-helper-text">{{ description }}</div>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'NumberInput',
	props: [
		'title',
		'description',
		'value',
		'min',
		'max'
	],
	data () {
		return {
			contents: '',
		}
	},
	computed: {
		htmlId () {
			return 'app-input-text'
		},
	},
	watch: {
		contents (value) {
			this.$emit('update:value', value)
		},
		value (value) {
			if (this.value !== undefined && this.value !== null) {
				this.contents = this.value
			}
		},
	},
	created () {
		if (this.value !== undefined && this.value !== null) {
			this.contents = this.value
		}
	},
	mounted () {
		autoInit()  // Material Design Components
	},
	updated () {
		autoInit()  // Material Design Components
	},
}

</script>

<style lang="scss" scoped>

.text-input {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;
	align-items: flex-start;
}

.max-width {
	width: 100%;
}

.text-input .input-field {
	flex: 0 1 auto;
}

.text-input .mdc-text-field {
	background-color: white;
}

.text-input .mdc-text-field__input {
	text-align: left;
}

.text-input button[type=submit] {
	visibility: hidden;
	display: none;
}

</style>
