<template>
	<span>{{ renderedText }}</span>
</template>

<script>

// Moment
import * as moment from 'moment'

export default {
	name: 'NanoSince',
	props: [
		'nanotime',
	],
	data () {
		return {
			intervalHandler: undefined,
			renderedText: undefined,
		}
	},
	watch: {
		nanotime () {
			this.updateRenderedText()
		},
	},
	created () {
		this.updateRenderedText()
	},
	mounted () {
		this.setupInterval()
	},
	beforeDestroy () {
		this.stopInterval()
	},
	methods: {
		setupInterval () {
			this.stopInterval()
			this.intervalHandler = setInterval(
				this.updateRenderedText,
				175 // 175ms
			)
		},
		stopInterval () {
			if (this.intervalHandler !== undefined) {
				clearInterval(this.intervalHandler)
				this.intervalHandler = undefined
			}
		},
		updateRenderedText () {
			let end = moment.utc() // now
			let start = moment.unix(this.nanoToUnix(this.nanotime))
			let seconds = moment.duration(end.diff(start)).asSeconds()
			if (seconds >= 0.1) {
				this.renderedText = seconds.toFixed(3) + 's'
			} else {
				this.renderedText = ''
			}
		},
		nanoToUnix (timestamp) {
			const nanoSecondsToSeconds = ts => ts / 1000000000
			return Math.round(nanoSecondsToSeconds(timestamp))
		},
		// nanoToHuman (timestamp) {
		// 	return moment.unix(this.nanoToUnix(timestamp)).format('dddd, MMMM Do YYYY, HH:mm:ss') + ' UTC'
		// },

	}
}
</script>

<style>

</style>
