<script>

import {mapActions, mapState} from 'vuex'

export default {
	name: 'CacheWatchMixin',
	data () {
		return {
			cacheWatches: {}, // map of term to promise
		}
	},
	computed: {
		...mapState('cache', [
			'watchedItems',
		]),
	},
	beforeDestroy () {
		Object.keys(this.cacheWatches).forEach(term => {
			this.cacheUnwatch(term)
		})
	},
	methods: {
		...mapActions('cache', {
			'cacheWatch': 'watch',
			'cacheUnwatch': 'unwatch',
		}),

		watchCachedItem (term) { // returns a promise
			if (this.cacheWatches[term] === undefined) {
				this.cacheWatches[term] = this.cacheWatch(term)
			}
			return this.cacheWatches[term]
		},
		getCachedItem (key) {
			this.watchCachedItem(key)
			let item = this.watchedItems[key]
			if (item === undefined) {
				return undefined
			}
			return item
		},
		getCachedItemValue (key) {
			let item = this.getCachedItem(key) || {value: undefined}
			return item.value
		},
	}

}

</script>
