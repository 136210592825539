<template>
	<div class="tree-list-inner">
		<ul class="mdc-list mdc-list--two-line" data-mdc-auto-init="MDCList" role="listbox">
			<li v-for="item in listData" v-show="!item.hidden"
				class="mdc-list-item"
				v-bind:class="{ 'mdc-list-item--activated': item.active }"
				v-on:click="clickHandler(item)"
			>
				<i aria-hidden="true" class="material-icons mdc-list-item__graphic">{{ itemIcon(item) }}</i>
				<span class="mdc-list-item__ripple"></span>
				<span class="mdc-list-item__text">
                  <span class="mdc-list-item__primary-text">{{ item.title }}</span>
                  <span class="mdc-list-item__secondary-text"> {{ item.text }}</span>
                </span>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
	components: {},
	props: [
		'listData',
	],
	data () {
		return {}
	},
	methods: {
		clickHandler (item) {
			this.$emit('tree-list-click', item.id)
		},
		itemIcon (item) {
			if (item.hidden) {
				return 'visibility_off'
			}
			return item.icon
		}
	}
}

</script>

<style lang="scss">

.tree-list-inner {
	display: block;
	border-right: 1px solid #e0e0e0;
	margin-right: -1px;
}

.tree-list-inner ul {
	padding: 0;
}

.tree-list-inner .mdc-list-item {
	border-right: 1px solid #e0e0e0;
	padding: 1px 15px 1px 16px;
	background-color: white;
	margin-right: -1px;
}

.tree-list-inner .mdc-list-item--activated {
	border-right: 0;
	padding: 0 16px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
}

.tree-list-inner .mdc-list-item--activated:first-child {
	border-right: 0;
	padding: 1px 16px 0;
	border-top: 0;
	border-bottom: 1px solid #e0e0e0;
}

.tree-list-inner .mdc-list-item--activated:last-child {
	border-right: 0;
	padding: 0 16px 1px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 0;
}

</style>
