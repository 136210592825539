<template>
	<div aria-describedby="my-dialog-content"
		 aria-labelledby="my-dialog-title"
		 aria-modal="true"
		 class="mdc-dialog actions-dialog"
		 data-mdc-auto-init="MDCDialog"
		 role="alertdialog">
		<div class="mdc-dialog__container">
			<div id="actions-dialog-surface" class="mdc-dialog__surface"><!--
            Title cannot contain leading whitespace due to mdc-typography-baseline-top()
                --><h2 id="my-dialog-title" class="mdc-dialog__title"><!--
     -->{{ title }}<!--
   --></h2>
				<div id="my-dialog-content" class="mdc-dialog__content mdc-typography">

					<div v-show="message !== undefined && message.length > 0" class="message">
						<p v-for="line in messageLines">{{ line }}</p>
					</div>

					<ActionsButtons :actions="actions"/>

				</div>
				<footer class="mdc-dialog__actions">
					<button class="mdc-button mdc-dialog__button" data-mdc-dialog-action="close" type="button"
							v-on:click="$emit('closed', '')"
					>
						<span class="mdc-button__label">Close</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'
import ActionsButtons from './ActionsButtons'

export default {
	name: 'ActionsDialog',
	components: {
		ActionsButtons
	},
	props: [
		'title',
		'open',
		'message',
		'actions',
	],
	data: function () {
		return {
			'dialogElement': undefined,
			'dialog': undefined
		}
	},
	computed: {
		isOpen () {
			if (this.dialog === undefined) {
				return false
			}
			return this.dialog.isOpen
		},
		messageLines () {
			if (this.message === undefined) {
				return undefined
			}
			return this.message.split('\n')
		}
	},
	watch: {
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openDialog()
				} else {
					this.closeDialog()
				}
			}
			this.$emit('update:open', value)
		},
		dialog () {
			this.listenForEvents()
		}
	},
	mounted () {
		let self = this
		autoInit()  // Material Design Components
		this.$nextTick(() => {
			this.dialogElement = this.$el // this.$el.querySelector('.mdc-dialog')
			if (this.dialogElement !== undefined && this.dialogElement !== null) {
				this.dialog = this.dialogElement.MDCDialog
				this.listenForEvents()
			}
		})
	},
	updated () {
		autoInit()  // Material Design Components
		if (this.dialog !== undefined) {
			this.dialog.layout()
		}
	},
	methods: {
		listenForEvents () {
			this.dialog.listen('MDCDialog:opened', () => {
				this.dialogElement.setAttribute('aria-hidden', 'true')
				this.$emit('update:open', true)
			})
			this.dialog.listen('MDCDialog:closed', () => {
				this.dialogElement.removeAttribute('aria-hidden')
				this.$emit('update:open', false)
			})
		},
		openDialog () {
			if (!this.isOpen) {
				this.dialog.open()
			}
		},
		closeDialog () {
			if (this.isOpen) {
				this.dialog.close()
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.actions-dialog {
}

#actions-dialog-surface {
	width: 580px;
	max-width: 580px;
}

@media (min-width: 592px) {
	#actions-dialog-surface {
		width: 580px;
		max-width: 580px;
	}
}

.message {
	margin-bottom: 1em;
}

</style>
