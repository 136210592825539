<template>
	<div id="keys-dialog"
		 aria-describedby="my-dialog-content"
		 aria-labelledby="my-dialog-title"
		 aria-modal="true"
		 class="mdc-dialog keys-dialog"
		 data-mdc-auto-init="MDCDialog"
		 role="alertdialog">
		<div class="mdc-dialog__container">
			<div id="keys-dialog-surface" class="mdc-dialog__surface keys-dialog-surface"><!--
            Title cannot contain leading whitespace due to mdc-typography-baseline-top()
                --><h2 id="my-dialog-title" class="mdc-dialog__title"><!--
     -->{{ title }}<!--
   --></h2>
				<div id="my-dialog-content" class="mdc-dialog__content mdc-typography">
					<template v-for="data in keysdata">
						<h3 class="mdc-typography--subtitle1">{{ data.title }}</h3>
						<pre :class="{'pre-with-word-wrap': wordwrap === true }">{{ data.key }}</pre>
					</template>
					<template v-if="showspinner">
						<ProgressSpinner/>
					</template>
				</div>
				<footer v-show="showclose" class="mdc-dialog__actions">
					<button class="mdc-button mdc-dialog__button" type="button" @click="closeDialog">
						<span class="mdc-button__label">Close</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'
import ProgressSpinner from '../material/ProgressSpinner.vue'

export default {
	components: {ProgressSpinner},
	props: [
		'title',
		'open',
		'keysdata',
		'showclose',
		'wordwrap',
		'showspinner',
	],
	data: function () {
		return {
			'dialog': undefined
		}
	},
	computed: {
		isOpen () {
			return this.dialog.isOpen
		}
	},
	watch: {
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openDialog()
				} else {
					this.closeDialog()
				}
			}
			this.$emit('update:open', value)
			if (!value) {
				this.$emit('closed', '')
			}
		}
	},
	mounted () {
		autoInit()  // Material Design Components
		this.dialog = this.$el.MDCDialog
		this.dialog.listen('MDCDialog:opened', () => {
			this.$el.setAttribute('aria-hidden', 'true')
			this.$emit('update:open', true)
		})
		this.dialog.listen('MDCDialog:closed', () => {
			this.$el.removeAttribute('aria-hidden')
			this.$emit('update:open', false)
		})
	},
	updated () {
		autoInit()  // Material Design Components
		this.dialog.layout()
	},
	methods: {
		openDialog () {
			if (!this.isOpen) {
				this.dialog.open()
			}
		},
		closeDialog () {
			if (this.isOpen) {
				this.dialog.close()
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.keys-dialog-surface {
	min-width: 30vw;
	max-width: 90vw;
	//min-height: 30vh;
	//max-height: 90vh;
}

</style>
