<template>
	<div :class="{ 'indented': indent === true }">
		<hr :class="{ 'mdc-list-divider': true, 'indented-divider': indent === true }">
	</div>
</template>

<script>

export default {
	props: [
		'id',
		'indent',
	],
}

</script>

<style lang="scss" scoped>

.indented {
	padding-left: 0 !important;
	margin: 2px 8px 0;
}

.mdc-drawer__content .indented-divider {
	padding: 0;
	margin-top: 4px;
	margin-bottom: 4px;
	border-color: ghostwhite;
}

</style>
