<template>
	<div class="results mdc-typography">
		<div v-if="!loading">
			<button v-show="hasValue(apiError)"
					class="mdc-button mdc-button--dense button-error button-right"
					@click.prevent="showApiError=!showApiError"
			>
				<i aria-hidden="true" class="material-icons mdc-button__icon">warning</i>
				<span class="mdc-button__label">API ERROR ({{ pollingCounter }})</span>
			</button>
		</div>
		<h2 class="mdc-typography--headline6 output-title">
			<span v-show="updated">Recently Updated</span>
			<span v-show="!updated">Recently Expired / Expiring Soon</span>
		</h2>

		<div v-if="controllable">
			<!-- Controls -->
			<div class="mdc-layout-grid">
				<div class="mdc-layout-grid__inner">
					<div class="mdc-layout-grid__cell--span-4">
						<div class="thirds">
							<div v-if="controlledInUse !== undefined">
								<YesNoEither
									:disabled="!canShowInUse"
									:value.sync="controlledInUse"
									either-text="Any"
									label="Linked"
									no-text="Not Used"
									yes-text="In Use"
								/>
							</div>
							<div v-if="controlledArchived !== undefined">
								<YesNoEither
									:disabled="!canShowArchived"
									:value.sync="controlledArchived"
									either-text="Any"
									label="Archived"
									no-text="Not Archived"
									yes-text="Archived"
								/>
							</div>
							<div v-if="controlledExpired !== undefined">
								<YesNoEither
									:value.sync="controlledExpired"
									either-text="Any"
									label="Expired"
									no-text="Not Expired"
									yes-text="Expired"
								/>
							</div>
						</div>
					</div>
					<div class="mdc-layout-grid__cell--span-3">
						<NumberInput
							:description="numberDaysDescription"
							:max="maxNumberDays"
							:min="0"
							:value.sync="controlledNumberDays"
							title="Number Days"
						/>
					</div>
					<div class="mdc-layout-grid__cell--span-3">
						<NumberInput
							:max="maxMaxResults"
							:min="1"
							:value.sync="controlledMaxResults"
							title="Max Results"
						/>
					</div>
					<div class="mdc-layout-grid__cell--span-2">
						<ProgressSpinner v-show="loading"/>
					</div>
				</div>
			</div>
		</div>

		<div v-if="hasValue(apiError) && showApiError">
			<div class="error-api-box">
				<h3 class="mdc-typography--headline5">API Error</h3>
				<pre>{{ asString(apiError) }}</pre>

				<button class="mdc-button mdc-button--dense mdc-button--raised"
						v-if="controllable"
						@click.prevent="retryLoading"
				>
					<div class="mdc-button__ripple"></div>
					<i aria-hidden="true" class="material-icons mdc-button__icon">refresh</i>
					<span class="mdc-button__label">Refresh ({{ pollingCounter }})</span>
				</button>
			</div>
		</div>
		<div v-else>
			<div v-if="!controllable">
				<!-- Explanation -->
				<p v-show="updated">
					Showing up to {{ actualMaxResults }} certificates which were
					updated within the last {{ actualNumberDays }} days.
				</p>
				<p v-show="!updated">
					Showing up to {{ actualMaxResults }} certificates which have already
					expired, or will expire within the next {{ actualNumberDays }} days.
				</p>
			</div>

			<div v-if="hasLoaded && actualResult !== undefined">
				<component
					:is="tableComponent"
					:result="actualResult"
					:updated="updated"
					@open-hostnames-dialog="openHostnamesDialog"
				/>
			</div>
			<div v-else class="loading">
				<ProgressSpinner md-diameter="65"/>
			</div>
		</div>
		<div>
			<ListDialog
				:listdata="listdata"
				:open.sync="listopen"
				:showclose="listshowclose"
				:title="listtitle"
				@closed="listDialogClosed"
			/>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

import ListDialog from '../../common/ListDialog'
import NumberInput from '../../material/NumberInput'
import ProgressSpinner from '../../material/ProgressSpinner'
import Select from '../../material/Select'
import TableSummary from './TableSummary'
import TableDetailed from './TableDetailed'
import PublicAPI from '../../common/PublicAPI'
import ProvisioningAPI from '../../common/ProvisioningAPI'
import YesNoEither from './YesNoEither'

const oneSecond = 1000 // milliseconds

export default {
	name: 'CertificatesList',
	mixins: [
		PublicAPI,
		ProvisioningAPI
	],
	components: {
		ListDialog,
		NumberInput,
		ProgressSpinner,
		Select,
		TableSummary,
		TableDetailed,
		YesNoEither,
	},
	inject: [
		'userPresence'
	],
	props: [
		'updated',
		'numberDays',
		'maxResults',
		'tableMode',
		'controllable',
	],
	data () {
		return {
			loading: false,
			refreshNeeded: false,

			maxMaxResults: 200,
			maxNumberDays: 750,

			controlledMaxResults: undefined,
			controlledNumberDays: undefined,

			controlledInUse: 'yes',
			controlledArchived: 'no',
			controlledExpired: 'either',

			lastMaxResults: undefined,

			result: undefined,

			listtitle: 'list',
			listopen: false,
			listdata: [],
			listshowclose: true,

			hasLoaded: false,
			showApiError: false,
			intervalId: undefined,
			pollingInterval: 60, // seconds
			pollingCounter: 60, // seconds
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
		tableComponent () {
			switch (this.tableMode) {
			case 'summary':
				return 'TableSummary'
			case 'detailed':
			default:
				return 'TableDetailed'
			}
		},
		numberDaysDescription () {
			if (this.updated) {
				return 'Update has occurred with this number of days (before now)'
			} else {
				return 'Expiry occurs before now plus this number of days'
			}
		},
		actualNumberDays () {
			let result = undefined
			if (this.hasValue(this.controlledNumberDays)) {
				result = this.asNumber(this.controlledNumberDays)
			}
			if (result === undefined && this.hasValue(this.numberDays)) {
				result = this.asNumber(this.numberDays)
			}
			if (result === undefined) {
				result = this.updated ? 14 : 45
			}
			if (result > this.maxNumberDays) {
				result = this.maxNumberDays
			}
			return result
		},
		actualMaxResults () {
			let result = undefined
			if (this.hasValue(this.controlledMaxResults)) {
				result = this.asNumber(this.controlledMaxResults)
				if (result !== undefined) {
					result = Math.ceil(result / 10) * 10
				}
			}
			if (result === undefined && this.hasValue(this.maxResults)) {
				result = this.asNumber(this.maxResults)
			}
			if (result === undefined) {
				result = 10
			}
			if (result > this.maxMaxResults) {
				result = this.maxMaxResults
			}
			return result
		},
		actualResult () {
			if (this.result === undefined) {
				return undefined
			}
			return this.result.slice(0, this.actualMaxResults)
		},

		canShowInUse () {
			return this.controlledArchived !== 'yes'
		},
		canShowArchived () {
			return this.controlledInUse !== 'yes'
		},
	},
	watch: {
		controlledMaxResults () {
			this.$nextTick(this.storeState)
			if (this.controlledMaxResults > this.lastMaxResults) {
				this.refreshNeeded = true
			}
		},
		controlledNumberDays () {
			this.$nextTick(this.storeState)
			this.refreshNeeded = true
		},
		controlledArchived () {
			if (this.controlledArchived === 'yes') {
				this.controlledInUse = 'no'
			}
			this.$nextTick(this.storeState)
			this.refreshNeeded = true
		},
		controlledInUse () {
			if (this.controlledInUse === 'yes') {
				this.controlledArchived = 'no'
			}
			this.$nextTick(this.storeState)
			this.refreshNeeded = true
		},
		controlledExpired () {
			this.$nextTick(this.storeState)
			this.refreshNeeded = true
		},
		refreshNeeded () {
			this.$nextTick(this.loadCertificates)
		},
		loading (value) {
			if (value === false) {
				this.hasLoaded = true
			}
		},
		hasLoaded (value) {
			if (value === true) {
				this.loading = false
			}
		},
		'userPresence.active' (active) {
			if (active) {
				console.debug('user is active - polling for updates - ', new Date())
				this.pollRestApi()
			} else {
				console.debug('user has become inactive - disabling polling - ', new Date())
				this.stopPollingRestApi()
				this.pollingCounter = 5 // refresh 5 seconds after user becomes active again
			}
		},
	},
	created () {
		if (this.controllable) {
			this.controlledMaxResults = this.actualMaxResults
			this.controlledNumberDays = this.actualNumberDays
			this.loadState()
		}
	},
	mounted () {
		this.$nextTick(this.loadCertificates)
	},
	beforeDestroy () {
		this.stopPollingRestApi()
	},
	methods: {
		hasValue (variable) {
			return variable !== undefined && variable !== null && variable !== ''
		},
		asNumber (variable) {
			variable = parseInt(variable)
			if (!this.hasValue(variable) || Number.isNaN(variable)) {
				variable = undefined
			}
			return variable
		},
		loadState () {
			if (this.controllable) {
				// let storedValue = JSON.parse(localStorage.getItem('cachefly-certificates-list'))
				// if (storedValue !== undefined && storedValue !== null) {
				// 	if (this.hasValue(storedValue.maxResults)) {
				// 		this.controlledMaxResults = storedValue.maxResults
				// 	}
				// 	if (this.hasValue(storedValue.numberDays)) {
				// 		this.controlledNumberDays = storedValue.numberDays
				// 	}
				// 	if (this.hasValue(storedValue.archived)) {
				// 		this.controlledArchived = storedValue.archived
				// 	}
				// }
			}
		},
		storeState () {
			if (this.controllable) {
				// localStorage.setItem('cachefly-certificates-list',
				// 	JSON.stringify({
				// 		'maxResults': this.actualMaxResults,
				// 		'numberDays': this.actualNumberDays,
				// 		'archived': this.actualArchived,
				// 	}),
				// )
			}
		},
		retryLoading () {
			this.stopPollingRestApi()
			this.hasLoaded = false
			this.apiError = undefined
			setTimeout(() => {
				this.refreshNeeded = true
			}, oneSecond)
		},
		loadCertificates () {

			let url = 'certificates/?'

			switch (this.controlledInUse) {
			case 'yes':
				url += '&inuse=true'
				break
			case 'no':
				url += '&inuse=false'
				break
			}
			switch (this.controlledExpired) {
			case 'yes':
				url += '&expired=true'
				break
			case 'no':
				url += '&expired=false'
				break
			}
			switch (this.controlledArchived) {
			case 'yes':
				url += '&archived=true'
				break
			case 'no':
				url += '&archived=false'
				break
			}

			this.lastMaxResults = this.actualMaxResults
			url += '&max=' + this.lastMaxResults

			if (this.updated) {
				url += '&updated=' + this.actualNumberDays
			} else {
				url += '&expires=' + this.actualNumberDays
			}

			url = url.replace('?&', '?')

			const cachedDataKey = 'cachefly-certificates-list-' + btoa(url)
			if (!this.controllable) {
				// serve stale and load fresh data
				let cachedData = sessionStorage.getItem(cachedDataKey)
				if (cachedData !== null) {
					this.result = JSON.parse(cachedData)
					this.hasLoaded = true
					this.refreshNeeded = true
				}
			}

			if (this.loading) {
				return Promise.reject('already loading')
			}

			let quietly = this.hasLoaded
			if (!quietly) {
				this.loading = true
			}

			this.refreshNeeded = false
			return this.callProvisioningApi(url, null, quietly).then(data => {
				if (data === undefined) {
					this.result = []
				} else {
					this.result = data
					if (!this.controllable) {
						sessionStorage.setItem(cachedDataKey, JSON.stringify(data))
					}
				}

			}).catch(error => {
				console.error(error)
				if (!this.hasLoaded || this.controllable) {
					this.showApiError = true
				}

			}).finally(() => {
				this.pollingCounter = this.pollingInterval

				if (this.refreshNeeded) {
					setTimeout(this.loadCertificates, 10)

				} else {
					if (this.intervalId === undefined) {
						this.pollRestApi()
					}

				}
			})

		},

		listDialogClosed (event) {
			this.$set(this, 'listdata', [])
		},

		certHostnames (item) {
			if (this.hasValue(item)) {
				if (this.hasValue(item.attributes.hostnames)) {
					return item.attributes.hostnames
				}
			}
			return []
		},
		linkedHostnames (item) {
			if (this.hasValue(item)) {
				if (this.hasValue(item.attributes.links)) {
					if (this.hasValue(item.attributes.links.hostnames)) {
						return item.attributes.links.hostnames
					}
				}
			}
			return []
		},
		openHostnamesDialog (item, showCert, showLinked) {

			// ICONS
			//  star            Wildcard Linked
			//  star_border     Wildcard Unlinked
			//  link    	    Linked
			//  link_off        Unlinked
			//  add_link		Linked (via wildcard)

			// console.debug('openHostnamesDialog', item)

			let listdata = []

			let explicit = this.certHostnames(item)
			let linked = this.linkedHostnames(item)
			let isLinked = (hostname) => {
				return linked.includes(hostname)
			}
			let isExplicit = (hostname) => {
				return explicit.includes(hostname)
			}

			let hostnames = []
			if (showCert && showLinked) {
				hostnames = explicit.concat(linked)
				hostnames = hostnames.filter((value, index, self) => {
					return self.indexOf(value) === index
				})
			} else if (showLinked) {
				hostnames = linked
			} else {
				hostnames = explicit
			}
			hostnames.sort()

			hostnames.forEach(hostname => {
				let icon = 'error'
				let text = 'Unknown (please report this to dev)'
				if (isExplicit(hostname)) {
					if (hostname.startsWith('*')) {
						if (isLinked(hostname)) {
							text = 'Cert, Wildcard, Linked'
							icon = 'star'
						} else {
							text = 'Cert, Wildcard'
							icon = 'star_border'
						}
					} else {
						if (isLinked(hostname)) {
							text = 'Cert, Linked'
							icon = 'link'
						} else {
							text = 'Cert'
							icon = 'link_off'
						}
					}
				} else {
					if (isLinked(hostname)) {
						text = 'Linked (via wildcard)'
						icon = 'add_link'
					}
				}
				if (hostname === item.attributes['common-name']) {
					text += ', Common Name'
				}
				listdata.push({
					key: 'hostname-' + hostname,
					hidden: false,
					active: false,
					icon: icon,
					title: hostname,
					text: text,
					callback: () => {
						// console.debug('clicked hostname: ' + hostname)
						this.$router.push({path: this.hostnameDetailsUrl(hostname)})
					},
				})
			})

			let title = ''
			if (showCert && showLinked) {
				title = 'All Hostnames'
			} else if (showCert) {
				title = 'Cert Hostnames'
			} else if (showLinked) {
				title = 'Linked Hostnames'
			}
			title += ' (cert: ' + explicit.length + ', linked: ' + linked.length + ')'

			this.$set(this, 'listtitle', title)
			this.$set(this, 'listdata', listdata)
			this.$nextTick(() => {
				this.$set(this, 'listopen', true)
			})
		},

		hostnameDetailsUrl (hostname) {
			return this.appConfig('BASE_URL') + 'config/hostname/' + hostname + '/'
		},

		stopPollingRestApi () {
			if (this.intervalId !== undefined) {
				clearInterval(this.intervalId)
				this.intervalId = undefined
			}
		},
		pollRestApi () {
			this.stopPollingRestApi()
			this.intervalId = setInterval(() => {
				if (this.pollingCounter <= 0) {
					if (this.userPresence.active === true) {
						this.pollingCounter = this.pollingInterval
						this.$nextTick(this.loadCertificates)
					}
				} else {
					this.pollingCounter--
				}
			}, oneSecond)
		},

		asString (value) {
			if (typeof value === 'string' || value instanceof String) {
				return value
			}
			return JSON.stringify(value, null, 2)
		},

	} // end methods
}

</script>

<style lang="scss" scoped>

.results .output-title {
	display: flex;
	flex-direction: row;
}

.thirds {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	gap: 1.333333%;
}

.thirds div {
	flex: 0 0 32%;
}

.loading {
	margin: 2em;
	width: 100%;
	text-align: center;
}

.button-right {
	float: right;
}

.button-error {
	color: orangered;
	border-color: orangered;
}

.error-api-box {
	background-color: salmon;
	border: 5px darkred dashed;
	padding: 0.5em 1em;
	margin: 0;
	overflow: hidden;
}

.error-api-box pre {
	max-width: 70vw;
}

.error-api-box button {
	margin: 1em;
}

</style>
