<template>
	<div id="list-dialog"
		 aria-modal="true"
		 class="mdc-dialog"
		 data-mdc-auto-init="MDCDialog"
		 role="alertdialog">
		<div class="mdc-dialog__container">
			<div id="list-dialog-surface" class="mdc-dialog__surface"><!--
            Title cannot contain leading whitespace due to mdc-typography-baseline-top()
                --><h2 id="my-dialog-title" class="mdc-dialog__title"><!--
     -->{{ title }}<!--
   --></h2>
				<div id="my-dialog-content" class="mdc-dialog__content mdc-typography">
					<TwoLineList
						v-bind:listdata="listdata"
						@click="clickHandler"
					/>
				</div>
				<footer v-show="showclose" class="mdc-dialog__actions">
					<button class="mdc-button mdc-dialog__button" data-mdc-dialog-action="close" type="button"
							v-on:click="$emit('closed', '')"
					>
						<span class="mdc-button__label">Close</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'
import TwoLineList from '../material/TwoLineList.vue'

export default {
	components: {TwoLineList},
	props: [
		'title',
		'open',
		'listdata',
		'showclose'
	],
	data: function () {
		return {
			'dialog': undefined
		}
	},
	computed: {
		isOpen () {
			return this.dialog.isOpen
		}
	},
	watch: {
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openDialog()
				} else {
					this.closeDialog()
				}
			}
			this.$emit('update:open', value)
		}
	},
	mounted () {
		this.setup()
	},
	updated () {
		this.setup()
	},
	methods: {
		setup () {
			autoInit()  // Material Design Components
			if (this.dialog !== undefined) {
				this.dialog.layout()
				return
			}
			this.dialog = this.$el.MDCDialog
			this.dialog.listen('MDCDialog:opened', () => {
				this.$el.setAttribute('aria-hidden', 'true')
				this.$emit('update:open', true)
			})
			this.dialog.listen('MDCDialog:closed', () => {
				this.$el.removeAttribute('aria-hidden')
				this.$emit('update:open', false)
			})
			this.dialog.layout()
		},
		openDialog () {
			if (!this.isOpen) {
				this.dialog.open()
			}
		},
		closeDialog () {
			if (this.isOpen) {
				this.dialog.close()
			}
		},
		clickHandler (item) {
			if (item.callback !== undefined) {
				item.callback()
			} else {
				this.$emit('click', item)
			}
		},
	},
}
</script>

<style lang="scss">

#list-dialog-surface {
	width: 580px;
	max-width: 580px;
}

@media (min-width: 592px) {
	#list-dialog-surface {
		width: 580px;
		max-width: 580px;
	}
}

</style>
