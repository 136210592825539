<template>
	<div>
		<!-- This template corresponds to the mixin functionality -->
		<div>
			<component
				:is="interactiveComponent"
				:actions="actions"
				:message="dialogMessage"
				:open.sync="dialogOpen"
				:pending="actionPending"
				:title="dialogTitle"
				@update:open="dialogOpen = $event"
			/>
		</div>
		<div class="actions">
			<KeysDialog
				v-bind:keysdata="keysData"
				v-bind:open.sync="keysOpen"
				v-bind:showclose="keysShowClose"
				v-bind:title="keysTitle"
				v-on:closed="keysDialogClosed"
			/>
		</div>
		<div class="actions">
			<ConfirmDialog
				v-bind:open.sync="confirmDialogOpen"
				v-bind:question="confirmQuestion"
				v-bind:title="confirmTitle"
				v-on:cancelled="confirmDialogCancelled"
				v-on:closed="confirmDialogClosed"
				v-on:confirmed="confirmDialogConfirmed"
			/>
		</div>
		<div>
			<Actions
				:activeInterfaces="activeInterfaces"
				:agent="singularAgent"
				:agentFocus="agentFocus"
				:detailsUrlIcon="detailsUrlIcon"
				:detailsUrlLabel="detailsUrlLabel"
				:open="singularOpen"
				:request="singularRequest"
				:server="singularServer"
				:server-type="serverType"
				mode="dialog"
			/>
		</div>
	</div>
</template>

<script>

import ActionsBase from './ActionsBase'
import ActionsUIMixin from './ActionsUI'
import ConfirmDialog from '../common/ConfirmDialog'
import KeysDialog from '../common/KeysDialog'
import Actions from './Actions'

export default {
	name: 'GroupActions',
	extends: ActionsBase,
	mixins: [ActionsUIMixin],
	components: {
		Actions, // the singular version
		KeysDialog,
		ConfirmDialog,
	},
	props: [

		// list of servers to act on
		'servers',

		// list of agents to act on
		// 'agents',

		// agentFocus is true if we wish to act against the agent (not the server)
		'agentFocus',

		// the active interfaces for the given server type
		'activeInterfaces',

		// a callback which refreshes the server data
		'refreshCallback',

		// the action to perform
		'request',

		// the icon used for the details url
		'detailsUrlIcon',

		// the label used for the details url
		'detailsUrlLabel',
	],
	data: function () {
		return {
			singularServer: undefined,
			singularAgent: undefined,
			singularRequest: undefined,
			singularOpen: false,

			actionsRefreshHint: 0,
			actionsRefreshInterval: undefined,
			groupResults: []
		}
	},
	computed: {

		actions () {
			// this hint causes vue to update this value on a timer as well
			// as when our source data changes - we do this to reflect deep
			// changes which vue's reactivity can't normally watch.
			let usefulHint = this.actionsRefreshHint
			usefulHint++

			let potentialActions = []
			if (this.servers !== undefined) {
				this.servers.forEach(server => {
					this.availableActions(
						this.singularActionCallback,
						server,
						undefined,
						this.agentFocus,
						this.activeInterfaces, // interfaces
						undefined,
						undefined,
						undefined,
						this.refreshCallback !== undefined,
						this.actionPending
					).forEach(action => {
						potentialActions.push(action)
					})
				})
			}
			// if (this.agents !== undefined) {
			// 	this.agents.forEach(agent => {
			// 		const actions = this.availableActions(
			// 			this.singularActionCallback,
			// 			undefined,
			// 			agent,
			// 			true,
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			this.refreshCallback !== undefined,
			// 			this.actionPending
			// 		)
			// 		// console.debug('agent actions', agent, actions)
			// 		actions.forEach(action => {
			// 			potentialActions.push(action)
			// 		})
			// 	})
			// }
			potentialActions = potentialActions.filter((action, pos, self) => {
				return !action.disabled // ignore disabled actions
			})
			let targets = {}
			potentialActions = potentialActions.filter((action, pos, self) => {
				if (targets[action.id] === undefined) {
					targets[action.id] = []
				}
				targets[action.id].push(action.target)
				return self.findIndex(action2 => action.id === action2.id) === pos
			})
			potentialActions.forEach(action => {
				if (targets[action.id] !== undefined) {
					action.label += ' (' + targets[action.id].length + '/' + this.totalServers + ')'
					if (targets[action.id].length > 1) {
						action.callback = () => this.groupActionCallback(action.id, targets[action.id], {})
					}
				}
			})
			this.sortActions(potentialActions)
			return potentialActions
		},
		dialogTitle () {
			return 'Actions'
		},
		dialogMessage () {
			return '(' + this.totalServers + ' servers)'
		},
		totalServers () {
			let count = 0
			if (this.servers !== undefined) {
				count += this.servers.length
			}
			// if (this.agents !== undefined) {
			// 	count += this.agents.length
			// }
			return count
		},

	},
	watch: {
		actionPending (value) {
			if (this.servers !== undefined) {
				this.servers.forEach(server => {
					server.pending = value
				})
			}
			// if (this.agents !== undefined) {
			// 	this.agents.forEach(agent => {
			// 		agent.pending = value
			// 	})
			// }
		},
		request (value) {
			if (value !== undefined) {
				this.requestChanged(value)
			}
		},
		groupResults () {
			let keysData = []
			this.groupResults.forEach(meta => {
				if (!meta.hidden) {
					keysData.push({
						'title': meta.target,
						'key': meta.result
					})
				}
			})
			if (keysData.length === 0 && !this.actionPending && this.keysOpen) {
				keysData.push({
					'title': this.totalServers + ' servers',
					'key': 'Completed'
				})
			}
			this.keysData = keysData
		}
	},
	mounted () {
		this.actionsRefreshInterval = setInterval(this.refreshActions, 1000)
	},
	methods: {

		refreshActions () {
			if (this.totalServers > 0) {
				this.actionsRefreshHint++
			}
		},
		stopRefreshingActions () {
			if (this.actionsRefreshInterval !== undefined) {
				clearInterval(this.actionsRefreshInterval)
			}
		},

		////////////////////////////////////////////////////////////////////////

		// Allows for an action to be triggered through a prop
		requestChanged (value) {
			if (typeof value === 'object' && value !== null) {
				this.requestActionByName(value.action)
			}
		},

		// Allows for an action to be triggered by name
		requestActionByName (action) {
			this.actions.forEach(possibleAction => {
				if (possibleAction.id === action) {
					return possibleAction.callback()
				}
			})
		},

		// Triggers an action against a single server - here I delegate all
		// the work of to a private instance of the singular action handler,
		// so when only one server is triggered we get the same behaviour as
		// triggering it directly.
		singularActionCallback (server, agent, action) {
			this.singularServer = server
			this.singularAgent = agent
			if (action === 'details') {
				this.$nextTick(() => this.singularOpen = true)
			} else {
				this.$nextTick(() => this.singularRequest = {'action': action}) // This triggers the action
			}
		},

		// Triggers an action against multiple servers at the same time. This
		// is the whole point of this component.
		groupActionCallback (action, targets, additional = {}) {
			const capitalizeFirstLetter = (string) => {
				return string.charAt(0).toUpperCase() + string.slice(1)
			}
			if (action === 'details') {
				return // ignore impossible action
			}
			if (action === 'refresh') { // refresh is safe and should be low friction
				if (this.refreshCallback !== undefined) {
					// this should return a promise
					return this.refreshCallback()
				}
				return Promise.reject('no refresh callback')
			}
			if (action === 'ping') { // it's ping - just do it
				this.performGroupActionsWithUI(
					action,
					capitalizeFirstLetter(action) + ' ' + targets.length + ' servers',
					targets,
					additional,
				)
				return
			}
			let question = 'Perform ' + action + ' action against ' + targets.length + ' servers?'
			if (targets.length <= 5) {
				question += '\n' + targets.join('\n')
			}
			if (action === 'disable') {
				question += '\n(do not do this on an ' + this.serverType + ' server which is currently serving production traffic)'
			}
			if (action === 'delete' || action === 'deregister') {
				question += '\n(this is a destructive action - be sure you understand what this means before you confirm)'
			}
			this.requestActionWithUI(
				action,
				question,
				capitalizeFirstLetter(action) + ' ' + targets.length + ' servers',
				targets,
				additional,
			)
		},

		////////////////////////////////////////////////////////////////////////

		requestActionWithUI (action, question, title, targets, additional = {}) {
			this.setActionPending()
			this.openConfirmDialog(
				title,
				question,
				() => this.performGroupActionsWithUI(action, title, targets, additional),
				this.clearActionPending
			)
		},

		////////////////////////////////////////////////////////////////////////

		performGroupActionsWithUI (action, title, targets, additional = {}) {

			this.setActionPending()
			this.openKeysDialog(title, [], true)

			this.groupResults = []
			targets.forEach(target => {
				this.groupResults.push({
					action: action,
					target: target,
					pending: true,
					started: false,
					hidden: true,
					result: '',
				})
			})

			let numberPending = targets.length
			let numberRemaining = targets.length // not started yet
			let availableSlots = 3
			const getAvailableSlot = () => {
				return new Promise(resolve => {
					const check = () => {
						if (availableSlots > 0) {
							availableSlots--
							resolve()
						} else {
							setTimeout(check, 100)
						}
					}
					check()
				})
			}
			this.groupResults.forEach((meta, index) => {
				getAvailableSlot().then(() => {
					numberRemaining--
					meta.started = true
					meta.hidden = false
					this.$set(this.groupResults, index, meta)
					this.performActionWithUICallbacks(
						this.serverType,
						meta.target,
						meta.action,
						false,
						this.performAction, // perform
						this.refreshCallback, // refresh
						(message) => { // updateCallback
							console.debug('updateCallback', message, meta)
							meta.result = message
							this.$set(this.groupResults, index, meta)
						},
						() => { // completedCallback
							console.debug('completedCallback', meta)
							meta.pending = false
							this.$set(this.groupResults, index, meta)
							numberPending--
							if (numberPending <= 0) {
								this.clearActionPending()
								this.keysShowClose = true
							}
							setTimeout(() => {
								meta.hidden = String(meta.result).includes('successfully')
								this.$set(this.groupResults, index, meta)
								availableSlots++
							}, 750)
						},
						additional, // Additional fields for API call
					)
				})
			})

		}, // end performGroupActionsWithUI

		////////////////////////////////////////////////////////////////////////

	},
}

</script>

<style lang="scss" scoped>

.actions {
	text-align: left;
}

</style>
